import { useSelector } from "react-redux";

const useHuisstijl = () => {
    const {primaryColor, buttonTextColor, logoImageUrl } = useSelector(state => state.dealer.dealer);

    return {
        primaryColor,
        buttonTextColor,
        logoImageUrl,
    };
}

export default useHuisstijl;