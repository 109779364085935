import React from "react";
import useInputTransition from "hooks/use-input-transition";
import { getSelectOptions } from "lib/voertuig";

const Select = React.forwardRef((props, ref) => {
    const {focusHandler, blurHandler, transitionClasses, isFocussed } = useInputTransition(props.value);
    var { options, label, placeholder } = props;

    var refValue;
    if(ref) refValue = ref.current?.value || false;
    var focussedOrWithValue = (props.value || isFocussed || refValue);
    if(props.set) options = getSelectOptions(props.set);
    return (
        <div className={"flex flex-col relative "+(props.className ? props.className : "")}>
            <label className={"pl-3 py-1 absolute transition ease-in "+transitionClasses}>{label ? label : placeholder}</label>
            <select disabled={props.disabled} style={{color: (!isFocussed && !props.value && !refValue) && '#aaa'}} onFocus={focusHandler} onBlur={blurHandler} className={"lg:h-[50px] border border-slate-300 rounded-xl "+(focussedOrWithValue ? 'px-3 pb-1 pt-5': 'p-3')} name={props.name} ref={ref} onChange={props.onChange} value={props.value}>
                {!props.disableFirst && <option value="">{props.label && !isFocussed && label}</option>}
                {(options?.length) && options.map(option => {
                    return <option data-key={option.value ? option.value : option.label} key={option.value ? option.value : option.label} data-extra={option.extra && option.extra} value={option.value}>{option.label ? option.label : option.value}</option>
                })}
            </select>
        </div>
    )
});

export default Select;