import { Routes, Route, Outlet } from "react-router-dom";
import Crm from "Crm";
import Dashboard from "./dashboard";
import Dealers from "./dealers";
import Verkopen from "./verkopen";
import Contacts from "./customer";
import Partners from "./partners";
import Selectie from "./selectie";
import DealerDetail from "./dealers/dealer-detail";
import PartnerDetail from "./partners/partner-detail";
import CustomerDetail from "./customer/customer-detail";
import NieuweDealer from "./dealers/nieuwe-dealer";
import VerkoopDetail from "./verkopen/verkoop-detail";
import NieuwePartner from "./partners/nieuwe-partner";
import Gebruikers from "./gebruikers";
import GebruikerDetail from "./gebruikers/gebruiker-detail";
import RoleForm from "components/Forms/RoleForm/RoleForm";
import CalculatorSettings from "./calculator";
import ProtectedSuperAdminRoute from "./ProtectedSuperAdminRoute";
import Tokens from "./tokens";
import CrmPage from ".";
import Inbox from "./inbox";
import GetekendeOffertes from "./getekende-offertes";
import ContractAfronding from "./contract-afronding";
import Sales from "./sales";

const CrmRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Crm />}>
                <Route index element={<CrmPage />} />
                <Route path="dealers/" element={<Outlet />}>
                    <Route index element={<Dealers />} />
                    <Route path=":dealerID" element={<DealerDetail />} />
                    <Route path="nieuwe-dealer" element={<NieuweDealer />} />
                </Route>
                <Route path="inbox" element={<Inbox />} />
                {/* <Route path="uitslagen" element={<div>Uitslagen</div>} /> */}
                <Route path="getekende-offertes" element={<GetekendeOffertes />} />
                <Route path="contract-afronding" element={<ContractAfronding />} />
                <Route path="sales" element={<Sales />} />

                <Route path="notificaties" element={<div>Notificaties</div>} />

                <Route path="/dashboard" element={<Dashboard />} />

                <Route path="verkopen" element={<Outlet />}>
                    <Route index element={<Verkopen />} />
                    <Route path=":dealID" element={<VerkoopDetail />} /> 
                </Route>

                <Route path="klanten" element={<Outlet />}>
                    <Route index element={<Contacts />} />
                    <Route path=":customerID" element={<CustomerDetail />} />
                </Route>

                <Route path="partners" element={<Outlet />}>
                    <Route index element={<Partners />} />
                    <Route path=":partnerID" element={<PartnerDetail />} />
                    <Route path="nieuwe-partner" element={<NieuwePartner />} /> 
                </Route>
                <Route path="selectie" element={<Selectie />} />
                <Route path="gebruikers" element={<ProtectedSuperAdminRoute><Outlet /></ProtectedSuperAdminRoute>}>
                    <Route index element={<Gebruikers />} />
                    <Route path=":userID" element={<GebruikerDetail />} /> 
                </Route>


                <Route path="tokens" element={<ProtectedSuperAdminRoute><Tokens /></ProtectedSuperAdminRoute>}></Route>
                <Route path="rollen" element={<ProtectedSuperAdminRoute><RoleForm /></ProtectedSuperAdminRoute>} /> 


                <Route path="calculator" element={<ProtectedSuperAdminRoute><CalculatorSettings /></ProtectedSuperAdminRoute>} /> 
            </Route>
        </Routes>
    )
}

export default CrmRoutes;