import { useDispatch } from "react-redux";
import useTutorial from "hooks/use-tutorial";

const Tutorial = () => {
    return (
        <>
        {/* <div className="bg-orange fixed top-0 py-5 left-0 w-full z-10">x</div> */}
        <div className="fixed bg-black opacity-40 w-full h-full xz-10">

        </div>
        </>
    )
}

export default Tutorial;