import { useState } from "react";

const Accordion = (props) => {
    const [isOpen, setIsOpen] = useState(props.open);

    const toggleVisibilityHandler = () => {
        setIsOpen(prevState => !prevState);
    };

    return (
        <div onClick={props.onClick} className={(props.className ? props.className : '')+'  border-b border-slate-200 mb-3'}>
            <div className="group text-lg cursor-pointer flex flex-row justify-between py-2" onClick={toggleVisibilityHandler}>
                <div className="select-none">{props.summary}</div>
                <div className={(isOpen ? 'fa-square-minus' : 'fa-square-plus')+" fa text-slate-300 group-hover:text-slate-600"}></div>
            </div>
            {isOpen && <div className="">{props.children}</div>}
        </div>
    ) 
}

export default Accordion;