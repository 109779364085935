import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/use-auth";
import { serialize} from "lib/helpers";
import usePagination from "hooks/use-pagination";
import { uiActions } from "store/ui-slice";
import Pagination from "components/Navigation/Pagination";
import Zoekmachine from "components/Zoekmachine/Zoekmachine";

const KlantOverzicht = (props) => {
  const { token: jwt, role } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page, perpage, changePageSize, nextPageHandler, prevPageHandler, toFirstPageHandler } = usePagination();

  const [query, setQuery] = useState('');

  const [customers, setCustomers] = useState({
    pageNumber: page,
    pageSize: perpage,
    totalPages: "",
    succeeded: "",
    totalRecords: "",
    data: []
  });
  const { pageNumber, pageSize, totalRecords, data } = customers;

  useEffect(() => {
    var queryParams = "";
    if(query) queryParams = serialize(query);

    dispatch(uiActions.showBackdrop());
    var fetchUrl = process.env.REACT_APP_SLD_API_BASE_URL + '/api/Customer/Filters?'+(!/pageNumber/.test(queryParams) ? 'pageNumber='+page+'&' : '&')+(!/pageSize/.test(queryParams) ? 'pageSize='+perpage : '&')+(queryParams ? '&'+queryParams : '');
    fetchUrl = fetchUrl.replace(/\?&*/, "?");
    fetch(fetchUrl, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwt
      }
    })
    .then(resp => {
      // console.log(resp.status);
      if(resp.status===401) throw new Error("401");
      return resp.json();
    })
    .then(responseData => {
      if(/pageNumber/.test(queryParams)) {
        toFirstPageHandler(99999);
      }
      setCustomers(responseData);
      dispatch(uiActions.hideBackdrop());
    })
    .catch(err => {
      console.log(err.message);
      // if(err?.message==401) dispatch(userLogout());
      dispatch(uiActions.hideBackdrop());
    })
  },[perpage, page, query]);

  const initSearch = {    
    pageNumber: 1,
    pageSize: 9999,
    companyName: "",
    postalCode: "",
    customerId: "",
    chamberNumber: "",
    place: ""
}

  const [initialSearch, setInitialSearch] = useState(initSearch);

  const clearHandler = () => {
    setQuery("");
    changePageSize(25);
  }
  
  return (
    <div className="text-xs lg:text-base max-w-6xl">

    {(role.includes("Admin") && !props.phase) && (
          <>
          <Zoekmachine disableArchive={true} searchFor="customers" initialSearch={initialSearch} token={jwt} onSearch={inputQuery => setQuery(inputQuery)} onClear={clearHandler} perpage={perpage} onChangePageSize={changePageSize} />
          <div className="p-3">{totalRecords} resulta{totalRecords===1 ? 'at' : 'ten'}</div> 
            </>
          )}

      {data.length ? (
        <div>
            <div className="bg-sld-blue text-white rounded-xl p-3 mb-3 items-center grid grid-cols-2 md:grid-cols-12 gap-x-2">
              <div className="w-[50px]">ID</div>
              <div className="col-span-3">Bedrijfsnaam</div>
              <div className="col-span-2">Naam</div>
              <div className="col-span-4">E-mail</div>
              <div className="col-span-2">Telefoon</div>
            </div>
            {data.map((customer) => {
                return (
                  <div key={'k'+customer.customerID}  className={"grid grid-cols-2 md:grid-cols-12 gap-x-2 items-center hover:bg-[#F6901E] hover:text-white px-5 py-3 shadow-md bg-white rounded-lg mb-5 cursor-pointer"+(customer.isArchive ? ' opacity-50' : '')} onClick={() => navigate(""+customer.customerID)}>
                    <div className="w-[50px]">{customer.customerID}</div>
                    <div className="col-span-3">{customer.companyName}</div>
                    <div className="col-span-2">{customer.firstName} {customer.lastName}</div>
                    <div className="col-span-4">{customer.email}</div>
                    <div className="col-span-2">{customer.phone}</div>
                  </div>
                )
            })}
        </div>
      ) : (
        <p>Er zijn nog geen klanten</p>
      )}
    <Pagination page={page} prevPageHandler={prevPageHandler} nextPageHandler={nextPageHandler} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
    </div>
  );
};

export default KlantOverzicht;
