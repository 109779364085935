import Container from "components/hoc/Container";
import Content from "components/Content/Content";
import KlantOverzicht from "components/Tables/KlantOverzicht";

const Customers = () => {
    return (
        <>
            <Container type="fullscreen">
                <Content title="Klanten" description="Bekijk hier alle klanten." />
            </Container>
            <KlantOverzicht />
        </>            
    )
}

export default Customers;