import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "store/auth-actions";

const ProtectedRoute = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const { userIsLoggedIn, userInfo, token } = auth;

    useEffect(() => {
        if(!userInfo && token) {
            dispatch(getUserInfo(token));
        }
        if(!userIsLoggedIn) {
            navigate("/");
        }
    },[userIsLoggedIn]);

    if(!userIsLoggedIn) return <>Niet ingelogd!</>;
    return (
        <>{props.children}</>
    )
}

export default ProtectedRoute;