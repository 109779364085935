import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import Content from "components/Content/Content";
import Container from "components/hoc/Container";
import Actieblokken from "components/Actieblokken/Actieblokken";
import useHead from "hooks/use-head";
import { resetDossier } from "store/dossier-actions";
import useCurrentCase from "hooks/use-current-case";

const VerzekerenStap1 = (props) => {
    const dispatch = useDispatch();
    useHead({title: "Verzekering"});
    useCurrentCase();

    useEffect(() => {
        dispatch(resetDossier());
    },[]);

    return (
        <>
            <Container type="fullscreen">
                <Content title="Verzekering" />
            </Container>
            <Outlet />
            <Actieblokken type="verzekering" />
        </>
    )
}

export default VerzekerenStap1;