import React, { useEffect, useState } from "react";
import Input from "components/ui/Input/Input";

const SmartSearch = React.forwardRef((props, ref) => {
    const { options } = props;

    const [searchValue, setSearchValue] = useState("");
    const [selected, setSelected] = useState(props.value);

    const [orgResults, setOrgResults] = useState([]);
    const [results, setResults] = useState([]);
    const [isFocussed, setIsFocussed] = useState(false);

    useEffect(() => {
        setOrgResults(options);
        setResults(options);
    },[options, orgResults])

    useEffect(() => {
        if(!orgResults || !props.visibleValue) return;
        setSearchValue(""+props.visibleValue);
    },[orgResults, props.visibleValue]);

    useEffect(() => {
        if(!orgResults) return;
        var filteredResults = orgResults.filter(result => result.label.toLowerCase().includes(searchValue.toLowerCase()));       
        setResults(filteredResults);
    },[searchValue, orgResults]);

    const setActive = (id, label) => {
        setSelected(id);
        setSearchValue(label);
        if(typeof props.onChange==="function") props.onChange({name: props.name, value: id});
        if(typeof props.onChange2==="function") props.onChange2(null, {name: props.name, value: id});
        setIsFocussed(false);
    } 
    const focusHandler = () => {
        setSearchValue("");
        setSelected("");
        setIsFocussed(true)
    }

    const blurHandler = () => {
        setTimeout(function() {
            setIsFocussed(false);
        }, 250);
    }

    return (
        <div className="flex flex-col relative" onBlur={blurHandler}>
            <input type="hidden" ref={ref} value={selected} name={props.name} />
            {props.disabled ?
            <div className="text-white p-3">{searchValue}</div>
            :
            <Input readOnly={props.disabled} type="text" label={props.label} placeholder={props.placeholder} spellCheck="false" onChange={evt => setSearchValue(evt.target.value)} onFocus={focusHandler} value={searchValue} />
            }
            <div className={` ${(!isFocussed || props.disabled) && 'hidden'} bg-white w-full border-x rounded-b-xl border-slate-300 absolute top-full left-0 max-h-96 overflow-y-scroll z-20 shadow ${(isFocussed && results.length>0) ? 'min-h-[100px]' : ''}`}>
                {(results.length>0) && results.map(result => { 
                    const randomKey = Math.floor(Math.random() * 9999999);
                    return (
                        <div data-key={"random"+randomKey} key={"random"+randomKey} onClick={(id, label) => setActive(result.value, result.label)} className={`${selected===result.value && 'bg-orange text-white'} select-none flex flex-col gap-3 hover:bg-orange hover:text-white px-3 py-1 cursor-pointer border-b border-slate-100`}>
                            {result.label}
                        </div>
                    )
                })}
            </div>
        </div>
    )
});

export default SmartSearch;