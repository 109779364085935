import { useState, useRef } from "react";
import Input from "components/ui/Input/Input";
import Button from "components/ui/Button/Button";
import useAuth from "hooks/use-auth";
import { useDispatch } from "react-redux";
import { registerRole } from "store/auth-actions";

const RoleForm = () => {
    const dispatch = useDispatch();
    const [role, setRole] = useState('');
    const roleRef = useRef();
    const { token } = useAuth();

    const submitHandler = (evt) => {
        evt.preventDefault();
        const dataObj = {
            token: token,
            role: roleRef.current.value
        }
        dispatch(registerRole(dataObj));
    }
    
    return (
        <>
        <h1>Rollen</h1>
        <form onSubmit={submitHandler}>
            <Input ref={roleRef} value={role} placeholder="Naam van rol" onChange={(evt) => setRole(evt.target.value)} />
            <Button className="btn-primary mt-5">Aanmaken</Button>
        </form>
        </>
    )
}

export default RoleForm;