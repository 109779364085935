export const makes = [
    "ALPINE",
    "Abarth",
    "Aixam",
    "Alfa Romeo",
    "Alpina",
    "Aston Martin",
    "Audi",
    "BMW",
    "Bentley",
    "CAPRON",
    "CI",
    "CUPRA",
    "Cadillac",
    "Carado",
    "Cenntro",
    "Cfmoto",
    "Chevrolet",
    "Chrysler",
    "Citro&euml;n",
    "DS",
    "Dacia",
    "Dodge",
    "Ducati",
    "EZGO",
    "Ferrari",
    "Fiat",
    "Fleurette",
    "Ford",
    "Ford USA",
    "GMC",
    "Herrador",
    "Honda",
    "Hymer",
    "Hyundai",
    "Infiniti",
    "Isuzu",
    "Itineo",
    "Iveco",
    "Jaguar",
    "Jeep",
    "Karma",
    "Kia",
    "Lamborghini",
    "Lancia",
    "Land Rover",
    "Lexus",
    "Ligier",
    "Lincoln",
    "Lynk &amp; Co",
    "MAN",
    "MG",
    "MINI",
    "Maserati",
    "Mazda",
    "Mc Louis",
    "McLaren",
    "Mercedes-Benz",
    "Mitsubishi",
    "Nissan",
    "Opel",
    "Peugeot",
    "Polestar",
    "Porsche",
    "RAM",
    "ROLLER",
    "Renault",
    "Rolls-Royce",
    "Seat",
    "Seres",
    "Share Ngo",
    "Skoda",
    "Smart",
    "SsangYong",
    "Subaru",
    "Sun Living",
    "Suzuki",
    "Tesla",
    "Toyota",
    "Volkswagen",
    "Volvo",
    "Westfalia",
    "Winnebago",
    "zhidou"
];
export const models = [
    "01",
    "1 Serie",
    "108",
    "124 Spider",
    "1500",
    "2",
    "2 Serie",
    "20 x Transporter Kombi",
    "2008",
    "208",
    "3",
    "3 Serie",
    "3008",
    "308",
    "4",
    "4 Serie",
    "418 Gran Coup&eacute;",
    "488",
    "5",
    "5 Serie",
    "500",
    "500 C",
    "5008",
    "500C",
    "500E CABRIO",
    "500L",
    "500X",
    "500e Cabriolet",
    "508",
    "530e Touring",
    "570",
    "570S Spider",
    "595",
    "595C",
    "6",
    "6 Serie",
    "695",
    "7",
    "7 Serie",
    "70C17 | 170 PK | Bakwagen met Laadklep | A/C | Clima | Cruise",
    "718 Boxster",
    "718 Cayman",
    "718 Spyder",
    "720S Spider",
    "740E",
    "8 Serie",
    "812 GTS",
    "9",
    "911",
    "A-Klasse",
    "A-klasse180 Business Solution",
    "A1",
    "A110",
    "A3",
    "A35 AMG 4MATIC",
    "A4",
    "A4 Allroad",
    "A5",
    "A6",
    "A6 Allroad",
    "A7",
    "A8",
    "ADAM",
    "ALPINA B5 Biturbo Touring Allrad",
    "AMG GT",
    "AMG GT 4-Door Coupe",
    "ARIYA",
    "ARKANA *",
    "ASX",
    "Acadia",
    "Agila",
    "Alhambra",
    "Allroad Quattro",
    "Alto",
    "Amarok",
    "Ampera-E",
    "Arkana",
    "Arona",
    "Arona 1.0 Tsi",
    "Arteon",
    "Arteon ShootingBrake",
    "Astra",
    "Ateca",
    "Auris",
    "Avensis",
    "Aventador",
    "Aygo",
    "B-Klasse",
    "B-MAX",
    "B3",
    "B3 touring",
    "B4 Cabrio",
    "B5 Biturbo touring allrad",
    "BMC-T",
    "BMC-i",
    "BRZ",
    "Baleno",
    "Bayon",
    "Beetle",
    "Bentayga",
    "Berlingo",
    "Bipper",
    "Born",
    "Boxer",
    "Bravo",
    "Brommobiel",
    "Bronco",
    "Buggy",
    "C",
    "C-Elysee",
    "C-HR",
    "C-Klasse",
    "C-MAX",
    "C-Zero",
    "C1",
    "C3",
    "C4",
    "C40",
    "C5",
    "C5 X",
    "CAMPER LMC BREEZER AUTOMAAT LENGTEB",
    "CC",
    "CForce",
    "CHALLENGER R/T SCAT PACK",
    "CLA-Klasse",
    "CLS-Klasse",
    "CR-V",
    "CT",
    "CT6",
    "CTS",
    "CX-3",
    "CX-30",
    "CX-5",
    "Caddy",
    "Caddy Maxi",
    "California",
    "Camaro",
    "Camper",
    "Camry",
    "Canter",
    "Captur",
    "Carado",
    "Caravelle",
    "Carens",
    "Cascada",
    "Cayenne",
    "Cayman",
    "Ceed",
    "Celerio",
    "Challenger",
    "Challenger GT 3.6 v6",
    "Charger",
    "Cherokee",
    "Citan",
    "Citigo",
    "Civic",
    "Clio",
    "Clubman",
    "Combo",
    "Combo-e",
    "Combo-e Life",
    "Compass",
    "Continental",
    "Cooper",
    "Cooper S",
    "Corolla",
    "Corsa",
    "Corsair",
    "Corvette",
    "Countryman",
    "Coupe",
    "Crafter",
    "Crossland X",
    "Crossover",
    "Cullinan",
    "Cupra Ateca",
    "D-max",
    "D2S",
    "D3 BiTurbo",
    "D4",
    "D5 S 408pk Touring Allrad",
    "DB11",
    "DS3",
    "DS4",
    "DS5",
    "Daily",
    "Dawn",
    "Defender",
    "Diavel",
    "Discovery",
    "Discovery Sport",
    "Doblo",
    "Dokker",
    "Du&eacute;",
    "Ducato",
    "Duster",
    "E",
    "E-Berlingo",
    "E-Citigo",
    "E-Crafter Kipper",
    "E-Ducato",
    "E-Klasse",
    "E-Pace",
    "E-Scudo",
    "E-Transporter",
    "ENYAQ Coup&eacute; iV",
    "ENYAQ IV80",
    "EQA",
    "EQA 250 AMG",
    "EQA 250 Electric Art",
    "EQA 250 Progressive",
    "EQB",
    "EQC",
    "EQC 400 4MATIC AMG Line",
    "EQE",
    "EQS",
    "ES",
    "EVITO TOURER",
    "Eclipse Cross",
    "EcoSport",
    "Edge",
    "Enyaq",
    "Enyaq iV Coupe",
    "Eqv",
    "Escalade",
    "Escape",
    "Espace",
    "Eurocargo",
    "Ev6",
    "Expert",
    "Explorer",
    "Express",
    "F",
    "F-150",
    "F-Pace",
    "F-Type",
    "FIAT DOBLO",
    "FUSO CANTER",
    "Fabia",
    "Fiesta",
    "Fiorino",
    "Florium",
    "Flying Spur",
    "Focus",
    "Forester",
    "Forfour",
    "Formentor",
    "Fortwo",
    "Fullback",
    "G",
    "G-Klasse",
    "G350d",
    "GL-Klasse",
    "GLA-Klasse",
    "GLA180 Business Solution AMG",
    "GLB 200 164PK",
    "GLB-Klasse",
    "GLB180   automaat",
    "GLC-Klasse",
    "GLE-Klasse",
    "GLS-Klasse",
    "GMC Sierra",
    "GS",
    "GT-R",
    "GT86",
    "GTC4",
    "GTC4 Lusso V12",
    "Galaxy",
    "Ghibli",
    "Giulia",
    "Giulietta",
    "Gladiator",
    "Golf",
    "Golf Sportsvan",
    "Golfkar",
    "Grand C-Max",
    "Grand C4",
    "Grand C4 SpaceTourer 7P",
    "Grand Caravan",
    "Grand Cherokee",
    "Grand Sc&eacute;nic",
    "Grand Voyager",
    "Grandland",
    "Grandland X",
    "HORON",
    "HR-V",
    "Hilux",
    "Huracan",
    "Hymer",
    "I-PACE",
    "ID.3",
    "ID.4",
    "ID.5",
    "ID.5 (1)",
    "IEV7S",
    "IONIQ",
    "IONIQ 5",
    "IS",
    "ITASCA",
    "Ibiza",
    "IbizaNavi",
    "Ignis",
    "Impreza",
    "Insignia",
    "JS50",
    "Jazz",
    "Jetta",
    "Jimny",
    "Jogger",
    "Js60",
    "Juke",
    "Jumper",
    "Jumpy",
    "K",
    "KARL",
    "Ka",
    "Kadjar",
    "Kamiq",
    "Kangoo",
    "Karoq",
    "Kodiaq",
    "Koleos",
    "Kona",
    "Kuga",
    "L200",
    "LIDO",
    "Lancer",
    "Land Cruiser",
    "Leaf",
    "Leon",
    "Levante",
    "Levorg",
    "Lodgy",
    "Logan",
    "Logistar",
    "M&eacute;gane",
    "M-Klasse",
    "M.go",
    "M2",
    "M3",
    "M4",
    "M5",
    "M6",
    "MC4",
    "MC740",
    "MKC",
    "MKX",
    "MKZ",
    "MX-30",
    "MX-5",
    "Macan",
    "Macan 2.0",
    "Master",
    "Maverick",
    "Mc4-73g",
    "Meriva",
    "Metropolis",
    "MiTo",
    "Micra",
    "Mii",
    "Mini",
    "Mirai",
    "Mirai New",
    "Model 3",
    "Model S",
    "Model X",
    "Model Y",
    "Mokka",
    "Mokka X",
    "Mokka-e",
    "Mondeo",
    "Movano",
    "Multivan",
    "Mustang",
    "NC",
    "NV200",
    "NV300",
    "NV400",
    "NX",
    "Nautilus",
    "Navigator",
    "Nemo",
    "Niro",
    "Niro EV",
    "Note",
    "Nugget",
    "Octavia",
    "One",
    "Optima",
    "Outback",
    "Outlander",
    "PROACE CITY Electric",
    "PROACE CITY Verso Electric",
    "PROACE Electric Verso",
    "Paceman",
    "Pacifica",
    "Pajero",
    "Panamera",
    "Panda",
    "Partner",
    "Passat",
    "Picanto",
    "Polo",
    "Primastar",
    "Prius",
    "ProAce",
    "ProAce Compact",
    "ProAce Worker",
    "ProCeed",
    "Pulsar",
    "Puma",
    "Punto",
    "Q2",
    "Q2 (A01 PI)",
    "Q3",
    "Q3  Sportback",
    "Q3 Sportback",
    "Q30",
    "Q4",
    "Q4 Sportback",
    "Q4 Sportback e-tron",
    "Q4 e-tron",
    "Q4 e-tron (A1)",
    "Q5",
    "Q50",
    "Q60",
    "Q7",
    "Q8",
    "Q8 (C2)",
    "QASHQAI",
    "QX70",
    "Quattro",
    "Quattroporte",
    "R",
    "R8",
    "RAV4",
    "RCZ",
    "RS 3",
    "RS Q3 Sportback",
    "RS3",
    "RS3 ABT",
    "RS4",
    "RS5",
    "RS6",
    "RS7",
    "RS7 Sportback",
    "RSQ3",
    "RSQ8",
    "RX",
    "Ram",
    "Range Rover",
    "Range Rover Evoque",
    "Range Rover Sport",
    "Range Rover Velar",
    "Ranger",
    "Rapid",
    "Renegade",
    "Revero",
    "Rexton",
    "Rifter",
    "Rio",
    "Roller",
    "Roomster",
    "S-Cross",
    "S-Klasse",
    "S-Max",
    "S1",
    "S3",
    "S4",
    "S5",
    "S6",
    "S60",
    "S7",
    "S8",
    "S80",
    "S90",
    "SCALA",
    "SF90 Stradale",
    "SIENNA",
    "SLC",
    "SLK",
    "SQ 7",
    "SQ5",
    "SQ5 SPORTBACK",
    "SQ7",
    "SQ8",
    "Sandero",
    "Santa Fe",
    "Sc&eacute;nic",
    "Scrambler",
    "Scudo",
    "Sharan",
    "Sierra",
    "Silverado",
    "Sorento",
    "Soul",
    "Sound up!!",
    "Space Star",
    "Splash",
    "Sportage",
    "Spring",
    "Spring Orange Pakket",
    "Sprinter",
    "Stelvio",
    "Stonic",
    "Superb",
    "Supra",
    "Swace",
    "Swift",
    "T",
    "T-Cross",
    "T-ROC AUT7",
    "T-Roc",
    "T-Roc  GP",
    "T-Roc GP",
    "T-Roc Sport",
    "T-Roc Sport AUTOMAAT",
    "T448",
    "T6.1 Transporter",
    "TGE",
    "TT",
    "Taigo",
    "Talento",
    "Talisman",
    "Tarraco",
    "Taycan",
    "Taycan Cross Turismo",
    "Team",
    "Tiguan",
    "Tipo",
    "Titan",
    "Tivoli",
    "Toledo",
    "Tonale",
    "Touareg",
    "Tour",
    "Touran",
    "Tourneo",
    "Tourneo Connect",
    "Trafic",
    "Transit",
    "Transit Connect",
    "Transit Courier",
    "Transit Custom",
    "Transporter",
    "Tucson",
    "Twingo",
    "Twizy (ex Accu)",
    "UX",
    "Urus",
    "V-Klasse",
    "V40",
    "V60",
    "V70",
    "V90",
    "Venga",
    "Verso",
    "Verso-S",
    "Vitara",
    "Vito",
    "Vivaro",
    "Voyager",
    "WRX STI",
    "Work",
    "Works",
    "Wraith",
    "Wrangler",
    "X-Klasse",
    "X-Trail",
    "X1",
    "X2",
    "X3",
    "X4",
    "X4 (g02)",
    "X4 M-Sport",
    "X4 M40i High Executive",
    "X5",
    "X6",
    "X7",
    "XC40",
    "XC60",
    "XC70",
    "XC90",
    "XE",
    "XF",
    "XJ",
    "XV",
    "Xceed",
    "Yaris",
    "Yaris Cross",
    "Yeti",
    "Ypsilon",
    "Z4",
    "Z4M-M40",
    "ZD",
    "ZOE",
    "ZS",
    "Zafira",
    "Zafira-e Life L",
    "e-Boxer",
    "e-Golf",
    "e-NV200 80KW Evalia",
    "e-Niro",
    "e-Partner",
    "e-Rifter",
    "e-Soul",
    "e-Traveller",
    "e-Up!",
    "e-tron",
    "e-tron GT",
    "e-tron GT QUATTRO",
    "e-tron Sportback",
    "eTGE",
    "highlander",
    "i10",
    "i20",
    "i3",
    "i30",
    "i4",
    "i40",
    "i8",
    "iX3",
    "ix20",
    "ix35",
    "nv",
    "up!"
  ];