import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import Spinner from "components/ui/Spinner/Spinner";
import Alert from "components/ui/Alert/Alert";
import Header from "components/Header/Header";
import Popup from "components/ui/Popup/Popup";
import Tutorial from "components/Tutorial/Tutorial";
import useTutorial from "hooks/use-tutorial";
import useAuth from "hooks/use-auth";
import useDealer from "hooks/use-dealer";
import Footer from "components/Footer/Footer";

function App() {
  const activeFunnel = useSelector(state => state.progress.activeFunnel);

  const { showTutorial, showBgLayer } = useTutorial();
  const { userIsLoggedIn, role, token, userInfo } = useAuth();
  const { dealerID } = useDealer();

  useEffect(() => {
    document.body.classList.remove("crm");
  },[]);

  return (
    <div className="bg-gray-100 flex flex-col items-stretch w-full"> 
      {/* <div className="xbg-beeldmerk bg-no-repeat bg-center fixed w-full h-full opacity-5"></div> */}
      <Alert />
      <Spinner />
      <Header />
      <Popup />
      <Outlet />
      {(role && role.includes("Admin") && !dealerID) && <div className="w-full h-full bg-black opacity-80 z-40 fixed left-0 top-0"></div>}

      {(showTutorial && userIsLoggedIn) && <Tutorial />}        
      <Footer activeFunnel={activeFunnel} /> 
    </div>
  );
}

export default App;
