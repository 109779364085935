import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressStep from "./Stap";
import Container from "components/hoc/Container";
import KentekenPlaat from "components/ui/KentekenPlaat";
import useVoertuig from "hooks/use-voertuig";
import { escapeSpecialChars } from "lib/voertuig";

const ProgressBar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const progress = useSelector(state => state.progress);

  var { merk, model, kenteken } = useVoertuig();

  const { currentCase, currentIndex } = progress;

  const changeStepHandler = (stap) => {
    var path = progress.steps[currentCase][stap].pathname;
    navigate(path);
  };

  return (
    <Container>
      <div className="grid grid-cols-1 lg:grid-cols-2 xxflex flex-col items-center lg:gap-10 xmb-20 xborder-b xborder-slate-300">
        <div className="flex flex-row mt-5 gap-5 lg:mt-0 lg:grid lg:grid-cols-3 lg:gap-10">
          {kenteken && <KentekenPlaat kenteken={kenteken} />}
          {merk &&
            <div>
              <div className="text-xs">Merk</div>
              <div>{escapeSpecialChars(merk)}</div>
            </div>
          }
          {model && 
            <div>
              <div className="text-xs">Model</div>
              <div>{escapeSpecialChars(model)}</div>
            </div>
          }
        </div>
        <div className="flex flex-row justify-between py-5">
          {/* currentstep >= 1 'selected' */}
          {progress.steps[currentCase].map((stap, index, row) => {
            return (
              <ProgressStep
                last={index + 1 === row.length}
                key={"stap" + index}
                onClick={() => changeStepHandler(index)}
                index={index + 1}
                current={(stap.pathname === location.pathname)}
                selected={currentIndex>=(index+1)}
                pathname={stap.pathname}
                />
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default ProgressBar;
