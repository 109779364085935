import { counterActions } from "./counter-slice";
import { sendRequest } from "lib/calc";

export const mergePhaseCounters = (jwt, data) => {
    return async (dispatch) => {
        try {
            const counterData = data || await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Deal/CountRecords', headers: { 'Authorization': 'Bearer ' + jwt } });
            const totalRecords = {};
            totalRecords.totalInbox = counterData.filter(item => /(Uw aanvraag is ontvangen|Uw aanvraag wordt beoordeeld|Klant dient extra informatie aan te leveren|Controleer en verzend offerte)/.test(item.phase)).reduce((partialSum, item) => partialSum + item.totalRecords, 0) || 0;
            totalRecords.totalContractClosing = counterData.filter(item => /(Contract wordt opgesteld door de bank|Contract ter ondertekening bij de klant|Contract ter ondertekening bij u|U dient het voertuig op naam van de klant te registreren|In afwachting van uitbetaling|Bevestiging van uitbetaling)/.test(item.phase)).reduce((partialSum, item) => partialSum + item.totalRecords, 0) || 0;
            totalRecords.totalSignedOffers = counterData.filter(item => /(Klant is akkoord met de offerte)/.test(item.phase)).reduce((partialSum, item) => partialSum + item.totalRecords, 0) || 0;
            totalRecords.totalSales = counterData.filter(item => /(Offerte verzonden)/.test(item.phase)).reduce((partialSum, item) => partialSum + item.totalRecords, 0) || 0;
            const updatedCounterData = {
                phaseRecords: {...counterData},
                totalRecords
            }           
            dispatch(counterActions.count(updatedCounterData));
        } catch (err) {
            console.log("failed getting counters");
        }
    }
}