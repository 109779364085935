import useBackDrop from "hooks/use-backdrop";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";

const Spinner = props => {
    const dispatch = useDispatch();
    const backdrop = useBackDrop();
    if(!backdrop) return;
    return (
        <div className="xhidden bg-black w-full h-full fixed opacity-80 z-50 flex justify-around items-center" onClick={() => dispatch(uiActions.hideBackdrop())}>
            <span className="animate-ping absolute inline-flex h-12 w-12 rounded-full bg-[#F2310E] opacity-75"></span>
        </div>
    )
}
export default Spinner;