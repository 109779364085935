import { useEffect, useState } from "react";
import Table, { Thead, Th, Td, Tr } from "components/ui/Table/Table";
import Container from "components/hoc/Container";
import useAuth from "hooks/use-auth";

const GebruikerOverzicht = () => {
    const [users, setUsers] = useState([]);
    const { token: jwt } = useAuth();

    useEffect(() => {
        fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/User', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }
        }).then(resp => resp.json()).then(data => {
            setUsers(data);
        });
    }, []);


    return (
        <div className="max-w-6xl">
            {users.length ?
                <Table>
                    <Thead>
                        <Tr thead="true">
                        <Th>ID</Th>
                        <Th>Gebruikersnaam</Th>
                        <Th>E-mailadres</Th>
                        <Th>Rollen</Th>
                        <Th>Actie</Th>
                        </Tr>
                    </Thead>
                    <tbody>
                        {users.map((user) => {
                            return (<Tr key={user.userrId} className="cursor-pointer">
                                <Td>{user.userrId}</Td>
                                <Td>{user.userName}</Td>
                                <Td>{user.email}</Td>
                                <Td>{user.role}</Td>
                                <Td>
                                    {/* <NavLink to={""+user.userrId} className="fa fa-pencil hover:text-black"></NavLink> */}
                                    </Td>
                            </Tr>)
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                        </tr>
                    </tfoot>
                </Table>
                :
                <p>Er zijn nog geen gebruikers</p>
            }
        </div>
    )
}

export default GebruikerOverzicht;