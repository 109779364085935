const Footer = ({activeFunnel}) => {
    return (
        <footer className="mx-auto mt-auto text-sm">
            <div className={`${!activeFunnel && 'cursor-pointer hover:text-slate-400'} flex flex-row gap-x-1 py-2 select-none`} onClick={activeFunnel ? () => {} : () => window.location.reload()}>
                <span>Versie {process.env.REACT_APP_VERSION}</span>
                {!activeFunnel && <><span className="fa fa-arrows-rotate cursor-pointer"></span></>}
            </div>
        </footer>
    )
}

export default Footer;