import CarForm from "components/Forms/CarForm/CarForm";
import VoorraadModule from "components/VoorraadModule/VoorraadModule";
import { useSearchParams } from "react-router-dom";
import ProgressBar from "components/ProgressBar/ProgressBar";
import Container from "components/hoc/Container";
import useHead from "hooks/use-head";

const StapVoertuigGegevens = () => {

  const [searchparams] = useSearchParams();
  var voorraadmodule = searchparams.get("type") === "voorraad";
  useHead({title: voorraadmodule ? 'Voertuig uit voorraad' : 'Voertuiggegevens'});
    
  return (
    <>
      <Container type="fullscreen">
        <ProgressBar />
      </Container>
      <Container zIndex={20}>
        {voorraadmodule ? (
          <VoorraadModule />
        ) : (
          <CarForm />
        )}
      </Container>

    </>
  );
};

export default StapVoertuigGegevens;
