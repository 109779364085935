import React, { Suspense, useEffect, useState } from "react";
import Container from "components/hoc/Container";
import ProgressBar from "components/ProgressBar/ProgressBar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dossierActions } from "store/dossier-slice";
import Content from "components/Content/Content";
import Button from "components/ui/Button/Button";
import { progressActions } from "store/progress-slice";
import { resetDossier } from "store/dossier-actions";
import useGetDeal from "hooks/use-get-deal";
import useTutorial from "hooks/use-tutorial";
import Uitleg from "components/Tutorial/Uitleg";
import { tutorialActions } from "store/tutorial-slice";
const Pdf2 = React.lazy(() => import("components/Pdf/Pdf2"));

const StapBedankt = () => {
  const { showTutorial } = useTutorial();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [downloadPdf, setDownloadPdf] = useState(false);

  const { dealID } = useSelector(state => state.deal.dealDetail);
  const dealDetail = useGetDeal(dealID);

  useEffect(() => {
    dispatch(resetDossier());
    if(showTutorial) dispatch(tutorialActions.changeStepName("financial-lease-bedankpagina"));
  },[]);
  
  const resetHandler = () => {
    dispatch(progressActions.reset());
    dispatch(dossierActions.reset());
    navigate("/");
  }

  if(!dealDetail) return <>Laden...</>;

  return (
    <>
      <Container type="fullscreen">
        <ProgressBar />
      </Container>
      <Container>
      <Content
          title="Bedankt voor uw aanvraag!"
          description={["Wij hebben uw aanvraag in goede orde ontvangen. Doorgaans is uw aanvraag op werkdagen binnen 24 uur beoordeeld.", <><br /><br /></>, "Onderstaand kunt u de offerte onder voorbehoud goedkeuring downloaden voor uw klant.", <><br /><br /></>, "Voor eventuele vragen kunt u contact met ons opnemen op het nummer 0180 23 41 41"]}
          contentAlign="left"
          />
        <div className="flex flex-row gap-5">
          <Button onClick={resetHandler} className="btn-secondary mb-5">Home</Button>
          <Button onClick={() => setDownloadPdf(true)} className="btn-primary mb-5">Download offerte (onder voorbehoud)</Button>
        </div>
      </Container>
      {showTutorial && <Uitleg hideNextButton={true} arrowPosition="left" title="Aanvraag succesvol ingediend" description={["De aanvraag is succesvol ingediend.", <br />, "Klik rechtsboven in het menu op 'Verkopen' om deze en andere verkopen te bekijken."]} />}
      {downloadPdf && <Suspense fallback={<div>Loading ...</div>}><Pdf2 hostname={"https://"+window.location.hostname} ondervoorbehoud={true} onDone={() => setDownloadPdf(false)} dealDetail={dealDetail} /></Suspense>}
    </>
  );
};

export default StapBedankt;
