import { createSlice } from "@reduxjs/toolkit";

export const tutorialSlice = createSlice({
    name: 'tutorial',
    initialState: {
        showTutorial: false, 
        tutorialStep: 1,
        showBgLayer: false,
        tutorialStepName: ""
    },
    reducers: {
        increaseStep(state, actions) {
            state.tutorialStep = state.tutorialStep + 1;
        },
        decreaseStep(state, actions) {
            state.tutorialStep = state.tutorialStep - 1;
        },
        changeStepName(state, actions) {
            state.tutorialStepName = actions.payload;
        },
        hideTutorial(state, actions) {
            state.showBgLayer = false;
            state.tutorialStepName =  "";
        },
        closeTutorial(state, actions) {
            state.showTutorial = false;
            state.showBgLayer = false;
            state.tutorialStepName =  "";
        }
    }
});

export const tutorialActions = tutorialSlice.actions;
