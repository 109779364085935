import PrevNext from "components/Navigation/PrevNext";
import BtwMargeForm from "components/Forms/BtwMargeForm/BtwMargeForm";
import Container from "components/hoc/Container";
import ProgressBar from "components/ProgressBar/ProgressBar";

const BtwMarge = () => {
    return (
        <>
            <Container type="fullscreen">
                <ProgressBar />
            </Container>
            <BtwMargeForm />
            <PrevNext disableNext={true} />
        </>
    )
}

export default BtwMarge;