import DealOverzicht from "components/Tables/DealOverzicht";
import { friendlySaleType } from "lib/voertuig";

const Verkopen = (props) => {
  return (
    <div>
      {/* <h1 className="flex flex-row items-center">Verkopen {props.saleType ? <><span className="text-sm mx-1">&raquo;</span><span>{friendlySaleType(props.saleType)}</span></> : ""}</h1> */}
      <DealOverzicht saleType={props.saleType} />
    </div>
  );
};

export default Verkopen;