import { useEffect } from "react";
import useDealer from "./use-dealer";

const useHead = (props) => {
  // const { favicon } = useHuisstijl();
  const dealer = useDealer();
  const { dealer_naam } = dealer;
  // var favDiv = document.getElementById("favicon");
  const { title } = props;

  useEffect(() => {
    document.title = title + (dealer_naam ? ' - '+dealer_naam : '');
    // favDiv.href = favicon;
  }, [dealer_naam, title]);
};

export default useHead;
