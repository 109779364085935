import { Routes, Route } from "react-router-dom";
import PageSettings from "../admin/index";
import PageDeals from "./deals";
import Customers from "./customers";
import DealDetail from "./deal-detail";
import CustomerDetail from "./customer-detail";

const AdminRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<PageDeals />} />
          <Route path="/:dealID" element={<DealDetail />} />
          {/* <Route path="/verkopen" element={<PageDeals />} /> */}
          <Route path="/klanten" element={<Customers />} />
          <Route path="/klanten/:customerID" element={<CustomerDetail />} />
        </Routes>
    )
}

export default AdminRoutes;