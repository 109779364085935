import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendRequest } from "lib/calc";
import { uiActions } from "store/ui-slice";
import { dealerActions } from "store/dealer-slice";
import { authActions } from "store/auth-slice";
import { userLogin } from "store/auth-actions";

const useAuthentication = () => {
    const dispatch = useDispatch();
    const [forgotPwdCalled, setForgotPwdCalled] = useState(false);

    const auth = useSelector(state => state.auth);

    const resetPassword = async (body) => {
        dispatch(uiActions.showBackdrop());
        const bodyEmail = body.email;
        const bodyPwd = body.password;
        try {
            const response = await sendRequest({url: process.env.REACT_APP_SLD_API_BASE_URL+"/api/Authenticate/reset-password", method: "POST", body: body, headers: {"Content-Type": "application/json"}});
            const { status, message } = response;
            if(status==="Error") throw new Error(message);
            if(status==="Success") {
                // send mail with code when changed succesfully
                dispatch(uiActions.hideBackdrop());
                dispatch(userLogin({
                    userName: bodyEmail,
                    password: bodyPwd
                }));
            }
            return response;
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({visible: true, type: "danger", message: "Het wachtwoord kan niet gereset worden, omdat de token ongeldig is. Neem contact op met SLD.", permanent: true}));
        }
    }

    const forgotPassword = async (body) => {
        dispatch(uiActions.showBackdrop());
        try {
            const response = await sendRequest({url: process.env.REACT_APP_SLD_API_BASE_URL+"/api/Authenticate/forgot-password", method: "POST", body: body, headers: {"Content-Type": "application/json"}});
            if(!response) throw new Error("Wachtwoord kan niet gereset worden.");
            setForgotPwdCalled(true);
            dispatch(uiActions.hideBackdrop());
            return response;
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({type: "danger", message: "Wachtwoord kan niet gereset worden.", permanent: true}));
        }
    }

    const dealerPasswordConfirmation = async (body) => {
        try {
            const response = await sendRequest({url: process.env.REACT_APP_SLD_API_BASE_URL+"/api/Authenticate/DealerPasswordConfirmationv2", method: "POST", body: body, headers: {"Content-Type": "application/json"}});
            const { token, expiration, status, dealer, role, message } = response;
            if(status==="Error") throw new Error(message);
            dispatch(uiActions.setAlert({message: "Uw account is succesvol geactiveerd", type: "success"}));
            localStorage.setItem('token', token);
            localStorage.setItem('expiration', expiration);
            const userId = response.dealer?.userId || "";
            userId && localStorage.setItem('userId', ''+userId);
            dispatch(dealerActions.setDealerData(dealer));
            dispatch(authActions.signin({"token": token, "expiration": expiration, "role": role, "dealer": dealer}));
            return response;
        } catch (err) { 
            var defaultMessage = "Uw account kan om onbekende reden niet geactiveerd worden. Neem contact op met SLD.";
            var errMessage = JSON.parse(err.message).message || defaultMessage;
            if(errMessage==="Password already created.") errMessage = "Uw account is al geactiveerd!";
            if(errMessage==="Enter valid code or password") errMessage = defaultMessage; 
            dispatch(uiActions.setAlert({message: errMessage, type: "danger", permanent: true}));
        }
    }

    return {
        resetPassword,
        forgotPassword,
        dealerPasswordConfirmation,
        auth,
        forgotPwdCalled
    }
}

export default useAuthentication;