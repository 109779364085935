import useHuisstijl from "hooks/use-huisstijl";
import styled from "styled-components";

const StapDivWapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 10;
  display: -webkit-box;
  flex-direction: ${props => props.last ? 'row' : 'unset'};
  justify-content: ${props => props.last ? 'flex-end' : 'unset'};
  align-items: center;
`;

const StapDiv = styled.div`
  border-style: ${props => props.last ? 'dashed' : 'solid'};
  border-width: ${props => props.last ? '4px' : (!props.selected && !props.current) ? '2px' : '0'};
  border-radius: ${props => props.last ? '0' : '9999px'};
  border-color: ${props => (!props.selected && !props.current) ? (props.last ? props.primaryColor : '') : ''};
  color: ${props => (props.selected && props.buttonTextColor) ? props.buttonTextColor : (props.primaryColor ? props.primaryColor : '#099AE6')};
  background-color: ${props => (props.selected && !props.last) ? props.primaryColor : (props.last ? 'none' : props.buttonTextColor)};
  width: ${props => (!props.selected || props.current) ? '3rem' : '2rem' };
  height: ${props => (!props.selected || props.current) ? '3rem' : '2rem' };
  font-size: ${props => (!props.selected || props.current) ? '1.5rem' : '1rem' };
  z-index: 20;
  font-weight: 900;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  text-align: center;
  line-height: 2rem;
`;

const BorderDiv = styled.div`
  border-top-width: 2px;
  width: 100%;
  position: absolute;
  z-index: -10;
  top: 50%;
  left: 0;
  border-color: ${props => (props.done) ? props.primaryColor : '#ddd'};
  `;

// border-color: ${props => props.done ? props.primaryColor : '#099AE6'};

const ProgressStep = (props) => {
  const { primaryColor, buttonTextColor } = useHuisstijl(); 

  return (
    <StapDivWapper last={props.last}>
      <StapDiv primaryColor={primaryColor} buttonTextColor={buttonTextColor} last={props.last} selected={props.selected} current={props.current}>
      {!props.last ? ((props.selected && !props.current)
      ?
      <span>&#10003;</span>
      :
      <>{props.index}<span className={"text-xs absolute -top-5"}></span></>) : <div className="fa fa-envelope" />}
      <BorderDiv primaryColor={primaryColor} done={(props.selected && !props.current)} buttonTextColor={buttonTextColor} />
      </StapDiv>
      </StapDivWapper>
  );
};

export default ProgressStep;
