import Button from "components/ui/Button/Button";
import Card from "components/ui/Card/Card";

import { useState } from "react";
const CalculatorSettings = () => {
    const [activeTab, setActiveTab] = useState("df");
    const [bankData, setBankData] = useState([
        {
            id: "df",
            name: "Dutch Finance",
            rentes: [8.99, 9.99, 10.99, 11.99, 12.99,13.99],
            provisies: [10,12.5,15,17.5,20,25]
        },
        {
            id: "fi",
            name: "Findio",
            rentes: [
                {
                    rente: 8.2
                },
                {
                    rente: 7.2,
                    leasebedrag_van: 5000,
                    leasebedrag_tot: 7499
                },
                {
                    rente: 6.2,
                    leasebedrag_van: 7500,
                    leasebedrag_tot: 14999
                },
                {
                    rente: 5.2,
                    leasebedrag_van: 15000,
                    leasebedrag_tot: 19999
                },
                {
                    rente: 4.2,
                    leasebedrag_van: 20000
                }
            ],
            provisies: [1,1.5,2,2.5,3,3.5,4,4.5,5]
        },
        {
            id: "hi",
            name: "Hiltermann Lease",
            rentes: [8.99, 9.99, 10.99, 12.19, 13.19, 14.19],
            provisies: [10,14,18,20,24,27]
        },
        {
            id: "al",
            name: "Alphera",
            rentes: [9.99, 8.74, 8.49],
            rentes: [
                {
                    rente: 9.99
                },
                {
                    rente: 8.74,
                    leasebedrag_van: 15000,
                    leasebedrag_tot: 34999
                },
                {
                    rente: 8.49,
                    leasebedrag_van: 35000
                }
            ],
            provisies: [1,2,3,4]
        },
        {
            id: "dfrr",
            name: "Dutch Finance rekenrente",
            rentes: [
                {
                    rente: 7.9
                },
                {
                    rente: 6.7,
                    leasebedrag_van: 12500,
                    leasebedrag_tot: 25000
                },
                {
                    rente: 6.5,
                    leasebedrag_van: 25001               
                }
            ],
            provisies: [1,1.5,2,2.5,3,3.5,4,4.5,5,5.5,6,6.5,7]
        }
    ]);

  var role = "";

  const setActiveHandler = (id) => {
    setActiveTab(id);
  }

  return (
    <>
    <h1>Calculator instellingen</h1>
    <ul className="flex flex-wrap xtext-sm xfont-medium text-center text-gray-500 border-b border-slate-200 mb-5">
    {bankData.map(bank => {
        return (
        <li key={bank.id} className="mr-2">
            <div onClick={(id) => setActiveHandler(bank.id)} className={` ${bank.id===activeTab ? 'border-b-2 font-bold' : 'border-transparent'} hover:border-b-2 inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 cursor-pointer`}>{bank.name}</div>
        </li>
        )
    })}
</ul>
    <form>
        <Card title={bankData.filter(item => item.id===activeTab).map(item => item.name)}>
          {/* <div>dealID: {dealDetail.dealID} &amp; relatedID: {dealDetail.relatedID}</div> */}
          <div className="bg-white grid md:grid-cols-3 lg:grid-cols-3 items-start">
            <div className="grid grid-cols-2 col-span-2 p-5">


        {bankData.filter(bank => bank.id===activeTab).map(data => {
            return (
                <div className={`col-span-2 xflex xflex-col p-5  border-slate-600 border-t-transparent`}>
                {data.rentes &&
                <div className="mb-5">
                    <div className="font-bold">Rentes (%)</div>
                    {/* <div className="font-light mb-3">Het standaard rentepercentage, is het percentage waar geen van/tot bedragen zijn ingevuld.</div> */}
                    <div className="grid grid-cols-3 gap-3">
                    {data.rentes.map(rente => {                    
                        return (
                        <>
                        <input type="number" placeholder="Leasebedrag van" className="border p-1.5 border-slate-300 rounded-md" value={(rente.leasebedrag_van || rente.leasebedrag_van===0) ? ''+rente.leasebedrag_van : ''} />
                        <input type="number" placeholder="Leasebedrag tot" className="border p-1.5 border-slate-300 rounded-md" value={rente.leasebedrag_tot ? rente.leasebedrag_tot : ''} />
                        <input type="number" value={rente.rente ? rente.rente : rente} className="border p-1.5 border-slate-300 rounded-md" />
                        </>
                        )

                    })}
                    </div>
                </div>
                }
                {data.provisies &&
                <div>
                    <div className="font-bold">Provisies (%)</div>
                    <div className="flex flex-col gap-3">
                    {data.provisies && data.provisies.map(provisie => {
                        return (
                            <>
                            <input type="number" value={provisie} className="border p-1.5 border-slate-300 rounded-md" />
                            </>                        
                        )
                    })}
                    </div>
                </div>
                }
            </div>       
            )
        })}


</div>
<div className="flex flex-col col-span-2 md:col-span-1 md:relative gap-5 p-5 bg-slate-50 h-full">
    <Button className="bg-gradient">Opslaan</Button>
</div>
</div>

</Card>
    </form>
    </>

  );
};

export default CalculatorSettings;
