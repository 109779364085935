import { configureStore } from "@reduxjs/toolkit";
import { uiSlice } from "./ui-slice";
import { authSlice } from "./auth-slice";
import { dossierSlice } from "./dossier-slice";
import { dealerSlice } from "./dealer-slice";
import { occSlice } from "./occ-slice";
import { progressSlice } from "./progress-slice";
import { dealSlice } from "./deal-slice";
import { customerSlice } from "./customer-slice";
import { tutorialSlice } from "./tutorial-slice";
import { counterSlice } from "./counter-slice";

const store = configureStore({
    reducer: {
        ui: uiSlice.reducer,
        auth: authSlice.reducer,
        dossier: dossierSlice.reducer,
        dealer: dealerSlice.reducer,
        occ: occSlice.reducer,
        progress: progressSlice.reducer,
        deal: dealSlice.reducer,
        customer: customerSlice.reducer,
        tutorial: tutorialSlice.reducer,
        counter: counterSlice.reducer
    }
});

export default store;