import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import InputKvk from "components/ui/InputKvk/InputKvk";
import useInput from "hooks/use-input";
import Input from "components/ui/Input/Input";
import Select from "components/ui/Select/Select";
import Container from "components/hoc/Container";
import InputDatum2 from "components/ui/InputDatum/InputDatum2";
import Button from "components/ui/Button/Button";
import ProgressBar from "components/ProgressBar/ProgressBar";
import useVoertuig from "hooks/use-voertuig";
import { noDotAndInteger } from "lib/helpers";
import useBerekening from "hooks/use-berekening";
import useAuth from "hooks/use-auth";
import { saveDeal } from "store/deal-actions";
import { dateToISO } from "lib/calc";
import Uitleg from "components/Tutorial/Uitleg";
import useTutorial from "hooks/use-tutorial";
import PrevNext from "components/Navigation/PrevNext";
import { escapeSpecialChars } from "lib/voertuig";

const VerzekerenForm = props => {
    const { showTutorial } = useTutorial();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token, userInfo } = useAuth();

    const bedrijfsnaamRef = useRef();

    const [formIsValid, setFormIsValid] = useState(false);

    const [enteredBedrijfsnaam, setEnteredBedrijfsnaam] = useState("");

    const [enteredVoornaam, setEnteredVoornaam] = useState("");   
    const [voornaamTouched, setVoornaamTouched] = useState(false);
    const voornaamIsValid = voornaamTouched && enteredVoornaam.length > 1;

    const [enteredAchternaam, setEnteredAchternaam] = useState("");
    const [achternaamTouched, setAchternaamTouched] = useState(false);
    const achternaamIsValid = achternaamTouched && enteredAchternaam.length > 1;

    const [enteredAanhef, setEnteredAanhef] = useState("");
    const [aanhefTouched, setAanhefTouched] = useState(false);
    const aanhefIsValid = /(Dhr|Mevr)/i.test(enteredAanhef);

    const [enteredEmail, setEnteredEmail] = useState("");
    const [emailTouched, setEmailTouched] = useState(false);
    const emailIsValid = emailTouched && /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,15})+$/.test(enteredEmail);

    const [enteredTelefoon, setEnteredTelefoon] = useState("");
    const [telefoonTouched, setTelefoonTouched] = useState(false);
    const telefoonIsValid = telefoonTouched && !/[^\d\-\+\(\)]/.test(enteredTelefoon) && enteredTelefoon.length>8;

    const [enteredDamageHistoryInsurance, setEnteredDamageHistoryInsurance] = useState("");
    const [damageHistoryTouched, setDamageHistoryTouched] = useState(false);
    const damageHistoryIsValid = damageHistoryTouched;

    const [enteredDamageFreeYearsInsurance, setEnteredDamageFreeYearsInsurance] = useState("");
    const [damageFreeYearTouched, setDamageFreeYearsTouched] = useState(false);
    const damageFreeYearsIsValid = damageFreeYearTouched;
    
    const [enteredKmPerYear, setEnteredKmPerYear] = useState("");
    const [kmPerYearTouched, setKmPerYearTouched] = useState(false);
    const kmPerYearIsValid = kmPerYearTouched;

    const [enteredTypeOfInsurance, setEnteredTypeOfInsurance] = useState("");
    const [typeOfInsuranceTouched, setTypeOfInsuranceTouched] = useState(false);
    const typeOfInsuranceIsValid =enteredTypeOfInsurance;

    const customer_company = useSelector(state => state.dossier.kvkData);
    const { bedrijfsnaam } = customer_company;
    const { dealerID: dealerID_from_dealer } = useSelector(state => state.dealer.dealer);

    const [enteredGeboortedatum, setEnteredGeboortedatum] = useState("");
    const [geboortedatumIsTouched, setGeboorteDatumIsTouched] = useState(false);


    var enteredGeboortedatumIsValid = enteredGeboortedatum.length > 2;
    var geboortedatumInputHasError = !enteredGeboortedatumIsValid && geboortedatumIsTouched; 



    var dealerID = (userInfo.dealerID ? userInfo.dealerID : dealerID_from_dealer);
    const enteredBedrijfsnaamIsValid = bedrijfsnaam?.length > 2;
    const bedrijfsnaamInputHasError = !enteredBedrijfsnaamIsValid;

    useEffect(() => {
        (voornaamIsValid&&achternaamIsValid&&aanhefIsValid&&emailIsValid&&telefoonIsValid&&damageHistoryIsValid&&damageFreeYearsIsValid&&kmPerYearIsValid&&typeOfInsuranceIsValid&&enteredBedrijfsnaamIsValid&&enteredGeboortedatumIsValid) ? setFormIsValid(true) : setFormIsValid(false);
    }, [voornaamIsValid,achternaamIsValid,aanhefIsValid,emailIsValid,telefoonIsValid,damageHistoryIsValid,damageFreeYearsIsValid,kmPerYearIsValid,typeOfInsuranceIsValid,enteredBedrijfsnaamIsValid,enteredGeboortedatumIsValid]);

    var voertuig = useVoertuig();
    const berekening = useBerekening();

    const submitDealHandler = (evt) => {
        evt.preventDefault();
        var address = (customer_company.straat ? customer_company.straat : '') + " " + (customer_company.huisnummer ? customer_company.huisnummer : '') + " " +(customer_company.huisnummertoevoeging ? customer_company.huisnummertoevoeging : '');
        
        var datum_eerste_toelating = dateToISO(voertuig.datum_eerste_toelating);
        var datum_oprichting = "";
        var btw_bedrag = noDotAndInteger(berekening.btw_bedrag);
        var voertuigsoort = voertuig.voertuigsoort;
        if(voertuigsoort==="Bedrijfswagen") voertuigsoort = "Bedrijfsauto";
        
        var amountpurchaseExclVAT = noDotAndInteger(berekening.aanschafwaarde_excl);
        if(/marge/i.test(voertuig.btw_marge)) {
            btw_bedrag = 0;
            amountpurchaseExclVAT = noDotAndInteger(berekening.aanschafwaarde);
        }   

        var carrosserie = voertuig.carrosserie_soort || "";
        var licensePlate = voertuig.kenteken || "";
        var fuelType = voertuig.brandstof || "";
        var grossBPM = voertuig.bruto_bpm || "";
        var engineCapacity = voertuig.cilinder_inhoud || "";
        var occasionNumber = voertuig.occasion_id || 0;
        var typeOfDeal = voertuig.btw_marge || "";

        var dealData = {
            "source": "Dealer-aanvraag",
            "dealerID": dealerID,
            "typeOfDeal": typeOfDeal,
            "amountpurchase": noDotAndInteger(berekening.aanschafwaarde),
            "commissionAmountDealer": +berekening.provisiebedrag,
            "totalToPay": berekening.totale_aanbetaling ? berekening.totale_aanbetaling : 0,
            "downpayment": berekening.aanbetaling ? +berekening.aanbetaling : 0,
            "monthlyamount": noDotAndInteger(berekening.leasetermijn),
            "vat": btw_bedrag,
            "kmPerYear": enteredKmPerYear,
            "description": "",
            "duration": berekening.looptijd,
            "administrationCost": berekening.bemiddelingskosten,
            "commissionAmountSLD": berekening.provisiebedrag_sld,
            "amountpurchaseExclVAT": amountpurchaseExclVAT,
            "damageFreeYearsInsurance": enteredDamageFreeYearsInsurance,
            "damageHistoryInsurance": enteredDamageHistoryInsurance,
            "chosenBank": "",
            "finalpayment": noDotAndInteger(berekening.slottermijn),
            "interest": +berekening.rente,
            "phase": "Uw aanvraag wordt beoordeeld",
            "typeOfInsurance": enteredTypeOfInsurance,
            "saleType": "Verzekering",
            "customerDetail": {
                "companyName": escapeSpecialChars(customer_company.bedrijfsnaam),
                "email": enteredEmail,
                "salutation": enteredAanhef,
                "firstName": enteredVoornaam,
                "lastName": enteredAchternaam,
                "dateofBirth": enteredGeboortedatum, // customer.geboortedatum
                "driverLicenseNumber": "",
                "phone": enteredTelefoon,
                "iban": "",
                "addition": "",
                "type": "Indivisul",
                "address": address,
                "chamberNumber": customer_company.dossiernummer,
                "dateofEsteb": datum_oprichting,
                "postalCode": customer_company.postcode,
                "place": customer_company.plaats,
                "age": customer_company.bedrijf_leeftijd
            },
            "vehicleDetail": {
              "occasionNumber": occasionNumber,
              "make": escapeSpecialChars(voertuig.merk),
              "model": escapeSpecialChars(voertuig.model),
              "marginVAT": 0, // marginVAT
              "mileage": noDotAndInteger(voertuig.kmstand),
              "vehicleType": voertuigsoort,
              "firstRegistrationDate": datum_eerste_toelating,
              "age": noDotAndInteger(voertuig.voertuigleeftijd),
              "engineCapacity": engineCapacity,
              "fuelType": fuelType,
              "grossBPM": grossBPM,
              "licensePlate": licensePlate,
              "listPrice": noDotAndInteger(voertuig.catalogusprijs),
              "positionJudgement": "",
              "restBPM": ""+voertuig.rest_bpm,
              "carrosserie": carrosserie
            }
          }
          dispatch(saveDeal(dealData, token)).then(resp => {
            if(resp) navigate('/verzekering/bedankpagina');
        });
    }

    const changeHandler = (evt) => {
        var fieldName = evt.target.name;
        var fieldValue = evt.target.value;
        if(fieldName==="aanhef") {
           setAanhefTouched(true);
           setEnteredAanhef(fieldValue)
        }
        if(fieldName==="kmPerYear") {
            setKmPerYearTouched(true);
            setEnteredKmPerYear(fieldValue);
        }
        if(fieldName==="typeOfInsurance") {
            setTypeOfInsuranceTouched(true);
            setEnteredTypeOfInsurance(fieldValue);
        }
    }

    const birthDayHandler = (input) => {
        setGeboorteDatumIsTouched(true);
        setEnteredGeboortedatum(input.value)
    }

    return (
        <>
            <Container type="fullscreen">
                <ProgressBar />
            </Container>
            <Container>
            <form onSubmit={submitDealHandler}>
                <h1>Persoonsgegevens</h1>
            <div className="">

                <div className="grid lg:grid-cols-2 gap-5">

                    <Select set="aanhef" label="Aanhef" view="horizontal" value={enteredAanhef} onChange={changeHandler} name="aanhef" />
                    <Input errorMessage="" name="voornaam" placeholder="Voornaam" value={enteredVoornaam} onChange={(evt) => setEnteredVoornaam(evt.target.value)} onBlur={() => setVoornaamTouched(true)} />
                    <Input errorMessage="" name="achternaam" placeholder="Achternaam" value={enteredAchternaam} onChange={(evt) => setEnteredAchternaam(evt.target.value)} onBlur={() => setAchternaamTouched(true)} />
                    <InputDatum2 hasError={geboortedatumInputHasError} errorMessage="Geboortedatum is verplicht" onChange={birthDayHandler} errorMessage="Geboortedatum is verplicht" placeholder="Geboortedatum" type="geboortedatum" value={enteredGeboortedatum} name="geboortedatum" wrapperClasses="order-5" />
                    {/* <InputDatum2 hasError={geboortedatumInputHasError} errorMessage="Geboortedatum is verplicht" placeholder="Geboortedatum" type="geboortedatum" value={enteredGeboortedatum} name="geboortedatum" wrapperClasses="order-5" /> */}

                </div>
                <h3>Bedrijfsgegevens</h3>
                <div className="grid gap-5">
                    {showTutorial && <Uitleg hideNextButton={true} arrowPosition="left" title="Contactgegevens" description="De bedrijfsgegevens worden via de KvK verkregen door te zoeken op bedrijfsnaam." />}
                    <InputKvk hasError={bedrijfsnaamInputHasError} className="lg:col-span-2" value={enteredBedrijfsnaam} ref={bedrijfsnaamRef} />
                    <Input value={enteredEmail} onChange={(evt) => setEnteredEmail(evt.target.value)} onBlur={() => setEmailTouched(true)} name="email" placeholder="E-mailadres" />
                    {/* <Input hasError={emailInputHasError} errorMessage="Ongeldig e-mailadres" name="email" placeholder="E-mailadres" value={enteredEmail} ref={emailRef} onChange={emailChangeHandler} onBlur={emailBlurHandler} /> */}
                    <Input errorMessage="Ongeldig telefoonnummer" name="telefoon" placeholder="Telefoonnummer" value={enteredTelefoon} onChange={(evt) => setEnteredTelefoon(evt.target.value)} onBlur={() => setTelefoonTouched(true)} />

                    {/* <Input ref={telefoonRef} errorMessage="" name="telefoonnummer" placeholder="Telefoonnummer" /> */}
                    {/* <Input value={enteredDamageHistoryInsurance} onChange={valueChangeHandler} ref={damageHistoryInsuranceRef} name="damageHistoryInsurance" placeholder="Schadeverleden (auto) afgelopen 5 jaar" /> */}
                    {/* <Input value={enteredDamageFreeYearsInsuranceRef} onChange={valueChangeHandler} ref={damageFreeYearsInsuranceRef} name="damageFreeYearsInsuranceRef" placeholder="Aantal geregistreerde schadevrije jaren" /> */}
           
                    <Input value={enteredDamageHistoryInsurance} onChange={evt => setEnteredDamageHistoryInsurance(evt.target.value)} onBlur={() => setDamageHistoryTouched(true)} name="damageHistoryInsurance" placeholder="Schadeverleden (auto) afgelopen 5 jaar" />
                    <Input value={enteredDamageFreeYearsInsurance} onChange={evt => setEnteredDamageFreeYearsInsurance(evt.target.value)} onBlur={() => setDamageFreeYearsTouched(true)} name="damageFreeYearsInsuranceRef" placeholder="Aantal geregistreerde schadevrije jaren" />
           
                    <Select value={enteredKmPerYear} onChange={changeHandler} name="kmPerYear" set="kmperjaar" label="Hoeveel kilometers per jaar" />
                </div>
                <h3>Verzekeringsgegevens</h3>
                <div className="grid gap-5">
                    <Select value={enteredTypeOfInsurance} set="typeOfInsurance" onChange={changeHandler} name="typeOfInsurance" label="Type verzekering" />
                </div>
                {/* <InputRadio type="checkbox" options={[{value: "Ik ga akkoord met de algemene voorwaarden"}]} /> */}
                <div className="mt-5">
                    <div className="lg:col-span-3">
                        <PrevNext disableNext={true}><Button className="btn-primary" disabled={!formIsValid}>Versturen</Button></PrevNext>
                    </div>                  
                </div>
            </div>
            </form>
        </Container>
        </>
    )
}

export default VerzekerenForm;