import pdfMake from "pdfmake/build/pdfmake";
import { useEffect } from "react";
pdfMake.fonts = {
    demo: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      },
}

const VoorraadModulePdf = ({method, dealerToken, dealerName, dealerDomain, onDisableDownload, onSendData}) => {
    console.log(dealerToken);

    const docDefinition = {
        pageSize: "A4",
        pageMargins: [40,60,40,60],
        pageOrientation: "landscape",
        defaultStyle: {
            font: 'demo',
            fontSize: 13,  
        },
        // header: function() {
        //     return {
        //         stack: [
        //             {columns: [
        //                 {alignment: "left", text: "Datum: "+new Date().toLocaleDateString("nl-NL")},
        //                 {alignment: "right", text: "Versie 1.0 "}
        //             ]}
        //         ],
        //         margin: [40,40]
        //     }
        // },
        // footer: function() {
        //     return {
        //         stack: [
        //                 {text: "Vragen of ondersteuning nodig?"},
        //                 {text: "Neem gerust contact op met onze technische ondersteuning voor verdere hulp of vragen."},
        //                 {text: "help@sld.nl We helpen je graag verder!"},
        //          ],
        //         margin: [40,0]
        //     }
        // },
        content: [
            {text: " ", lineHeight: 2},
            {text: "Integratie handleiding voorraadmodule SLD.", style: "header"},
            {text: " ", lineHeight: 2},
            {text: `Door gebruik te maken van de voorraadmodule van SLD geeft u uw klanten de mogelijkheid direct een Financial Lease offerte aan te vragen op uw beschikbare voertuigen. De aanvragen worden bij ons in het systeem geregistreerd en uiteraard ook direct beoordeeld. Bij goedkeuring ontvangt u hier bericht van en kunt u contact opnemen met uw klant voor het uitbrengen van een lease offerte.`},
            {text: " ", lineHeight: 2}, 
            {text: "Hoe kunt u de voorraadmodule toevoegen?", bold: true},
            {text: " "}, {
                ol: [
                    {text: "Creëer een nieuwe pagina en menu-item \"Financial Lease voorraad\"", margin: [ 20,0,0,10 ]},
                    {
                        margin: [ 20,0,0,10 ],
                        text: [
                    {text: "Voeg volgend script toe:\n"},
                    {background: "#dddddd", fontSize: 11, text: "<script type=\"text\/javascript\" src=\""+(dealerDomain ? dealerDomain : "https://dealermodule.sld.nl")+"/iframeResizer.min.js\"></script>"},
                    ]},
                    {
                        margin: [ 20,0,0,10 ],
                        text: [
                            {text: "Voeg vervolgens de volgende div toe:\n"},
                    {background: "#dddddd", fontSize: 11, text: "<div data-api=\""+dealerToken+"\" id=\"sld_dealermodule\" data-domain=\""+(dealerDomain ? dealerDomain : "https://dealermodule.sld.nl")+"\"></div>"},
                    ]}
                ]
            },
            {text: " "},
            {text: " "},
            {text: "Let op: Plaats de div met id \"sld_dealermodule\" op de plaats waar de voorraadmodule van SLD ingeladen dient te worden."},
            {text: " ", lineHeight: 2},
            {text: "Vragen?", bold: true},
            {text: " "},
            {text: [
            "Uiteraard helpen wij u graag verder bij vragen. U kunt een mail sturen naar ",
            {text: "info@sld.nl ", decoration: "underline", color: "blue"},
            "of telefonisch contact met ons opnemen. Wij zijn bereikbaar op het nummer ", 
            {text: "0180 23 41 41", bold: true}
            ]},
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                lineHeight: 2
            }
        }
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    useEffect(() => {
        if(!method) return;
        if(method==="download") {
            pdfDocGenerator.download("SLD Dealermodule handleiding", (x,y) => {
                onDisableDownload();
            });
        }
        if(method==="send") {
            pdfDocGenerator.getBlob((data) => {
                onSendData(data); 
            });    
        }
    }, []);
   
    return <></>
}

export default VoorraadModulePdf;
