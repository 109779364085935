import { useState, useEffect } from "react";
import useAuth from "./use-auth";

const useGetDeal = (dealID) => {
    const [deal, setDeal] = useState({});
    const { token: jwt } = useAuth();

    useEffect(() => {
        fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Deal/' + dealID, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            }
        }).then(resp => resp.json())
            .then(data => {
                setDeal(data);
            });
    }, []);
    return deal;
}

export default useGetDeal;