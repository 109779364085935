import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dossierActions } from "store/dossier-slice";
import useVoertuig from "hooks/use-voertuig";
import useHuisstijl from "hooks/use-huisstijl";
import styled from "styled-components";

const StyledDiv = styled.div`
    color: ${props => props.selected ? props.primaryColor : 'inherit'};
    margin: -2px 0 0 -2px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    border-width: 2px;
    font-size: 1.875rem;
    line-height: 2.25rem;
    gap: 2.5rem;
    display: flex;
    max-width: 100%;
    cursor: pointer;
    flex-direction: row;
    background-color: white;
    align-items: center;
    position: relative;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    max-width: 470px;
    &:hover {
        color: ${props => props.primaryColor ? props.primaryColor : 'inherit'};
        border-color: ${props => props.primaryColor ? props.primaryColor : 'inherit'};
        z-index: 10;
    }
`;

const BtwMargeForm = () => {
    const dispatch = useDispatch();
    const { btw_marge } = useVoertuig();
    const currentCase = useSelector(state => state.progress.currentCase);
    var nextLink = (currentCase==="FinancialLease" ? "/financiering/financial-lease/calculator" : "/verzekering/klantgegevens");
    const { primaryColor } = useHuisstijl();

    return (
        <>
            <form className="">
            <div className="flex flex-col md:flex-row my-10 justify-center">
                <NavLink to={nextLink}>
                    <StyledDiv className="rounded-t-xl md:rounded-r-none md:rounded-bl-xl" primaryColor={primaryColor} selected={(/btw/i.test(btw_marge))} onClick={(btw_marge) => dispatch(dossierActions.setBtwMarge('BTW'))}>
                        <div className="fa fa-badge-percent"></div>
                        <div>BTW-voertuig</div>
                    </StyledDiv>
                </NavLink>
                <NavLink to={nextLink}>
                    <StyledDiv className="rounded-b-xl md:rounded-l-none md:rounded-tr-xl" primaryColor={primaryColor} selected={(/marge/i.test(btw_marge))} onClick={(btw_marge) => dispatch(dossierActions.setBtwMarge('Marge'))}>
                        <div className="fa fa-car"></div>
                        <div>Marge-voertuig</div>
                    </StyledDiv>
                </NavLink>
            </div>
            <div className="flex flex-col md:flex-row my-10 justify-center">
                <details className="text-center cursor-pointer">
                    <summary>Wat is het verschil tussen een BTW-voertuig en een Marge-voertuig?</summary>
                    <div className="xtext-left mt-10">
                        <div className="w-full font-bold text-xl">Marge-voertuig</div>
                        <div className="mb-5">Een marge-auto is BTW-vrij. Hoe dat kan? De BTW is al een keer betaald door een particulier. Als ondernemer hoeft u de BTW over uw nieuwe tweedehands auto niet nog een keer te betalen. U profiteert met een marge-auto dus van een financieel voordeel. Vaak is deze auto de beste optie voor startende ondernemers of ZZP’ers — de groep met weinig tot geen startkapitaal.</div>
                        <div className="w-full font-bold text-xl">BTW-voertuig</div>
Over een BTW-auto is de BTW nog niet eerder betaald. U betaalt de BTW — of nou ja, u schiet het eigenlijk voor. De BTW over de gebruikte auto is terug te vorderen bij de Belastingdienst. Dit geldt ook als u een leasecontract afsluit. De BTW-auto is een prima optie als u genoeg geld hebt om de BTW voor te schieten en als u wilt profiteren van het BTW-voordeel door dit als aftrekpost op te geven bij de Belastingdienst.
                    </div>
                </details>
            </div>
            </form>
        </>
    )
}

export default BtwMargeForm;