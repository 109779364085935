import { useState, useEffect } from "react";
import Container from "components/hoc/Container";
import Content from "components/Content/Content";
import { useSelector, useDispatch } from "react-redux";
import { fetchDealers, fetchDealerById } from "store/auth-actions";
import Button from "components/ui/Button/Button";
import useAuth from "hooks/use-auth";

const PageAdminDealers = () => {
  const dispatch = useDispatch();
  const {token} = useAuth();
  const dealerList = useSelector(state => state.auth.dealers);

  useEffect(() => {
    dispatch(fetchDealers(token));
  },[]);

  // const [dealers] = useState([
  //   {dealer_naam: "Scherpe Lease Deal"},
  //   {dealer_naam: "Goedman Automotive"},
  //   {dealer_naam: "Munsterhuis Occasion Center"}
  // ]);

  return (
    <Container>
      <Content
        title="Overzicht van bedrijven"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <table className="table-auto">
        <thead>
            <tr>
                <th>ID</th>
                <th>Naam</th>
                <th>Bekijk</th>
            </tr>
        </thead>
        <tbody>
          {dealerList.map(dealer => {
            return (
              <tr key={dealer.dealerID}>
                <td>{dealer.dealerID}</td><td>{dealer.name}</td><td><Button className="btn-secondary" onClick={(id) => dispatch(fetchDealerById(dealer.dealerID))}>Bekijk Bedrijfsgegevens</Button></td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Container>
  );
};

export default PageAdminDealers;
