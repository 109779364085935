import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { escapeSpecialChars, sortDataByKey } from "lib/voertuig";
import Input from "components/ui/Input/Input";
import Select from "components/ui/Select/Select";
// import InputRadio from "components/ui/InputRadio/InputRadio";
import Button from "components/ui/Button/Button";
import { getDealFilters } from "store/deal-actions";

const Zoekmachine = (props) => {
  const [searchParams] = useSearchParams();

  const { searchFor, disableArchive } = props;
    const dispatch = useDispatch();
    const filters = useSelector(state => state.deal.dealFilters);
    const [searchInput, setSearchInput] = useState(props.initialSearch);

    const [archiveUpdated, setArchiveUpdated] = useState(0);

    const archiveHandler = () => {
      setSearchInput(prevState => ({...prevState, isArchive: !prevState.isArchive}));
      setArchiveUpdated(prevState => prevState + 1);
    }

    useEffect(() => {
        dispatch(getDealFilters(props.token));
    },[dispatch]);

    useEffect(() => {
        if(!archiveUpdated) return;
        props.onSearch(searchInput);
    },[archiveUpdated]);

    function searchHandler(evt) {
        evt.preventDefault();
        searchInput['licensePlate'] = searchInput?.licensePlate?.replace(/\-/g,"") || "";
        // searchInput['make'] = searchInput?.make.trim();
        console.log(searchInput);

        props.onSearch(searchInput);
    }

    const changeHandler = (evt) => {
        const dataName = evt.target.name;
        const dataValue = evt.target.value;
        setSearchInput(prevState => {
            return {
                ...prevState,
                [dataName]: dataValue
            }
        });
    }

    const clearHandler = () => {
        setSearchInput(props.initialSearch);
        props.onClear();
    }

    useEffect(() => {
      clearHandler();
    },[props.initialSearch]);

    // const dealerFilters = sortDataByKey(filters.dealers?.filter(item => item!=="").map(item => ({label: item.name, value: item.dealerID})),"label") || {};

    return (
        <>
        <form onSubmit={searchHandler} className="flex flex-col gap-3 mb-5 p-5 rounded-xl">
              <div className="grid grid-cols-4 items-center gap-3">
                
                {searchFor==="customers" &&
                <>
                <Input name="customerId" value={searchInput.customerId} onChange={changeHandler} type="text" placeholder="Klant ID" />
                <Input name="chamberNumber" value={searchInput.chamberNumber} onChange={changeHandler} type="text" placeholder="KvK nummer" />
                </>
                }

                {searchFor==="dealers" &&
                <>
                <Input name="dealerId" value={searchInput.dealerId} onChange={changeHandler} type="text" placeholder="Dealer ID" />
                <Input name="chamberNumber" value={searchInput.chamberNumber} onChange={changeHandler} type="text" placeholder="KvK nummer" />
                <Select set="statusDealer" name="status" value={searchInput.status} onChange={changeHandler} type="text" label="Status" placeholder="Status"></Select>
                </>
                }

                {searchFor==="deals" &&
                <>
                <Input name="relatedID" value={searchInput.relatedID} onChange={changeHandler} type="text" placeholder="Verkoop ID" />
                <Select name="saleType" value={searchInput.saleType} onChange={changeHandler} placeholder="Financieringsvorm" label="Financieringsvorm" options={filters.saleTypes?.filter(item => item!=="").map(item => ({label: item.replace("FinancialLease", "Financial Lease").replace("BtwFinanciering", "Btw Financiering"), value: item}))}></Select>
                <Select name="source" value={searchInput.source} onChange={changeHandler} placeholder="Source" label="Source" set="source"></Select>
                </>
                }
                {!disableArchive &&
                <div className="col-start-4">
                  <Button type="button" onClick={archiveHandler} name="isArchive" value={searchInput.isArchive}>{searchInput.isArchive ? 'Actueel': 'Geschiedenis'}</Button>
                </div>
                }
              </div>

              <div className="grid grid-cols-4 gap-3">
                {/* <Select name="dealerId" value={searchInput.dealerId} onChange={changeHandler} label="Autobedrijf" options={dealerFilters} /> */}
                {searchFor==="dealers" &&
                <Input name="name" value={searchInput.name} onChange={changeHandler} type="text" placeholder="Bedrijfsnaam" />
                }
                {searchFor==="deals" &&
                <Input name="dealerName" value={searchInput.dealerName} onChange={changeHandler} type="text" placeholder="Bedrijfsnaam" />
                }
                <Input name="postalCode" value={searchInput.postalCode} onChange={changeHandler} type="text" placeholder="Postcode" />

                {/(dealers|customers)/.test(searchFor) &&
                <Input name="place" value={searchInput.place} onChange={changeHandler} type="text" placeholder="Plaats" />
                }
                {/(deals|customers)/.test(searchFor) &&
                <Input name="companyName" value={searchInput.companyName} onChange={changeHandler} type="text" placeholder="Klant" />
                }
              </div>
              {searchFor==="deals" &&
              <div className="grid grid-cols-4 gap-3">
                <Input name="licensePlate" value={searchInput.licensePlate} onChange={changeHandler} type="text" placeholder="Kenteken" />
                <Select name="make" label="Merk" value={searchInput.make} onChange={changeHandler} options={filters.makesfiltered?.filter(item => item!=="").sort().map(item => ({label: escapeSpecialChars(item), value: escapeSpecialChars(item).trim()}))}></Select>
                <Select name="phase" set={(searchInput.isArchive) ? 'isArchive_phase': 'phase'} label="Fase" value={searchInput.phase} onChange={changeHandler}></Select>
              </div>
              }
              <div className="grid grid-cols-4 gap-3 items-center">
                <Button type="submit" className="btn-secondary !w-full !max-w-full" disabled={(searchInput === props.initialSearch)}>Zoeken</Button>
                {searchInput !== props.initialSearch &&
                <Button type="button" className="cursor-pointer ml-2 !bg-[#D20B00]" onClick={clearHandler}><span className="fa fa-xmark"></span></Button>
                } 
                <div className="col-start-4">
                  {searchParams.get("perpage")!=="99999" &&
                  <Select disableFirst={true} value={props.perpage} onChange={evt => props.onChangePageSize(evt.target.value)} label="Aantal resultaten" placeholder="Aantal resultaten" options={[{value: 25},{value: 50},{value: 100}, {value: 9999, label: "Alles"}]} />
                  }
                  </div>
              </div>
          </form>
          </>
    )
}

export default Zoekmachine;