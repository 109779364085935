import SearchForm from "components/Forms/SearchForm/SearchForm";
import KlantOverzicht from "components/Tables/KlantOverzicht";

const Contacts = () => {
    return (
        <>
            {/* <h1>Klanten</h1> */}
            <KlantOverzicht />
        </>
    )
}

export default Contacts;