import CarForm from "components/Forms/CarForm/CarForm";
import VoorraadModule from "components/VoorraadModule/VoorraadModule";
import { useSearchParams } from "react-router-dom";
import ProgressBar from "components/ProgressBar/ProgressBar";
import Container from "components/hoc/Container";

const StapVoertuigGegevens = () => {
    const [searchparams] = useSearchParams();

    return (
        <>
            <Container type="fullscreen">
                <ProgressBar />
            </Container>
            <Container>
            {searchparams.get("type") === 'voorraad' ?
                <VoorraadModule />
                :
                <CarForm />
            }
            </Container>
        </>
    )
}

export default StapVoertuigGegevens;