export const checkGrijsKenteken = (kenteken) => {
    // eerste letter kenteken is B of V
    if(!kenteken) return;
    if(/^(B|V)/.test(kenteken)) return true;
    return false;
} 

// export const validateField = (fieldType, value) => {
//     if(!fieldType || !value) return true;
//     switch(fieldType) {
//         case "email":
//             if(value => /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,15})+$/.test(value)) return true;
//     }
//     return false;
// }
 
export const getFirstImage = (images) => {
    return;
    // if(!images) return;
    // var first_image = images.split("|")[0];
    // var base_url = "https://www.sld.nl/640x480/";
    // var image_url = base_url+first_image;
    // return image_url;
}

export const sortDataByKey = (data, keyToSort) => {
    if(!data) return;
    var sortedData = data.sort((a, b) => (a[keyToSort] > b[keyToSort]) ? 1 : -1);
    return sortedData;
}

export const brandstofToId = (inputBrandstof) => {
    if(!inputBrandstof) return;
    switch(true) {
        case /(aardgas|^a$)/i.test(inputBrandstof): inputBrandstof = "A"; break;
        case /(benzine|^b|benzin$)/i.test(inputBrandstof): inputBrandstof = "B"; break;
        case /(diesel|^d$)/i.test(inputBrandstof): inputBrandstof = "D"; break;
        case /(elektrisch|^e$)/i.test(inputBrandstof): inputBrandstof = "E"; break;
        case /(hybride benzine|^hb$)/i.test(inputBrandstof): inputBrandstof = "HB"; break;
        case /(hybride diesel|^hd$)/i.test(inputBrandstof): inputBrandstof = "HD"; break;
        case /(hybride|^h$)/i.test(inputBrandstof): inputBrandstof = "H"; break;
        case /(lpg g3|^3$)/i.test(inputBrandstof): inputBrandstof = "3"; break;
        case /(lpg|^g$)/i.test(inputBrandstof): inputBrandstof = "G"; break;
        case /(xxxx|^o$)/i.test(inputBrandstof): inputBrandstof = "XXXX"; break;
        default: 
            break;
    }
    return inputBrandstof;
}

export const carrosserieToId = (inputCarrosserie) => {
    if(!inputCarrosserie) return;
    switch(true) {
        case /(trekker)/i.test(inputCarrosserie): inputCarrosserie = "BETR"; break;
        case /(bakw|bawa)/i.test(inputCarrosserie): inputCarrosserie = "BAWA"; break;
        case /(best)/i.test(inputCarrosserie): inputCarrosserie = "BEST"; break;
        case /(cabr)/i.test(inputCarrosserie): inputCarrosserie = "CABR"; break;
        case /(chassis)/i.test(inputCarrosserie): inputCarrosserie = "CHCA"; break;
        case /(coup)/i.test(inputCarrosserie): inputCarrosserie = "COUP"; break;
        case /(glas)/i.test(inputCarrosserie): inputCarrosserie = "GLRE"; break;
        case /(hatchback|hbck)/i.test(inputCarrosserie): inputCarrosserie = "HBCK"; break;
        case /(kipr|kipper)/i.test(inputCarrosserie): inputCarrosserie = "KIPR"; break;
        case /(limo)/i.test(inputCarrosserie): inputCarrosserie = "LIMO"; break;
        case /(opla|open)/i.test(inputCarrosserie): inputCarrosserie = "OPLA"; break;
        case /(oprijw|orwn)/i.test(inputCarrosserie): inputCarrosserie = "ORWN"; break;
        case /(overig|xxxx)/i.test(inputCarrosserie): inputCarrosserie = "XXXX"; break;
        case /(pebu|personenbu)/i.test(inputCarrosserie): inputCarrosserie = "PEBU"; break;
        case /(pick)/i.test(inputCarrosserie): inputCarrosserie = "PICK"; break;
        case /(rolstoel|rovv)/i.test(inputCarrosserie): inputCarrosserie = "ROVV"; break;
        case /(seda)/i.test(inputCarrosserie): inputCarrosserie = "SEDA"; break;
        case /(station|stat)/i.test(inputCarrosserie): inputCarrosserie = "STAT"; break;
        case /(terr)/i.test(inputCarrosserie): inputCarrosserie = "TERR"; break;
        case /(verkoop|vkwn)/i.test(inputCarrosserie): inputCarrosserie = "VKWN"; break;
        default: 
            break;
    }
    return inputCarrosserie;
}

export const friendlySaleType = (saleType) => {
    if(!saleType) return "";
    if(saleType==="BtwFinanciering") return "BTW financiering";
    if(saleType==="FinancialLease") return "Financial Lease";
    return saleType;
}

export const getSelectOptions = (field) => {
    if(!field) return;
    var options = [];
    switch(field) {
        case "aanhef":
            options = [{label: "Dhr", value: "Dhr"},{label: "Mevr", value: "Mevr"}];
            break;
        case "activityType":
            options = [{label: "Werkblok"},{label: "Belblok"},{label: "Mailing"},{label: "Uitslag"}];
            break;
        case "all_activityTypes":
            options = [{label: "Handmatige activiteiten", value: "Dealer update|Werkblok|Belblok|Mailing|Uitslag"},{label: "Automatische activiteiten", value: "Deal aangemaakt|Deal bijgewerkt|Deal gearchiveerd|Deal gedearchiveerd"}];
            break;
        case "chosenBank":
            options = [{label: "Dutch Finance", value: "df"}, {label: "Dutch Finance rekenrente", value: "dfrr"}, {label: "Alphera", value: "al"}, {label: "Findio", value: "fi"}, {label: "Findio Elektrisch Tarief", value: "fi_e"},{label: "Hiltermann Lease", value: "hi"}];
            break;
        case "statusDealer":
            options = [{label: "Lost Prospect", value: "lost_prospect"}, {label: "(cold) Prospect", value: "cold_prospect"},{label: "Engaged Prospect", value: "engaged_prospect"},{label: "Hot prospect", value: "hot_prospect"}, {label: "New customer", value: "new_customer"},{label: "Customer", value: "customer"}];
            break;
        case "statusBank":
            options = [{label: "Aanvraag ingediend"}, {label: "Aanvraag ingediend voor herbeoordeling"},{label: "Aanvraag afgewezen"}, {label: "Aanvraag akkoord"}, {label: "Aanvraag aanvullend akkoord"}, {label: "Aanvullende informatie benodigd"}];
            break;
        case "voertuigsoort":
            options = [{label: "Personenauto"},{label: "Bedrijfsauto"}];
            break;
        case "carrosserie_soort":
            options = [{label:"Ambulance",value:"Ambulance"},{label:"BE-trekker",value:"BETR"},{label:"Bakwagen",value:"BAWA"},{label:"Bestelauto",value:"BEST"},{label:"Buscamper",value:"Buscamper"},{label:"Cabriolet",value:"CABR"},{label:"Chassis cabine",value:"CHCA"},{label:"Coupe",value:"COUP"},{label:"Glasresteel",value:"GLRE  "},{label:"Half-integraal",value:"Half-integraal"},{label:"Hatchback",value:"HBCK"},{label:"Hoogwerker",value:"Hoogwerker"},{label:"Huifzeil",value:"Huifzeil"},{label:"Integraal",value:"Integraal"},{label:"Kipper",value:"KIPR"},{label:"Koel-vriestransport",value:"Koel-vriestransport"},{label:"Limousine",value:"LIMO"},{label:"MPV",value:"MPV"},{label:"Off-road",value:"Off-road"},{label:"Open laadbak",value:"OPLA"},{label:"Oprijwagen",value:"ORWN"},{label:"Overig",value:"XXXX"},{label:"Personenbus",value:"PEBU"},{label:"Personenvervoer",value:"Personenvervoer"},{label:"Pick-up",value:"PICK"},{label:"Rolstoelvervoer",value:"ROVV"},{label:"SUV",value:"SUV"},{label:"Sedan",value:"SEDA"},{label:"Stationwagon",value:"STAT"},{label:"Terreinwagen",value:"TERR"},{label:"Tour",value:"Tour"},{label:"Verkoopwagen",value:"VKWN"}];
            break;
        case "brandstof":
            options = [{label:"Aardgas",value:"A"},{label:"Benzine",value:"B"},{label:"Diesel",value:"D"},{label:"Elektrisch",value:"E"},{label:"Hybride",value:"H"},{label:"Hybride Benzine",value:"Hybride Benzine"},{label:"Hybride Diesel",value:"HD"},{label:"LPG",value:"G"},{label:"LPG G3",value:"3"},{label:"Overig",value:"O"}];
            break;
        case "btw_marge":
            options = [{label: "BTW", value: "BTW"},{label: "Marge", value: "Marge"}];
            break;
        case "duration":
            options = [{label: "12 maanden", value: "12"}, {label: "24 maanden", value: "24"}, {label: "36 maanden", value: "36"}, {label: "48 maanden", value: "48"}, {label: "60 maanden", value: "60"}, {label: "72 maanden", value: "72"}];
            break;
        case "phase":
            options = [{label: "Uw aanvraag is ontvangen"},{label: "Uw aanvraag wordt beoordeeld"},{label:"Klant dient extra informatie aan te leveren"},{label:"Controleer en verzend offerte"},{label: "Offerte verzonden"},{label: "Klant is akkoord met de offerte"},{label: "Contract wordt opgesteld door de bank"},{label: "Contract ter ondertekening bij de klant"},{label: "Contract ter ondertekening bij u"},{label: "U dient het voertuig op naam van de klant te registreren"},{label: "In afwachting van uitbetaling"},{label: "Bevestiging van uitbetaling"}];
            break;
        case "isArchive_phase":
            options = [{label: "Ander object"},{label: "Andere financiering"},{label: "Afwijzing"},{label: "Offerte verlopen"},{label: "Lopend"},{label: "Verloren"}];
            break;
        case "dealerCategory":
            options = [{ value: "A" },{ value: "B" },{ value: "C" },{ value: "D" }];
            break;
        case "typeOfInsurance":
            options = [{ value: "WA" },{ value: "All Risk / Casco" },{ value: "Schade inzittenden (optioneel)" },{ value: "Rechtsbijstand (optioneel)" }];
            break;
        case "kmperjaar":
            options = [{value: "0 - 5.000 km per jaar"}, {value: "5001 - 10.000 km per jaar"}, {value: "10.001 - 20.000 km per jaar"}, {value: "Meer dan 20.000 km per jaar"}];
            break;
        case "source":
            options = [{label: "Dealer-aanvraag", value: "Dealer-aanvraag"},{label: "Website-aanvraag", value: "Website-aanvraag"}];
            break;
        default:
            break;
    }
    return options;
}

export const escapeSpecialChars = (input) => {
    if(!input) return;
    var returnValue = input.replace("&euml;","\xEB")
                           .replace("&eacute;", "\xE9")
                           .replace("&amp;", "&");

    // consoleLog("input="+input+" / returnValue="+returnValue);
    return returnValue;
}

export const getSalutation = () => {
    var message = "";
    var hour = new Date().getHours();
    if(hour>=6&&hour<12) message = "Goedemorgen!";
    if(hour>=12&&hour<18) message = "Goedemiddag!";
    if(hour>18&&hour<24) message = "Goedenavond!";
    if(hour>0&&hour<6) message = "Guten nacht!";
    return message;
  }
export const friendlyFuelType = (fuelType) => {
    if(fuelType==="D") return "Diesel";
    if(fuelType==="B") return "Benzine";
    if(fuelType==="3") return "LPG G3";
    if(fuelType==="E") return "Elektrisch";
    if(fuelType==="H") return "Hybride";
    if(fuelType==="BE") return "Hybride Benzine";
    if(fuelType==="DE") return "Hybride Diesel";
    if(fuelType==="A") return "Aardgas";
    if(fuelType==="8") return "Benzine/E85";
    if(fuelType==="O") return "Overig";
    if(fuelType) return fuelType;
    if(!fuelType) return "Onbekend";
}

export const friendlyTransmission = (transmision) => {
    if(transmision==="A") return "Automaat";
    if(transmision==="H") return "Handgeschakeld";
    if(!transmision) return "Onbekend";
}

export const friendlyVatMargin = (vatMargin) => {
    if(vatMargin==="M") return "Marge-auto";
    if(/^(V|B)$/.test(vatMargin)) return "BTW-auto";
    if(vatMargin) return vatMargin;
    if(!vatMargin) return "Onbekend";
}

export const friendlyAutoCategory = (input) =>{
    if(input==="AUTO") return "Personenauto";
    if(input==="BEDRIJF") return "Bedrijfswagen";
    if(input) return input;
    if(!input) return "Onbekend";
}
