import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";
import Modal from "components/hoc/Modal";
import Input from "components/ui/Input/Input";
import TextArea from "components/ui/TextArea/TextArea";
import Button from "components/ui/Button/Button";
import Select from "components/ui/Select/Select";
import useAuth from "hooks/use-auth";
import { uiActions } from "store/ui-slice";
import ActivitySummary from "./ActivitySummary";
import { dateToDutch } from "lib/kvk";

const ActivityForm = (props) => {

    const dispatch = useDispatch();
    const [activity, setActivity] = useState({});
    const [showModal, setShowModal] = useState(false);

    const autoAssignedType = (/Deal\s(aangemaakt|bijgewerkt|gearchiveerd)/.test(activity.type));
    const editable = /(Werkblok|Belblok|Mailing|Uitslag)/.test(activity.type) || activity.type === "";

    const typeRef = useRef();
    const nameRef = useRef();
    const descriptionRef = useRef();
    const { token: jwt } = useAuth();
    const { id, updated } = props;

    useEffect(() => {
        setActivity({});
        if(id) {
            fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/ActivitiesDeal/'+id, 
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(resp => resp.json())
            .then(data => {
                setActivity(data);
                setShowModal(true);
            })
            .catch(err =>{
                setShowModal(false);
            });
        }
    },[id, updated]);


    const submitHandler = (evt) => {
        evt.preventDefault();
        dispatch(uiActions.showBackdrop());

        const formData = new FormData();
        formData.append("activityID", activity.activityID);

        formData.append("createAt", activity.createAt);
        formData.append("name", nameRef.current.value);
        formData.append("description", descriptionRef.current.value);
        formData.append("offer", "0");
        formData.append("documentURLForm", "");
        formData.append("dealID", activity.dealID);
        formData.append("type", typeRef.current.value);

        fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/ActivitiesDeal/'+id, {
            body: formData,
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
        .then(resp => resp.json())
        .then(data => {
            setActivity(data);
            dispatch(uiActions.hideBackdrop());
            setShowModal(false);
            props.onUpdate();

        })
        .catch(err =>{
            setShowModal(false);
            dispatch(uiActions.hideBackdrop());
            setShowModal(false);

        });
    }

    const inputChangeHandler = (evt) => {
        var dataName = evt.target.name;
        var dataValue = evt.target.value;
        setActivity(prevState => {
            return {
                ...prevState,
                [dataName]: dataValue
            }
        })
    }

    const ModalComponent = <Modal title={activity.type+" - "+activity.name} show={showModal} onClose={() => setShowModal(false)}>
    <form onSubmit={submitHandler} className="flex flex-col gap-3">
            <div className="flex flex-row justify-between px-3">
                <div className="flex flex-row">
                    <span className="mr-1 text-slate-500">Geregistreerd door:</span>
                    <span>{activity.createdBy}</span>
                </div>
                <div className="flex flex-row">
                    <span className="mr-1 text-slate-500">Datum:</span>
                    <span>{dateToDutch(activity.createAt)} {new Date(activity.createAt).toLocaleTimeString()}</span>
                </div>
            </div>

        {!editable && (
        <div className="flex flex-col justify-between px-3">
            <div className="flex flex-row">
                <span className="mr-1 text-slate-500">Type:</span><span>{activity.type}</span>
            </div>
            <div className="flex flex-row">
                <span className="mr-1 text-slate-500">Onderwerp:</span><span>{activity.name}</span>
            </div>
            <div className="flex flex-row">
                <span className="mr-1 text-slate-500">Source:</span><span>{autoAssignedType && JSON.parse(activity.description).source}</span>
            </div>
            {activity?.type==="Dealer update" && (
            <div className="flex flex-col mt-5">
                <span className="mr-1 text-slate-500">Bericht:</span>
                <div className="whitespace-pre-wrap" dangerouslySetInnerHTML={{__html: activity.description}}></div>
            </div>
            )}
        </div>
        )}

        {editable && (
        <>
            <Select ref={typeRef} value={activity.type} name="type" set="activityType" placeholder="Type" label="Type" onChange={inputChangeHandler} />
            <Input ref={nameRef} value={activity.name} name="name" placeholder="Onderwerp" onChange={inputChangeHandler} />
        </>
        )}


        {autoAssignedType && (
            <div className="p-3 border border-slate-300 rounded-xl">
            <div className="text-lg mb-3">{JSON.parse(activity.description).typeOfDeal} berekening</div>
                <ActivitySummary activity={activity} autoAssignedType={autoAssignedType} />
            </div>
        )}

        {editable && (
            <TextArea ref={descriptionRef} value={activity.description} name="description" placeholder="Omschrijving" onChange={inputChangeHandler} />
        )}

        {editable &&
        <span className="col-span-2">
            <Button data-modal-toggle="defaultModal" className="btn-secondary">Activiteit bijwerken</Button>
        </span>
        }
    </form>
</Modal>;

    return (
        <>
        {createPortal(ModalComponent, document.body)}
        </>
    )
}


export default ActivityForm;