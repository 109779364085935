import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useAuth from "hooks/use-auth";
import Card from "components/ui/Card/Card";
import Input from "components/ui/Input/Input";
import Button from "components/ui/Button/Button";
import { registerAdmin } from "store/auth-actions";

const GebruikerForm = () => {
    const [formIsValid, setFormIsValid] = useState(false);
    const dispatch = useDispatch();
    const [userName, setUserName] = useState(null);
    const [pwd, setPwd] = useState(null);
    const [email, setEmail] = useState(null);

    const userNameRef = useRef();
    const pwdRef = useRef();
    const emailRef = useRef();

    const params = useParams();
    const { userID } = params;
    const { token } = useAuth();

    useEffect(() => {
        (userNameRef.current?.value && pwdRef.current?.value && emailRef?.current.value) ? setFormIsValid(true) : setFormIsValid(false);
    },[userName, pwd, email]);

    const submitHandler = (evt) => {
        // alert("submitHandler!");
        evt.preventDefault();
        const dataObj = {
            userData: {
                userName: userNameRef.current.value,
                password: pwdRef.current.value,
                email: emailRef.current.value
            },
            token: token
        }
        dispatch(registerAdmin(dataObj));        
    }

    return (
        <form onSubmit={submitHandler}>
            <Card title="Nieuwe gebruiker">
                <div className="bg-white grid grid-cols-3 items-start group">
             <div className="grid col-span-2 gap-3 p-5">
                  <Input placeholder="Gebruikersnaam" name="userName" value={userName} ref={userNameRef} onChange={(evt) => setUserName(evt.target.value)} />
                  <Input placeholder="E-mailadres" name="email" value={email} ref={emailRef} onChange={(evt) => setEmail(evt.target.value)} />
                  <Input placeholder="Wachtwoord" name="password" type="password" ref={pwdRef} value={pwd} onChange={(evt) => setPwd(evt.target.value)} />
                </div>
                <div className="flex flex-col gap-5 p-5 bg-slate-50 h-full">

              <Button className="btn-primary" disabled={!formIsValid}>Registreren</Button>
            </div>
                </div>
            </Card>

{/* {
  "username": "string",
  "email": "user@example.com",
  "password": "string"
} */}

        </form>
    )
}

export default GebruikerForm;