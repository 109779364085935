import { useState, useEffect, useRef } from "react";
import useAuth from "hooks/use-auth";
import Table, { Td, Thead, Tr } from "components/ui/Table/Table";
import Input from "components/ui/Input/Input";
import Button from "components/ui/Button/Button";
import { uiActions } from "store/ui-slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TokenDetail from "./token-detail";
import Modal from "components/hoc/Modal";

const Tokens = () => {
    const emailRef = useRef();
    const dispatch = useDispatch();
    const { token: jwt } = useAuth();
    const [updateCounter, setUpdateCounter] = useState(0);
    const navigate = useNavigate();

    const [tokenDetailData, setTokenDetailData] = useState({});


    const [tokens, setTokens] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/Authenticate/GetTokens', {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        }).then(resp => resp.json())
        .then(data => {
            setTokens(data);
        })
    },[updateCounter]);


    const addTokenHandler = (evt) => {
        evt.preventDefault();
        dispatch(uiActions.showBackdrop());
        var email = emailRef.current?.value;
        if(!email) return;
        var formData = JSON.stringify({
            "email": email
        });
        fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/Authenticate/TokenGeneration', {
            method: "POST",
            body: formData,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+jwt
            }
        }).then(() => {
            setUpdateCounter(prevState => prevState + 1);
            dispatch(uiActions.hideBackdrop());
        }).catch(() => {
            dispatch(uiActions.hideBackdrop());
        });
    }

    const openDetailHandler = (id) => {
        alert(id);
    }

    return (
        <div>
            <h1>API keys</h1>

            <form className="flex flex-row gap-x-3 mb-5 p-5 rounded-xl w-content">
                <Input type="email" name="email" ref={emailRef} label="E-mailadres" />
                <Button onClick={addTokenHandler}>Token toevoegen</Button>
            </form>
            <TokenDetail data={tokenDetailData} />
            <Table>
                <Thead className="">
                <Tr>
                    <Td className="text-white">ID</Td>
                    <Td className="text-white">E-mail</Td>
                    <Td className="text-white">Token</Td>
                </Tr>
                </Thead>
                <tbody>
                {tokens && tokens.map(token => {
                    return (
                        <Tr key={token.id} className="cursor-pointer" onClick={() => setTokenDetailData(token)}>
                            <Td>{token.id}</Td>
                            <Td>{token.email}</Td>
                            <Td>{token.token}</Td>
                        </Tr>
                    )
                })}
                </tbody>
            </Table>
            
            {/* <Modal show={true}>  */}
                
            {/* </Modal> */}
        </div>

    )
}

export default Tokens;