import { NavLink } from "react-router-dom";
import DealForm from "components/Forms/DealForm/DealForm";
import Button from "components/ui/Button/Button";

const VerkoopDetail = () => {
  return (
    <>
      <NavLink to=".."><Button type="button" className="btn-secondary">Terug naar verkopen</Button></NavLink>
      <DealForm />     
    </>
  );
};

export default VerkoopDetail;
