import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { dossierActions } from "store/dossier-slice";
import { progressActions } from "store/progress-slice";
import { resetDossier } from "store/dossier-actions";
import useHuisstijl from "hooks/use-huisstijl";
import useCurrentCase from "hooks/use-current-case";

const PrevNext = props => {
    const { showInlossingModal, inlossingConfirmed } = props;

    useCurrentCase();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const { primaryColor } = useHuisstijl();
    const progress = useSelector(state => state.progress);
    const { currentCase, prevPath, nextPath } = progress;

    useEffect(() => {
        if(!currentCase || !pathname) return;
        var currentIndex = progress.steps[currentCase].findIndex(item => item.pathname === pathname);
        if(!currentIndex||currentIndex==-1) return;
        var prevPathName = progress.steps[currentCase][currentIndex - 1].pathname;
        var nextPathName = progress.steps[currentCase][currentIndex + 1].pathname;
        dispatch(progressActions.changeStep({ prevPath: prevPathName, nextPath: nextPathName, currentIndex: (currentIndex + 1) }));
    }, [pathname, currentCase]);

    useEffect(() => {
        if(inlossingConfirmed) onNextClickHandler();
    },[inlossingConfirmed]);

    const onPrevClickHandler = () => {
        if (typeof props.prevAction === "function") {
            props.prevAction();
            navigate(prevPath);
        }
        if (props.prevClearDossier) {
            dispatch(dossierActions.reset());
            dispatch(dossierActions.resetVoertuig());
            dispatch(resetDossier());
        }

        navigate(prevPath);
    }

    const onNextClickHandler = () => {      
        if(showInlossingModal) {
            props.onShowPopup();
            return;
        }
        if (typeof props.nextAction === "function") {
            props.nextAction();
            navigate(nextPath);
        }
        navigate(nextPath);
    }

    return (
        <div className="container mx-auto flex flex-row justify-between my-10">
            {/* {prevPath && <Link to={prevPath}><Button className="btn-secondary hover:scale-105 flex flex-row items-center" onClick={props.onReset && clearVoertuigHandler}><ArrowIcon className="w-4 h-4 mr-2 rotate-180" />{props.prevLabel || "Vorige"}</Button></Link>} */}
            {prevPath && <div onClick={onPrevClickHandler} className="z-0 cursor-pointer btn-secondary hover:scale-105 flex flex-row items-center"><div className="fa fa-chevron-left mr-2"></div>{props.prevLabel || "Vorige"}</div>}
            {(nextPath && !props.disableNext) && <div onClick={!props.nextDisabled ? onNextClickHandler : () => {}} type={props.nextType ? props.nextType : ''} style={{backgroundColor: (primaryColor ? primaryColor : 'black')}} className={(props.nextDisabled ? "opacity-50 cursor-default" : "cursor-pointer hover:scale-105")+" z-0 btn-primary disabled:opacity-50 flex flex-row items-center"} disabled={props.nextDisabled ? 'disabled' : ''}>{props.nextLabel || 'Volgende'}<div className="fa fa-chevron-right ml-2"></div></div>}
            {props.children}
        </div>
    )
}

export default PrevNext;