import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useAuth from "hooks/use-auth";
import useVoertuig from "hooks/use-voertuig";
import useTutorial from "hooks/use-tutorial";
import useBerekening from "hooks/use-berekening";

import { dossierActions } from "store/dossier-slice";

import { calcRestBpm, calcExPrice, calcDefaultLastAmount, formatThousandNotation, dotAndInteger, calcsrc_p, calcNominaleRente, PMTx, calcProvisieBedrag, calcMaxLastAmount, formattedPMT_v2, getClientInterestRates, calcTotaleAanbetaling, calcBtwBedrag, calcAgeInMonths, calcMaxDuration } from "lib/calc";
import { noDotAndInteger, getStorageItem } from "lib/helpers";

import Button from "components/ui/Button/Button";
import Modal from "components/hoc/Modal";
import PrevNext from "components/Navigation/PrevNext";
import Uitleg from "components/Tutorial/Uitleg";
import Input from "components/ui/Input/Input";
import InputRange from "components/ui/InputRange/InputRange";
import Select from "components/ui/Select/Select";

const Calculator2 = (props) => {
    const [formIsValid, setFormIsValid] = useState(false);
    const { showTutorial, tutorialStepName } = useTutorial();
    const dispatch = useDispatch();

    const [renteState] = useState({
        rente: props.interest || "",
        basisrente: "",
        klantrente: ""
    });
    
    const [calculatorMessage, setCalculatorMessage] = useState({
        show: false,
        title: "",
        content: ""
    });

    const [showInlossing, setShowInlossing] = useState(false);
    
    var initialInlossingState = (!props.leaseAmount && getStorageItem("inlossingstate")) ? getStorageItem("inlossingstate") : {
        active: false,
        saldo: "",
        kenteken: "",
        contractnummer: "",
        bank: ""
    }
    const [inlossing, setInlossing] = useState(initialInlossingState);
    const [inlossingConfirmed, setInlossingConfirmed] = useState(false);

    const [herbeoordeling, setHerbeoordeling] = useState(false);
    // var { leeftijd_in_maanden } = useVoertuig(); // bruto_bpm, rest_bpm, datum_deel_1
    var { aanschafwaarde: store_aanschafwaarde, max_looptijd: store_max_looptijd } = useBerekening();
    const { voertuigsoort } = useVoertuig();


    const aanschafRef = useRef();
    const btwbedragRef = useRef();
    const aanschafExRef = useRef();
    const aanbetalingRef = useRef();
    const slottermijnRef = useRef();
    const looptijdRef = useRef();
    const renteRef = useRef();
    const calcsrcRef = useRef();
    const leasebedragRef = useRef();
    const inruilRef = useRef();
    const inlossingRef = useRef();
    const inlossingKentekenRef = useRef();
    const inlossingContractNrRef = useRef();
    const inlossingBankRef = useRef();

    const { role, userInfo } = useAuth();
   
    var administrationCost = "";
    if(props.administrationCost===0 || props.administrationCost) {
        administrationCost = ""+props.administrationCost;
    } else {
        administrationCost = 249;
    }

    var vehicleAgeInMonths, calculatedMaxDuration;
    if(props.firstRegistrationDate) {
        vehicleAgeInMonths = calcAgeInMonths(props.firstRegistrationDate);
        calculatedMaxDuration = calcMaxDuration(vehicleAgeInMonths) || store_max_looptijd || 72;
    }
    if(calculatedMaxDuration<=0 || !calculatedMaxDuration) calculatedMaxDuration = store_max_looptijd || 72;

    // maxDuration can be overwritten by admins
    if(role && role.includes("Admin")) calculatedMaxDuration = 72;

    var initialState = (!props.leaseAmount && getStorageItem("berekeningstate")) ? getStorageItem("berekeningstate") : {
        administrationCost:  administrationCost,
        inruil: props.tradeInAmount || "",
        inlossing: props.repayment || "",
        leasebedrag: props.leaseAmount || "",
        aanschafwaarde: props.amountpurchase || store_aanschafwaarde || "",
        aanschafwaarde_excl: props.amountpurchaseExclVAT || "",
        btw_bedrag: props.vat || "",
        leasetermijn: "",
        slottermijn: props.finalpayment || "",
        rente: props.interest || "",
        calcrente: "",
        provisiepercentage: props.provisiePercentage || "",
        prov: props.provisiePercentage || "",
        default_provisiepercentage: 12.5,
        provisietotaal: "",
        looptijd: props.duration || 60,
        max_looptijd: calculatedMaxDuration,
        aanbetaling: props.downpayment || "",
        manual_aanbetaling: "",
        min_aanbetaling: "",
        totale_aanbetaling: "",
        calcsrc: props.calcsrc || "df",
        rentes: [9.99, 10.99, 11.99, 12.99, 13.99, 14.99], 
        provisiebedrag: "",
        provisiebedrag_rest: "",
        typeOfDeal: props.typeOfDeal || "",
        dealerCategory: "",
        pmtType: 0,
        vehicleType: props.vehicleType || ""
    }

    // if(!props.leaseAmount) initialState = getStorageItem("berekeningstate");
    const [calculatorState, setCalculatorState] = useState(initialState); 

    const [maxSlottermijnDisabled] = useState(props.disableMaxSlottermijn);
    const [slottermijnIsTouched, setSlottermijnIsTouched] = useState(props.slottermijnTouched);
    const [aanbetalingIsTouched, setAanbetalingIsTouched] = useState(false);
    const [aanschafExTouched, setAanschafExTouched] = useState(!!props.amountpurchaseExclVAT);

    const [showMessage, setShowMessage] = useState(false);
    var dealerCategoryFromDealer = useSelector(state => state.dealer.dealer.dealerCategory);

    var { btw_marge, leeftijd_in_maanden, bruto_bpm } = useSelector(state => state.dossier.voertuig);
    var dealerCategory = userInfo?.dealerCategory || dealerCategoryFromDealer;
    let initialLoad = true;
    if(props.typeOfDeal) btw_marge = props.typeOfDeal;
    
    const checkHerbeoordeling = () => {
        // Slottermijn hoger? -> Herbeoordeling
        // Leasebedrag hoger? -> herbeoordeling
        // Looptijd hoger? -> Herbeoordeling
        // Aanschafprijs lager en leasebedrag hetzelfde? -> herbeoordeling
        var reassessment = false;
        if(noDotAndInteger(calculatorState.slottermijn)>props.finalpayment) reassessment = true;
        if(calculatorState.looptijd>props.duration) reassessment = true; 
        if( (calculatorState.aanbetaling ? noDotAndInteger(calculatorState.aanbetaling) : 0) < props.downpayment) reassessment = true;
        if( (noDotAndInteger(calculatorState.aanschafwaarde) > props.amountpurchase) && (noDotAndInteger(calculatorState.leasebedrag) <= props.leaseAmount) ) reassessment = true;
        if( (noDotAndInteger(calculatorState.inruil) < props.tradeInAmount)) reassessment = true;
        if( (noDotAndInteger(calculatorState.inlossing) > props.repayment )) reassessment = true;   
        setHerbeoordeling(reassessment);
    }

    useEffect(() => {
        checkHerbeoordeling();
    },[calculatorState])

    // useEffect(() => {
    //     calcChangeHandler();
    // },[dealerCategory, calculatorState.calcsrc]);

    const inlossingHandler = (evt) => {      
        var inlossing = noDotAndInteger(inlossingRef.current?.value);
        setInlossing(prevState => {
            return {
                ...prevState,
                kenteken: inlossingKentekenRef.current?.value,
                contractnummer: inlossingContractNrRef.current?.value,
                saldo: formatThousandNotation(inlossing),
                bank: inlossingBankRef.current?.value
            }
        });
        var aanschafwaarde = noDotAndInteger(aanschafRef.current.value);
        var aanschafwaarde_excl = noDotAndInteger(aanschafExRef.current?.value);
        var aanbetaling = noDotAndInteger(aanbetalingRef.current?.value);
        var inruil = noDotAndInteger(inruilRef.current?.value);
        var leasebedrag = (/btw/i.test(btw_marge)) ? (aanschafwaarde_excl - aanbetaling - inruil)+inlossing : (aanschafwaarde - aanbetaling - inruil)+inlossing;      
        var aanschaf_slot = (/btw/i.test(btw_marge)) ? aanschafwaarde_excl : aanschafwaarde;

        // validateLeasebedrag(leasebedrag,inlossing,inruil,aanschaf_slot, null, true);    

        setCalculatorState(prevState => {
            return {
                ...prevState,
                inlossing: formatThousandNotation(inlossing),
                leasebedrag: formatThousandNotation(leasebedrag)
            }
        });
    };

    const submitInlossingHandler = () => {
        setShowInlossing(false);
        setInlossingConfirmed(true);
        inlossingHandler();
    }

    const blurAanbetalingHandler = () => {
        setCalculatorState(prevState => {
            return {
                ...prevState,
                manual_aanbetaling: noDotAndInteger(aanbetalingRef.current.value)
            }
        });
    }


    
    const validateLeasebedrag = (leasebedrag,manual_aanbetaling,inlossing,inruil,aanschaf_slot,current_field=null, aanbetaling, btw_marge, aanschafwaarde, aanschafwaarde_excl) => {
        // var aanbetaling_to_check = manual_aanbetaling || aanbetaling;
        let valid = true;
        var errMessage = {};
        // || ( (leasebedrag-aanbetaling_to_check)+(inlossing-inruil) >aanschaf_slot) 
        if( (leasebedrag>aanschaf_slot) ) {
            valid = false;
            errMessage.title = "Leasebedrag te hoog";
            errMessage.content = ["Het leasebedrag kan niet hoger zijn dan de aanschafwaarde Excl. BTW.", <br />, "Verhoog de aanbetaling of de inruil."]
        }
        if( (/btw/i.test(btw_marge)) &&  (aanschafwaarde_excl>aanschafwaarde) ) {
            valid = false;
            errMessage.title = "Aanschaf Excl. te hoog";
            errMessage.content = "Aanschaf Excl.BTW mag niet hoger zijn dan Aanschaf Incl.BTW";
        }
        if(leasebedrag<5000) {
            valid = false;
            errMessage.title = "Leasebedrag te laag";
            errMessage.content = "Leasebedrag kan niet lager zijn dan \u20AC 5.000,00";
        }
        if(!valid) {      
            setCalculatorMessage(prevState => {
                return {
                    ...prevState,
                    show: true,
                    title: errMessage.title,
                    content: errMessage.content
                }
            });      
        } else {
            setCalculatorMessage({show: false});
            valid = true;
        }
        setFormIsValid(valid);
    }

    const bankChangeHandler = () => {
        var calcsrc = calcsrcRef.current.value;
        if(!calcsrc) return;
        var default_provisiepercentage;
        if(calcsrc==="df") default_provisiepercentage = 12.5;
        if(calcsrc==="dfrr") default_provisiepercentage = 5;
        const { rentes } = getClientInterestRates(calcsrc, {leasebedrag: calculatorState.leasebedrag, final_looptijd: calculatorState.looptijd, final_slottermijn: -calculatorState.slottermijn});
        setCalculatorState(prevState => {
            return {
                ...prevState,
                calcsrc: calcsrc,
                rente: "",
                klantrente: "",
                provisiepercentage: "",
                default_provisiepercentage, 
                rentes,
                calcrente: ""
            }
        });
        setTimeout(function() {
            calcChangeHandler(); 
        }, 500);
    }

    useEffect(() => {
        (!calculatorMessage.show && (parseInt(calculatorState.leasetermijn)>0) ) ? setFormIsValid(true) : setFormIsValid(false);
    },[calculatorMessage, calculatorState.leasetermijn]);

    const calcChangeHandler = evt => {
        var current_field = evt?.target.name || calculatorState.current_field || "";
        if(current_field==="slottermijn") setSlottermijnIsTouched(true);
        if(current_field==="aanbetaling") setAanbetalingIsTouched(true);
        if(current_field==="aanschafwaarde_excl_btw") setAanschafExTouched(true);
        
        var type_getal = 0;
        var inruil = dotAndInteger(inruilRef.current?.value) || "";
        var inlossing = dotAndInteger(inlossingRef.current?.value) || "";

        var aanschafwaarde = dotAndInteger(aanschafRef.current?.value) || "";
        var aanschafwaarde_excl = dotAndInteger(aanschafExRef.current?.value) || "";
        var btw_bedrag = dotAndInteger(btwbedragRef.current?.value) || "";
        var looptijd = dotAndInteger(looptijdRef.current?.value) || "";
        var max_looptijd = calculatorState.max_looptijd;
        var aanbetaling = dotAndInteger(aanbetalingRef.current?.value) || "";
        var calcsrc = calcsrcRef.current?.value || "df";
        if(role && !role.includes("Admin")) {
            calcsrc = props.calcsrc || "df";
        }
        var slottermijn = dotAndInteger(slottermijnRef.current?.value) || "";
        var selectedIndex = renteRef.current?.selectedIndex || "";

        // rest_bpm
        var rest_bpm = calcRestBpm(leeftijd_in_maanden, bruto_bpm);
        if(props.restBPM) rest_bpm = +props.restBPM || 0;
        if(/Bedrijf/i.test(voertuigsoort) || /Bedrijf/i.test(calculatorState.vehicleType)) rest_bpm = 0;

        // aanschaf_excl
        aanschafwaarde_excl = (current_field==="aanschafwaarde_excl_btw" || aanschafExTouched) ? aanschafwaarde_excl : calcExPrice(aanschafwaarde, rest_bpm, "0");
        aanschafwaarde_excl = +aanschafwaarde_excl;

        // string to integer
        aanbetaling = +aanbetaling;
        inlossing = +inlossing;
        inruil = +inruil;
        
        // leasebedrag
        var leasebedrag = (/btw/i.test(btw_marge)) ? (aanschafwaarde_excl - aanbetaling - inruil)+inlossing : (aanschafwaarde - aanbetaling - inruil)+inlossing;      
        var aanschaf_slot = (/btw/i.test(btw_marge)) ? aanschafwaarde_excl : aanschafwaarde;

        var provisiepercentage = 0;
        var basisrente, klantrente, calcrente;

        if(selectedIndex) {
            // provisiepercentage = renteRef.current[selectedIndex]?.getAttribute("data-pp") || calculatorState.default_provisiepercentage;
            provisiepercentage = (/^(fi|hi)$/.test(calcsrc) && current_field!=="rente") ? calculatorState.prov : renteRef.current[selectedIndex]?.getAttribute("data-pp");
            var calculated_basisrente = getClientInterestRates(calcsrc, {leasebedrag: noDotAndInteger(leasebedrag), final_looptijd: calculatorState.looptijd, final_slottermijn: calculatorState.slottermijn}, "TEST").basisrente;
            basisrente = (/^(al|dfrr|fi|hi)$/.test(calcsrc) && current_field!=="rente") ? calculated_basisrente : renteRef.current[selectedIndex]?.getAttribute("data-br");
            klantrente = (/^(al|dfrr|fi|hi)$/.test(calcsrc) && current_field!=="rente") ? (renteState.rente || "") : renteRef.current[selectedIndex]?.getAttribute("data-kr");
            calcrente = +(basisrente ? basisrente : klantrente);
            if(/^(al)$/.test(calcsrc)) calcrente = klantrente;
        }

        validateLeasebedrag(leasebedrag,calculatorState.manual_aanbetaling,inlossing,inruil,aanschaf_slot,current_field, aanbetaling, btw_marge, aanschafwaarde, aanschafwaarde_excl);    
    
        // slottermijn
        if(current_field!=="slottermijn" && !slottermijnIsTouched) slottermijn = calcDefaultLastAmount(aanschaf_slot, looptijd);
        var max_slottermijn = calcMaxLastAmount(aanschaf_slot, looptijd);
            if(slottermijnIsTouched && (slottermijn>max_slottermijn) && !maxSlottermijnDisabled) {
                setCalculatorMessage(prevState => {
                    return {
                        ...prevState,
                        show: true,
                        title: ["Slottermijn te hoog"],
                        content: ["De opgegeven slottermijn is hoger dan toegestaan.", <br />, "De maximale slottermijn is \u20AC "+max_slottermijn, <br />, "De slottermijn is automatisch aangepast naar het maximum"]
                    }
                });
                slottermijn = max_slottermijn;
                setTimeout(function() {
                    setCalculatorMessage(prevState => {
                        return {
                            ...prevState,
                            show: false
                        }
                    })
                }, 3000);
                // setShowMessage(true);
            }
            if(slottermijnIsTouched && (slottermijn>max_slottermijn)) {
                setShowMessage(true);
            }
        
        // btw_bedrag
        if(current_field!=="btw_bedrag") btw_bedrag = calcBtwBedrag(aanschafwaarde, aanschafwaarde_excl);

        var provisietotaal = (leasebedrag/100)*provisiepercentage;
        // var leasebedrag_org = props.leaseAmount || leasebedrag;
        var leasebedrag_org = leasebedrag;

        var final_leasebedrag = (/\b(fi|fi_e)\b/.test(calcsrc) || calcsrc==="dfrr") ? (leasebedrag+provisietotaal) : leasebedrag;
        
        if(calcsrc==="al") { 
            type_getal = 1;
            calcrente = calcNominaleRente(calcrente);
        }
        var leasetermijn = "";
            leasetermijn = PMTx(calcrente, looptijd, final_leasebedrag, slottermijn, type_getal);       
            
        var { provisietotaal, provisiebedrag_rest, provisiebedrag_dealer } = calcProvisieBedrag(calcsrc, looptijd, leasetermijn, slottermijn, leasebedrag_org, provisiepercentage, dealerCategory, 0, 0, noDotAndInteger(inlossingRef.current?.value));       

        provisietotaal = +provisietotaal;


        const { rentes } = getClientInterestRates(calcsrc, {leasebedrag: final_leasebedrag, final_looptijd: looptijd, final_slottermijn: -slottermijn}, "HIER");
        
        // POST transform calcfields
        var min_aanbetaling = calculatorState.min_aanbetaling;
        if(aanbetaling<min_aanbetaling) totale_aanbetaling = min_aanbetaling;
        var totale_aanbetaling = calcTotaleAanbetaling(btw_marge, aanbetaling, btw_bedrag);

        if(calcsrc==="dfrr") max_looptijd = 60;
        if(max_looptijd>72 || !max_looptijd) max_looptijd = 72;
        setCalculatorState(prevState => {
            return {
                ...prevState,
                ...(current_field && {current_field: current_field}),
                inruil: formatThousandNotation(inruil) || "",
                inlossing: formatThousandNotation(inlossing) || "",
                leasebedrag: formatThousandNotation(leasebedrag) || "",
                aanschafwaarde: formatThousandNotation(aanschafwaarde) || "",
                aanschafwaarde_excl: formatThousandNotation(aanschafwaarde_excl) || "",
                btw_bedrag: formatThousandNotation(btw_bedrag) || "",
                slottermijn: formatThousandNotation(slottermijn) || "",
                looptijd: looptijd,
                aanbetaling: formatThousandNotation(aanbetaling) || "",
                calcsrc: calcsrc,
                leasetermijn: formattedPMT_v2(leasetermijn),
                max_slottermijn: formatThousandNotation(max_slottermijn) || "",
                min_aanbetaling: min_aanbetaling,
                rentes: rentes,
                provisiepercentage: provisiepercentage || "",
                calcrente: calcrente || "",
                provisietotaal,
                provisiebedrag_rest,
                provisiebedrag: provisiebedrag_dealer,
                dealerCategory: dealerCategory,
                totale_aanbetaling,
                max_looptijd,
                klantrente: klantrente || ""
            }
        });
        initialLoad = false;
    }

    useEffect(() => {
        calcChangeHandler();
    },[dealerCategory]);

    useEffect(() => {
        if(!calculatorState.leasetermijn) return;
        // description: (inlossingChecked ? JSON.stringify(inlossing) : ''),
        dispatch(dossierActions.saveCalculation({
            ...calculatorState,
            btw_marge: btw_marge,
            calc_popup: (props.leaseAmount ? true : false),
            description: (calculatorState.inlossing ? JSON.stringify(inlossing) : '')
        }));
        dispatch(dossierActions.saveInlossingDetails({...inlossing}));
    },[calculatorState.leasetermijn, inlossing, calculatorState.btw_bedrag]);  

    useEffect(() => {
        // load default provisie for inventory
        if(calculatorState.provisiepercentage) return;
        renteRef.current.value = 12.5;
        calcChangeHandler();
    },[calculatorState.leasebedrag]);

    return (
        <>
            {!props.disableTitle && <h1>Bereken maandbedrag</h1>}

            <div className="grid md:grid-cols-2 gap-10">
                <div className={`flex flex-col gap-3`}>
                    {calculatorMessage.show && <div className="border-2 bg-[#f8d7da] border-[#f5c6cb] color-[#721c24] p-3 text-sm">{calculatorMessage.content}</div>}
                    {(role && role.includes("Admin")) && 
                        <>
                        <Select disableFirst={true} ref={calcsrcRef} set="chosenBank" name="chosenBank" label="Bank" value={calculatorState.calcsrc} onChange={bankChangeHandler} />
                        </>
                    }
                    <div className={`relative ${(showTutorial && (!tutorialStepName || tutorialStepName==="calculator_aanschafwaarde_incl") ) ? 'z-10' : ''}`}>
                        {(showTutorial && (!tutorialStepName || tutorialStepName==="calculator_aanschafwaarde_incl")) && <Uitleg nextStepName="calculator_aanbetaling" prevStepName="" view="absolute" arrowPosition="center" title="Aanschafwaarde incl. BTW" description={["Bereken het maandbedrag door te rekenfactoren aan te passen.", <br />, "Het aanschafbedrag is automatisch gekoppeld aan het gekozen voertuig."]} />}
                        <Input valuta="euro" name="aanschafwaarde" placeholder={/btw/i.test(btw_marge) ? "Aanschafwaarde Incl. BTW" : "Aanschafwaarde"} inputMode="numeric" value={calculatorState.aanschafwaarde} ref={aanschafRef} onChange={calcChangeHandler} />
                    </div>
                    
                    {/btw/i.test(btw_marge) &&
                        <>
                            <Input backgroundColor="#ccc" valuta="euro" readOnly name="btw_bedrag" placeholder="BTW-bedrag" inputMode="numeric" value={calculatorState.btw_bedrag} ref={btwbedragRef} onChange={calcChangeHandler} help={["Het is niet mogelijk de BTW mee te nemen in de Financial Lease.", <div className="h-2">&nbsp;</div>, "De klant dient deze van tevoren bij u aan te betalen. Is het niet mogelijk om de BTW in één keer te voldoen? Geen enkel probleem! Wij kunnen eventueel de mogelijkheden bekijken voor een kortlopende financiering. Neem hiervoor contact op met ", <strong>SLD</strong>]} />
                            <Input valuta="euro" name="aanschafwaarde_excl_btw" placeholder="Aanschaf Excl. BTW" inputMode="numeric" value={calculatorState.aanschafwaarde_excl} ref={aanschafExRef} onChange={calcChangeHandler} />
                        </>
                    }

                    <div className={`relative ${showTutorial && tutorialStepName==="calculator_aanbetaling" ? 'z-10' : ''}`}>
                        {showTutorial && tutorialStepName==="calculator_aanbetaling" && <Uitleg nextStepName="calculator_inruil" prevStepName="calculator_aanschafwaarde_incl" view="absolute" arrowPosition="center" title="Aanbetaling" description={["Aan te betalen bedrag", <br />, "Lorem"]} />}
                        <Input valuta="euro" onBlur={blurAanbetalingHandler} name="aanbetaling" placeholder="Aanbetaling" inputMode="numeric" value={calculatorState.aanbetaling} ref={aanbetalingRef} onChange={calcChangeHandler} help="Wilt de klant een extra aanbetaling doen naast de (eventuele) BTW? U kunt hier het gewenste bedrag invullen. Wij zullen dit in mindering brengen van het leasebedrag."/>
                   </div>
                    {/* <div className="text-xs">Minimale aanbetaling is: &euro; {calculatorState.min_aanbetaling}</div> */}
                    <div className={`relative ${showTutorial && tutorialStepName==="calculator_inruil" ? 'z-10' : ''}`}>
                        {showTutorial && tutorialStepName==="calculator_inruil" && <Uitleg nextStepName="calculator_leasebedrag" prevStepName="calculator_aanbetaling" view="absolute" arrowPosition="center" title="Inruil" description={["Inruilwaarde van uw inruilauto", <br />, "Lorem"]} />}
                        <Input valuta="euro" name="inruil" placeholder="Inruil" inputMode="numeric" value={calculatorState.inruil} ref={inruilRef} onChange={calcChangeHandler} help={["Heeft de klant een inruilauto? Vul hier de waarde in! Dit zal dan worden gebruikt als extra aanbetaling op de financiering.", <div className="h-2">&nbsp;</div>, "Wilt de klant dit niet meenemen in de financiering? Laat het veld dan leeg. Het is dan ook noodzakelijk om hier een losse factuur van te maken."]} />
                    </div>
                    <Input valuta="euro" name="inlossing" placeholder="Inlossing openstaand saldo" inputMode="numeric" value={calculatorState.inlossing} ref={inlossingRef} onChange={calcChangeHandler} />

                    <div className={`relative ${showTutorial && tutorialStepName==="calculator_leasebedrag" ? 'z-10' : ''}`}>
                        {showTutorial && tutorialStepName==="calculator_leasebedrag" && <Uitleg nextStepName="calculator_slottermijn" prevStepName="calculator_inruil" view="absolute" arrowPosition="center" title="Leasebedrag" description={["Het kredietbedrag", <br />, "Lorem"]} />}
                        <Input valuta="euro" name="leasebedrag" help={["Uw leasebedrag wordt berekend als volgt:", <div className="h-2">&nbsp;</div>,"Aanschaf Excl.BTW", <br />, "Aanbetaling -", <br />, "Inruil -", <br />, "Eventuele inlossing +"]} placeholder="Leasebedrag" inputMode="numeric" value={calculatorState.leasebedrag} ref={leasebedragRef} onChange={calcChangeHandler} />
                    </div>

                    <div className={`relative ${showTutorial && tutorialStepName==="calculator_slottermijn" ? 'z-10' : ''}`}>
                        {showTutorial && tutorialStepName==="calculator_slottermijn" && <Uitleg nextStepName="calculator_looptijd" prevStepName="calculator_leasebedrag" view="absolute" arrowPosition="center" title="Slottermijn" description={["Bedrag dat u aan het einde van de looptijd betaald.", <br />, "Lorem"]} />}
                        <Input valuta="euro" name="slottermijn" placeholder="Slottermijn" inputMode="numeric" value={calculatorState.slottermijn} ref={slottermijnRef} onChange={calcChangeHandler} info="Dit is het bedrag dat aan het einde van de looptijd nog openstaat. Over dit bedrag betaal je tijdens de looptijd geen aflossing. Het slottermijn wordt afgeschreven met het laatste maandtermijn." />
                        {showMessage && <div className="text-xs">Maximale slottermijn is: &euro; {calculatorState.max_slottermijn}</div>}
                    </div>

                    <div className={`relative ${showTutorial && tutorialStepName==="calculator_looptijd" ? 'z-10' : ''}`}>
                        {showTutorial && tutorialStepName==="calculator_looptijd" && <Uitleg nextStepName="calculator_provisie" prevStepName="calculator_leasebedrag" view="absolute" arrowPosition="center" title="Looptijd" description={["Duur van de lease in maanden", <br />, "Lorem"]} />}
                        <InputRange suffix=" maanden" label="Looptijd" name="looptijd" min="12" max={calculatorState.max_looptijd} step="6" ref={looptijdRef} value={calculatorState.looptijd} onChange={calcChangeHandler} />
                    </div>
                    {(role.includes("Admin") && props.currentCalculation) && 
                        <Input valuta="euro" name="administrationCost" placeholder="Handelingsfee" value={calculatorState.administrationCost} onChange={(evt) => setCalculatorState(prevState => ({...prevState, administrationCost: evt.target?.value?.replace(".", ",")}))} />
                    }

                    <div className={`relative ${showTutorial && tutorialStepName==="calculator_provisie" ? 'z-10' : ''}`}>
                        {showTutorial && tutorialStepName==="calculator_provisie" && <Uitleg prevStepName="calculator_looptijd" view="absolute" arrowPosition="center" title="Leasebedrag" description={["Het kredietbedrag", <br />, "Lorem"]} />}
                        <details className="p-3 border border-slate-300 cursor-pointer text-slate-500">
                                <summary>Aanvullende instellingen</summary>
                                {calculatorState.rentes &&
                                <select id="rente" className={`border border-slate-300 rounded-xl p-3`} name="rente" ref={renteRef} value={calculatorState.provisiepercentage} onChange={calcChangeHandler}>
                                    <option value=""></option>
                                    {calculatorState.leasebedrag && calculatorState.rentes.map((rente,index) => {       
                                        var calcsrc = calculatorState.calcsrc;
                                        
                 
                                        var provisie = calcsrc_p[calcsrc][index]; 
                                        if(!provisie) return; 
                                        var klantrente = rente.klantrente ? rente.klantrente : rente;
                                        var selected_calcsrc = calcsrcRef.current?.value || "df";
                                        if(role && !role.includes("Admin")) {
                                            selected_calcsrc = props.calcsrc || "df";
                                        }

                                        var selected_rest_bpm = calcRestBpm(leeftijd_in_maanden, bruto_bpm);
                                        if(props.restBPM) selected_rest_bpm = +props.restBPM || 0;
                                        if(/Bedrijf/i.test(voertuigsoort) || /Bedrijf/i.test(calculatorState.vehicleType)) selected_rest_bpm = 0;

                                        var selected_aanschafwaarde = dotAndInteger(aanschafRef.current?.value) || 0;
                                        var selected_aanschafwaarde_excl = (aanschafExTouched) ? dotAndInteger(aanschafExRef.current?.value) : calcExPrice(selected_aanschafwaarde, selected_rest_bpm, "0");

                                        var selected_aanbetaling = dotAndInteger(aanbetalingRef.current?.value) || 0;        
                                        var selected_inruil = dotAndInteger(inruilRef.current?.value) || 0;
                                        var selected_inlossing = dotAndInteger(inlossingRef.current?.value) || 0;
                                        var selected_slottermijn = dotAndInteger(slottermijnRef.current?.value) || 0;
                                        selected_inlossing=+selected_inlossing;
                                        // var selected_leasebedrag = (/btw/i.test(btw_marge)) ? (selected_aanschafwaarde_excl - selected_aanbetaling) : (selected_aanschafwaarde - selected_aanbetaling);      
                                        var selected_leasebedrag = (/btw/i.test(btw_marge)) ? ((selected_aanschafwaarde_excl - selected_aanbetaling -selected_inruil)+selected_inlossing) : ((selected_aanschafwaarde - selected_aanbetaling-selected_inruil)+selected_inlossing);      
                                        selected_leasebedrag = +selected_leasebedrag;    

                                        var leasebedrag = (/btw/i.test(btw_marge)) ? (selected_aanschafwaarde_excl - selected_aanbetaling) : (selected_aanschafwaarde - selected_aanbetaling);  

                                        leasebedrag = +leasebedrag; 

                                        var selected_looptijd = looptijdRef.current?.value;  
                                        selected_looptijd = +selected_looptijd;
            
                                        var selected_basisrente = rente.basisrente;
                                        var selected_calcrente = (selected_basisrente ? selected_basisrente : klantrente);
                                        var selected_dealer_category = dealerCategory;

                                        var type_getal = 0; 
                                        if(selected_calcsrc==="al") { 
                                            type_getal = 1;
                                            selected_calcrente = calcNominaleRente(selected_calcrente);
                                            if(selected_looptijd<=24 && provisie>2) return;
                                        }
                                        var selected_provisiepercentage = calcsrc_p[calcsrc][index]; // provsiepercentage

                                        var selected_final_leasebedrag = (/\b(fi|fi_e)\b/.test(calcsrc) || calcsrc==="dfrr") ? (selected_leasebedrag) : selected_leasebedrag;
        
                                        var selected_final_calcrente = +selected_calcrente;

                                        var selected_leasetermijn = PMTx(selected_final_calcrente, selected_looptijd, selected_final_leasebedrag, selected_slottermijn, type_getal);  
                                        selected_leasetermijn = +selected_leasetermijn;

                                        var { provisiebedrag_dealer } = calcProvisieBedrag(selected_calcsrc, selected_looptijd, selected_leasetermijn, selected_slottermijn, selected_final_leasebedrag, selected_provisiepercentage, selected_dealer_category, 0, 0, selected_inlossing);

                                        var randomId = Math.floor(Math.random() * 999999);
                                        /** /calc prov */
                                        return (<option data-key={'pp'+calcsrc+randomId} key={'pp'+calcsrc+randomId} data-pp={provisie} data-br={rente.basisrente} data-kr={rente.klantrente ? rente.klantrente : rente} value={provisie}>klantrente: {klantrente}%   -   provisiebedrag: &euro; {provisiebedrag_dealer}</option>)
                                    })}
                                </select>
                            }
                            {(role.includes("Admin") && typeof props.currentCalculation) && (
                                <div className="border-2 bg-[#d1ecf1] border-[#bee5eb] color-[#0c5460] mt-5 p-4">
                                <div className="font-semibold">Admin info</div>

                            <div className="">Totale provisie: &euro; {calculatorState.provisietotaal ? formattedPMT_v2(calculatorState.provisietotaal): ''}</div>
                            <div className="">Provisie dealer: &euro; {calculatorState.provisiebedrag!=='NaN' ? formattedPMT_v2(calculatorState.provisiebedrag) : ''}</div>
                            <div className="">Provisie rest: &euro; {calculatorState.provisiebedrag_rest!=='NaN' ? formattedPMT_v2(calculatorState.provisiebedrag_rest) : ''}</div>
                            <div className="">dealerCategory: {calculatorState.dealerCategory ? calculatorState.dealerCategory : ''}</div>
                            </div>
                            )}

                        </details> 
                    </div>
       

                </div>
                <div className={`flex flex-col max-w-sm`}>
                    <div className={`${showTutorial ? 'bg-white z-20': ''} p-1 gap-5`}>
                        <div className="text-2xl font-semibold">Overzicht aanbetalingen:</div>
                        {/btw/i.test(btw_marge) &&
                        <div className="grid grid-cols-7">
                            <div className="col-span-4 text-md">BTW</div>
                            <div className="col-span-2 text-md font-semibold">&euro; {calculatorState.btw_bedrag ? <>{formatThousandNotation(calculatorState.btw_bedrag)}</> : <>0</>}</div>
                            <div>+</div>
                        </div>
                        }
                        <div className="grid grid-cols-7">
                            <div className="col-span-4 text-md">Aanbetaling</div>
                            <div className="col-span-2 text-md font-semibold">&euro; {calculatorState.aanbetaling ? <>{formatThousandNotation(calculatorState.aanbetaling)}</> : <>0</>}</div>
                            <div>+</div>
                        </div>
                        <div className="grid grid-cols-7 border-t">
                            <div className="col-span-4 text-md">Totaal aan te betalen</div>
                            <div className="col-span-2 text-xl font-semibold">&euro; {calculatorState.totale_aanbetaling ? <>{formatThousandNotation(calculatorState.totale_aanbetaling)}</> : <>0</>}</div>
                            <div>&nbsp;</div>
                        </div>

                        <div className="text-2xl font-semibold mt-5">Samenvatting financiering:</div>
                        <div className="grid grid-cols-7">
                            <div className="col-span-4 text-md">Te financieren bedrag</div>
                            <div className="col-span-2 text-md font-semibold">&euro; {calculatorState.leasebedrag ? <>{formatThousandNotation(calculatorState.leasebedrag)}</> : <>0</>}</div>
                            <div>&nbsp;</div>
                        </div>
                        <div className="grid grid-cols-7">
                            <div className="col-span-4 text-md">Slottermijn</div>
                            <div className="col-span-2 text-md font-semibold">&euro; {calculatorState.slottermijn ? <>{formatThousandNotation(calculatorState.slottermijn)}</> : <>0</>}</div>
                            <div>&nbsp;</div>
                        </div>
                        <div className="grid grid-cols-7">
                            <div className="col-span-4 text-md">Looptijd</div>
                            <div className="col-span-2 text-md font-semibold">{calculatorState.looptijd ? <>{formatThousandNotation(calculatorState.looptijd)} maanden</> : <>0</>}</div>
                            <div>&nbsp;</div>
                        </div>
                        {(formIsValid && calculatorState.provisiepercentage && calculatorState.provisietotaal>0 && calculatorState.klantrente) &&
                        <div className="grid grid-cols-7 items-center border-t">
                            <div className="col-span-4 text-md">Maandbedrag</div>
                            <div className="col-span-2 text-xl font-semibold">&euro; {calculatorState.looptijd ? <>{formatThousandNotation(calculatorState.leasetermijn)}</> : <>0</>}</div>
                            <div>&nbsp;</div>

                        </div>
                        }
                    </div>
                    {props.currentCalculation 
                    &&
                    <div className="h-full flex flex-col flex-end justify-between items-end">
                    <details className="flex flex-col bg-slate-100 p-5 text-sm w-full">
                    <summary className="font-semibold cursor-pointer">Eerdere berekening</summary>
                    {Object.keys(props.currentCalculation).map((item, index) => {
                        if(/marge/i.test(props.typeOfDeal) && /(btw|excl)/i.test(item)) return;
                        if(!role.includes("Admin") && /(typeOfDeal|provisiepercentage)/.test(item)) return;
                        return (
                            <div key={'key'+index} className="flex flex-row justify-between">
                                <div className="">{item}</div>
                                <div className="">{props.currentCalculation[item]}</div>
                            </div>
                        )
                    })}
                    <Button onClick={props.onClose}>Deze berekening behouden</Button>
                    </details>
                    {!calculatorState.provisiepercentage && <div>Selecteer provisie</div>}
                        <div className="flex flex-col gap-5">
                        { (formIsValid && herbeoordeling && calculatorState.provisiepercentage && calculatorState.klantrente) && <button style={{backgroundColor: 'red'}} onClick={() => props.onUpdate(true, calculatorState, props.relatedID)} className="btn-primary hover:scale-105">Herbeoordeling aanvragen</button>}
                        {( (formIsValid) && (calculatorState.provisiepercentage) && (!herbeoordeling || role.includes("Admin") ) && (calculatorState.klantrente) ) &&  <button style={{backgroundColor: 'green'}} onClick={() => props.onUpdate(false, calculatorState)} className="btn-primary hover:scale-105">Financiering aanpassen</button>}
                        </div>
                    </div>
                    }
                </div>
            </div>

            <Modal title="Klant heeft lopend contract" show={showInlossing} onClose={() => setShowInlossing(false)}>
                <div>U heeft aangegeven dat er een openstaand saldo actief is op de inruilauto. Vul de volgende gegevens in:</div>
                <Input ref={inlossingKentekenRef} name="inlossing_kenteken" placeholder="Kenteken" value={inlossing.kenteken} onChange={inlossingHandler} />
                <Input ref={inlossingContractNrRef} name="inlossing_contractnummer" placeholder="Contractnummer" value={inlossing.contractnummer} onChange={inlossingHandler} />
                <Input ref={inlossingBankRef} name="inlossing_bank" placeholder="Bank" value={inlossing.bank} onChange={inlossingHandler} />
                <Button type="button" className="btn-primary" onClick={submitInlossingHandler}>Bevestigen</Button>
            </Modal>
            <PrevNext onShowPopup={() => setShowInlossing(true)} showInlossingModal={calculatorState.inlossing && !inlossingConfirmed} inlossingConfirmed={inlossingConfirmed} nextDisabled={!formIsValid} />
            </>
    )
}

export default Calculator2;