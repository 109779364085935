import { escapeSpecialChars } from "./voertuig";

export const consoleLog = (message, forceDebug) => {
    if(!message) return;
    var debug = false;
    if(forceDebug) debug = true;
    // if(debug) console.log(message);
}

export const serialize = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && obj[p]!=="") {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

export const setError = (err) => {

    var errorResponse, title, objKeys;
    console.log(JSON.stringify(err));
    if(err.message) errorResponse = JSON.parse(err.message);
    if(errorResponse) var { title } = errorResponse;
    
    const errors = [];
    if(errorResponse) {
      objKeys = Object.keys(errorResponse.errors);
      Object.values(errorResponse.errors).forEach(function(e,i) {
        errors.push(objKeys[i]+":"+e[0]);
      });
    }
    title = "";
    return { title, errors };
}

export const setStorageItem = (itemName, itemData, storageType='session') => {
    if(!Storage || !itemName || !itemData) return;
    storageType==="local" ? localStorage.setItem(itemName, JSON.stringify(itemData)) : sessionStorage.setItem(itemName, JSON.stringify(itemData));
}

export const removeStorageItem = async (items, storageType='session') => {
    if(!Storage || !items.length) return;
    await items.forEach(function(itemName, i) {
        storageType==="local" ? localStorage.removeItem(itemName) : sessionStorage.removeItem(itemName);
    });
}

export const getStorageItem = (itemName, storageType='session') => {
    if(!Storage || !itemName) return;
    var returnValue = storageType==="local" ? JSON.parse(localStorage.getItem(itemName)) : JSON.parse(sessionStorage.getItem(itemName));
    return returnValue;
}

export const noDotAndInteger = (inputValue) => {
  if(typeof inputValue==="number") return inputValue;
  if(inputValue === null || inputValue===undefined) return;
  inputValue = inputValue.replace(".", "");
  inputValue = +inputValue;
  return inputValue;
}

export const getBase64ImageFromURL = (url, retryCounter=1) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      console.log("image error..", error);
      if(retryCounter>2) {
        reject(error);
        return;
      };
      retryCounter = retryCounter + 1;
      getBase64ImageFromURL(url, retryCounter);
    };

    img.src = url;
  });
}
export const mapToIdAndLabel = (inputArr) => {
  return inputArr.map(item => {
    return (
        {
            id: escapeSpecialChars(item),
            label: escapeSpecialChars(item)
        }
    )
  });
}