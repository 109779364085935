import styled from "styled-components";
import placeholderImage from "assets/images/placeholder.png";
import KentekenPlaat from "components/ui/KentekenPlaat";
import { formatThousandNotation } from "lib/calc";
import { escapeSpecialChars, friendlyFuelType, friendlyTransmission } from "lib/voertuig";

const StyledDiv = styled.div`
    &:hover, &:hover .fa {
        background-color: ${props => props.primaryColor ? props.primaryColor : 'black'};
        color: ${props => props.buttonTextColor ? props.buttonTextColor : 'white'};
    }
`;

const Result = (props) => {
    const { view, data } = props;
    const imageUrl = data.vehicleImages[0]?.uploadURL || "";
    var uitvoering = escapeSpecialChars(data.type) || "";
    var merk = escapeSpecialChars(data.make);
    var model = escapeSpecialChars(data.model);

    return (
        <StyledDiv primaryColor={props.primaryColor} buttonTextColor={props.buttonTextColor} className="grid grid-cols-5 mb-12 bg-white shadow-lg rounded-xl" onClick={props.onClick}>
            <div className={`relative ${view === 'list' && 'col-span-2'} ${view === 'grid' && 'col-span-5'}`}>
                <img alt={merk} className="rounded-bl-none lg:rounded-bl-xl rounded-l-xl max-w-full mb-3 lg:mb-0" src={imageUrl ? imageUrl : placeholderImage} />
                {data.licensePlate && (
                <div className="lg:hidden">
                    <KentekenPlaat size="xs" kenteken={data.licensePlate} />
                    <div className="p-3 text-xs font-bold">&euro; {formatThousandNotation(data.price)} {data.prijzen_in_ex==='e' && 'excl BTW'}</div> 

                </div>
                )} 
            </div>
            
            <div className={`flex flex-col ${view === 'list' ? 'p-2 md:p-3 lg:p-5 col-span-3' : 'p-3 col-span-5'}`}>
                <div className="flex flex-row justify-between items-center">
                    <h3 className={`m-0 ${view==='grid' && 'tracking-tighter'} text-xl lg:text-3xl font-bold`}>{merk} {model}</h3>
                    <div className="hidden md:block font-bold text-xl lg:text-2xl">&euro; {formatThousandNotation(data.price)} {data.prijzen_in_ex==='e' && 'excl BTW'}</div>
                </div>
                <div className="font-light pt-1 text-sm lg:text-lg">{uitvoering.slice(0, 35)}{uitvoering.length>35 ? '...' : ''}</div>
                <div className={`grid grid-cols-2 my-3 max-w-xs text-xs lg:text-base font-light ${view==='grid' ? 'leading-6' : 'leading-5 lg:leading-7'}`}>
                    <div className="flex flex-row gap-x-2 lg:gap-x-3">
                        <div className="hidden lg:flex w-2 lg:w-4 flex-row justify-center">
                            <span className="text-slate-700 fa fa-road"></span>
                        </div>
                        <span>{data.mileage ? formatThousandNotation(data.mileage)+" km" : ""}</span>
                    </div>
                    <div className="flex flex-row gap-x-2 lg:gap-x-3">
                        {data.transmission && (
                        <div className="hidden lg:flex w-2 lg:w-4 flex-row justify-center">
                            <span className="text-slate-700 fa fa-engine"></span>
                        </div>
                        )}
                        <span>{data.transmission ? friendlyTransmission(data.transmission) : ""}</span>
                    </div>
                    <div className="flex flex-row gap-x-2 lg:gap-x-3">
                    <div className="hidden lg:flex w-2 lg:w-4 flex-row justify-center">
                            <span className="text-slate-700 fa fa-calendar-days"></span>
                        </div>
                        <span>{data.constructionYear}</span></div>
                        <div className="flex flex-row gap-x-2 lg:gap-x-3">
                        <div className="hidden lg:flex w-2 lg:w-4 flex-row justify-center">

                            <span className="text-slate-700 fa fa-gauge-simple-max"></span>
                        </div>
                        <div><span>{data.engineHP} PK</span><span className="hidden md:flex-row"> / {data.powerMotorKW} kW</span></div>
                    </div>


                    <div className="flex flex-row gap-x-2 lg:gap-x-3">
                    <div className="hidden lg:flex w-2 lg:w-4 flex-row justify-center">

                            <span className="text-slate-700 fa fa-gas-pump"></span>
                        </div>

                        <span>{data.fuelType ? friendlyFuelType(data.fuelType) : ""}</span></div>
                        <div className="flex flex-row gap-x-2 lg:gap-x-3">
                        <div className="hidden lg:flex w-2 lg:w-4 flex-row justify-center">

                            <span className="text-slate-700 fa fa-location-dot"></span>
                        </div>
                        <span>{data.marginVAT ? data.marginVAT.replace(/^M$/i, "Marge").replace(/b.*/i, "BTW")+"-auto" : ""}</span>
                        </div>
                </div>
                <div className="mt-auto hidden lg:block justify-between">
                    <div className="flex flex-row justify-between items-center">
                        <div className={`flex ${view==='grid' ? 'flex-col' : 'flex-row'} items-end gap-x-2 lg:gap-x-3`}>
                            {data.licensePlate && <KentekenPlaat kenteken={data.licensePlate} />}
                        </div>
                        <div className="relative">
                            <span className="text-md xl:text-2xl">
                                <span>Selecteer voertuig</span>
                                <span className="fa fa-chevron-right ml-5"></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </StyledDiv>
    )
}

export default Result;