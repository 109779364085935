import Container from "components/hoc/Container";
import Content from "components/Content/Content";
import DealOverzicht from "components/Tables/DealOverzicht";

const PageDeals = () => {
  return (
    <>
      <Container type="fullscreen">
        <Content title="Verkopen" />
      </Container>
      <div className="mx-auto">
        <DealOverzicht />
      </div>
    </>

  );
};

export default PageDeals;
