const Table = (props) => {
    const className = "w-full table-auto text-xs lg:text-base text-left xshadow-xl";
    return (
        <table className={`${className} ${props.className ? props.className : ''}`}>
            {props.children}
        </table>
    )
}

export const Thead = props => {
    return (
        <thead className="text-xs text-slate-700 bg-[#212853]">{props.children}</thead>
    )
}

export const Th = props => {
    return (
        <th {...props} className={`py-2 px-3 lg:py-5 lg:px-6 first:rounded-tl-xl first:lg:rounded-tl-3xl last:rounded-tr-3xl text-white ${props.className ? props.className : ''}`}>{props.children}</th>
    )
}

export const Td = props => {
    return (
        <td {...props} className={`py-2 px-3 lg:py-4 lg:px-6 ${props.className ? props.className : ''}`}>{props.children}</td>
    )
}

export const Tr = (props) => {
    return (
        <tr {...props} className={`${props.disableclasses ? '' : 'border-b-2 border-[#212853]'} ${props.thead ? "" : "hover:bg-[#F6901E] hover:text-white"} ${props.className ? props.className : ''}`}>{props.children}</tr>
    )
}

export default Table;