import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const usePagination = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(searchParams.get("page") || 1);
    const [pageSize, setPageSize] = useState(searchParams.get("perpage") || 25);

    const setQuery = (queryObj) => {
        const { perpage, page } = queryObj;
        var query = "";
        if(perpage!==25) query+="perpage="+perpage+"&";
        if(page && page!==1) query+="page="+page;
        query = query.replace(/&$/,"");
        setSearchParams(query);
    }

    const nextPageHandler = () => {
        var newPageNumber = +(pageNumber)+1;
        setPageNumber(newPageNumber);
        setQuery({
            page: newPageNumber,
            perpage: pageSize
        });
    }

    const prevPageHandler = () => {
        var newPageNumber = +(pageNumber)-1;
        if(newPageNumber<1) pageNumber = 1;
        setPageNumber(newPageNumber);
        setQuery({
            page: newPageNumber,
            perpage: pageSize
        });
    }

    const changePageSize = (perpage) => {
        setPageSize(perpage);
        setQuery({
            page: pageNumber,
            perpage: perpage
        });
    }

    const toFirstPageHandler = (perpage) => {
        setPageNumber(1);
        setQuery({
            page: 1,
            perpage: perpage
        });
    }

    return {
        page: pageNumber,
        perpage: pageSize,
        nextPageHandler,
        prevPageHandler,
        changePageSize,
        toFirstPageHandler
    }
}

export default usePagination;