import { Route, Routes, Outlet } from "react-router-dom";

import HomeStap from "routes/financiering/financial-lease/index";
import BtwFinanciering from "routes/financiering/btw-financiering/index";
import OperationalLease from "routes/financiering/operational-lease/index";
import PrivateFinance from "routes/financiering/private-finance/index";
import StapBtwMarge from "routes/financiering/financial-lease/btw-marge";
import StapCalculator from "routes/financiering/financial-lease/calculator";
import StapContact from "routes/financiering/financial-lease/contactgegevens";
import StapBedankt from 'routes/financiering/financial-lease/bedankpagina';
import StapVoertuigGegevens from "routes/financiering/financial-lease/voertuiggegevens";
import BtwFinancieringBedankt from "./btw-financiering/bedankpagina";

const FinancieringRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Outlet />}>
                <Route path="/financial-lease" element={<HomeStap />} />
                <Route path="/financial-lease/voertuiggegevens" element={<StapVoertuigGegevens />} />
                <Route path="/financial-lease/btw-marge" element={<StapBtwMarge />} />
                <Route path="/financial-lease/calculator" element={<StapCalculator />} />
                <Route path="/financial-lease/klantgegevens" element={<StapContact />} />
                <Route path="/financial-lease/bedankpagina" element={<StapBedankt />} />
                <Route path="/btw-financiering" element={<BtwFinanciering />} />
                <Route path="/btw-financiering/bedankpagina" element={<BtwFinancieringBedankt />} />
                <Route path="/operational-lease" element={<OperationalLease />} />
                <Route path="/private-finance" element={<PrivateFinance />} />
            </Route>
        </Routes>
    )
}

export default FinancieringRoutes;