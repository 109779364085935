import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import useAuth from "hooks/use-auth";
import { sortDataByKey } from "lib/voertuig";
import { dealerActions } from "store/dealer-slice";
import useDealer from "hooks/use-dealer";
import SmartSearch from "../SmartSearch/SmartSearch";

const SelectDealer = (props) => {
    const dispatch = useDispatch();
    const [dealers, setDealers] = useState([]);
    const [options, setOptions] = useState([]);
    const { token: jwt } = useAuth();
    const dealerRef = useRef();

    const { dealerID } = useDealer();

    useEffect(() => {
        fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/Dealer?pageSize=9999&isActive=true', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },        
        })
        .then(resp => resp.json())
        .then(responseData => {
            setDealers(responseData.data);
            var options = responseData.data.map(option => {
                return {
                    label: option.name+' ('+option.dealerID+')',
                    value: option.dealerID
                }
            });
            var sortedOptions = sortDataByKey(options, 'label');
            setOptions(sortedOptions);
        })
        .catch(err => console.log(err));
    },[]);

    const changeDealerHandler = (obj) => {
        if(!obj) return;
       var selectedDealerID = obj.value || "";
       const selectedDealerData = dealers.filter(dealer => dealer.dealerID===+selectedDealerID)[0];
       dispatch(dealerActions.setDealerData(selectedDealerData));
    }

    useEffect(() => {
        changeDealerHandler();
    },[]);

    return (
        <>
        <SmartSearch ref={dealerRef} value={dealerID} options={options} label="Kies een bedrijf" placeholder="Kies een bedrijf" onChange={id => changeDealerHandler(id)} disabled={props.disabled} />
        </>
    )
};

export default SelectDealer;