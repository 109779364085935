import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import PageContact from "./routes/contact/index";
import './fonts/kenteken.ttf';
import { Provider } from "react-redux";
import store from "./store/index";

import Page404 from "./routes/page-404";
import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedCrmRoute from "./routes/ProtectedCrmRoute";

import FinancieringRoutes from './routes/financiering/routes';
import VerzekeringRoutes from "./routes/verzekering/routes";
import AdminRoutes from './routes/admin/routes';

import PageAdminDealers from "./routes/superadmin/page-clients";
import ScrollToTop from 'components/ui/ScrollToTop';
import PageHome from 'routes/index'; 

import CrmRoutes from 'routes/crm/routes';
import PageProfiel from 'routes/profiel';
import ReactGA from "react-ga4";

import Auth from 'components/Auth/Auth';
import Introductie from 'routes/introductie';
import Bedankpagina from 'routes/bedankpagina';
import PageVoorraadModule from 'routes/voorraadmodule';
const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize("G-J8TVR886LJ");

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" index element={<PageHome />} />
          <Route path="/financiering/*" element={<ProtectedRoute><FinancieringRoutes /></ProtectedRoute>} />         
          
  	      <Route path="/login" element={<Auth case="login" title="Inloggen" /> } />
          <Route path="/activeren" element={<Auth case="activate" title="Account activeren" />} />
          <Route path="/wachtwoord-vergeten" element={<Auth case="forgotpwd" title="Wachtwoord vergeten" /> } />
          <Route path="/wachtwoord-reset" element={<Auth case="resetpwd" title="Reset wachtwoord" /> } />

          <Route path="verkopen/*" element={<ProtectedRoute><AdminRoutes /></ProtectedRoute>} />
          <Route path="bedrijven" element={<ProtectedRoute><PageAdminDealers /></ProtectedRoute>} />
          <Route path="verzekering/*" element={<ProtectedRoute><VerzekeringRoutes /></ProtectedRoute>} />
          <Route path="profiel" element={<ProtectedRoute><PageProfiel /></ProtectedRoute>} />
          <Route path="voorraadmodule" element={<ProtectedRoute><PageVoorraadModule /></ProtectedRoute>} />

          <Route path="autobedrijf-aanmelden" element={<Introductie />} />
          <Route path="autobedrijf-aanmelden/bedankpagina" element={<Bedankpagina />} />

          <Route path="contact" element={<PageContact />} />
          <Route path="*" element={<Page404 />} />
        </Route>
        <Route path="crm/*" element={<ProtectedCrmRoute><CrmRoutes /></ProtectedCrmRoute>} />
      </Routes>
      </ScrollToTop>
    </BrowserRouter>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(consoleLog))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();