import React, { useState, useEffect, useRef, Suspense } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import useAuth from "hooks/use-auth";
import useFormValidation from "hooks/use-form-validation";

import Input from "components/ui/Input/Input";
import Select from "components/ui/Select/Select";
import InputColor from "components/ui/InputColor/InputColor";
import { updateDealer, archiveDealer, removeDealer } from "store/dealer-actions";
import { dateToDutch } from "lib/kvk";
import Card from "components/ui/Card/Card";
import Button from "components/ui/Button/Button";
import Accordion from "components/ui/Accordion/Accordion";
import DropArea from "components/ui/DropArea/DropArea";
import { uiActions } from "store/ui-slice";
import Toggle from "components/ui/Toggle/Toggle";
import SmartSearch from "components/ui/SmartSearch/SmartSearch";
import useDealers from "hooks/use-dealers";
const Pdf2 = React.lazy(() => import("components/Pdf/Pdf2"));

const DealerForm = (props) => {
  const importIDRef = useRef();
  const { data: dealersData, isLoaded: dealerDataLoaded } = useDealers();

  const [sentMail, setSentMail] = useState({
    activation: false,
    forgotpwd: false
  });

  const [searchImportId, setSearchImportId] = useState(false);
  const [isSaved, setIsSaved] = useState(0);
  const [dealer, setDealer] = useState(''); 
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [logoDeleted, setLogoDeleted] = useState(false);
  const logoImageUrlFormRef = useRef();
  const isActiveRef = useRef();

    const params = useParams();
    const auth = useAuth();
    const { token: jwt, userInfo, role } = auth;
    const dispatch = useDispatch();
    var { dealerID } = params;
    if(!dealerID) dealerID = userInfo.dealerID;

    useEffect(() => {
          if(!dealerID) return;
            dispatch(uiActions.showBackdrop());
            fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Dealer/'+dealerID, {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + jwt
              }
            })
            .then(resp => {
              if(resp.status===401) throw new Error("401");
              return resp.json();
            })
            .then(data => {
              setDealer({
                ...data,
                status: (data.status ? data.status : ""),
                phone: (data.phone ? data.phone : ""),
                iban: (data.iban ? data.iban : "")
              });
              dispatch(uiActions.hideBackdrop());
            })
            .catch(() => {
              dispatch(uiActions.hideBackdrop());
            });
    }, [dealerID, isSaved, dispatch, jwt]);

    useEffect(() => {
      (!dealer.logoImageUrl) ? setSentMail(prevState => ({...prevState, activation: true})) : setSentMail(prevState => ({...prevState, activation: false}));
    },[dealer]);

    const submitFormHandler = async (evt) => {
      evt.preventDefault();

      const updatedDealer = {
        ...dealer
      }     
      updatedDealer.logoImageUrlForm = logoImageUrlFormRef.current?.files[0];
      if(logoDeleted) updatedDealer.logoImageUrl = "";
      updatedDealer.postalCode = (updatedDealer.postalCode) ? updatedDealer.postalCode.replace(/\s/g, "").toUpperCase() : "";
      updatedDealer.isActive = isActiveRef.current.checked;
      updatedDealer.rating = 0;

      if(updatedDealer.dealerDomain && !/http(s)?:\/\//.test(updatedDealer.dealerDomain)) updatedDealer.dealerDomain = "https://"+updatedDealer.dealerDomain;

      const dealerID = dealer.dealerID;
      const keys = Object.keys(updatedDealer);
      const values = Object.values(updatedDealer);
      const updatedFormData = new FormData();
      keys.forEach(function(key,index) {
        var value = values[index] || "";
        if(value) {
          updatedFormData.append(key, value);
        }
      });
      updatedFormData.isActive = isActiveRef.current.checked;
      const response = await dispatch(updateDealer(dealerID, updatedFormData, jwt));
      if(response==="SUCCESS") setIsSaved(prevState => prevState + 1);
    };
  
    const archiveDealerHandler = (obj, reset) => {
        const updatedDealer = {
          ...obj,
          isArchive: (reset ? false : true)
        }
        const dealerID = dealer.dealerID;
        const keys = Object.keys(updatedDealer);
        const values = Object.values(updatedDealer);
        const updatedFormData = new FormData();
        keys.forEach(function(key,index) {
          updatedFormData.append(key, values[index]);
        });
        dispatch(archiveDealer(dealerID, updatedFormData, jwt));
    }

    const removeDealerHandler = (id, jwt) => {
      dispatch(removeDealer(id, jwt));
    }

    const valueChangeHandler = (evt, addObj=false) => {
      const dataName = evt?.target?.name || addObj.name;
      var dataValue = evt?.target?.value || addObj.value;

      console.log(dataName);
      console.log(dataValue);

      if(dataName==="dealerDomain" && dataValue) dataValue=dataValue.trim().toLowerCase();

      setDealer(prevState => {
        return {
          ...prevState,
          [dataName]: (dataValue ? dataValue : "")
        }
      });
    };

    const sendMailHandler = (mailCase, dealerId) => {
      dispatch(uiActions.showBackdrop());

      var url;
      var options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+jwt
        }
      }

      if(mailCase==="activation") {
        url = "/api/Authenticate/resendEmailVerificationToDealerV2?dealerId="+dealerId;
      }
      if(mailCase==="forgotpwd") {
        url = "/api/Authenticate/forgot-password";
        options.method = "POST";
        options.body = JSON.stringify({
          "email": dealer.email
        });
      }

      fetch(process.env.REACT_APP_SLD_API_BASE_URL+url, options)
      .then(() => {
        setSentMail(prevState => {
          return {
            ...prevState,
            [mailCase]: true
          }
        });
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({visible: true, type: "success", message: "Er is een e-mail verstuurd"}));
      }).catch(() => {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({visible: true, type: "danger", message: "Er is wat fout gegaan bij het versturen. Probeer opnieuw"}));
      });
    }

    const [formIsValid] = useState(false);
    const { ref: ibanRef, hasError: ibanInputHasError } = useFormValidation();

    
    if(!dealer || !dealerDataLoaded) return <>Laden...</>;

    return (
      <>
        <form className="text-xs lg:text-base mt-5" onSubmit={submitFormHandler}>
        <Card title={dealer.name ? (dealer.name + (dealer.chamberNumber!=null ? ' (KvK '+dealer.chamberNumber+')' : "")) : 'Autobedrijf aanmaken'} titleEnd={dealer.dealerID}>
          <div className="bg-white grid md:grid-cols-3 items-start group">
            <div className="grid col-span-2 gap-3 p-5">
              <Accordion open={true} summary="Bedrijfsgegevens">
                <div className="grid grid-cols-2 gap-3 py-5">
                  <Input value={dealer.name} onChange={valueChangeHandler} placeholder="Bedrijfsnaam" name="name" />           
                  <Input value={dealer.chamberNumber} onChange={valueChangeHandler} placeholder="KvK nummer" name="chamberNumber" />           
                  {role.includes("Admin") &&
                  <Select set="dealerCategory" value={dealer.dealerCategory} errorMessage="Classificatie is een verplicht veld" onChange={valueChangeHandler} label="Classificatie" name="dealerCategory" />
                  }
                  <Input value={dealer.postalCode} onChange={valueChangeHandler} placeholder="Postcode" name="postalCode" />
                  <Input value={dealer.address} onChange={valueChangeHandler} placeholder="Adres" name="address" />      
                  <Input value={dealer.place} onChange={valueChangeHandler} placeholder="Plaats" name="place" />
                  <Input hasError={ibanInputHasError} errorMessage="IBAN dient uit 18 karakters te bestaan" name="iban" placeholder="IBAN" value={dealer.iban} ref={ibanRef} onChange={valueChangeHandler} />
                  <Input value={dealer.vaTnumber} onChange={valueChangeHandler} placeholder="BTW nummer" name="vaTnumber" />
                  {role.includes("Admin") &&
                  <div className="grid grid-cols-2 gap-3">
                    <Input disabled={true} value={dealer.importID} name="importID" label="Import ID" placeholder="Import ID" onChange={valueChangeHandler} />
                    <Button type="button" onClick={() => setSearchImportId(true)}><span className="fa fa-magnifying-glass"></span></Button>
                    <div className={`${searchImportId ? 'col-span-2' : 'hidden'}`}>                    
                      <SmartSearch ref={importIDRef} value={dealer.importID} onChange2={valueChangeHandler} name="importID" options={dealersData} label="Import ID" placeholder="Import ID" />
                    </div>
                  </div>
                  }
                  <Input value={dealer.website} onChange={valueChangeHandler} placeholder="Website" name="website" />
                </div>
              </Accordion>                 
              <Accordion open={true} summary="Hoofdcontactpersoon">
                <div className="grid grid-cols-2 gap-3 py-5">
                <Input value={dealer.telephone} onChange={valueChangeHandler} placeholder="Telefoonnummer" name="telephone" />
                <Input value={dealer.phone} placeholder="Mobiel nummer" onChange={valueChangeHandler} name="phone" />
                <Input value={dealer.email} onChange={valueChangeHandler} placeholder="E-mail" name="email" />
                </div>
              </Accordion>
              <Accordion summary="Vormgeving">
                <div className="grid grid-cols-2 gap-3 py-5">
                  <div className="col-span-2 gap-3 flex flex-col">
                  <DropArea onAdd={() => setLogoDeleted(false)} onDelete={() => setLogoDeleted(true)} ref={logoImageUrlFormRef} value={dealer.logoImageUrl} uploadText="Upload logo" name="logoImageUrlForm" />
                  <InputColor placeholder="Primaire kleur" name="primaryColor" onChange={valueChangeHandler} value={dealer.primaryColor} />
                  <InputColor placeholder="Button tekstkleur" name="buttonTextColor" onChange={valueChangeHandler} value={dealer.buttonTextColor} />  
                  </div>
                </div>
              </Accordion>
              {role.includes("Admin") &&
              <>
              <Accordion summary="Account verwijderen">
              <div className="font-bold underline text-[#ff0000] hover:text-black cursor-pointer mt-5 w-fit" onClick={(id) => {removeDealerHandler(dealer.dealerID, jwt)}}>Ja, ik wil het account definitief verwijderen</div>
              </Accordion>
              <Accordion summary="Voorraadmodule">
                  <div className="grid grid-cols-2 gap-3 py-5 items-center">
                    {/* <span className="break-words col-span-2 bg-slate-100 xborder xp-1 xborder-slate-300 text-sm">{dealer.dealerToken}</span> */}
                    <Input name="dealerDomain" value={dealer.dealerDomain} label="Subdomein" placeholder="Subdomein" onChange={valueChangeHandler} />
                    {/* <div className="grid grid-cols-3">
                        <span className="col-span-2">AccesstoCreateDeal</span><span>{JSON.stringify(dealer.hasAccesstoCreateDeal)}</span>
                        <span className="col-span-2">AccesstoGetDealer</span><span>{JSON.stringify(dealer.hasAccesstoGetDealer)}</span>
                        <span className="col-span-2">AccesstoGetVehicleFilters</span><span>{JSON.stringify(dealer.hasAccesstoGetVehicleFilters)}</span>
                        <span className="col-span-2">AccesstoGetVehicles</span><span>{JSON.stringify(dealer.hasAccesstoGetVehicles)}</span>
                    </div> */}
                    <a href={`${dealer.dealerDomain ? dealer.dealerDomain : 'https://dealermodule.sld.nl'}?api=${dealer.dealerToken}`} rel="noreferrer" target="_blank" className="btn btn-secondary h-fit">Bekijk voorraadmodule</a>
                  </div>
                  <div className="text-xs flex flex-col">
                  <p className="mb-3">Beste {dealer.name},</p>
                  <p className="mb-3">Om de dealermodule van SLD in te laden op uw eigen website. Kunt u onderstaande verzoek direct doorsturen naar uw eigen webbouwer.</p>
                  <p className="mb-3">Beste webbouwer,</p>
                  <p className="mb-3">Ik zou graag de dealermodule van SLD op onze website inladen. Hierbij het verzoek om onderstaande code toe te voegen.</p>
                  <div className="mb-3 flex flex-col bg-slate-200">
                    <code>&lt;script type="text/javascript" src="https://dealermodule.sld.nl/iframeResizer.min.js"&gt;&lt;/script&gt;</code>
                    <code>&lt;div id="sld_dealermodule" data-api="{dealer.dealerToken}" data-domain="{dealer.dealerDomain}"&gt;&lt;/div&gt;</code>
                  </div>
                  <div>Let op: Plaats de div met id "sld_dealermodule" op de plaats waar de voorraadmodule van SLD ingeladen dient te worden.</div>
                  <div></div>
                </div>

              </Accordion>
              </>
            }

            </div>
            <div className="flex flex-col col-span-2 md:col-span-1 gap-5 p-5 bg-slate-50 h-full justify-between">
              <div className="">
                <div className="flex flex-col gap-y-5 mb-5">
                  <div className="flex flex-row items-center gap-x-2">
                    <div className={`rounded-full w-8 h-8 p-0 m-0 items-center ${dealer.isActive ? 'bg-[#008002]': 'bg-[#FF0000]'}`}></div>
                    <div>{dealer.isActive ? "Actief" : "Niet actief"}</div>
                  </div>
                  <div>Datum aangemaakt {dateToDutch(dealer.createdDate)}</div>
                  <div>Laatst bijgewerkt {dateToDutch(dealer.updatedDate)}</div>

                  <div><Toggle label="Actief" ref={isActiveRef} checked={dealer.isActive ? true : false} /></div>
                  <div><Select set="statusDealer" value={dealer.status} onChange={valueChangeHandler} label="Dealer status" name="status" /></div>
                </div>
                <div className="flex flex-row gap-5">
                <Button className="bg-gradient" data-disabled={!formIsValid}>Opslaan</Button>
                {(role.includes("Admin")) &&
                  <>
                  {!dealer.isArchive && <Button type="button" className="btn-secondary" onClick={(obj) => archiveDealerHandler(dealer)}>Archiveren</Button>}
                  {dealer.isArchive && <Button type="button" className="btn-secondary" onClick={(obj) => archiveDealerHandler(dealer, 'reset')}>Herstellen uit archief</Button>}
                  </>
                }
                </div>
                <div className="flex flex-col mt-5">
                  <Button type="button" onClick={() => setDownloadPdf(true)}>Test PDF</Button>
                </div>
                <div className="flex flex-col mt-10 gap-3">
                  <Button type="button" onClick={() => sendMailHandler('activation', dealer.dealerID)} disabled={sentMail.activation}>Verstuur activatiemail</Button>
                  <Button type="button" onClick={() => sendMailHandler('forgotpwd')} disabled={sentMail.forgotpwd}>Verstuur wachtwoord reset</Button>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </form>
      {downloadPdf && <Suspense fallback={<div>Loading ...</div>}><Pdf2 dealerDetail={dealer} hostname={"https://"+window.location.hostname} onDone={() => setDownloadPdf(false)} /></Suspense>}
    </>
    )
}

export default DealerForm;