import { NavLink } from "react-router-dom";
import Button from "components/ui/Button/Button";
import PartnerForm from "components/Forms/PartnerForm/PartnerForm";

const PartnerDetail = () => {
  return (
    <>
      <NavLink to=".."><Button type="button" className="btn-secondary">Terug naar partners</Button></NavLink>
      <PartnerForm detail={true} />     
    </>
  );
};

export default PartnerDetail;
