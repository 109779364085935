import Container from "components/hoc/Container";
import ProgressBar from "components/ProgressBar/ProgressBar";
import useHead from "hooks/use-head";
import Calculator2 from "components/Calculator/Calculator_nieuw";

const StapCalculator = props => { 
    useHead({title: "Calculator"});
    return (
        <>
            <Container type="fullscreen">
                <ProgressBar />
            </Container>
            <Container>
                <Calculator2 />
                {/* <PrevNext prevLink="/stap-btw-marge" nextLink="/financiering/financial-lease/contactgegevens" /> */}
            </Container>
        </>
    )
}

export default StapCalculator;