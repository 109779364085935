import { createSlice } from "@reduxjs/toolkit";
import { consoleLog } from "lib/helpers";

const initialState = {
    currentCase: sessionStorage.getItem("currentCase") || "FinancialLease",  // Verzekering | FinancialLease | PrivateFinance | BtwFinanciering | OperationalLease --> PascalCase
    currentIndex: 0,
    activeFunnel: false,
    prevPath: "",
    nextPath: "",
    steps: {
      "FinancialLease": [     
          {pathname: "/financiering/financial-lease", title: "Financial Lease"},
          {pathname: "/financiering/financial-lease/voertuiggegevens", title: "Voertuiggegevens"},     
          {pathname: "/financiering/financial-lease/btw-marge", title: "BTW / Marge"},    
          {pathname: "/financiering/financial-lease/calculator", title: "Calculator"},
          {pathname: "/financiering/financial-lease/klantgegevens", title: "Contactgegevens"},
          {pathname: "/financiering/financial-lease/bedankpagina", title: "Bedankpagina"}
      ],
      "Verzekering": [
          {pathname: "/verzekering", title: "Verzekering"},
          {pathname: "/verzekering/voertuiggegevens", title: "Voertuiggegevens"},     
          {pathname: "/verzekering/btw-marge", title: "BTW / Marge"},
          {pathname: "/verzekering/klantgegevens", title: "Klantgegevens"},
          {pathname: "/verzekering/bedankpagina", title: "Bedankpagina"}
      ],
      "BtwFinanciering": [
        {pathname: "/financiering/btw-financiering", title: "BTW financiering"},
        {pathname: "/financiering/btw-financiering/bedankpagina", title: "Bedankpagina"}
      ]
    }
};

export const progressSlice = createSlice({
    name: 'progress',
    initialState: initialState,
    reducers: {
        reset: () => initialState,
        changeCase(state, actions) {
            sessionStorage.setItem("currentCase", actions.payload);
            state.currentCase = actions.payload;
        },
        changeStep(state,actions) {
            consoleLog(actions.payload);
            state.activeFunnel = true;
            state.prevPath = actions.payload.prevPath;
            state.nextPath = actions.payload.nextPath;
            state.currentIndex = actions.payload.currentIndex;
        }
    }
});

export const progressActions = progressSlice.actions;