import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dossierActions } from "store/dossier-slice";
import Button from "components/ui/Button/Button";
import { userLogout } from "store/auth-actions";
import useHuisstijl from "hooks/use-huisstijl";
import SelectDealer from "components/ui/SelectDealer/SelectDealer";
import { resetDossier } from "store/dossier-actions";
import useTutorial from "hooks/use-tutorial";

const Header = () => {
  const dispatch = useDispatch();
  const { showTutorial, tutorialStepName } = useTutorial();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const companyName = useSelector((state) => state.dealer.dealer_naam);
  const activeFunnel = useSelector(state => state.progress.activeFunnel);

  const { userIsLoggedIn, role } = auth;
  const { logoImageUrl } = useHuisstijl();

  const logoutHandler = useCallback(() => {
    setMenuIsOpen(false);
    dispatch(userLogout());
    dispatch(dossierActions.reset());
  }, []);

  const clearDossierHandler = () => {
    dispatch(dossierActions.reset());
    dispatch(resetDossier());
    navigate("/financiering/financial-lease");
  }

  const [intervalClasses, setIntervalClasses] = useState("");

  useEffect(() => {
    if(tutorialStepName!=="financial-lease-bedankpagina") return;
    const interval = setInterval(() => {
      setIntervalClasses(prevState => prevState==="font-black" ? "" : "font-black");
    }, 650);
    return () => clearInterval(interval);
    }, [showTutorial, tutorialStepName]);

  return (
    <>
      {(role && (role.includes("Admin") || role.includes("SuperAdmin"))) && (
        <>
        <div className={`z-50 py-1 px-5 flex flex-row items-center justify-between bg-sld-blue gap-x-2`}>
              <div className="w-[400px] max-w-full xmax-w-fit">
                <SelectDealer disabled={activeFunnel} />
              </div>
              <div className="xw-1/2 text-right">
                <NavLink to="/crm" className="text-white font-black">Terug naar CRM</NavLink>
              </div>
          </div>
          </>)
      }
      {!activeFunnel && 
        <>
        {menuIsOpen && <div onClick={() => setMenuIsOpen(false)} className="fixed top-0 left-0 w-full h-full bg-black opacity-80 lg:hidden z-40"></div>}
        <div className="mobileMenu p-3 relative lg:hidden select-none bg-white-transparent">
          <div className="flex flex-row justify-between items-center"> 
            <img src={(logoImageUrl && logoImageUrl?.length>8) ? logoImageUrl : "/SLD_logo.png"} title={companyName} alt={companyName} className="max-h-[50px]" />
            <div className="flex flex-col">
              <span className="fa fa-bars items-center text-3xl px-3 cursor-pointer" onClick={() => setMenuIsOpen(true)}></span>
            </div>
          </div>
          <div className={`overflow-y-scroll lg:overflow-clip fixed top-0 x-left-full bg-white w-3/4 max-w-[400px] h-full z-50 shadow-2xl transition-all ${menuIsOpen ? 'right-0' : '-right-full'}`}>
            <div className="p-3 h-full">
            <div className="flex flex-col h-full">
              <div className="mb-5">
                  <div className="flex flex-row justify-between">
                        <img src={(logoImageUrl && logoImageUrl?.length>8) ? logoImageUrl : "/SLD_logo.png"} title={companyName} alt={companyName} className="max-h-[50px]" />
                        <span className="fa fa-xmark items-center text-3xl px-3 cursor-pointer lg:hidden select-none" onClick={() => setMenuIsOpen(false)}></span>
                    </div>

              </div>
              {userIsLoggedIn ?
              <>
              <NavLink end onClick={() => setMenuIsOpen(false)} to="/financiering/financial-lease" className={"py-2 border-b border-b-slate-300 border-slate-100"}>Financiering</NavLink>
              <div className="pl-3 flex flex-col"> 
                <NavLink onClick={() => setMenuIsOpen(false)} to="/financiering/financial-lease" className={(navData) => (navData.isActive ? "font-black" : "") + " py-2 border-b border-b-slate-300 border-slate-100"}>Financial Lease</NavLink>
                <NavLink onClick={() => setMenuIsOpen(false)} to="/financiering/btw-financiering" className={(navData) => (navData.isActive ? "font-black" : "") + " py-2 border-b border-b-slate-300 border-slate-100"}>BTW-financiering</NavLink>
              </div>
              <NavLink onClick={() => setMenuIsOpen(false)} to="/verzekering" className={(navData) => (navData.isActive ? "font-black" : "") + " py-2 border-b border-b-slate-300 border-slate-100 "}>Verzekering</NavLink>
              <NavLink onClick={() => setMenuIsOpen(false)} to="/verkopen" className={(navData) => (navData.isActive ? "font-black" : "") + " py-2 border-b border-b-slate-300 border-slate-100"}>Verkopen</NavLink>
              <NavLink onClick={() => setMenuIsOpen(false)} to="/voorraadmodule" className={(navData) => (navData.isActive ? "font-black" : "") + " py-2 border-b border-b-slate-300 border-slate-100"}>Voorraadmodule</NavLink>
              {/* <NavLink onClick={() => setMenuIsOpen(false)} to="/profiel" className={(navData) => (navData.isActive ? "font-black" : "") + " py-2 border-b border-b-slate-300 border-slate-100"}>Profiel</NavLink> */}
              </>
              :
              <>
              <NavLink onClick={() => setMenuIsOpen(false)} to="/" className={(navData) => (navData.isActive ? "font-black" : "") + " py-2 border-b border-b-slate-300 border-slate-100"}>Inloggen</NavLink>
              <NavLink onClick={() => setMenuIsOpen(false)} to="/autobedrijf-aanmelden" className={(navData) => (navData.isActive ? "font-black" : "") + " py-2 border-b border-b-slate-300 border-slate-100"}>Autobedrijf aanmelden</NavLink>
              </>
              }
              <NavLink onClick={() => setMenuIsOpen(false)} to="/contact" className={(navData) => (navData.isActive ? "font-black" : "") + " py-2 xborder-b border-b-slate-300 border-slate-100"}>Contact</NavLink>
              {userIsLoggedIn && <div onClick={logoutHandler} className="py-2 border-t border-t-slate-300 border-slate-100 cursor-pointer mt-auto">Uitloggen</div>}
            </div>
            </div>
          </div>
        </div>
        </>
      }

      <header className="py-2 lg:py-8 lg:flex border-b border-slate-200 hidden bg-white-transparent">
        <div className="container mx-auto px-5 xmax-w-5xl">
          <div className="grid grid-cols-12 xjustify-items-stretch items-center">
          {/* <div className="flex flex-row justify-between"> */}

            <div className="col-span-1 col-start-1 col-end-3">
              {activeFunnel
                ?
                <img src={(logoImageUrl && logoImageUrl?.length>8) ? logoImageUrl : "/SLD_logo.png"} title={companyName} alt={companyName} className="max-h-[100px]" />
                :
                <NavLink className={(navData) => (navData.isActive ? "font-black" : "")} to="/">
                  <img className="max-h-[100px] ml-5 lg:ml-0" src={(logoImageUrl && logoImageUrl?.length>8) ? logoImageUrl : "/SLD_logo.png"} title={companyName} alt={companyName} />
                </NavLink>
              }
            </div> 
            {!activeFunnel &&
              <>
                <nav className={`flex col-start-4 col-end-9 flex-row space-x-7 col-span-7 justify-start text-lg`}>
                  {/* <NavLink
                    className={(navData) => (navData.isActive ? "font-black" : "") + " py-2 px-3 cursor-pointer hover:bg-slate-300"}
                    to="/"
                  // onClick={startNewDossierHandler}
                  >
                    Home
                  </NavLink> */}

                  {userIsLoggedIn ?
                    <>
                      <div className="flex flex-row group relative justify-end">
                        <NavLink end to="/financiering/financial-lease" className="py-2"><span className="flex flex-row items-center"><span>Financiering</span><span className="fa fa-chevron-down text-xs text-slate-400 px-1"></span></span></NavLink>
                        <div className="z-20 hidden shadow group-hover:flex flex-col absolute text-base w-48 top-full bg-white">
                          <NavLink to="/financiering/financial-lease" className={(navData) => (navData.isActive ? "font-bold" : "") + " py-3 px-5 cursor-pointer hover:bg-slate-100 border-b border-slate-300"}>Financial Lease</NavLink>
                          {/* <NavLink to="/financiering/operational-lease" className={(navData) => (navData.isActive ? "text-orange" : "") + " py-2 px-3 cursor-pointer hover:bg-slate-300"}>Operational Lease</NavLink>
                          <NavLink to="/financiering/private-finance" className={(navData) => (navData.isActive ? "text-orange" : "") + " py-2 px-3 cursor-pointer hover:bg-slate-300"}>Private Finance</NavLink> */}
                          <NavLink to="/financiering/btw-financiering" className={(navData) => (navData.isActive ? "font-bold" : "") + " py-3 px-5 cursor-pointer hover:bg-slate-100 border-b border-slate-300"}>BTW-financiering</NavLink>
                        </div>
                      </div>
                      <NavLink
                        className={(navData) => (navData.isActive ? "font-black" : "") + " py-2"}
                        to="/verzekering"
                      >
                        Verzekering
                      </NavLink>
                    </>
                  :
                  <NavLink
                    className={(navData) => (navData.isActive ? "font-black" : "") + " py-2"}
                    to="/autobedrijf-aanmelden"
                  >
                    Autobedrijf aanmelden
                  </NavLink>
                  }
                  <NavLink
                    className={(navData) => (navData.isActive ? "font-black" : "") + " py-2"}
                    to="/contact"
                  >
                    Contact
                  </NavLink>
                </nav>
                <div className="col-start-10 col-end-13 flex justify-end">
                  {userIsLoggedIn && (
                    <>
                      <div className="flex flex-row group relative">
                        <div className="font-bold ml-1 p-2">
                          <div className="flex flex-row items-center text-lg">
                              <span className="fa fa-circle-user mr-2"></span>
                              <span>{auth.userInfo.name ? auth.userInfo.name : ''}</span>
                              <span className="fa fa-chevron-down text-xs text-slate-400 px-1"></span>
                          </div>
                        </div>
                        <div className={`${(showTutorial && tutorialStepName=="financial-lease-bedankpagina") ? "" : "hidden"} xleft-full w-40 shadow z-20 flex group-hover:flex flex-col absolute top-full bg-white`}>
                          <NavLink to="/verkopen" className={(navData) => (navData.isActive ? "font-black" : "")+ `${intervalClasses} py-3 px-5 cursor-pointer hover:bg-slate-100 border-b border-slate-300`}>Verkopen</NavLink>
                          <div className={`${intervalClasses} py-3 px-5 cursor-pointer hover:bg-slate-100 border-b border-slate-300 justify-between flex flex-row items-center`}><span>Versie {process.env.REACT_APP_VERSION}</span><span className="fa fa-arrows-rotate cursor-pointer" onClick={() => window.location.reload()}></span></div>
                          <NavLink to="/voorraadmodule" className={(navData) => (navData.isActive ? "font-black" : "")+ `${intervalClasses} py-3 px-5 cursor-pointer hover:bg-slate-100 border-b border-slate-300`}>Voorraadmodule</NavLink>
                          {/* <NavLink to="/profiel" className={(navData) => (navData.isActive ? "font-black" : "") + `${intervalClasses} py-3 px-5 cursor-pointer hover:bg-slate-100 border-b border-slate-300`}>Profiel</NavLink> */}
                          <div onClick={logoutHandler} className="py-3 px-5 cursor-pointer hover:bg-slate-100 border-b border-slate-300">Uitloggen</div>
                        </div>
                      </div>
                    </>
                  )}
                  {(!userIsLoggedIn && location.pathname !== "/") &&
                    <NavLink to="/">

                      <Button
                        className="btn-primary"
                      >
                        <div className="flex flex-row items-center">
                          <span>Inloggen</span>
                        </div>
                      </Button>
                    </NavLink>
                  }
                </div>
                </>
            }
            {(activeFunnel && userIsLoggedIn) && <div className="col-start-11 col-span-2 text-right xmr-5"><Button onClick={clearDossierHandler}>Aanvraag annuleren</Button></div>}
          </div>
        </div>
      </header>
    </>
  );
};

export default React.memo(Header);
