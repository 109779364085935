import { sendRequest } from "lib/calc"
import { uiActions } from "./ui-slice";
import { dealerActions } from "./dealer-slice";
import { setError } from "lib/helpers";
import { dossierActions } from "./dossier-slice";

export const getDealerById = (id, jwt) => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            const responseData = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Dealer/' + id, headers: { 'Authorization': 'Bearer ' + jwt } });
            dispatch(dealerActions.setSingleDealer(responseData));
            dispatch(uiActions.hideBackdrop());
        } catch (err) {
            const { title, errors } = setError(err);
            dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
            dispatch(uiActions.hideBackdrop());
        }
    }
}

export const fetchDealers = (jwt, paginationData) => {
    const page = paginationData?.page;
    const perpage = paginationData?.perpage;
    var searchParams;
    if(page || perpage) searchParams="?";
    if(page) searchParams+='PageNumber='+page+'&';
    if(perpage) searchParams+='PageSize='+perpage;
    searchParams=searchParams?.replace(/\&$/,"");

    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            const responseData = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Dealer'+(searchParams ? searchParams : ''), headers: { 'Authorization': 'Bearer ' + jwt } });
            dispatch(dealerActions.setDealers(responseData));
            dispatch(uiActions.hideBackdrop());
        } catch (err) {
            const { title, errors } = setError(err);
            dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
            dispatch(uiActions.hideBackdrop());
        }
    }
}

export const newDealer = (dealerObj, jwt) => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            const responseData = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Authenticate/registerDealerV2', method: "POST", headers: {'Authorization': 'Bearer ' + jwt }, body: dealerObj }, "formData");
            if(responseData.status==="Error") {
                throw new Error();
            }
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({ message: "Dealer succesvol aangemaakt!", type: "success"}));
            return "SUCCESS";
        } catch (err) {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({ message: "Fout bij het aanmaken van de dealer!", type: "danger"}));
            return JSON.parse(err.message);
        }
    }
}

export const updateDealer = (dealerID, dealerObj, jwt) => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            const responseData = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Dealer/'+dealerID, method: "PUT", headers: {'Authorization': 'Bearer ' + jwt }, body: dealerObj }, "formData");
            dispatch(uiActions.setAlert({ message: "Dealer succesvol bijgewerkt!", type: "success"}));
            dispatch(uiActions.hideBackdrop());
            return "SUCCESS";
        } catch (err) {
            setError(err.message);
            dispatch(uiActions.setAlert({ message: "Dealer kan niet bijgewerkt worden", type: "danger"}));
            dispatch(uiActions.hideBackdrop());
            return "FAIL";
        }
    }
}

export const archiveDealer = (dealerID, dealerObj, jwt) => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            const responseData = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Dealer/'+dealerID, body: dealerObj, method: "PUT", headers: { 'Authorization': 'Bearer ' + jwt } }, "formData");
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({ message: "Dealer succesvol gearchiveerd!", type: "success"}));
            dispatch(dossierActions.archiveDealer());

        } catch(err) {
            const { title, errors } = setError(err);
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({ message: "Er is wat fout gegaan bij het archiveren", type: "danger"}));
        }
    }
}

export const removeDealer = (id, jwt) => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            const responseData = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Dealer/'+id, method: "DELETE", headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt } });
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({ message: "Dealer succesvol verwijderd!", type: "success"}));

        } catch(err) {
            const { title, errors } = setError(err);
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({ message: "Er is wat fout gegaan bij het verwijderen", type: "danger"}));
        }
    }
}

export const clearDealer = () => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            dispatch(dealerActions.clearDealer());
            dispatch(uiActions.hideBackdrop());
        } catch(err) {
            const { title, errors } = setError(err);
            dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
            dispatch(uiActions.hideBackdrop());
        }
    }
}