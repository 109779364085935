import { createSlice } from "@reduxjs/toolkit";

export const dealSlice = createSlice({
    name: 'deal',
    initialState: {
        changeCounter: 0,
        changed: false,
        dealId: null,
        pageNumber: 1,
        pageSize: "",
        deals: [],
        dealDetail: {},
        dealFilters: {}
    },
    reducers: {
        setDealDetail(state, actions) {
            state.dealDetail = actions.payload;
        },
        setDealFilters(state, actions) {
            state.dealFilters = actions.payload;
        },
        setDeals(state, actions) {
            state.deals = actions.payload.data;
        },
        archiveDeal(state, actions) {
            state.changeCounter = state.changeCounter+1;
        },
        updateCounter(state) {
            state.changeCounter = state.changeCounter + 1;
        },
        updateDeal(state, actions) {
            if(actions.payload.objectName) {
                const { objectName, dataName, dataValue } = actions.payload;
                if(objectName==="vehicleDetail") {
                    state.dealDetail.vehicleDetail = {
                        ...state.dealDetail.vehicleDetail,
                        [dataName]: dataValue
                    }
                }
            } else {
                state.dealDetail = {
                    ...state.dealDetail,
                    ...actions.payload
                };
            }          
            state.changeCounter = state.changeCounter+1;
        },
        storeDeal(state, actions) {
            state.changed = true;
            state.deals = [...state.deals, actions.payload];
        },
        removeDeal(state, actions) {
            state.deals = state.deals.filter(deal => deal.dealID!==actions.payload.dealID);
            state.changed = true;
        }
    }
})

export const dealActions = dealSlice.actions;