import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import Calculator2 from "components/Calculator/Calculator_nieuw";
import { getSalutation } from "lib/voertuig";
import { resetDossier } from "store/dossier-actions";
import Footer from "components/Footer/Footer";

export const CrmPage = () => {
    const dispatch = useDispatch();

    const clearPreviousRequestHandler = () => {
        dispatch(resetDossier());
    }

    return (
        <div>
            <div className="text-3xl mb-10 lg:mb-20">{getSalutation(new Date().getHours())}</div>
            <div className="text-3xl mb-10">Wil je een nieuwe aanvraag indienen?</div>
            <div className="grid grid-cols-3 max-w-5xl mb-20 gap-3 lg:p-5 lg:gap-10">
                <NavLink onClick={clearPreviousRequestHandler} to="/financiering/financial-lease" className="flex flex-col p-3 lg:p-5 bg-white shadow-xl rounded-xl text-center justify-center items-center lg:text-2xl w-32 h-32 lg:w-64 lg:h-64 hover:shadow-2xl">Financial Lease aanvraag</NavLink>
                <NavLink onClick={clearPreviousRequestHandler} to="/verzekering" className="flex flex-col p-3 lg:p-5 bg-white shadow-xl rounded-xl text-center justify-center items-center lg:text-2xl w-32 h-32 lg:w-64 lg:h-64 hover:shadow-2xl">Verzekering aanvragen</NavLink>
                <NavLink onClick={clearPreviousRequestHandler} to="/financiering/btw-financiering" className="flex flex-col p-3 lg:p-5 bg-white shadow-xl rounded-xl text-center justify-center items-center lg:text-2xl w-32 h-32 lg:w-64 lg:h-64 hover:shadow-2xl">BTW-financiering aanvragen</NavLink>
            </div>
            <div className="text-3xl mb-10">Snelle calculatie</div>
            <Calculator2 disableTitle={true} />
        </div>
    )
}

export default CrmPage;