import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "components/ui/Input/Input";
import useAuth from "hooks/use-auth";
import Card from "components/ui/Card/Card";
import Button from "components/ui/Button/Button";
import { uiActions } from "store/ui-slice";

const NewPartnerForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const nameRef = useRef();
  const addressRef = useRef();
  const placeRef = useRef();
  const postalCodeRef = useRef();
  const websiteRef = useRef();
  const kvkNumberRef = useRef();
  const ibanNumberRef = useRef();
  const phoneNumber1Ref = useRef();
  const phoneNumber2Ref = useRef();
  const phoneNumber3Ref = useRef();
  const eMailAddress1Ref = useRef();
  const eMailAddress2Ref = useRef();
  const eMailAddress3Ref = useRef();
  
  const auth = useAuth();
  const { token: jwt } = auth; 

    const [partnerData, setPartnerData] = useState({
      "name": "",
      "address": "",
      "place": "",
      "postalCode": 0,
      "website": "",
      "kvkNumber": 0,
      "ibanNumber": "",
      "phoneNumber1": "",
      "phoneNumber2": "",
      "phoneNumber3": "",
      "eMailAddress1": "",
      "eMailAddress2": "",
      "eMailAddress3": ""
  });



const submitFormHandler = (evt) => {
  evt.preventDefault();
  dispatch(uiActions.showBackdrop());

  var website = websiteRef.current?.value;
  if(!/http/.test(website)) website = "https://"+website;

  var postalCode = postalCodeRef.current?.value.replace(/\s/g, "").toUpperCase();

  var partnerData = {
    "name": nameRef.current.value,
    "address": addressRef.current.value,
    "place": placeRef.current.value,
    "postalCode": postalCode,
    "website": website,
    "kvkNumber": kvkNumberRef.current.value,
    "ibanNumber": ibanNumberRef.current.value,
    "phoneNumber1": phoneNumber1Ref.current.value,
    "phoneNumber2": phoneNumber2Ref.current.value,
    "phoneNumber3": phoneNumber3Ref.current.value,
    "eMailAddress1": eMailAddress1Ref.current.value,
    "eMailAddress2": eMailAddress2Ref.current.value,
    "eMailAddress3": eMailAddress3Ref.current.value,
  }    
  fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Partner', {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + jwt
    },
    body: JSON.stringify(partnerData)
  }).then(resp => resp.json())
  .then(data => {
    setPartnerData(data);
    if(data.status===400) throw Error();
    dispatch(uiActions.hideBackdrop());
    dispatch(uiActions.setAlert({message: "Partner succesvol aangemaakt", type: "success"}));
    navigate("../");
  }).catch(() => {
    dispatch(uiActions.hideBackdrop());
    dispatch(uiActions.setAlert({message: "Partner kan niet aangemaakt worden", type: "danger"}));
  });
};
    return (
        <form onSubmit={submitFormHandler}>
          <Card title={'Partner aanmaken'}>
          <div className="bg-white grid grid-cols-3 items-start group">
          <div className="grid col-span-2  p-5">
          <h3>Contactgegevens partner</h3>
            <div className="grid grid-cols-2 gap-3">
                <Input ref={nameRef} placeholder="Naam" name="name" />
                <Input ref={addressRef} placeholder="Adres" name="address" />
                <Input ref={placeRef} placeholder="Plaats" name="place" />
                <Input ref={postalCodeRef} placeholder="Postcode" name="postalCode" />
                <Input ref={websiteRef} placeholder="Website" name="website" />
                <Input ref={kvkNumberRef} placeholder="KvK nummer" name="kvkNumber" />
                <Input ref={ibanNumberRef} placeholder="IBAN" name="ibanNumber" />
                <Input ref={phoneNumber1Ref} placeholder="Telefoon 1" name="phoneNumber1" />
                <Input ref={phoneNumber2Ref} placeholder="Telefoon 2" name="phoneNumber2" />
                <Input ref={phoneNumber3Ref} placeholder="Telefoon 3" name="phoneNumber3" />
                <Input ref={eMailAddress1Ref} placeholder="E-mailadres 1" name="eMailAddress1" />
                <Input ref={eMailAddress2Ref} placeholder="E-mailadres 2" name="eMailAddress2" />
                <Input ref={eMailAddress3Ref} placeholder="E-mailadres 3" name="eMailAddress3" />
        </div>
        </div>
        <div className="flex flex-col gap-5 p-5 bg-slate-50 h-full justify-between">
              <div> 
      
                <Button className="bg-gradient">Registreren</Button>
              </div>
            </div>
        </div>

        </Card>

      </form>
    )
}

export default NewPartnerForm;
