import { NavLink } from "react-router-dom";
import GebruikerOverzicht from "components/Tables/GebruikerOverzicht";

const Gebruikers = () => {
    return (
        <div>
            <h1>Gebruikers</h1>
      <NavLink
        to="nieuwe-gebruiker"
        className="hover:scale-105 bg-[#212853] w-[50px] h-[50px] rounded-full justify-center text-center flex flex-col text-white mb-12"
      >
        <span className="fa fa-plus"></span>
      </NavLink>
            <GebruikerOverzicht />
        </div>
    )
}

export default Gebruikers;