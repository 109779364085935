import "react-datepicker/dist/react-datepicker.css";

import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import nl from 'date-fns/locale/nl';
import DatePicker, { registerLocale } from "react-datepicker";

import { dateToDateFormat, calcAgeInMonths, localDate } from "lib/calc";
import { dossierActions } from "store/dossier-slice";
import { dateToDutch } from "lib/kvk";

import useInputTransition from "hooks/use-input-transition";
import Select from "../Select/Select";
import useWindowDimension from "hooks/use-window-dimensions";

registerLocale('nl', nl);

const InputDatum2 = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const windowDimensions = useWindowDimension();
    
    const dateChangeHandler = (date, dateType) => {
        if(props.onChange) {
            setStartDate(date);
            var myDate = new Date(date+"Z");
            props.onChange({name: props.name, value: new Date(myDate).toISOString()});
            return;
        }
        setStartDate(date);
        if (dateType === "geboortedatum") {
            dispatch(dossierActions.setAdditionalFieldData({
                geboortedatum: dateToDutch(date)
            }));
        }
        if (dateType === "datum_eerste_toelating") {
            dispatch(dossierActions.setVoertuigDataManually({
                eerste_registratie: date.toLocaleDateString('nl-NL'),
                datum_eerste_toelating_dt: "" + date,
                datum_eerste_toelating: date.toLocaleDateString('nl-NL'),
                leeftijd_in_maanden: calcAgeInMonths(date)
            }));
        }
    };

    var minYear = props.minYear || 1900;
    var maxYear = new Date().getFullYear()+1;
    if(props.yearsAbove) maxYear+=props.yearsAbove;

    const [startDate, setStartDate] = useState(dateToDateFormat(props.value));
    const {blurHandler, transitionClasses, isFocussed} = useInputTransition(props.value);

    const years = useMemo(() => range(minYear, maxYear));
    const months = [
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december",
    ];

    var smallScreen = windowDimensions.width<=1024;

    return (
        <div className={"flex flex-col relative "+props.className}>
            {props.placeholder && <label className={"pl-3 py-1 absolute z-20 transition ease-in "+transitionClasses}>{(props.value || isFocussed) && props.placeholder}</label>}
            <input type="hidden" ref={ref} value={localDate(startDate)} onBlur={props.onBlur} />
            <DatePicker
                withPortal={smallScreen}
                portalId="datepicker-root"
                className={"border border-slate-300 rounded-xl w-full max-w-full "+((isFocussed || props.value) ? "pl-3 px-3 pb-1 pt-5": 'p-3')}
                // onFocus={focusHandler}
                onFocus={e => (smallScreen ? e.target.blur() : {})}
                onCalendarOpen={() => {}}
                onBlur={blurHandler}
                name={props.name}
                autoComplete="off"
                locale="nl"
                dateFormat="dd-MM-yyyy"
                placeholderText={(!isFocussed && props.placeholder) ? props.placeholder : ''}
                calendarStartDay={1}
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth
                }) => (
                    // <div className="flex flex-row items-center justify-center">
                        <div className="grid grid-cols-2 ml-3 items-center">
                        <Select placeholder="Maand" options={[...months.map(option => ({value: option, label: option}))]}
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                            }                       >
                        </Select>
                        <Select
                            placeholder="Jaar"
                            options={[...years.map(option => ({value: option, label: option}))]}
                            className="input"
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                        >
                        </Select>


                    </div>
                )}
                selected={startDate}
                value={startDate}
                onChange={(date, dateType) => dateChangeHandler(date, props.name)}
            />
            {props.hasError && <p className="top-full absolute text-sm p-1 text-red-500">{props.errorMessage}</p>}
        </div>
    );
});

export default InputDatum2;