import { sendRequest } from "lib/calc";
import { dealActions } from "./deal-slice";
import { setError } from "lib/helpers";
import { uiActions } from "./ui-slice";

export const createActivity = async (action, deal, jwt) => {
  // offerte verzonden wordt al verstuurd

  var dealSource = deal.source || "";
  var inlossing_details = deal.description || "";
  
  var financeData = JSON.stringify({
    source: dealSource,
    typeOfDeal: deal.typeOfDeal,
    amountpurchase: deal.amountpurchase,
    commissionAmountDealer: deal.commissionAmountDealer,
    totalToPay: deal.totalToPay,
    downpayment: deal.downpayment,
    monthlyamount: deal.monthlyamount,
    vat: deal.vat,
    duration: deal.duration,
    administrationCost: deal.administrationCost,
    commissionAmountSLD: deal.commissionAmountSLD,
    amountpurchaseExclVAT: deal.amountpurchaseExclVAT,
    chosenBank: deal.chosenBank,
    finalpayment: deal.finalpayment,
    interest: deal.interest,
    provisiePercentage: deal.provisiePercentage,
    leaseAmount: deal.leaseAmount,
    tradeInAmount: deal.tradeInAmount,
    repayment: deal.repayment,
    inlossing_details
  });

  var file = "";
  var formData = new FormData();
  formData.append("name", ""+deal.phase);
  formData.append("description", financeData);
  formData.append("dealID", deal.dealID);
  formData.append("documentUrlForm", file);
  formData.append("offer", "2");
  formData.append("createAt", new Date());
 
  var bodyType = "";

  try {    
      switch(action) {
          case "create": bodyType = "Deal aangemaakt"; break;
          case "update": bodyType = "Deal bijgewerkt"; break;
          case "delete": bodyType = "Deal verwijderd"; break;
          case "gearchiveerd": bodyType = "Deal gearchiveerd"; break;
          case "gedearchiveerd": bodyType = "Deal gedearchiveerd"; break;
          case "Offerte verzonden": bodyType = "Offerte verzonden"; break;
          default: bodyType ="Overig"; break;
      }
      formData.append("type", bodyType);
      fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/ActivitiesDeal', {
        headers: {
          "Authorization": "Bearer " + jwt
        },
        body: formData,
        method: "POST",
      });
    } catch (err) {
      console.log("failed adding activity");
  }
}

export const fetchDealById = (id, jwt) => {
  return async (dispatch) => {
    dispatch(uiActions.showBackdrop());
    try {
      const responseData = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Deal/'+id, headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt } });
      dispatch(dealActions.setDealDetail(responseData));
      dispatch(uiActions.hideBackdrop());
    } catch (err) {
      const { title, errors } = setError(err);
      dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
      dispatch(uiActions.hideBackdrop());
    }
  };
}

export const getDealFilters = (jwt, query) => {
  return async (dispatch) => {
    dispatch(uiActions.showBackdrop());
    try {
      const responseData = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Deal/UniqueFilters', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt } });
      dispatch(dealActions.setDealFilters(responseData));
      dispatch(uiActions.hideBackdrop());
    } catch (err) {
      const { title, errors } = setError(err);
      dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
      dispatch(uiActions.hideBackdrop());
    }
  };
};

export const fetchDeals = (jwt, query, page, perpage) => {
  return async (dispatch) => {
    dispatch(uiActions.showBackdrop());
    try {
      query+="&pageNumber="+page+"&pageSize="+perpage;
      const responseData = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Deal/Filters'+(query ? '?'+query : ''), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt } });
      dispatch(dealActions.setDeals(responseData));
      dispatch(uiActions.hideBackdrop());
    } catch (err) {
      const { title, errors } = setError(err);
      dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
      dispatch(uiActions.hideBackdrop());
    }
  };
};

export const saveDeal = (dealData, jwt) => {
  return async (dispatch) => {
    dispatch(uiActions.showBackdrop());

    try {
      const { data_object: responseData } = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Deal/CreateDeal', method: 'POST', body: dealData, headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt } });
      // await dispatch(dealActions.storeDeal(responseData));
      await dispatch(dealActions.setDealDetail(responseData));
      dealData.dealID = responseData.dealID;
      createActivity('create', dealData, jwt);
      dispatch(uiActions.hideBackdrop());
      return true;
    } catch (err) {
      const { title, errors } = setError(err);
      // alert("Er is iets fout gegaan bij het opslaan van de deal. Probeer opnieuw");

      dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
      dispatch(uiActions.hideBackdrop());
    }
  };
}

export const updateDeal = (dealData, jwt, attachment=null, action='update') => {
  return async (dispatch) => {
    dispatch(uiActions.showBackdrop());
    try {
      await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Deal/' + dealData.dealID, method: 'PUT', body: dealData, headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt } });
      dispatch(dealActions.updateDeal(dealData));
      createActivity(action, dealData, jwt, attachment);
      dispatch(uiActions.hideBackdrop());
      var message = "Deal succesvol bijgewerkt";
      // console.log("dealData",dealData);
      switch(dealData.phase) {
        case "Klant is akkoord met de offerte":
          message = "Financiering succesvol gereserveerd";
          break;
        // case "Offerte verzonden":
        //   message = "Offerte succesvol verzonden";
        //   break;
        default: 
          break;
      } 
      dispatch(uiActions.setAlert({message: message, type: "success"}));
      return "SUCCESS";
    } catch (err) {
      console.log("fail");
      const { title, errors } = setError(err);
      dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
      dispatch(uiActions.hideBackdrop());
      dispatch(uiActions.setAlert({message: "Deal kan niet bijgewerkt worden", type: "danger"}));
    }
  };
}

export const removeDeal = (id, jwt) => {
  return async (dispatch) => {
    dispatch(uiActions.showBackdrop());
    try {
      await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Deal/' + id, method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt } });
      const dealData = {
        dealID: id
      }
      dispatch(dealActions.removeDeal({ dealID: id }));
      createActivity('delete', dealData, jwt);
      dispatch(uiActions.hideBackdrop());
    } catch (err) {
      const { title, errors } = setError(err);
      dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
      dispatch(uiActions.hideBackdrop());
    }
  };
}

export const archiveDeal = (dealObj, jwt) => {
  return async (dispatch) => {
    dispatch(uiActions.showBackdrop());
    try {
      await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Deal/' + dealObj.dealID, method: 'PUT', body: dealObj, headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt } });
      // dispatch(dealActions.removeDeal({ dealID: dealID }));
      var message = "Deal is succesvol ";
      var activityType;
      if(!dealObj.isArchive) {
        message+="gedearchiveerd";
        activityType = "gedearchiveerd";
      }
      if(dealObj.isArchive) {
        message+="gearchiveerd";
        activityType = "gearchiveerd";
      }
      dispatch(dealActions.archiveDeal());
      createActivity(activityType, dealObj, jwt);
      dispatch(uiActions.setAlert({message: message, type: "success"}));
      dispatch(uiActions.hideBackdrop());
    } catch (err) {
      const { title, errors } = setError(err);
      dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
      dispatch(uiActions.hideBackdrop());
    }
  };
}