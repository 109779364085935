import { localDate, sendRequest } from "lib/calc"
import { consoleLog, setError } from "lib/helpers";
import { customerActions } from "./customer-slice";
import { uiActions } from "./ui-slice";

export const fetchCustomers = (jwt) => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            const customers = await sendRequest({url: process.env.REACT_APP_SLD_API_BASE_URL+"/api/Customer", headers: {"Content-Type": "application/json", 'Authorization': 'Bearer ' + jwt}});
            dispatch(customerActions.setCustomers(customers));
            dispatch(uiActions.hideBackdrop());
        } catch (err) {
            consoleLog(err.message, true);
            dispatch(uiActions.setAlert({message: "Kan lijst met gebruikers niet laden", type: "danger"}));
            dispatch(uiActions.hideBackdrop());
        }
    }
} 

export const getCustomerById = (id, jwt, disableBackDrop) => {
    return async (dispatch) => {
        if(!disableBackDrop) dispatch(uiActions.showBackdrop());
        try {
            const customer = await sendRequest({url: process.env.REACT_APP_SLD_API_BASE_URL+"/api/Customer/"+id, headers: {"Content-Type": "application/json", 'Authorization': 'Bearer ' + jwt}});
            dispatch(customerActions.setSingleCustomer(customer));
            if(!disableBackDrop) dispatch(uiActions.hideBackdrop());
            return customer;

        } catch (err) {
            consoleLog(err.message, true);
            dispatch(uiActions.setAlert({message: "Kan gebruiker niet laden", type: "danger"}));
            if(!disableBackDrop) dispatch(uiActions.hideBackdrop());
        }
    } 
}

export const updateCustomer = (customerObj, jwt) => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Customer/'+customerObj.customerID, method: "PUT", headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt }, body: customerObj });
            dispatch(uiActions.setAlert({message: "Gebruiker succesvol bijgewerkt", type: "success"}));
            dispatch(uiActions.hideBackdrop());
            return "SUCCESS";
        } catch (err) {
            const { title, errors } = setError(err);
            dispatch(uiActions.setNotification({ visible: true, title: title, errors: errors}));
            dispatch(uiActions.hideBackdrop());
        }
    }
}

export const removeCustomer = (id, jwt) => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            await sendRequest({url: process.env.REACT_APP_SLD_API_BASE_URL+"/api/Customer/"+id, method: "DELETE", headers: {"Content-Type": "application/json", 'Authorization': 'Bearer ' + jwt}});
            dispatch(customerActions.removeCustomer({id: id}));
            dispatch(uiActions.setAlert({message: "Gebruiker succesvol verwijderd", type: "success"}));
            dispatch(uiActions.hideBackdrop());
        } catch (err) {
            consoleLog(err.message, true);
            dispatch(uiActions.setAlert({message: "Kan gebruiker niet verwijderen", type: "danger"}));
            dispatch(uiActions.hideBackdrop());
        }
    }
}