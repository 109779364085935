import Button from "components/ui/Button/Button";

const Pagination = (props) => {
    const { page, prevPageHandler, nextPageHandler, totalRecords, pageNumber, pageSize } = props;

  return (
    <div className="flex flex-row justify-between mt-5">
        <div>
        {page!==1 &&
        <Button className="btn-secondary relative z-0" onClick={prevPageHandler}>Vorige</Button>
        }
        </div>
        <div>
        {totalRecords > (pageNumber*pageSize) &&
        <Button className="btn-secondary relative z-0" onClick={nextPageHandler}>Volgende</Button>
        }
        </div>
  </div>
  );
};

export default Pagination;
