import useHuisstijl from "hooks/use-huisstijl";
import styled from "styled-components";

const StyledButton = styled.button`
background-color: ${props => (props.isPrimaryBtn && props.primaryColor) ? props.primaryColor : "#212853"};
color: ${props => (props.isPrimaryBtn && props.buttonTextColor) ? props.buttonTextColor : 'white'};
padding-top: 0.5rem;
padding-bottom: 0.5rem;
padding-left: 1.25rem;
padding-right: 1.25rem;    
border-radius: 0.75rem; 
max-width: -moz-fit-content;
max-width: fit-content;
min-width: max-content;

&:hover {
    transform: scale(1.05);
}

&:disabled {
    opacity: .5;

    &:hover {
        transform: scale(1);
    }
}
`;

const Button = (props) => {
    const { primaryColor, buttonTextColor } = useHuisstijl();       
    return (
        <>
        <StyledButton className={props.className} primaryColor={primaryColor} buttonTextColor={buttonTextColor} isPrimaryBtn={/btn-primary/.test(props.className)} disabled={props.disabled ? props.disabled : false} type={props.type ? props.type : ""} onClick={props.onClick}>{props.children}</StyledButton>
        </>
    )
}

export default Button;