import { useEffect, useState, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import useCars from "hooks/use-cars";
import useHuisstijl from "hooks/use-huisstijl";
import useCurrentCase from "hooks/use-current-case";
import useTutorial from "hooks/use-tutorial";

import { dossierActions } from "store/dossier-slice";

import Result from "./Result";
import Input from "components/ui/Input/Input";
import Button from "components/ui/Button/Button";
import PrevNext from "components/Navigation/PrevNext";
import Uitleg from "components/Tutorial/Uitleg";

const VoorraadModule = (props) => {
    useCurrentCase();
    const [query, setQuery] = useState("");
    const { data: cars }  = useCars({query});

    const dispatch = useDispatch();
    const location = useLocation();
    const { showTutorial } = useTutorial();

    const [nextPath, setNextPath] = useState('');
    const [keyword, setKeyword] = useState('');
    const keywordRef = useRef();

    const recalculation = useSelector(state => state.dossier.recalculation);
    const currentCase = useSelector(state => state.progress.currentCase);

    const { primaryColor, buttonTextColor } = useHuisstijl();  

    useEffect(() => {
        if(!location) return;
        var path = location.pathname.replace(/\/\w+$/g,"");
        setNextPath(path);
    },[currentCase, location])

    const setDataHandler = (data) => {   
        dispatch(dossierActions.setCarDataByInventory(data));
    }

    const searchHandler = (evt) => {
        evt.preventDefault();
        if(!keywordRef.current.value) return;
        setKeyword(keywordRef.current.value);
        setQuery("&keyword="+keywordRef.current.value);
     }

     const showAllHandler = () => {
        setQuery("");
        setKeyword("");
     }

    return (
        <>
            {showTutorial && <Uitleg hideNextButton={true} arrowPosition="left" title="Auto selecteren" description="Selecteer een auto uit uw eigen voorraad" />}
            <h1 className="">Selecteer een voertuig uit uw voorraad</h1>
            <form onSubmit={(evt) => evt.preventDefault()} className="flex flex-row gap-3 lg:gap-5 border-b border-slate-300 py-5 lg:p-10 lg:my-10 max-w-xl">
                <Input className="w-[140px] md:w-full" ref={keywordRef} name="keyword" placeholder="Zoek op trefwoord" value={keyword} onChange={(evt) => setKeyword(evt.target.value)}/>
                <Button type="submit" className="btn-primary" onClick={searchHandler}><span className="hidden lg:block">Zoeken</span><span className="lg:hidden fa fa-magnifying-glass"></span></Button>
                <Button className="btn-secondary" onClick={showAllHandler}><span className="hidden lg:block">Bekijk alle voorraad</span><span className="lg:hidden fa fa-trash"></span></Button>
            </form>
            <div className="max-w-7xl mr-auto">
            {cars.map(car => {
                var image_url = "";
                var autocategorie = car.vehicleType;
                autocategorie = autocategorie.replace(/^AUTO$/, "Personenauto").replace(/^BEDRIJF$/, "Bedrijfsauto");
                var voertuigData = {
                    'merk': car.make,
                    'model': car.model,
                    'kenteken': car.licensePlate,
                    'brandstof': car.fuelType,
                    'bouwjaar': car.constructionYear,
                    'datum_deel_1': car.datePart1,
                    'datum_deel_1b': car.datePart1b,
                    "cilinder_inhoud": car.cylinderCapacity,
                    'kmstand': car.mileage,
                    'btw_marge': car.marginVAT.replace(/^m.*/i, "Marge").replace(/^b.*/i, "BTW"),
                    'voertuigsoort': autocategorie,
                    'aanschafwaarde': car.price,
                    'bruto_bpm': car.brutoBPM,
                    "image_url": image_url,
                    'carrosserie_soort': car.carrosserie,
                    'catalogusprijs': car.listPrice,
                    'tellerstandoordeel': /j/i.test(car.napWebLabel) && 'Logisch'
                };              
                var carPath;
                if(!car.marginVAT) {
                    carPath = nextPath+'/btw-marge';
                } else {
                    switch(currentCase) {
                        case "FinancialLease":
                            carPath=nextPath+'/calculator';
                            break;
                        case "Verzekering":
                            carPath=nextPath+'/klantgegevens';
                            break;
                        default:
                            break;
                        }
                }
                return (
                    <NavLink to={carPath} key={car.vehicleID}>
                        <Result primaryColor={primaryColor} buttonTextColor={buttonTextColor} data={car} view='list' onClick={() => setDataHandler(voertuigData)} />
                    </NavLink>
                )
            })}
            {(!cars.length && keyword) && (<div className="flex flex-row items-center mt-5 mb-40 gap-x-3">
                <div>Er zijn geen voertuigen gevonden met het zoekwoord</div>
                <div onClick={showAllHandler} className="flex flex-row gap-x-3 items-center justify-between bg-slate-500 hover:bg-slate-400 text-white py-1 px-3 cursor-pointer rounded-lg"><span>{keyword}</span><span className="fa fa-xmark"></span></div>
                </div>)}
            </div>
            <PrevNext prevLabel={recalculation ? "Ander voertuig" : "Aanvraag annuleren"} prevClearDossier={true} disableNext={true} />
            </>
    )
}

export default VoorraadModule;