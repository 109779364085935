import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { serialize} from "lib/helpers";
import { escapeSpecialChars, friendlySaleType } from "lib/voertuig";
import { formatThousandNotation } from "lib/calc";
import { uiActions } from "store/ui-slice";

import useAuth from "hooks/use-auth";
import usePagination from "hooks/use-pagination";

import Button from "components/ui/Button/Button";
import Pagination from "components/Navigation/Pagination";
import Zoekmachine from "components/Zoekmachine/Zoekmachine";

const DealOverzicht = (props) => {
  const { token: jwt, role } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page, perpage, changePageSize, nextPageHandler, prevPageHandler, toFirstPageHandler } = usePagination();
  const { saleType, phase } = props;
  const [searchParams] = useSearchParams();

  const [deals, setDeals] = useState({
    pageNumber: page,
    pageSize: perpage,
    totalPages: "",
    succeeded: "",
    totalRecords: 0,
    data: []
  });
  const [query, setQuery] = useState('');

  const { pageNumber, pageSize, totalRecords, data } = deals;

  useEffect(() => {
    if(!role || !role.length) return;
    var queryParams = "";
    if(query) queryParams = serialize(query);

    dispatch(uiActions.showBackdrop());
    var fetchUrl = process.env.REACT_APP_SLD_API_BASE_URL + '/api/Deal/Filters?'+(!/pageNumber/.test(queryParams) ? 'pageNumber='+page+'&' : '&')+(!/pageSize/.test(queryParams) ? 'pageSize='+perpage : '&')+(queryParams ? '&'+queryParams : '')+(phase ? '&phase='+phase : '');
    if(role.includes("User")) fetchUrl+="&source=Dealer-aanvraag";
    if(saleType) fetchUrl=fetchUrl.replace(/saleType=(\w+)[^&;]/,"")+"&saleType="+saleType; 
    fetchUrl = fetchUrl.replace(/\?&*/, "?");

    fetch(fetchUrl, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwt
      }
    })
    .then(resp => {
      // console.log(resp.status);
      if(resp.status===401) throw new Error("401");
      return resp.json();
    })
    .then(responseData => {
      console.log("responseData=", responseData);
      if(/pageNumber/.test(queryParams)) {
        toFirstPageHandler(99999);
      }
      setDeals(responseData);
      dispatch(uiActions.hideBackdrop());
    })
    .catch(err => {
      console.log(err.message);
      dispatch(uiActions.hideBackdrop());
    })
  },[dispatch, jwt, phase, saleType, perpage, page, query, role]);
  
  const initSearch = {
    pageNumber: 1,
    pageSize: 9999,
    source: "",
    relatedID: "",
    saleType: "",
    isArchive: false,
    dealerName: "",
    companyName: "",
    postalCode: "",
    dealerId: "",
    licensePlate: "",
    make: "",
    phase: ""
}

  const [initialSearch, setInitialSearch] = useState(initSearch);

  const clearHandler = () => {
    setQuery("");
    changePageSize(25);
  }

    return (
        <div className="text-xs lg:text-base max-w-6xl">
          
          {(role.includes("Admin") && !props.phase) && (
          <>
          <Zoekmachine searchFor="deals" initialSearch={initialSearch} token={jwt} onSearch={inputQuery => setQuery(inputQuery)} onClear={clearHandler} perpage={perpage} onChangePageSize={changePageSize} />
          <div className="p-3">{totalRecords} resulta{totalRecords===1 ? 'at' : 'ten'}</div> 
            </>
          )}

          <div>
          <div className={`bg-sld-blue text-white rounded-xl p-3 mb-3 items-center grid grid-cols-2 ${role.includes("Admin") ? 'md:grid-cols-5' : 'md:grid-cols-4'} gap-x-2 gap-y-2`}>
                {role.includes("Admin") && <div className=""></div>}
                <div>Bedrijfsgegevens</div>
                {/(BtwFinanciering)/.test(saleType) ? <div>Te financieren bedrag</div> : <div>Voertuig</div>}
                {role.includes("Admin") && <div className="rounded-tr-xl lg:rounded-tr-none">Source</div>}
                <div className="rounded-tr-xl lg:rounded-tr-none">Status</div>
            </div>
            <div className="">
              {(totalRecords>0) 
              && 
              data.map((deal) => {
                const make = escapeSpecialChars(deal.vehicleDetail.make);
                const model = escapeSpecialChars(deal.vehicleDetail.model);
                const licensePlate = deal.vehicleDetail.licensePlate ? deal.vehicleDetail.licensePlate : 'NNB';
                var navPath = (role.includes("Admin")) ? "/crm/verkopen/"+deal.dealID : "/verkopen/"+deal.dealID;
                // return (<div key={deal.dealID} className={"grid grid-cols-2 md:grid-cols-4 gap-x-2 items-center hover:bg-[#F6901E] hover:text-white px-5 py-3 shadow-md bg-white rounded-lg mb-5 cursor-pointer"+(deal.isArchive ? ' opacity-50' : '')} onClick={() => navigate("/crm/verkopen/"+deal.dealID)}>
                return (<div key={deal.dealID} className={`grid grid-cols-2 ${role.includes("Admin") ? 'md:grid-cols-5' : 'md:grid-cols-4'} gap-x-2 items-center hover:bg-[#F6901E] hover:text-white px-5 py-3 shadow-md bg-white rounded-lg mb-5 cursor-pointer`+(deal.isArchive ? ' opacity-50' : '')} onClick={() => navigate(navPath)}>
                 
                  {role.includes("Admin") && (
                  <div className="flex flex-col">
                    <div><span className="opacity-40 mr-1">Autobedrijf:</span><span className="">{deal.dealerDetail.name}</span></div>
                    <div><span className="opacity-40 mr-1">Verkoop ID:</span><span className="">{deal.dealID}</span></div>
                  </div>
                  )}
                  <div className="flex flex-col">
                    <span>{deal.customerDetail.companyName.slice(0, 25)}{deal.customerDetail.companyName?.length>25 && "..."}</span>
                    <span>{deal.customerDetail.postalCode && deal.customerDetail.postalCode+''}{deal.customerDetail.postalCode&&deal.customerDetail.place && ', '}{deal.customerDetail.place && deal.customerDetail.place}</span>
                  </div>
                  {/(BtwFinanciering)/.test(saleType) && <div className="">&euro; {formatThousandNotation(deal.leaseAmount)}</div>}
                  {(/(FinancialLease|Verzekering)/.test(saleType) || !saleType) &&
                    <div className="flex flex-col"> 
                      <div><span className="opacity-40 mr-1 md:hidden">Merk/model:</span><span>{make} {model}</span></div>
                      <div><span className="opacity-40 mr-1 md:hidden">Kenteken:</span><span>{licensePlate}</span></div>
                    </div>
                  }
                  {(role.includes("Admin")) && <div>{deal.source}</div>}                               
                  <div className="flex flex-col">
                    <div>{deal.phase}</div>
                    {!saleType
                    ?
                    <div className="">{friendlySaleType(deal.saleType)}</div>
                    :
                    <div className="">{deal.createdDate!=="0001-01-01T00:00:00" && new Date("" + deal.createdDate).toLocaleDateString('nl-NL') + "\n" + new Date("" + deal.createdDate).toLocaleTimeString()}</div>
                  }
                  </div>
                </div>);
              })
              }
            </div>
              <div>&nbsp;</div>
          </div>

          {!totalRecords && 
          <div className="flex flex-row justify-between bg-white p-5 items-center">
            <span>Er zijn geen deals gevonden</span>
            <Button className="btn-secondary" onClick={() => setInitialSearch(initSearch)}>reset filters</Button>
          </div>
          }
          <Pagination page={page} prevPageHandler={prevPageHandler} nextPageHandler={nextPageHandler} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
      </div>
    )
}

export default DealOverzicht;