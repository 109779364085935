import Container from "components/hoc/Container";
import { NavLink } from "react-router-dom";
import DealForm from "components/Forms/DealForm/DealForm";
import Button from "components/ui/Button/Button";

const DealDetail = () => {
      
    return (
        <Container>
            <div className="mb-5">
                <NavLink to="/verkopen"><Button className="btn-secondary">Terug naar alle verkopen</Button></NavLink>
            </div>
            <DealForm />
        </Container>
    )
}

export default DealDetail;