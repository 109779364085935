import React, { useState, useEffect, Suspense, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";

import Card from "components/ui/Card/Card";
import Input from "components/ui/Input/Input";
import Select from "components/ui/Select/Select";
import Button from "components/ui/Button/Button";
import InputDatum2 from "components/ui/InputDatum/InputDatum2";
import TextArea from "components/ui/TextArea/TextArea";
import Accordion from "components/ui/Accordion/Accordion";
import ActivityOverzicht from "components/Tables/ActivityOverzicht";
import Modal from "components/hoc/Modal";
import Popup2 from "components/ui/Popup/Popup2";
import NewActivityForm from "../ActivityForm/NewActivityForm";

import { noDotAndInteger } from "lib/helpers";
import { dateToISO, formattedPMT_v2, formatThousandNotation, intTo2DecStr, localDate, valueTo2DecInt } from "lib/calc";
import { carrosserieToId, brandstofToId, escapeSpecialChars } from "lib/voertuig";

import useAuth from "hooks/use-auth";
import useDealers from "hooks/use-dealers";

import { uiActions } from "store/ui-slice";
import { resetDossier } from "store/dossier-actions";
import { dossierActions } from "store/dossier-slice";
import { dealActions } from "store/deal-slice";
import { archiveDeal, createActivity, updateDeal } from "store/deal-actions";

const DocumentForm = React.lazy(() => import("components/Forms/DocumentForm/DocumentForm"));
const Pdf2 = React.lazy(() => import("components/Pdf/Pdf2"));
const Calculator2 = React.lazy(() => import("components/Calculator/Calculator_nieuw"))  ;
const BankForm = React.lazy(() => import("components/Forms/BankForm/BankForm"))  ;
const CustomerForm = React.lazy(() => import("components/Forms/CustomerForm/CustomerForm"))  ;

const DealForm = (props) => {
  const location = useLocation();
  const [pathName, setPathName] = useState(false);

  const [changesMade, setChangesMade] = useState(false);
  const [offerIsSent, setOfferIsSent] = useState(false);
  
  useEffect(() => {
    setPathName(window.location.protocol+"//"+window.location.hostname+(window.location.port ? ":"+window.location.port : ""));
  },[location]);

  const navigate = useNavigate();
  const phaseRef = useRef();
  const firstRegistrationDateRef = useRef();
  const startDateContractRef = useRef();

  const [showBankDetails, setShowBankDetails] = useState(false);
  const [changeCustomerInDeal, setChangeCustomerInDeal] = useState(false);
  const [showCalculator, setShowCalculator] = useState(false);

  const [pdfMethod, setPdfMethod] = useState("download");
  const [loadPdf, setLoadPdf] = useState(false);

  const [showPhaseChoice, setShowPhaseChoice] = useState(false);
  const [dealDetail, setDealDetail] = useState("");
  const dealChangeCounter = useSelector(state => state.deal.changeCounter);
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activityCounter, setActivityCounter] = useState(0);
  const [documentCounter, setDocumentCounter] = useState(0);

  const { token } = useAuth();
  const dispatch = useDispatch();
  const auth = useAuth();
  const params = useParams();
  const { token: jwt, role } = auth;

  const dealers = useDealers();
  var { dealID } = params;

  useEffect(() => {
    dispatch(uiActions.showBackdrop());
    dispatch(resetDossier());
    fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Deal/'+dealID, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwt
      }
    })
    .then(resp => {
      if(resp.status===401) throw new Error("401");
      return resp.json();
    })
    .then(responseData => {
      setDealDetail(responseData);
      const { customerDetail } = responseData;
      var aanvullendegegevens = {
        aanhef: customerDetail.salutation ? customerDetail.salutation : "",
        voornaam: customerDetail.firstName ? customerDetail.firstName : "",
        achternaam: customerDetail.lastName ? customerDetail.lastName : "",
        geboortedatum: customerDetail.dateofBirth ? customerDetail.dateofBirth : "",
        rijbewijsnummer: customerDetail.driverLicenseNumber ? customerDetail.driverLicenseNumber : "",
        iban: customerDetail.iban ? customerDetail.iban : "",
        email: customerDetail.email ? customerDetail.email : "",
        telefoon: customerDetail.phone ? customerDetail.phone : ""
      }
      dispatch(dossierActions.setAdditionalFieldData(aanvullendegegevens));
      var kvkdata = {
        dossiernummer: customerDetail.chamberNumber ? customerDetail.chamberNumber : "",
        bedrijfsnaam: customerDetail.companyName ? customerDetail.companyName : "",
        handelsnaam: customerDetail.companyName ? customerDetail.companyName : "",
        postcode: customerDetail.postalCode ? customerDetail.postalCode : "",
        plaats: customerDetail.place ? customerDetail.place : "",
        straat: customerDetail.address ? customerDetail.address : ""
      }
      dispatch(dossierActions.setKvkData(kvkdata));
      dispatch(uiActions.hideBackdrop());
    })
    .catch(err => {
      dispatch(uiActions.hideBackdrop());
    });
  }, [dealChangeCounter, dealID, dispatch, jwt]);

const updateWidgetHandler = (type='activity') => {
  switch(type) {
    case "activity": 
      setActivityCounter(prevCounter => prevCounter + 1);
      break;
    case "documents": 
      setDocumentCounter(prevCounter => prevCounter + 1);
      break;
    case "update_customerDetails":
      setChangeCustomerInDeal(false);
      dispatch(uiActions.hideBackdrop());
      dispatch(dealActions.updateCounter());
      break;
    default:
      break;
  }
}

const updateCalculationHandler = (herbeoordeling, calcData, id) => {
  var btw_bedrag = noDotAndInteger(calcData.btw_bedrag);
  var aanschafwaarde = noDotAndInteger(calcData.aanschafwaarde);
  var aanschafwaarde_excl = noDotAndInteger(calcData.aanschafwaarde_excl);
  if(/marge/i.test(calcData.typeOfDeal)) {
    btw_bedrag = 0;
    aanschafwaarde_excl = aanschafwaarde;
  }

    const updatedData = {
    "provisiePercentage": +calcData.provisiepercentage,
    "tradeInAmount": noDotAndInteger(calcData.inruil),
    "repayment": noDotAndInteger(calcData.inlossing),
    "leaseAmount": noDotAndInteger(calcData.leasebedrag),
    "typeOfDeal": ""+calcData.typeOfDeal,
    "amountpurchase": aanschafwaarde,
    "commissionAmountDealer": Number(calcData.provisiebedrag),
    "totalToPay": calcData.totale_aanbetaling,
    "downpayment": calcData.aanbetaling ? noDotAndInteger(calcData.aanbetaling) : 0,
    "monthlyamount": Number(calcData.leasetermijn.replace(/\./g,"").replace(",",".")),
    "vat": btw_bedrag,
    "description": "",
    "duration": +calcData.looptijd,
    "administrationCost": calcData.administrationCost ? valueTo2DecInt(calcData.administrationCost) : 0,
    "commissionAmountSLD": (calcData.provisiebedrag_rest ? Number(calcData.provisiebedrag_rest) : 0),
    "amountpurchaseExclVAT": aanschafwaarde_excl,
    "damageFreeYearsInsurance": "",
    "damageHistoryInsurance": "",
    "chosenBank": ""+calcData.calcsrc,
    "finalpayment": noDotAndInteger(calcData.slottermijn),
    "interest": +calcData.klantrente,
  };

  if(herbeoordeling) {
    const newDealData = {
      ...dealDetail,
      ...updatedData,
      relatedID: id,
      phase: "Uw aanvraag is ontvangen"
    }
    dispatch(updateDeal(newDealData, jwt));

  } else {
    // geen herbeoordeling --> update deal
    const updatedDeal = {
      ...dealDetail,
      ...updatedData
    }
   dispatch(updateDeal(updatedDeal, jwt));
  }
  setShowCalculator(false);
  dispatch(uiActions.hideBackdrop());
  setTimeout(function() {
    updateWidgetHandler('activity');
  }, 1000);
}
const prepareArchiveHandler = () => {
  setShowPhaseChoice(true);
}

const archiveDealHandler = (obj, reset) => {
  var isArchive = (reset ? false : true);
  const updatedDeal = {
    ...obj,
    isArchive: isArchive
  }
  var chosenPhase = phaseRef.current.value;
  if(!chosenPhase) return;
  updatedDeal.phase = chosenPhase;
  dispatch(archiveDeal(updatedDeal, jwt));
  setShowPhaseChoice(false);
}

  const otherVehicleHandler = () => {
    const updatedDeal = {
      ...dealDetail,
      isArchive: true,
      phase: "Ander object"
    }
    dispatch(uiActions.setNotification({
      visible: true,
      title: "Doorgaan?",
      message: "Weet u zeker dat u wilt doorgaan? De huidige verkoop wordt gearchiveerd.",
      buttons: [{
        primary: true,
        action: "otherVehicle",
        data: updatedDeal,
        url: "/financiering/financial-lease",
        label: "Ja, doorgaan"
      },
      {
        label: "Nee, annuleren",
        action: "cancel"
      }]
    }));
  }
 
  const reCalculateHandler = () => {
    setShowCalculator(true);
    setShowModal(true);
  }


  const submitFormHandler = async (evt) => {
    evt.preventDefault();

    var updatedDeal = {
      ...dealDetail,
      ...(startDateContractRef?.current?.value && {"startDateContract": dateToISO(startDateContractRef.current.value)}),
      "vehicleDetail": {
        ...dealDetail.vehicleDetail,
        ...(firstRegistrationDateRef.current?.value && {"firstRegistrationDate": dateToISO(firstRegistrationDateRef.current.value)})
      }
    }
    const response = await dispatch(updateDeal(updatedDeal, token));
    if(response==="SUCCESS") {
      updateWidgetHandler('activity');
      setChangesMade(false);
    }
  };

  const popupSubmitHandler = () => {
    setPdfMethod("base64");
    setLoadPdf(true);
  }

  const downloadPDFHandler = () => {
    setPdfMethod("download");
    setLoadPdf(true)
  }

  const submitSendOffer = (data) => {
    if(offerIsSent) return;
    dispatch(uiActions.showBackdrop());

    var file = new File([data], "attachment.pdf", { type: "application/pdf"});
    var formData = new FormData();
    formData.append("name", ""+dealDetail.phase);
    formData.append("description", "Verzonden via klik op button");
    formData.append("dealID", dealDetail.dealID);
    formData.append("documentUrlForm", file);
    formData.append("offer", "2");
    formData.append("type", "Offerte verzonden");
    formData.append("createAt", new Date());
    fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/ActivitiesDeal', {
      headers: {
        "Authorization": "Bearer " + jwt
      },
      body: formData,
      method: "POST",
    }).then((response) => {
      if(response.status!==200) throw Error();
      setOfferIsSent(true);
      setLoadPdf(false);
      dispatch(uiActions.hideBackdrop());
      dispatch(uiActions.setAlert({message: "De offerte is succesvol verzonden", type: "success"}));
    }).catch(() => {
      setOfferIsSent(false);
      setLoadPdf(false);
      dispatch(uiActions.hideBackdrop());
      dispatch(uiActions.setAlert({message: "Fout bij het versturen van de offerte. Probeer opnieuw!", type: "danger"}));
    });
  }

  const valueChangeHandler = (evt, addObj=false) => {
    const dataName = evt?.target?.name || addObj.name;
    var dataValue = evt?.target?.value || addObj.value;

    if(/(commissionAmountDealer|commissionAmountSLD|administrationCost)/.test(dataName)) {
      // replace input 230,95 euro to 230.95
      dataValue = dataValue.replace(",", ".");
    }    
    
    if(addObj) {
      setDealDetail(prevState => {
        return {
          ...prevState,
          vehicleDetail: {
            ...prevState.vehicleDetail,
            [dataName]: dataValue
          }
        }
      });
    } else {
      setDealDetail(prevState => {
        return {
          ...prevState,
          [dataName]: dataValue
        }
      });
    }
    setChangesMade(true);
  };

  const btwFinancieringHandler = () => {
    dispatch(dossierActions.attachBtwFinanciering());
    navigate("/financiering/btw-financiering");
  }
  var ondervoorbehoud = /(Uw aanvraag is ontvangen|Uw aanvraag wordt beoordeeld|Klant dient extra informatie aan te leveren)/.test(dealDetail.phase);

  const saveChangedBankDataHandler = (deal) => {
    dispatch(updateDeal(deal, jwt));
  }
  
  const closeCustomerModalHandler= () => {
    setChangeCustomerInDeal(false);
    dispatch(uiActions.hideBackdrop());
  }

  useEffect(() => {
    if(!offerIsSent) return;
    var updatedDeal = {
      ...dealDetail,
      phase: "Offerte verzonden"
    }
    createActivity("update", updatedDeal, jwt);
    updateWidgetHandler('activity');
    // get new version of deal
    dispatch(dealActions.updateCounter());
    
  },[offerIsSent, jwt, dispatch]);

  if (!dealDetail.customerDetail || !pathName) return <>Laden...</>;

  return (
    <>
        <form className="text-xs lg:text-base mt-5" onSubmit={changeCustomerInDeal ? () => {} : submitFormHandler}>
        <Card titleEnd={dealDetail.dealID} title={(dealDetail.vehicleDetail.make ? escapeSpecialChars(dealDetail.vehicleDetail.make) : '') + " " + (dealDetail.vehicleDetail.model ? escapeSpecialChars(dealDetail.vehicleDetail.model) : '') + " - " + dealDetail.customerDetail.companyName}>
          <div className="bg-white grid md:grid-cols-3 lg:grid-cols-3 items-start rounded-b-xl">
            <div className="grid grid-cols-2 col-span-2 p-5">
            {role.includes("Admin") &&
              <Accordion className="col-span-2" summary={`Gegevens autobedrijf`}>
                <div className="grid grid-cols-2 gap-3 py-5">
                  <div>
                    
                    <div>{dealDetail.dealerDetail.name}</div>
                    <div>kvk: {dealDetail.dealerDetail.chamberNumber}</div>
                    <div>{dealDetail.dealerDetail.address}</div>
                    <div>{dealDetail.dealerDetail.postalCode} {dealDetail.dealerDetail.place}</div>
                  </div>
                  <div>
                    <div>{dealDetail.dealerDetail.telephone}</div>
                    <div>{dealDetail.dealerDetail.phone}</div>
                    <div><a href={`mailto:${dealDetail.dealerDetail.email}`}>{dealDetail.dealerDetail.email}</a></div>
                    <div><a href={dealDetail.dealerDetail.website} target="_blank" rel="noreferrer">{dealDetail.dealerDetail.website}</a></div>
                  </div>
                  <div className="col-start-2">
                    <Select options={dealers.isLoaded && dealers.data && dealers.data.map(dealer => ({"label": dealer.label, "value": dealer.dealerID}))} name="dealerID" placeholder="dealerID" selected={dealDetail.dealerID} value={dealDetail.dealerID} onChange={valueChangeHandler} />
                  </div>
                </div>
                </Accordion>
              }

              <Accordion className="col-span-2" summary="Bedrijfsgegevens &amp; contactpersoon" open={dealDetail.saleType==="BtwFinanciering" ? true : false}>
                <div className="grid grid-cols-2 gap-3 py-5">
                  <div>
                    <div>{dealDetail.customerDetail.companyName} kvk: {dealDetail.customerDetail.chamberNumber}</div>
                    <div>{dealDetail.customerDetail.address}</div>
                    <div>{dealDetail.customerDetail.postalCode} {dealDetail.customerDetail.place}</div>
                    {dealDetail.saleType!=="BtwFinanciering" && <div>IBAN {dealDetail.customerDetail.iban}</div>}
                    <div>Datum oprichting {localDate(dealDetail.customerDetail.dateofEsteb)}</div>
                  </div>
                  <div>
                    <div>{dealDetail.customerDetail.salutation} {dealDetail.customerDetail.firstName} {dealDetail.customerDetail.lastName}</div>
                    <div>{dealDetail.customerDetail.phone}</div>
                    <div>{dealDetail.customerDetail.email}</div>
                    <div>Geboortedatum {localDate(dealDetail.customerDetail.dateofBirth)}</div>
                    {dealDetail.saleType!=="BtwFinanciering" && <div>Rijbewijsnummer {dealDetail.customerDetail.driverLicenseNumber}</div>}
                  </div>
                  {role.includes("Admin") &&
                  <>
                  <div className="lg:col-start-2">
                    <Button type="button" onClick={() => setChangeCustomerInDeal(true)}>Wijzig gegevens</Button>
                    {changeCustomerInDeal && createPortal(
                    <Suspense fallback={<div>Loading ...</div>}>
                      <Modal childrenOnly={true} show={changeCustomerInDeal}>
                        <CustomerForm title="Klantgegevens aanpassen" onClose={closeCustomerModalHandler} disableBackDropHandler={true} inModal={changeCustomerInDeal} onUpdate={() => updateWidgetHandler('update_customerDetails')} customerID={dealDetail.customerDetail.customerID} detail={true} />
                      </Modal>
                    </Suspense>, document.body)
                    }
                  </div>
                  </>
                  }
                </div>
              </Accordion>

{dealDetail.saleType!=="BtwFinanciering" &&
              <Accordion className="col-span-2" summary="Voertuiggegevens" open={true}>
                <div className="grid grid-cols-2 gap-3 py-5">
                  {role.includes("Admin")
                    ?
                    <>
                      <Input name="make" placeholder="Merk" value={escapeSpecialChars(dealDetail.vehicleDetail.make)} onChange={(evt) => valueChangeHandler(evt, 'vehicleDetail')} />
                      <Input name="model" placeholder="Model" value={escapeSpecialChars(dealDetail.vehicleDetail.model)} onChange={(evt) => valueChangeHandler(evt, 'vehicleDetail')} />
                      <Select set="voertuigsoort" name="vehicleType" placeholder="Voertuigsoort" value={dealDetail.vehicleDetail.vehicleType} onChange={(evt) => valueChangeHandler(evt, 'vehicleDetail')} />
                      <Select set="carrosserie_soort" name="carrosserie" placeholder="Carrosserie" value={carrosserieToId(dealDetail.vehicleDetail.carrosserie)} onChange={(evt) => valueChangeHandler(evt, 'vehicleDetail')} />
                      <Input name="licensePlate" placeholder="Kenteken" value={dealDetail.vehicleDetail.licensePlate} onChange={(evt) => valueChangeHandler(evt, 'vehicleDetail')} />
                      <Select name="typeOfDeal" set="btw_marge" placeholder="BTW/Marge" value={dealDetail.typeOfDeal} onChange={valueChangeHandler} />
                      <Input name="engineCapacity" placeholder="Cilinderinhoud (cc)" value={dealDetail.vehicleDetail.engineCapacity} onChange={(evt) => valueChangeHandler(evt, 'vehicleDetail')} />
                      <Select set="brandstof" name="fuelType" placeholder="Brandstof" value={brandstofToId(dealDetail.vehicleDetail.fuelType)} onChange={(evt) => valueChangeHandler(evt, 'vehicleDetail')} />
                      <Input name="mileage" placeholder="Kilometerstand" value={dealDetail.vehicleDetail.mileage} onChange={(evt) => valueChangeHandler(evt, 'vehicleDetail')} />
                      {dealDetail.vehicleDetail.positionJudgement && (
                      <div className="flex flex-col p-1 xborder border-slate-300 rounded-xl px-2">
                        <div className="text-xs">Tellerstandoordeel</div>  
                        <div>{dealDetail.vehicleDetail.positionJudgement}</div>
                      </div>
                      )}
                      <InputDatum2 ref={firstRegistrationDateRef} placeholder="Eerste registratie" value={localDate(dealDetail.vehicleDetail.firstRegistrationDate)} />
                      {dealDetail.vehicleDetail.occasionNumber ?
                        <div className="flex flex-row col-span-2 items-center justify-end">
                          <a className="btn-secondary hover:scale-105" href={`https://www.sld.nl/lease-voorraad/veh${dealDetail.vehicleDetail.occasionNumber}`} target="_blank" rel="noreferrer">Bekijk op website</a>
                        </div>
                        : <></>
                      }

                    </>
                    :
                    <>
                      <div className="grid grid-cols-2 items-center">
                        <div>Merk</div><div>{escapeSpecialChars(dealDetail.vehicleDetail.make)}</div>
                        <div>Model</div><div>{escapeSpecialChars(dealDetail.vehicleDetail.model)}</div>
                        <div>Voertuigsoort</div><div>{dealDetail.vehicleDetail.vehicleType}</div>
                        <div>Carrosserie</div><div>{dealDetail.vehicleDetail.carrosserie}</div>
                        <div>Kenteken</div><div>{dealDetail.vehicleDetail.licensePlate}</div>
                        <div>BTW/Marge</div><div>{dealDetail.typeOfDeal}</div>
                        <div>Cilinderinhoud</div><div>{dealDetail.vehicleDetail.engineCapacity} cc</div>
                        <div>Kilometerstand</div><div>{dealDetail.vehicleDetail.mileage} km</div>
                        <div>Tellerstandoordeel</div><div>{dealDetail.vehicleDetail.positionJudgement ? dealDetail.vehicleDetail.positionJudgement : 'Onbekend'}</div>
                        <div>Eerste registratie</div><div>{localDate(dealDetail.vehicleDetail.firstRegistrationDate)}</div>
                      </div>
                    </>
                  }
                </div>
              </Accordion>
}
{dealDetail.saleType==="FinancialLease" &&
              <Accordion className="col-span-2" summary="Verkoopgegevens" open={true}>
                <div className="grid grid-cols-2 lg:grid-cols-4 items-center py-5">
                  <div>Aanschaf Incl.</div><div>&euro; {formatThousandNotation(dealDetail.amountpurchase)}</div>
                  {/btw/i.test(dealDetail.typeOfDeal) && 
                  <>
                  <div>BTW</div><div>&euro; {formatThousandNotation(dealDetail.vat)}</div>
                  <div>Aanschaf Excl.</div><div>&euro; {formatThousandNotation(dealDetail.amountpurchaseExclVAT)}</div>
                  </>
                  }
                  <div>Aanbetaling</div><div>&euro; {formatThousandNotation(dealDetail.downpayment)}</div>
                  <div>Inruil</div><div>&euro; {formatThousandNotation(dealDetail.tradeInAmount)}</div>
                  <div>Inlossing openstaand saldo</div><div>&euro; {formatThousandNotation(dealDetail.repayment)}</div>
                  <div>Te financieren</div><div>&euro; {formatThousandNotation(dealDetail.leaseAmount)}</div>
                  <div>Slottermijn</div><div>&euro; {formatThousandNotation(dealDetail.finalpayment)}</div>
                  <div>Maandbedrag</div><div>&euro; {formattedPMT_v2(dealDetail.monthlyamount)}</div>
                  <div>Looptijd</div><div>{dealDetail.duration} maanden</div>
                  <div>Rente</div><div>{dealDetail.interest}%</div>
                  <div>Handelingsfee</div><div>&euro; {dealDetail.administrationCost===0 ? "0": intTo2DecStr(dealDetail.administrationCost)}</div>
                </div>
                {(dealDetail.saleType==="FinancialLease" && !dealDetail.isArchive) &&
                <div className="flex flex-row justify-between mb-5">
                  <Button size="xs" className="btn-secondary mb-5" type="button" onClick={reCalculateHandler}>Financiering aanpassen</Button>
                  <Button size="xs" className="btn-secondary mb-5" type="button" onClick={otherVehicleHandler}>Ander voertuig</Button>
                </div>
                }
              </Accordion>
}
{(dealDetail.saleType==="FinancialLease" && role.includes("Admin")) &&
<Accordion onClick={() => setShowBankDetails(true)} className="col-span-2 select-none" summary="Bankgegevens" open={false}>
  {showBankDetails && <Suspense fallback="Loading..."><BankForm dealDetail={dealDetail} onSave={saveChangedBankDataHandler} /></Suspense>}
</Accordion>
}

{dealDetail.saleType==="Verzekering" &&
              <Accordion className="col-span-2" summary="Verzekeringsgegevens" open={true}>
              <div className="grid grid-cols-2 gap-3 py-5">
                {role.includes("Admin") ?
              <>
                <Select set="typeOfInsurance" name="typeOfInsurance" placeholder="Type verzekering" value={dealDetail.typeOfInsurance} onChange={valueChangeHandler} />
                <Input name="damageFreeYearsInsurance" placeholdersm="Schadevrije jaren" placeholder="Aantal geregistreerde schadevrije jaren" value={dealDetail.damageFreeYearsInsurance} onChange={valueChangeHandler} />
                <Input name="damageHistoryInsurance" placeholdersm="Schade afgelopen 5 jaar" placeholder="Schadeverleden (auto) afgelopen 5 jaar" value={dealDetail.damageHistoryInsurance} onChange={valueChangeHandler} />
                <Select value={dealDetail.kmPerYear} name="kmPerYear" set="kmperjaar" placeholdersm="Kilometers per jaar" label="Hoeveel kilometers per jaar" onChange={valueChangeHandler} />
                </>
              :
              <div className="grid grid-cols-2">
                <div className="">Type verzekering</div><div>{dealDetail.typeOfInsurance}</div>
                <div className="">Schadevrije jaren</div><div>{dealDetail.damageFreeYearsInsurance} </div>
                <div className="">Schade afgelopen 5 jaar</div><div>{dealDetail.damageHistoryInsurance}</div>
                <div className="">Kilometers per jaar</div><div>{dealDetail.kmPerYear}</div>
                </div>
              }
              </div> 
              {/* <Button size="xs" className="btn-secondary" type="button" onClick={reCalculateHandler}>Financiering aanpassen</Button> */}
            </Accordion>
}
{dealDetail.saleType==="BtwFinanciering" && 
  <div className="col-span">
    <div className="grid lg:grid-cols-2">
          <div>Te financieren</div><div>&euro; {formatThousandNotation(dealDetail.leaseAmount)}</div>
    </div>
  </div>
}
            </div>

            <div className="flex flex-col col-span-2 md:col-span-1 md:relative gap-5 p-5 bg-slate-50 h-full rounded-br-xl">

              {(role.includes("Admin") && !dealDetail.isArchive) ?
              <>
                <div className="flex flex-row">
                  <InputDatum2 minYear={new Date().getFullYear()-1} yearsAbove={3} ref={startDateContractRef} name="startDateContract" placeholder="Startdatum contract" value={localDate(dealDetail.startDateContract)} />
                </div>
                <div className="flex flex-col gap-3">
                  <Input valuta="euro" name="commissionAmountDealer" placeholder="Dealer provisie" value={dealDetail.commissionAmountDealer} onChange={valueChangeHandler} />
                  <Input valuta="euro" name="commissionAmountSLD" placeholder="SLD provisie" value={dealDetail.commissionAmountSLD} onChange={valueChangeHandler} />
                </div>
              </>
                :
                <>
                  {/* <div>Startdatum contract {localDate(dealDetail.startDateContract)}</div> */}
                </>
              }
              <div>Registratiedatum <span className="inline-block">{localDate(dealDetail.createdDate)} {new Date("" + dealDetail.createdDate).toLocaleTimeString()}</span></div> 


              {role.includes("Admin")
                ?
                <>
                  <Select set={dealDetail.isArchive ? 'isArchive_phase' : 'phase'} name="phase" placeholder="Fase" value={dealDetail.phase} onChange={valueChangeHandler} />
                  <TextArea name="description" label="Beschrijving" placeholder="Beschrijving" value={dealDetail.description} onChange={valueChangeHandler} />
                  {dealDetail.saleType!=="BtwFinanciering" && <Select disabled set="chosenBank" value={dealDetail.chosenBank} label="Gekozen bank" name="chosenBank" onChange={valueChangeHandler} />}
                  <Select set="source" name="source" label="Source" placeholder="Source" value={dealDetail.source} onChange={valueChangeHandler} />
                </>
                :
                <div>Fase: {dealDetail.phase}</div>
              }

              <div className="flex flex-row lg:flex-col xl:flex-row gap-5"> 
                <Button className="bg-gradient">Opslaan</Button>
                {role.includes("Admin") &&
                  <>
                  {!dealDetail.isArchive && <Button type="button" className="btn-secondary" onClick={prepareArchiveHandler}>Archiveren</Button>}
                  {dealDetail.isArchive && <Button type="button" className="btn-secondary" onClick={prepareArchiveHandler}>Herstellen uit archief</Button>}
                  </>
                }
              </div>
              { ( (/(Controleer en verzend offerte|Offerte verzonden|Klant is akkoord met de offerte)/.test(dealDetail.phase)) && (dealDetail.saleType==="FinancialLease") ) &&
              <>
              {(!changesMade && !offerIsSent) ?
              <Button className="btn-secondary" type="button" onClick={() => setShowPopup(true)} disabled={(changesMade || offerIsSent)}>Offerte versturen</Button>
              :
              <Button className="btn-secondary opacity-50" type="button" onClick={() => dispatch(uiActions.setAlert({message: "Om de offerte te kunnen versturen dient u eerst de laatste wijzigingen op te slaan.", type: "danger", time: 5000}))}>Offerte versturen</Button>
              }
              </>
              }
              {dealDetail.saleType==="FinancialLease" &&
              <div className="flex flex-col gap-y-3">
                <Button className="btn-secondary flex flex-col" type="button" onClick={downloadPDFHandler}><span className="">Download offerte </span><span className="inline-block text-xs">{ondervoorbehoud && '(Onder voorbehoud)'}</span></Button>
                {(!ondervoorbehoud && /btw/i.test(dealDetail.typeOfDeal) && dealDetail.source!=="Website-aanvraag") && <Button className="btn-secondary" onClick={btwFinancieringHandler}>BTW-financiering</Button>}    
              </div>
              }
            </div>
            </div>
        </Card>
</form>

    {loadPdf && <Suspense fallback={<div>Loading ...</div>}><Pdf2 method={pdfMethod} hostname={pathName} ondervoorbehoud={ondervoorbehoud} onStartLoad={() => setShowPopup(false)} onLoadData={(data) => submitSendOffer(data)} onDone={() => setLoadPdf(false)} dealDetail={dealDetail} /></Suspense>}

    <Modal title="Geef op" show={showPhaseChoice} onClose={() => setShowPhaseChoice(false)}>
      <Select ref={phaseRef} set={dealDetail.isArchive ? 'phase' : 'isArchive_phase'} name="phase" placeholder="Fase" />
      {dealDetail.isArchive 
      ?
      <Button type="button" className="btn-primary" onClick={(obj) => archiveDealHandler(dealDetail, 'reset')}>Dearchiveren bevestigen</Button>
      : 
      <Button type="button" className="btn-primary" onClick={(obj) => archiveDealHandler(dealDetail)}>Archiveren bevestigen</Button>
      }
    </Modal>


    {(dealDetail.saleType==="FinancialLease" && showCalculator) &&
    <Suspense fallback={<div>Loading ...</div>}>
    <Modal title="Financiering aanpassen" show={showModal} onClose={() => setShowModal(false)}>
      <Calculator2 size="xs" onUpdate={updateCalculationHandler} onClose={() => setShowModal(false)} currentCalculation={{
        "Aanschaf Incl.": "\u20AC "+formatThousandNotation(dealDetail.amountpurchase),
        "BTW": "\u20AC "+formatThousandNotation(dealDetail.vat),
        "Aanschaf Excl.": "\u20AC "+formatThousandNotation(dealDetail.amountpurchaseExclVAT),
        "Aanbetaling": "\u20AC "+(dealDetail.downpayment ? formatThousandNotation(dealDetail.downpayment) : 0),
        "Inruil": "\u20AC "+(dealDetail.tradeInAmount ? formatThousandNotation(dealDetail.tradeInAmount) : 0),
        "Inlossing": "\u20AC "+(dealDetail.repayment ? formatThousandNotation(dealDetail.repayment) : 0),
        "Te financieren": "\u20AC "+formatThousandNotation(dealDetail.leaseAmount),
        "Slottermijn": "\u20AC "+(dealDetail.finalpayment ? formatThousandNotation(dealDetail.finalpayment) : 0),
        "Looptijd": dealDetail.duration+" maanden",
        "Maandbedrag": "\u20AC "+dealDetail.monthlyamount,
        "typeOfDeal": dealDetail.typeOfDeal,
        "Rente": dealDetail.interest+"%",
        "Provisiepercentage": dealDetail.provisiePercentage+"%"
      }}
      firstRegistrationDate={dealDetail.vehicleDetail.firstRegistrationDate}
      relatedID={dealDetail.relatedID}
      dealID={dealDetail.dealID}
      calcsrc={dealDetail.chosenBank}
      administrationCost={intTo2DecStr(dealDetail.administrationCost)}      
      disableTitle={true}
      restBPM={dealDetail.vehicleDetail.restBPM}
      leaseAmount={dealDetail.leaseAmount}
      typeOfDeal={dealDetail.typeOfDeal}
      disableMaxSlottermijn={true}
      slottermijnTouched={true}
      showDifference={true}
      vat={dealDetail.vat}
      duration={dealDetail.duration}
      finalpayment={dealDetail.finalpayment}
      amountpurchase={dealDetail.amountpurchase}
      amountpurchaseExclVAT={dealDetail.amountpurchaseExclVAT}
      provisiePercentage={dealDetail.provisiePercentage}
      downpayment={dealDetail.downpayment}
      interest={dealDetail.interest} 
      tradeInAmount={dealDetail.tradeInAmount} 
      repayment={dealDetail.repayment}
      vehicleType={dealDetail.vehicleDetail.vehicleType}
      />
    </Modal>
    </Suspense>
    }

<div className="text-xs lg:text-base mt-5">

<Card title={`Documenten${role.includes("Admin") ?' en activiteiten' : ''}`}>
  <div className="bg-white grid grid-cols-1 items-start rounded-b-xl">

    <div className={`flex flex-col gap-5 p-5 bg-white h-full ${role.includes("Admin") ? 'rounded-bl-xl' : 'rounded-b-xl'}`}>
       
        {dealDetail.saleType!=="Verzekering" &&
        <DocumentForm updated={documentCounter} onUpdate={() => updateWidgetHandler('documents')} dealID={dealDetail.dealID} relatedID={dealDetail.relatedID} saleType={dealDetail.saleType} dealerName={dealDetail.dealerDetail.name} />
    }
    </div>
    {role.includes("Admin") &&
    <div className="flex flex-col p-5 bg-slate-50 h-full rounded-br-3xl">
      <div className="flex flex-col gap-3 mt-5">
        <NewActivityForm onUpdate={updateWidgetHandler} dealID={dealDetail.dealID} companyName={dealDetail.dealerDetail.name} source={dealDetail.source} />
        <ActivityOverzicht onUpdate={updateWidgetHandler} relatedID={dealDetail.relatedID} updated={activityCounter} />
       </div>
    </div>
    }
  </div>
</Card>

</div>
    {showPopup && <Popup2 visible={true} onSubmit={popupSubmitHandler} onCancel={() => setShowPopup(false)} title="Offerte versturen aan de klant?" message={["Weet u zeker dat u dit voorstel wilt doen aan uw klant?. Wij sturen uw klant een mail met de definitieve offerte en het verzoek de benodigde persoonsgegevens aan te leveren."]} />}
    </>
  );
};

export default DealForm;
