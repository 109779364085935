import { useState, useRef } from "react";

const useFormValidation = (field, enteredValue, validateValue = () => true) => {
    const fieldRef = useRef();

    const [isTouched, setIsTouched] = useState(false);
    const valueIsValid = validateValue(enteredValue);
    const hasError = !valueIsValid && isTouched;

    const inputBlurHandler = evt => {      
        if(evt.target.value.length) {
            setIsTouched(true);
        }
    }

    return {
        ref: fieldRef,
        value: enteredValue,
        hasError,
        inputBlurHandler,
        isValid: valueIsValid
    }

}

export default useFormValidation;