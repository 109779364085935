import React from "react";

const InputRadio = React.forwardRef((props,ref) => { 
    let classes = "grid";
    if (props.className) classes += " " + props.className;
    if(props.view === "horizontal") classes+= " grid-cols-2";
    if(props.view === "vertical") classes+= " grid-cols-1";
    var inputType = props.type || 'radio';
    if(!props.options) return <></>;

    return (
        <div>
            {props.label && <label>{props.label}</label>}
            <div className={classes}>
                {props.options.map(option => {
                    return (
                        <label key={option.value ? option.value : option.label} className="select-none">
                            <input type={inputType} name={props.name} value={option.value} ref={ref} onChange={props.onChange} checked={props.checked} />
                            <span className="ml-2">{option.label ? option.label : option.value}</span>
                        </label>
                    )
                })}
            </div>
        </div>
    )
});

export default InputRadio;