import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import PrevNext from "components/Navigation/PrevNext";
import { dossierActions } from "store/dossier-slice";
import Input from "components/ui/Input/Input";
import InputDatum2 from "components/ui/InputDatum/InputDatum2";
import Select from "components/ui/Select/Select";
import useHead from "hooks/use-head";
import useMakeModel from "hooks/use-makemodel";
import useVoertuig from "hooks/use-voertuig";
import { formatThousandNotation } from "lib/calc";
import useCurrentCase from "hooks/use-current-case";

const CarForm = () => {
    const [manualModel, setManualModel] = useState(0);
    const dispatch = useDispatch();
    useCurrentCase();
    useHead({ title: "Voertuiggegevens" });
    const [formIsValid, setFormIsValid] = useState(false);

    const kmstandRef = useRef();
    const merkRef = useRef();
    const modelRef = useRef();
    const datumEersteToelatingRef = useRef();
    const voertuigsoortRef = useRef();
    const recalculation = useSelector(state => state.dossier.recalculation);

    const { merk, model, datum_eerste_toelating, kenteken, voertuigsoort, kmstand } = useVoertuig();

    const changeInputHandler = (evt) => {
        var value = evt.target.value;
        var fieldname = evt.target.name;
        if(fieldname==="kmstand") value = formatThousandNotation(value);
        dispatch(dossierActions.setVoertuigDataManually({ [fieldname]: value }));
    }
    const { makes, models, merkChangeHandler, modelChangeHandler } = useMakeModel(merkRef, modelRef, kenteken);

    useEffect(() => {
        if(merk) merkChangeHandler(merk, model);
    },[]);

    useEffect(() => {
        (merk && model && datum_eerste_toelating && voertuigsoort && kmstand) ? setFormIsValid(true) : setFormIsValid(false);
    },[merk, model, kmstand, voertuigsoort, datum_eerste_toelating]);

    useEffect(() => {
        if(!manualModel) return;
        modelRef.current.focus();
    }, [manualModel]);

    return (
        <>
                <h1>Voertuiginformatie</h1>
                <form className="">
                    <div className="grid md:grid-cols-2 gap-5">
                        {!kenteken ?
                            <>
                                <div className="">
                                {makes && <Select className="order-1" name="merk" label="Merk" value={merk} ref={merkRef} onChange={merkChangeHandler} options={makes.map(item => {
                                    return { key: item.label, label: item.label, value: item.id }
                                })} />}
                                </div>
                                <div className="">
                                    <div className="flex flex-row items-center gap-x-3">
                                    {(models && !manualModel) ? <Select className="grow" name="model" label={manualModel===0 ? "Model": "Model (uit modellenlijst)"} value={model} ref={modelRef} onChange={modelChangeHandler} options={models.map(item => {
                                        return { key: item.id, label: item.label, value: item.id, extra: item.voertuigsoort }
                                    })} />
                                    :
                                    <Input className="grow" name="model" placeholder="Model (handmatig)" label="Model" value={model} ref={modelRef} onChange={changeInputHandler} />
                                    }
                                    <div className="flex flex-row gap-x-2 p-2 items-center cursor-pointer hover:text-slate-600" onClick={() => setManualModel(prevState => !prevState)}>
                                        <span className={`fa ${manualModel ? "fa-list" : "fa-pen-to-square"}`}></span>
                                        <span>{manualModel ? "Kies uit modellenlijst" : "Handmatig invoeren"}</span>
                                    </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <Input className="order-1" name="merk" placeholder="Merk" value={merk} ref={merkRef} onChange={changeInputHandler} />
                                <Input className="order-2" name="model" placeholder="Model" value={model} ref={modelRef} onChange={changeInputHandler} />
                            </>
                        }
                        <Select name="voertuigsoort" className="order-3" label="Kies voertuigsoort" options={[{ key: "AUTO", label: "Personenauto", value: "Personenauto" }, { key: "BEDRIJF", label: "Bedrijfsauto", value: "Bedrijfsauto" }]} value={voertuigsoort} ref={voertuigsoortRef} onChange={changeInputHandler} />
                        <Input className="order-4" name="kmstand" placeholder="Kmstand" inputMode="numeric" value={kmstand} ref={kmstandRef} onChange={changeInputHandler} />
                        <InputDatum2 className="order-5" ref={datumEersteToelatingRef} placeholder="Datum 1e toelating" value={datum_eerste_toelating!=="Invalid Date" ? datum_eerste_toelating: ""} name="datum_eerste_toelating" />
                    </div>
                </form>
                <PrevNext nextDisabled={!formIsValid} prevLabel={recalculation ? "Ander voertuig" : "Aanvraag annuleren"} prevClearDossier={!recalculation} />
        </>
    );
}

export default CarForm;