import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import useAuth from "hooks/use-auth";
import { dateToDutch } from "lib/kvk";
import { uiActions } from "store/ui-slice";
import usePagination from "hooks/use-pagination";
import Pagination from "components/Navigation/Pagination";
import useSearch from "hooks/use-search";
import { serialize } from "lib/helpers";
import Zoekmachine from "components/Zoekmachine/Zoekmachine";
import SelectDealer from "components/ui/SelectDealer/SelectDealer";

const DealerOverzicht = () => {
  const { token: jwt, role } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page, perpage, changePageSize, nextPageHandler, prevPageHandler, toFirstPageHandler } = usePagination();
  const [query, setQuery] = useState('');
  const archiveRef = useRef();
  
  const [dealers, setDealers] = useState({
    pageNumber: page,
    pageSize: perpage,
    totalPages: "",
    succeeded: "",
    totalRecords: "",
    data: []
  });

  const { pageNumber, pageSize, totalRecords, data } = dealers;
  const { resetHandler, searchHandler, isTouched: searchIsTouched, isResetted: searchIsResetted, searchResult } = useSearch(dealers.data);
  
  useEffect(() => {
    var queryParams = "";
    if(query) queryParams = serialize(query);

    dispatch(uiActions.showBackdrop());
    var fetchUrl = process.env.REACT_APP_SLD_API_BASE_URL + '/api/Dealer?'+(!/pageNumber/.test(queryParams) ? 'pageNumber='+page+'&' : '&')+(!/pageSize/.test(queryParams) ? 'pageSize='+perpage : '&')+(queryParams ? '&'+queryParams : '');
    fetchUrl = fetchUrl.replace(/\?&*/, "?");

    fetch(fetchUrl, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwt
      }
    })
    .then(resp => resp.json())
    .then(responseData => {
      if(/pageNumber/.test(queryParams)) {
        toFirstPageHandler(99999);
      }
      setDealers(responseData);
      dispatch(uiActions.hideBackdrop());
    })
    .catch(() => {
      dispatch(uiActions.hideBackdrop());
    })
  },[perpage, page, query, searchIsResetted]);

  useEffect(() => {
    setDealers(prevState => {
      return {
        ...prevState,
        data: searchResult
      }
    });
  },[searchResult]);

  const initSearch = {
    pageNumber: 1,
    pageSize: 9999,
    dealerId: "",
    name: "",
    isArchive: false,
    chamberNumber: "",
    postalCode: "",
    place: "",
    status: ""
}

  const [initialSearch, setInitialSearch] = useState(initSearch);

  const clearHandler = () => {
    setQuery("");
    changePageSize(25);
  }
  
  return (
    <div className="text-xs lg:text-base max-w-6xl">
       
        {role.includes("Admin") && (
        <>
        <Zoekmachine searchFor="dealers" initialSearch={initialSearch} token={jwt} onSearch={inputQuery => setQuery(inputQuery)} onClear={clearHandler} perpage={perpage} onChangePageSize={changePageSize} />
        <div className="p-3">{totalRecords} resulta{totalRecords===1 ? 'at' : 'ten'}</div> 
          </>
        )}

      {data.length ? (
        <div>
            <div className="bg-sld-blue text-white rounded-xl p-3 mb-3 items-center grid grid-cols-2 md:grid-cols-12 gap-x-2">
              <div>Actief / ID</div>
              <div className="col-span-3">Bedrijfsnaam</div>
              <div className="col-span-3">E-mail</div>
              <div className="col-span-2">Telefoon</div>
              <div className="col-span-2">Datum</div>
              <div>Status</div>
          </div>

            {data.map(dealer => {
                return (
                <div key={dealer.dealerID} className={"grid grid-cols-2 md:grid-cols-12 gap-x-2 items-center hover:bg-[#F6901E] hover:text-white px-5 py-3 shadow-md bg-white rounded-lg mb-5 cursor-pointer"+(dealer.isArchive ? ' opacity-50' : '')} onClick={() => navigate(""+dealer.dealerID)}>
                  <div className="">
                    <div className={`flex flex-row justify-center rounded-full w-8 h-8 p-0 m-0 items-center ${dealer.isActive ? 'bg-[#008002]': 'bg-[#FF0000]'}`}>
                      <span className="text-white text-xs">{dealer.dealerID}</span>
                    </div>
                  </div>
                  <div className="col-span-3 flex flex-col">
                    <div>{dealer.name}</div>
                    <div>KvK: {dealer.chamberNumber}</div>
                  </div>
                  <div className="col-span-3">{dealer.email}</div>
                  <div className="col-span-2">{dealer.phone}</div>
                  <div className="col-span-2">{dateToDutch(dealer.createdDate)}</div>
                  <div className="">{dealer.status!=="undefined" && dealer.status}</div>
                </div>
              )
            })}
        </div>
      ) : (
        <p>Er zijn nog geen dealers</p>
      )}  
      <Pagination page={page} prevPageHandler={prevPageHandler} nextPageHandler={nextPageHandler} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
    </div>
  );
};

export default DealerOverzicht;
