import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import useAuth from "hooks/use-auth";

import Container from "components/hoc/Container";
import Content from "components/Content/Content";
import Button from "components/ui/Button/Button";
import Input from "components/ui/Input/Input";
import VoorraadModulePdf from "components/Pdf/VoorraadModulePdf";
import { uiActions } from "store/ui-slice";

const PageVoorraadModule = () => {
    const { token: jwt } = useAuth();
    const dispatch = useDispatch();
    const emailRef = useRef();
    const { dealerToken, name: dealerName, dealerDomain } = useSelector(state => state.dealer.dealer);
    const [method, setMethod] = useState("");
    const [pdfIsSent, setPdfIsSent] = useState(false);
    
    const sendPdfHandler = (data) => {
        if(pdfIsSent) return;
        dispatch(uiActions.showBackdrop());   
        var file = new File([data], "attachment.pdf", { type: "application/pdf"});
        var formData = new FormData();
        formData.append("fileUrlForm", file);
        formData.append("email", emailRef.current?.value);

        // formData.append("name", ""+dealDetail.phase);
        // formData.append("description", "Verzonden via klik op button");
        // formData.append("dealID", dealDetail.dealID);
        // formData.append("offer", "2");
        // formData.append("type", "Offerte verzonden");
        // formData.append("createAt", new Date());

        fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/ActivitiesDeal', {
          headers: {
            "Authorization": "Bearer " + jwt
          },
          body: formData,
          method: "POST",
        }).then((response) => {
          if(response.status!==200) throw Error();
          setPdfIsSent(true);
          dispatch(uiActions.hideBackdrop());
          dispatch(uiActions.setAlert({message: "De handleiding is succesvol verzonden", type: "success"}));
        }).catch(() => {
            setPdfIsSent(false);
          dispatch(uiActions.hideBackdrop());
          dispatch(uiActions.setAlert({message: "Fout bij het versturen van de handleiding. Probeer opnieuw!", type: "danger"}));
        });
      }

    return (
        <>
            <Container type="fullscreen">
                <Content title="Voorraadmodule" />
            </Container>
            {/* onDone={() => setLoadPdf(false)}  */}
            {method && <VoorraadModulePdf dealerDomain={dealerDomain} dealerToken={dealerToken} dealerName={dealerName} method={method} onSendData={(data) => sendPdfHandler(data)} onDisableDownload={() => setMethod("")} />}
            <Container className="mb-10">
                <div className="flex flex-col gap-y-3">
                <div className="text-xl">Voorraadmodule van SLD op uw website?</div>
                <p className="mb-5">Middels de voorraadmodule van SLD geeft u uw klanten de mogelijkheid direct een Financial Lease offerte aan te vragen op uw beschikbare voertuigen. De aanvragen worden bij ons in het systeem geregistreerd en uiteraard ook direct beoordeeld. Bij goedkeuring ontvangt u hier bericht van en kunt u contact opnemen met uw klant voor het uitbrengen van een lease offerte.</p>
                    <div className="flex flex-col sm:flex-row gap-3">
                        <a href={`https://dealermodule.sld.nl/?api=${dealerToken}`} target="_blank" className="btn-secondary">Bekijk voorbeeld</a>
                        <Button onClick={() => setMethod("download")}>Download handleiding</Button>
                    </div>
                    {/* <div className="mt-5 text-xl">Handleiding aan webbouwer versturen</div>
                    <p>Vul hieronder het e-mailadres van jouw webbouwer in en hij/zij ontvangt direct de implementatie handleiding van de SLD dealermodule. Zodat u deze op uw eigen website kunt tonen.</p>
                    <Input ref={emailRef} type="email" name="email" placeholder="E-mailadres van webbouwer" />
                    <Button className="btn-primary" onClick={sendPdfHandler}>Versturen</Button> */}
                </div> 
            </Container>
        </>
    )
}


export default PageVoorraadModule; 