import { NavLink } from "react-router-dom";
import DealerOverzicht from "components/Tables/DealerOverzicht";

const Dealers = () => {
  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-5">
      {/* <h1>Autobedrijven</h1> */}
      <NavLink
        to="nieuwe-dealer"
        className="hover:scale-105 bg-[#212853] w-[50px] h-[50px] rounded-full justify-center text-center flex flex-col text-white"
        >
        <span className="fa fa-plus"></span>
      </NavLink>
        </div>
      <DealerOverzicht />
    </div>
  );
};

export default Dealers;
