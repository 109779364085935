import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from "components/ui/Input/Input";
import Select from "components/ui/Select/Select";
import { dateToDutch } from "lib/kvk";
import { getCustomerById, updateCustomer, updateCustomer2 } from "store/customer-actions";
import Card from "components/ui/Card/Card";
import useAuth from "hooks/use-auth";
import { customerActions } from "store/customer-slice";
import InputDatum2 from "components/ui/InputDatum/InputDatum2";
import Button from "components/ui/Button/Button";
import Accordion from "components/ui/Accordion/Accordion";
import { dateToISO, localDate } from "lib/calc";

const CustomerForm = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const params = useParams();
    const { token, role } = useAuth();
    const dispatch = useDispatch();

    var customerID = params.customerID || props.customerID;

    // const { customerID } = params;

    const dateofBirthRef = useRef();
    const dateofEstebRef = useRef();
    var customer = useSelector(state => state.customer.customer);

    useEffect(() => {
        (async () => {
            if (props.detail) {
                await dispatch(getCustomerById(customerID, token, props.disableBackDropHandler));
                setIsLoaded(true);
            }
        })();
    }, []);

    const submitFormHandler = async (evt) => {
        evt.preventDefault();
        const updatedCustomer = {
            ...customer,
            ...(dateofBirthRef && {"dateofBirth": dateToISO(dateofBirthRef.current?.value)}),
            ...(dateofEstebRef && {"dateofEsteb": dateToISO(dateofEstebRef.current?.value)})
        }
        updatedCustomer.postalCode = updatedCustomer.postalCode.replace(/\s/g, "").toUpperCase();

        var resp = await dispatch(updateCustomer(updatedCustomer, token));
        if(resp==="SUCCESS" && props.inModal) props.onUpdate();  
    };

    const valueChangeHandler = (evt) => {
        dispatch(customerActions.setCustomerData({ [evt.target.name]: evt.target.value }));
    };

    const cardTitle = props.title || (customer.firstName+" "+customer.lastName+" - "+customer.companyName);
    if(!customer.companyName || !isLoaded) return <>Laden...</>;

    return (
        <form onSubmit={submitFormHandler} className={`text-xs lg:text-base ${props.inModal ? 'mt-0' : 'mt-5'}`}>
            <Card title={cardTitle} showClose={props.inModal} onClose={props.onClose}>
                <div className="bg-white grid lg:grid-cols-3 items-start rounded-b-3xl">
                    <div className="grid col-span-2 gap-3 p-5">
                        {role.includes("Admin") &&
                        <Accordion open={true} summary="Bedrijfsgegevens">
                            <div className="grid grid-cols-2 gap-3 py-5">
                            <Input value={customer.companyName} onChange={valueChangeHandler} placeholder="Bedrijfsnaam" name="companyName" />
                            <Input value={customer.chamberNumber} onChange={valueChangeHandler} placeholder="KvK nummer" name="chamberNumber" />
                            <Input value={customer.address} onChange={valueChangeHandler} placeholder="Adres" name="address" />
                            <Input value={customer.place} onChange={valueChangeHandler} placeholder="Plaats" name="place" />
                            <Input value={customer.postalCode} onChange={valueChangeHandler} placeholder="Postcode" name="postalCode" />
                            <InputDatum2 ref={dateofEstebRef} placeholder="Datum oprichting" value={localDate(customer.dateofEsteb)} />
                            </div>
                        </Accordion>}
                        <Accordion open={true} summary="Persoonsgegevens">
                            <div className="grid grid-cols-2 gap-3 py-5">
                            <Select value={customer.salutation} label="Aanhef" set="aanhef" name="salutation" onChange={valueChangeHandler} />
                            <Input value={customer.firstName} onChange={valueChangeHandler} placeholder="Voornaam" name="firstName" />
                            <Input value={customer.lastName} onChange={valueChangeHandler} placeholder="Achternaam" name="lastName" />
                            <Input value={customer.phone} onChange={valueChangeHandler} placeholder="Telefoon" name="phone" />
                            <Input value={customer.email} onChange={valueChangeHandler} placeholder="E-mailadres" name="email" />
                            <InputDatum2 ref={dateofBirthRef} name="dateofBirth" placeholder="Geboortedatum" value={localDate(customer.dateofBirth)} />
                            <Input value={customer.driverLicenseNumber} onChange={valueChangeHandler} placeholder="Rijbewijsnummer" name="driverLicenseNumber" />
                            <Input value={customer.iban} onChange={valueChangeHandler} placeholder="IBAN" name="iban" />
                            </div>
                        </Accordion>
                    </div>
                    <div className="flex flex-col col-span-2 md:col-span-1 gap-5 p-5 bg-slate-50 h-full rounded-br-3xl">
                        <div>Datum aangemaakt {dateToDutch(customer.createdDate)}</div>
                        <div>Laatst bijgewerkt {dateToDutch(customer.updatedDate)}</div>
                        <Button className="bg-gradient">Opslaan</Button>
                    </div>
                </div>
            </Card>
        </form>
    )
}

export default CustomerForm;
