import { useDispatch, useSelector } from "react-redux";
import Button from "components/ui/Button/Button";
import styles from "./Uitleg.module.css";
import { tutorialActions } from "store/tutorial-slice";

const Uitleg = (props) => {
    const dispatch = useDispatch();
    const { tutorialStep } = useSelector(state => state.tutorial);

    var classes = styles.arrowCenter;
    var view = "fixed top-1/4";
    if(props.arrowPosition==="left") classes = styles.arrowLeft;
    if(props.arrowPosition==="center") classes = styles.arrowCenter;
    if(props.arrowPosition==="right") classes = styles.arrowRight;
    if(props.view==="absolute") view = "absolute bottom-full mb-10";

    return (
        <div className={`${view} z-30 items-center flex flex-col w-full left-0`}>
            <div className={`${styles.Uitleg} ${classes} max-w-2xl w-full text-left relative`}>
                <div className="relative bg-white rounded-2xl">

                    {/* Modal header */}
                    <div className="flex justify-between border-b bg-[#212853] text-white py-1 p-5 rounded-t-2xl items-center">
                        <h3 className="text-xl font-semibold text-gray-900">{props.title}</h3>
                        <Button onClick={() => dispatch(tutorialActions.closeTutorial())} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Sluiten</span>
                        </Button>
                    </div>
                    <div className="p-6 space-y-6">{props.description}</div>

                <div className={`flex flex-row z-20 relative pb-5 px-5 ${(!/^(1|4)$/.test(tutorialStep) || props.prevStepName) ? 'justify-between' : 'justify-end'}`}>
                    {(!/^(1|4)$/.test(tutorialStep) || props.prevStepName) && <Button type="button" onClick={() => props.prevStepName ? dispatch(tutorialActions.changeStepName(props.prevStepName)) : dispatch(tutorialActions.decreaseStep())}>Vorige</Button>}
                    {((tutorialStep<4 || props.nextStepName) && !props.hideNextButton) && <Button className="btn-primary" type="button" onClick={() => props.nextStepName ? dispatch(tutorialActions.changeStepName(props.nextStepName)) : dispatch(tutorialActions.increaseStep())}
                    
                    
                    >Volgende</Button>}
                </div>
                </div>
            </div>
        </div>
    )
}

export default Uitleg;