import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.body.scrollTo(0, 0); 
    },[location.pathname]);

    return props.children;
}

export default ScrollToTop;