import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Input from "../Input/Input";
import { dossierActions } from "store/dossier-slice";

const InputKvk = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [kvkLoader, setKvkLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [kvkListData, setKvkListData] = useState([]);
  const [isFocussed, setIsFocussed] = useState(true);

  useEffect(() => {
    if(searchQuery) setKvkLoader(true);
    const timer = setTimeout(function() {
      if(!searchQuery) {
        setKvkListData([]);
        setKvkLoader(false);
        return;
      };
      fetch("https://zoeken.kvk.nl/Address.ashx?site=handelsregister&q="+searchQuery)
      .then(resp => resp.json())
      .then(({ resultatenHR: response }) => {
        setKvkListData(response);
        setKvkLoader(false);
      })
      .catch(err => {
        setKvkLoader(false);
        console.log("Fout bij het ophalen van de KvK data", err)});
    }, 450);
    return () => clearTimeout(timer);       
  },[searchQuery]);
  
  const kvkData = useSelector((state) => state.dossier.kvkData);
  const {
    dossiernummer,
    bedrijfsnaam,
    straat: bedrijf_straat,
    huisnummer: bedrijf_huisnummer,
    huisnummertoevoeging: bedrijf_huisnummertoevoeging,
    postcode: bedrijf_postcode,
    plaats: bedrijf_plaats,
  } = kvkData;


  const setKvKDataHandler = (kvkData) => {
    // setKvkListData([]);
    dispatch(dossierActions.setKvkData(kvkData));
    setTimeout(function() {
      setIsFocussed(false);
    },250);
  }

  const clearKvkHandler = () => {
    setKvkListData([]);
    dispatch(dossierActions.setKvkData({}));
  }

  return (
    <>
      <div className={props.className}>
        <div className="relative">       
          {!bedrijfsnaam &&
          <Input
            ref={ref}
            inputClasses={((isFocussed && ref.current?.value) ? 'rounded-bl-none rounded-br-none focus:outline-none': '')}
            name="bedrijfsnaam"
            placeholder={props.placeholder ? props.placeholder : "Bedrijfsnaam"}
            onKeyUp={(evt) => setSearchQuery(evt.target.value)}
            // onKeyUp={searchKvkHandler}
            onFocus={() => setIsFocussed(true)}
            onChange={props.onChange}
            showSpinner={kvkLoader}
             />
          }
            {(kvkListData && isFocussed) &&
            <div className={`${!bedrijfsnaam ? 'border-bx' : ''} bg-slate-50 w-full border-x rounded-b-xl border-slate-300 absolute top-100 left-0 md:absolute bg-white max-h-96 overflow-y-scroll z-20 shadow ${(isFocussed && kvkListData.length) ? 'min-h-[100px]' : ''}`}>
            {kvkListData.map((company, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => setKvKDataHandler(company)}
                    className="flex xflex-row items-center gap-3 grid md:grid-cols-5 hover:bg-orange hover:text-white px-3 py-1 cursor-pointer border-b border-slate-100"
                  > 
                    <div className="col-span-3">{company.handelsnaam}</div>
                    <div className="">{company.plaats}</div>
                    <div className="">{company.dossiernummer}</div>
                  </div>
                );
              }
              )}
              </div>
            }

        {dossiernummer && (
          <div className={(bedrijfsnaam ? 'cursor-default items-center border rounded-xl border-slate-300' : 'border-slate-500 border-1')+" flex flex-row justify-between gap-x-5 bg-white top-1 p-3"}>
            <div className="">
              <span>{bedrijfsnaam}</span>
              <span className="ml-5">{bedrijf_straat} {bedrijf_huisnummer} {bedrijf_huisnummertoevoeging
                ? bedrijf_huisnummertoevoeging
                : ""} {bedrijf_straat && ','} {bedrijf_postcode} {bedrijf_plaats}</span>           
            </div>
            <div onClick={clearKvkHandler} className="fa fa-circle-xmark hover:text-slate-400 text-xl cursor-pointer"></div>
          </div>
        )}
        </div>

      </div>
    </>
  );
});

export default InputKvk;