import { useState, useEffect } from "react";
import useAuth from "hooks/use-auth";
import RoleForm from "components/Forms/RoleForm/RoleForm";

const Dashboard = () => {
    const { token : jwt, role } = useAuth();
    // useEffect(() => {
    //     if(role.includes("SuperAdmin")) {
        
    //     }
    // },[]);

    
    const [dealProvisie, setDealProvisie] = useState("");
    const [countBedrijven, setCountBedrijven] = useState("");
    const [countDeals, setCountDeals] = useState("");
    // const [countArchivedDeals, setCountArchivedDeals] = useState("");
    const [countFinancialLeaseDeals, setCountFinancialLeaseDeals] = useState("");
    const [countBtwDeals, setCountBtwDeals] = useState("");
    const [countVerzekeringDeals, setCountVerzekeringDeals] = useState("");

    const dealerData = fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Dealer", {headers:{"Authorization": "Bearer "+jwt}}).then((res) => res.json()).catch(err => {});
    const dealData = fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/Filters", {headers:{"Authorization": "Bearer "+jwt}}).then((res) => res.json()).catch(err => {});          
    // const archivedDealData = fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/Filters?isArchive=true", {headers:{"Authorization": "Bearer "+jwt}}).then((res) => res.json()).catch(err => {});            
    const financialLeaseDealData = fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/Filters?saleType=FinancialLease", {headers:{"Authorization": "Bearer "+jwt}}).then((res) => res.json()).catch(err => {});            
    const btwDealData = fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/Filters?saleType=BtwFinanciering", {headers:{"Authorization": "Bearer "+jwt}}).then((res) => res.json()).catch(err => {});            
    const verzekeringDealData = fetch(process.env.REACT_APP_SLD_API_BASE_URL+"/api/Deal/Filters?saleType=Verzekering", {headers:{"Authorization": "Bearer "+jwt}}).then((res) => res.json()).catch(err => {});            

    useEffect(() => {
        const allData = Promise.all([dealerData, dealData, financialLeaseDealData,btwDealData,verzekeringDealData]);
        allData.then(([bedrijven, deals, financialLeaseDeals, btwDeals, verzekeringDeals]) => {
            setCountBedrijven(bedrijven);
            setCountDeals(deals);
 
            var provisie = 0;
            var provisies = deals.data.filter(item => item.commissionAmountSLD).map(x => x.commissionAmountSLD);
            provisies.forEach(function(dealprovisie) {
                provisie+=+dealprovisie;
            });

            setDealProvisie(provisie);

            // setCountArchivedDeals(archivedDeals.totalRecords);
            setCountFinancialLeaseDeals(financialLeaseDeals);
            setCountVerzekeringDeals(btwDeals);
            setCountBtwDeals(verzekeringDeals);
        });
    },[]);


    if(role.includes("SuperAdmin")) return;


    return (
        <div className="max-w-6xl">
        {/* <h1>Dashboard</h1> */}
        <div className="mt-5 grid grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-20 text-center justify-center items-center">
            <div className="flex flex-col justify-center border border-slate-300 p-3 rounded-full w-40 h-40 lg:w-60 lg:h-60 items-center">
                {/* <span className="text-3xl fa fa-address-card"></span> */}
                <span className="text-3xl lg:text-5xl font-bold">{countBedrijven.totalRecords}</span>
                <span className="text-lg lg:text-2xl">Aantal bedrijven</span>
            </div>
            <div className="flex flex-col justify-center border border-slate-300 p-3 rounded-full w-40 h-40 lg:w-60 lg:h-60 items-center">
                {/* <span className="text-3xl fa fa-handshake"></span> */}
                <span className="text-3xl lg:text-5xl font-bold">&euro; </span>
                <span className="text-lg lg:text-2xl">Provisie</span>
            </div>
            <div className="flex flex-col justify-center border border-slate-300 p-3 rounded-full w-40 h-40 lg:w-60 lg:h-60 items-center">
                {/* <span className="text-3xl fa fa-handshake"></span> */}
                <span className="text-3xl lg:text-5xl font-bold">{countDeals?.totalRecords}</span>
                <span className="text-lg lg:text-2xl">Totaal verkopen</span>
            </div>
            <div className="flex flex-col justify-center border border-slate-300 p-3 rounded-full w-40 h-40 lg:w-60 lg:h-60 items-center">
                {/* <span className="text-3xl fa fa-car-md"></span> */}
                <span className="text-3xl lg:text-5xl font-bold">{countFinancialLeaseDeals.totalRecords}</span>
                <span className="text-lg lg:text-2xl">Financial Lease</span>
            </div>
            <div className="flex flex-col justify-center border border-slate-300 p-3 rounded-full w-40 h-40 lg:w-60 lg:h-60 items-center">
                {/* <span className="text-3xl fa fa-euro-sign"></span> */}
                <span className="text-3xl lg:text-5xl font-bold">{countBtwDeals.totalRecords}</span>
                <span className="text-lg lg:text-2xl">Btw Financiering</span>
            </div>
            <div className="flex flex-col justify-center border border-slate-300 p-3 rounded-full w-40 h-40 lg:w-60 lg:h-60 items-center">
                {/* <span className="text-3xl fa fa-shield"></span> */}
                <span className="text-3xl lg:text-5xl font-bold">{countVerzekeringDeals.totalRecords}</span>
                <span className="text-lg lg:text-2xl">Verzekering</span>
            </div>
        </div>
        </div>
    )
}

export default Dashboard;