import Button from "../Button/Button";

const Popup2 = (props) => {
    const { title, message, visible } = props;
    return (
        <>
            {visible && (
                <>
                    <div className="flex flex-col left-0 top-0 fixed w-full h-full bg-black opacity-75 z-20"></div>
                    <div className="flex flex-col left-0 top-0 fixed w-full h-full items-center justify-center z-50">
                        <div className="bg-white p-8 w-fit rounded-xl text-center relative shadow-xl">
                            <div className="text-3xl font-black mb-5 border-b border-[#D9D9D9] pb-3">{title}</div>
                            <div className="mb-5 whitespace-pre-wrap text-left">{message}</div>
                            <div className="flex flex-row justify-end gap-2">
                                <Button className="btn-primary" onClick={props.onSubmit}>Ja, versturen</Button>
                                <Button className="btn-secondary" onClick={props.onCancel}>Nee, annuleren</Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Popup2;