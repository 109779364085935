import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "./auth-actions";

var initialState = {
    list: [],
    partners: [],
    pageNumber: 1,
    pageSize: "",
    totalPages: "",
    totalRecords: "",
    dealer: {
        dealerID: "",
        name: "",
        address: "",
        email: "",
        importID: "",
        logoImageUrl: "",
        postalCode: "",
        telephone: "",
        phone: "",
        website: "",
        iban: "",
        place: "",
        buttonTextColor: "",
        primaryColor: "#f8700d",
        dealerCategory: "",
    }
}

export const dealerSlice = createSlice({
    name: 'dealer',
    initialState: initialState,
    reducers: {
        reset: () => initialState,
        changeHuisstijl(state, actions) {
            state.huisstijl = {
                ...state.huisstijl,
                ...actions.payload
            }
        },
        clearDealer(state, actions) {
            state.dealer = {};
        },
        addDealer(state, actions) {
            
            // state.list = state.list.push(actions.payload);
        },
        updateDealer(state, actions) {
            
        },
        setDealers(state, actions) {
            state.list = actions.payload.data;
            state.pageNumber= actions.payload.pageNumber;
            state.pageSize = actions.payload.pageSize;
            state.totalPages = actions.payload.totalPages;
            state.totalRecords = actions.payload.totalRecords;
        },
        setPartners(state, actions) {
            state.partners = actions.payload;
        },
        setDealerData(state, actions) {
            state.dealer = {
                ...state.dealer,
                ...actions.payload
            };
        },
        setSingleDealer(state,actions) {
            state.dealer = actions.payload;
        },
        changeDealer(state, actions) {
      
        }
    },
    extraReducers: (builder) => {
        // builder.addCase(userLogin.fulfilled, (state, {payload}) => {
        //     const { dealer } = payload;
        //     if(dealer) state.dealer = dealer;
        //     // state.dealer = actions.payload;
        // })
    }
});


export const dealerActions = dealerSlice.actions;
