import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { progressActions } from "store/progress-slice";

const useCurrentCase = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        if(!location) return;
        const currentCaseArr = location.pathname.split("/");
        if(currentCaseArr.includes("verzekering")) dispatch(progressActions.changeCase("Verzekering"));
        if(currentCaseArr.includes("financial-lease")) dispatch(progressActions.changeCase("FinancialLease"));
        if(currentCaseArr.includes("btw-financiering")) dispatch(progressActions.changeCase("BtwFinanciering"));
    },[]);   
}

export default useCurrentCase;