import { Routes, Route, Outlet } from "react-router-dom";
import VerzekerenStap1 from "./index";
import VerzekerenForm from "./klantgegevens";

import Voertuiggegevens from "./voertuiggegevens";
import StapBedankt from "./bedankpagina";
import StapBtwMarge from "./btw-marge";
const VerzekeringRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Outlet />}>
                <Route index element={<VerzekerenStap1 />} />
                <Route path="voertuiggegevens" element={<Voertuiggegevens />} />
                <Route path="btw-marge" element={<StapBtwMarge />} />
                <Route path="klantgegevens" element={<VerzekerenForm />} />
                <Route path="bedankpagina" element={<StapBedankt />} />
            </Route>
        </Routes>
    )
}

export default VerzekeringRoutes;