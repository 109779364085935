
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import useAuth from 'hooks/use-auth';
import { useDispatch } from 'react-redux';
import { counterActions } from 'store/counter-slice';
import { mergePhaseCounters } from 'store/counter-actions';
import { NavLink } from 'react-router-dom';
import { useState, useRef, useMemo, useEffect } from 'react';


const Chat = () => {

    let audio = useMemo(() => new Audio("/ding.mp3"));

    const [messageCounter, setMessageCounter] = useState(0);

    const [btnDisabled, setBtnDisabled] = useState("disabled");
    const [messageOpen, setMessageOpen] = useState(false);
    const [transformClasses, setTransformClasses] = useState("-bottom-full");
    const { token: jwt } = useAuth();
    const [ connection, setConnection ] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!messageCounter) return;
        setMessageOpen(true);
        var audioTimer = setTimeout(function() {
            audio.play();
        }, 500);
        setTransformClasses("bottom-0");
        var timer = setTimeout(function() {
            hideMessageHandler();
        },10000);

        return () => {
            clearTimeout(timer);
            clearTimeout(audioTimer);
        }
    },[messageCounter]);


    const hideMessageHandler = () => {
        setMessageOpen(false)
        setTransformClasses("-bottom-full")
    }

    useEffect(() => {
        dispatch(mergePhaseCounters(jwt));
    }, []);

    useEffect(() => {
        if(!jwt) return;
        const connection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_SLD_API_BASE_URL+'/dealCountingHub', {
                headers: {
                   'Authorization': 'Bearer ' + jwt
               }
           }) // Hub URL for connection 
            .withAutomaticReconnect()
            .build();

        connection.start()
            .then(result => {
                console.log('Connected!');

                connection.on('CountDealOnPhase', dealPhaseList => { // DealPhasesList this is object that have data in it 
                    // CountDealOnPhase this method is using to connect client to the Hub.
                    // console.log(dealPhaseList);
                    dispatch(mergePhaseCounters(jwt, dealPhaseList));
                    // receiveMessageHandler();
                    setMessageCounter(prevState => prevState + 1);
                });
            })
            .catch(e => console.log('Connection failed: ', e));
    }, []);

    const sendMessage = async (user, message) => { // this is just a demo you need to pass the actual values coming from DealPhasesList object like Phase and TotalRecords
        const chatMessage = {
            user: user, // this is just a demo you need to pass the actual values coming from DealPhasesList object like Phase and TotalRecords
            message: message
        };

        try {
            await fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/SendMessage/CountDeals', { 
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                }
            });
        }
        catch(e) {
            console.log('Sending message failed.', e);
        }
    }

    return (
        <>

        {/* <button className="btn btn-primary" onClick={sendMessage}>Send {btnDisabled && "DISABLED"}</button> */}
            <div className={`fixed duration-1000 transition-all right-5 w-[360px] flex flex-col gap-y-3 mt-5 bg-white py-5 px-8 rounded-xl  shadow-xl ${transformClasses}`}>
                <div className="flex flex-row justify-between">
                    <div className="font-semibold">Nieuwe aanvraag ingediend</div>
                    <span className="fa fa-xmark cursor-pointer px-5 relative -right-5 text-end" onClick={hideMessageHandler}></span>
                </div>
                <p className="text-sm">Goed nieuws! Er is weer een nieuwe aanvraag ingediend!</p>
                <div className="flex flex-row"><NavLink to="/crm/inbox" className="btn-primary">Bekijk aanvraag</NavLink></div>
            </div>
        </>
    );
};

export default Chat;