import React from "react";
import useInputTransition from "hooks/use-input-transition";

const TextArea = React.forwardRef((props,ref) => {   
    const {focusHandler, blurHandler, transitionClasses, isFocussed, keyUpHandler} = useInputTransition(props.value, props.onFocus, props.onBlur, props.onKeyUp);
 
    return (
        <div className={"flex flex-col relative "+(props.className ? props.className : '')}>
            {props.placeholder && <label className={"pl-3 py-1 absolute transition ease-in "+transitionClasses}>{(props.value || isFocussed) && props.placeholder}</label>}
            <textarea rows={props.rows ? props.rows : 5} style={props.backgroundColor ? {backgroundColor:props.backgroundColor} : {}} className={"border border-slate-300 rounded-xl "+((isFocussed || props.value) ? 'px-3 pb-1 pt-5 ': 'p-3 ')+(props.inputClasses ? props.inputClasses : '')} type={props.type ? props.type : 'text'} name={props.name} placeholder={(!isFocussed) ? props.placeholder : ''} ref={ref} onChange={props.onChange} onKeyUp={keyUpHandler} onBlur={blurHandler} spellCheck={props.spellCheck ? props.spellCheck : 'false'} inputMode={props.inputMode && props.inputMode} onFocus={focusHandler} readOnly={props.readOnly ? props.readOnly : false} autoComplete="off" value={props.value ? props.value : this}></textarea>
            {props.hasError && <p className="top-full absolute text-sm p-1 text-red-500">{props.errorMessage}</p>}
            {props.infoText && <p>{props.infoText}</p>}
        </div>
    )
});

export default TextArea;