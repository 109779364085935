import { useState } from "react";

const useSearch = (dataToSearch) => {
  const [isResetted, setIsResetted] = useState('init');
  const [isTouched, setIsTouched] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const searchHandler = (keyword) => {
    setIsResetted(false);
    if(!keyword) return;
    keyword = keyword.toLowerCase();
    var data_with_keyword;
    var search_result;
    var stringified_data = dataToSearch.map((data) => JSON.stringify(data));
    if(stringified_data) {
      data_with_keyword = stringified_data.filter(data =>
          data.toLowerCase().includes(keyword)
      );
    }
    search_result = data_with_keyword.map(item => JSON.parse(item));
    setSearchResult(search_result);
    setIsTouched(true);
  };

  const resetHandler = () => {
    setIsTouched(false);
    setIsResetted(true);
  };


  return {
    searchHandler,
    resetHandler,
    isResetted,
    isTouched,
    searchResult
  };
};

export default useSearch;
