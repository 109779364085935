import { consoleLog } from "./helpers";
export const sortKvKDataByCompany = (data) => {
    // consoleLog(data, true);
    // var filteredData = data.filter(item => item.plaats).filter(item => !item.subtype).filter(item => !/rechtspersoon/.test(item.id));
    // consoleLog(filteredData, true);
    // var sortedData = filteredData.sort((a, b) => (a.handelsnaam > b.handelsnaam) ? 1 : -1);
    var sortedData = data.sort((a, b) => (a.handelsnaam > b.handelsnaam) ? 1 : -1);
    consoleLog(sortedData, true);
    return sortedData;
}

export const dateToDutch = (date) => {
    if(!date) return "";
    var datum = new Date(date).toLocaleDateString('nl-NL');
    return datum;
}