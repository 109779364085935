import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Content from "components/Content/Content";
import ProgressBar from "components/ProgressBar/ProgressBar";
import Container from "components/hoc/Container";
import Button from "components/ui/Button/Button";
import { progressActions  } from "store/progress-slice";
import { dossierActions } from "store/dossier-slice";
import { resetDossier } from "store/dossier-actions";

const BtwFinancieringBedankt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetDossier());
  },[]);
  
  const resetHandler = () => {
    dispatch(progressActions.reset());
    dispatch(dossierActions.reset());
    navigate("/");
  }

 
  return (
    <>
      <Container type="fullscreen">
        <ProgressBar />
      </Container>
      <Container>
        <Content
          title="Bedankt voor uw aanvraag!"
          description={["Wij hebben uw aanvraag in goede orde ontvangen.", <><br /><br /></>, "Voor eventuele vragen kunt u contact met ons opnemen"]}
          contentAlign="left"
        />
        <div className="flex flex-row gap-5">
          <Button onClick={resetHandler} className="btn-secondary mb-5">Home</Button>
        </div>
      </Container>
    </>
  );
};

export default BtwFinancieringBedankt;
