import Container from "components/hoc/Container";
import { NavLink } from "react-router-dom";
import CustomerForm from "components/Forms/CustomerForm/CustomerForm";
import Button from "components/ui/Button/Button";
const CustomerDetail = () => {
    // const {firstName } = useCustomer();
    return (
        <Container>
            {/* <NavLink to=".."><Button type="button" className="btn-secondary">Terug naar klantoverzicht</Button></NavLink> */}
            <CustomerForm detail={true} />
        </Container>
    )
}

export default CustomerDetail;