import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Input from "components/ui/Input/Input";
import useAuth from "hooks/use-auth";
import Card from "components/ui/Card/Card";
import Button from "components/ui/Button/Button";
import Accordion from "components/ui/Accordion/Accordion";
import { uiActions } from "store/ui-slice";

const PartnerForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [partnerData, setPartnerData] = useState({
    "name": "",
    "address": "",
    "place": "",
    "postalCode": 0,
    "website": "",
    "kvkNumber": 0,
    "ibanNumber": "",
    "phoneNumber1": "",
    "phoneNumber2": "",
    "phoneNumber3": "",
    "eMailAddress1": "",
    "eMailAddress2": "",
    "eMailAddress3": ""
});

const valueChangeHandler = (evt) => {
  var fieldName = evt.target.name;
  var fieldValue = evt.target.value;

  // if(fieldName==="postalCode") fieldValue=fieldValue.replace(/\s/,"").toUpperCase(); 

  setPartnerData(prevState => {
    return {
      ...prevState,
      [fieldName]: fieldValue
    }
  });
}
  
  const auth = useAuth();
  const { token: jwt, role } = auth; 

    useEffect(() => {
      dispatch(uiActions.showBackdrop());
      fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/partner/'+params.partnerID, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwt
        }})
        .then(resp => resp.json())
        .then(data => {
          setPartnerData(data);
          dispatch(uiActions.hideBackdrop());
        }).catch(() => {
          dispatch(uiActions.hideBackdrop());
        })
    },[]);

    const removePartnerHandler = () => {
      dispatch(uiActions.showBackdrop());
      fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Partner/'+partnerData.partnerID, {
        method: "DELETE",
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
        }
      })
      .then(resp => resp.json())
      .then(data => {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({message: "Partner succesvol verwijderd", type: "success"}));
        navigate("../");
      })
      .catch(err => {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({message: "Partner kan niet verwijderd worden", type: "danger"}));
      })
    }

    const archiveHandler = (partnerData, reset) => {
      const data = {
        ...partnerData,
        isArchive: (reset ? false : true)
      };

      dispatch(uiActions.showBackdrop());
      fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Partner/'+partnerData.partnerID, {
        method: "PUT",
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
        },
        body: JSON.stringify(data)
      })
      .then(resp => resp.json())
      .then(data => {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({message: "Partner succesvol gearchiveerd", type: "success"}));
      })
      .catch(() => {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({message: "Partner kan niet worden gearchiveerd", type: "danger"}));
      })
    }

    const submitFormHandler = (evt) => {
      evt.preventDefault();
      dispatch(uiActions.showBackdrop());

      partnerData.postalCode = partnerData.postalCode.replace(/\s/g, "").toUpperCase();

      fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Partner/'+partnerData.partnerID, {
        method: "PUT",
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
        },
        body: JSON.stringify(partnerData)
      })
      .then(resp => resp.json())
      .then(data => {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({message: "Partner succesvol gewijzigd", type: "success"}));
      })
      .catch(() => {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({message: "Partner kan niet worden gewijzigd", type: "danger"}));
      })
    };

    if(!partnerData.name) return <>Laden...</>;
    
    return (
        <form className="mt-5" onSubmit={submitFormHandler}>
          <Card title={'Partner aanmaken'}>
          <div className="bg-white grid grid-cols-3 items-start group">
            <div className="grid col-span-2 gap-3 p-5">
              <Accordion open={true} summary="Contactgegevens partner">
                <div className="grid grid-cols-2 gap-3 py-5">
                  <Input value={partnerData.name} onChange={valueChangeHandler} placeholder="Naam" name="name" />
                  <Input value={partnerData.address} onChange={valueChangeHandler} placeholder="Adres" name="address" />
                  <Input value={partnerData.place} onChange={valueChangeHandler} placeholder="Plaats" name="place" />
                  <Input value={partnerData.postalCode} onChange={valueChangeHandler} placeholder="Postcode" name="postalCode" />
                  <Input value={partnerData.website} onChange={valueChangeHandler} placeholder="Website" name="website" />
                  <Input value={partnerData.kvkNumber} onChange={valueChangeHandler} placeholder="KvK nummer" name="kvkNumber" />
                  <Input value={partnerData.ibanNumber} onChange={valueChangeHandler} placeholder="IBAN" name="ibanNumber" />
                  <Input value={partnerData.phoneNumber1} onChange={valueChangeHandler} placeholder="Telefoon 1" name="phoneNumber1" />
                  <Input value={partnerData.phoneNumber2} onChange={valueChangeHandler} placeholder="Telefoon 2" name="phoneNumber2" />
                  <Input value={partnerData.phoneNumber3} onChange={valueChangeHandler} placeholder="Telefoon 3" name="phoneNumber3" />
                  <Input value={partnerData.eMailAddress1} onChange={valueChangeHandler} placeholder="E-mailadres 1" name="eMailAddress1" />
                  <Input value={partnerData.eMailAddress2} onChange={valueChangeHandler} placeholder="E-mailadres 2" name="eMailAddress2" />
                  <Input value={partnerData.eMailAddress3} onChange={valueChangeHandler} placeholder="E-mailadres 3" name="eMailAddress3" />
              </div>
            </Accordion>
            <Accordion summary="Account verwijderen">
              <div className="font-bold underline text-[#ff0000] hover:text-black cursor-pointer mt-5 w-fit" onClick={(id) => {removePartnerHandler(partnerData.partnerID)}}>Ja, ik wil de partner definitief verwijderen</div>
            </Accordion>
        </div>
        <div className="flex flex-col gap-5 p-5 bg-slate-50 h-full justify-between">
              <div> 
      
              <div className="flex flex-row gap-5">
                <Button className="bg-gradient">Opslaan</Button>
                {role.includes("Admin") &&
                  <>
                  {!partnerData.isArchive && <Button type="button" className="btn-secondary" onClick={(obj) => archiveHandler(partnerData)}>Archiveren</Button>}
                  {partnerData.isArchive && <Button type="button" className="btn-secondary" onClick={(obj) => archiveHandler(partnerData, 'reset')}>Herstellen uit archief</Button>}
                  </>
                }
              </div>
              </div>
            </div>
        </div>

        </Card>

      </form>
    )
}

export default PartnerForm;
