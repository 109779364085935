import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Card from "components/ui/Card/Card";
import Input from "components/ui/Input/Input";
import InputKvk from "components/ui/InputKvk/InputKvk";
import Select from "components/ui/Select/Select";
import Button from "components/ui/Button/Button";
import useAuth from "hooks/use-auth";
import { newDealer } from "store/dealer-actions";
import DropArea from "components/ui/DropArea/DropArea";
import InputColor from "components/ui/InputColor/InputColor";
import { uiActions } from "store/ui-slice";

const NewDealerForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dealerStatus, setDealerStatus] = useState("");
    const [formIsValid, setFormIsValid] = useState(true);
    const { token } = useAuth();
    const bedrijfsnaamRef = useRef();
    const dealerCategoryRef = useRef();
    const postalCodeRef = useRef();
    const addressRef = useRef();
    const placeRef = useRef();
    const ibanRef = useRef();
    const vaTnumberRef = useRef();
    const importIDRef = useRef();
    const logoImageUrlFormRef = useRef();
    const websiteRef = useRef();
    const telephoneRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();
    const primaryColorRef = useRef();
    const buttonTextColorRef = useRef();

    const {postcode: kvk_postcode, huisnummer: kvk_huisnummer, huisnummertoevoeging:kvk_huisnummertoevoeging, dossiernummer, bedrijfsnaam, handelsnaam, straat:kvk_straat, plaats: kvk_plaats} = useSelector(state => state.dossier.kvkData);
    const [companyData, setCompanyData] = useState({
        postalCode: ""+kvk_postcode,
        huisnummer: ""+kvk_huisnummer,
        huisnummertoevoeging: ""+kvk_huisnummertoevoeging,
        dossiernummer: dossiernummer,
        bedrijfsnaam: ""+bedrijfsnaam,
        handelsnaam: handelsnaam,
        straat: ""+kvk_straat,
        address: ''+(kvk_straat ? kvk_straat : '')+(kvk_huisnummer ? ' '+kvk_huisnummer : '')+(kvk_huisnummertoevoeging ? ''+kvk_huisnummertoevoeging : ''),
        place: ""+kvk_plaats,
        primaryColor: "",
        status: ""
    });

    useEffect(() => {
        postalCodeRef.current.value = "";
        placeRef.current.value = "";
        addressRef.current.value = "";

        setCompanyData({
            postalCode: ""+kvk_postcode,
            huisnummer: ""+kvk_huisnummer,
            huisnummertoevoeging: ""+kvk_huisnummertoevoeging,
            dossiernummer: dossiernummer,
            bedrijfsnaam: ""+bedrijfsnaam,
            handelsnaam: handelsnaam,
            straat: ""+kvk_straat,
            place: ""+kvk_plaats,
            address: ''+(kvk_straat ? kvk_straat : '')+(kvk_huisnummer ? ' '+kvk_huisnummer : '')+(kvk_huisnummertoevoeging ? ''+kvk_huisnummertoevoeging : '')
        });
    },[dossiernummer]);

  const submitHandler = async (evt) => {
      evt.preventDefault();
      var website = websiteRef.current?.value;
      if(!/http/.test(website)) {
        website="https://"+website;
      }
      var formData = new FormData();

      var errorMessage = "";

      var logo = logoImageUrlFormRef.current?.files[0];
      var email = emailRef.current?.value;
      var importID = importIDRef.current?.value || "";
      var telephone = telephoneRef.current?.value;
      var vaTnumber = vaTnumberRef.current?.value || 0;

      // if(!logo) errorMessage = "Logo ontbreekt! Dealer kan niet aangemaakt worden.";
      if(!email) errorMessage = "Ongeldig e-mailadres. Dealer kan niet aangemaakt worden.";
      if(!importID) errorMessage = "Vul een importID in om de auto voorraad van het bedrijf te kunnen tonen."
      if(!telephone) errorMessage = "Telefoonummer ontbreekt. Dealer kan niet aangemaakt worden."
      // if(!vaTnumber) errorMessage = "BTW nummer ontbreekt. Dealer kan niet aangemaakt worden."

      if(!email||!importID||!telephone) {
        dispatch(uiActions.setAlert({ message: errorMessage, type: "danger"}));
        return;
      }
 
      var postalCode = companyData.postalCode.replace(/\s/g, "").toUpperCase();

      formData.append("status", dealerStatus);
      formData.append("chamberNumber", companyData.dossiernummer ? ""+companyData.dossiernummer : "");
      formData.append('logoImageUrlForm', logo);
      formData.append("name", companyData.bedrijfsnaam);
      formData.append("address", companyData.address);
      formData.append("importID", importID);
      formData.append("postalCode", postalCode);
      formData.append("telephone", telephone);
      formData.append("website", website);
      formData.append("vaTnumber", vaTnumber);
      formData.append("email", email);
      formData.append("iban", ibanRef.current?.value);
      formData.append("phone", phoneRef.current?.value);
      formData.append("place", companyData.place);
      formData.append("buttonTextColor", buttonTextColorRef.current?.value ? buttonTextColorRef.current?.value : "#fff");
      formData.append("primaryColor", primaryColorRef.current?.value ? primaryColorRef.current?.value : "#F8700D");
      formData.append("dealerCategory", "B");
      formData.append("rating", 0);
 
      const response = await dispatch(newDealer(formData, token));
      if(response==="SUCCESS") {
        navigate("../");
        return;
      }
      if(response.message==="User already exists with this email or user name!") {
        errorMessage = "Er bestaat al een gebruiker met dit e-mailadres!";
        dispatch(uiActions.setAlert({ message: errorMessage, type: "danger"}));
        return;
      }
  };

  const valueChangeHandler = (evt) => {
    const inputName = evt.target.name;
    const inputValue = evt.target.value;
    setCompanyData(prevState => {
        return {
            ...prevState,
            [inputName]: inputValue
        }
    });
  }

  const { status } = companyData;
  
  return (
    <form onSubmit={submitHandler}>
      <Card title="Nieuw autobedrijf">
        <div className="bg-white grid grid-cols-3 items-start group">
          <div className="grid grid-cols-2 col-span-3 lg:col-span-2 gap-3 p-5">
          <h3 className="col-span-2">Bedrijfsgegevens</h3>
                <InputKvk placeholder="Bedrijfsnaam*" ref={bedrijfsnaamRef} className="col-start-1 col-span-2" value={companyData.bedrijfsnaam} onChange={valueChangeHandler} />
                <Select ref={dealerCategoryRef} set="dealerCategory" errorMessage="Classificatie is een verplicht veld" label="Classificatie" name="dealerCategory" />
                <Input ref={postalCodeRef} placeholder="Postcode" name="postalCode" value={companyData.postalCode} onChange={valueChangeHandler} />
                <Input ref={addressRef} placeholder="Adres*" name="address" value={companyData.address} onChange={valueChangeHandler} />     
                <Input ref={placeRef} placeholder="Plaats*" name="place" value={companyData.place} onChange={valueChangeHandler} />
                <Input ref={ibanRef} errorMessage="IBAN dient uit 18 karakters te bestaan" name="iban" placeholder="IBAN"  />
                <Input ref={vaTnumberRef} placeholder="BTW nummer" name="vaTnumber" />
                <Input ref={importIDRef} placeholder="Import ID*" label="Import ID" name="importID" />
                <Input ref={websiteRef} placeholder="Website" name="website" />
                <h3 className="col-span-2">Hoofcontactpersoon</h3>
                <Input ref={telephoneRef} placeholder="Telefoonnummer*" name="telephone" />
                <Input ref={phoneRef} placeholder="Mobiel nummer" name="phone" />
                <Input ref={emailRef} placeholder="E-mail*" name="email" />
                <h3 className="col-span-2">Vormgeving</h3>
                <div className="col-span-2">
                  <DropArea ref={logoImageUrlFormRef} uploadText="Upload logo" name="logoImageUrlForm" />
                </div>
                 <InputColor ref={primaryColorRef} placeholder="Primaire kleur" name="primaryColor" />
                 <InputColor ref={buttonTextColorRef} placeholder="Button tekstkleur" name="buttonTextColor" />
          </div>
          <div className="flex flex-col col-span-3 lg:col-span-1 gap-5 p-5 bg-slate-50 h-full justify-between">
              <div>
                <div className="flex flex-col gap-y-5 mb-5">
                  <div><Select set="statusDealer" onChange={valueChangeHandler} name="status" value={status} label="Dealer status" /></div>
                </div>
                <Button disabled={!formIsValid} className="bg-gradient">Registreren</Button>
              </div> 
            </div>
        </div>
      </Card>
    </form>
  );
};

export default NewDealerForm;
