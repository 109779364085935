import { createSlice } from "@reduxjs/toolkit";
import { calcRestBpm, calcAgeInMonths, calcMaxDuration } from "lib/calc";
import { checkGrijsKenteken } from "lib/voertuig";
import { consoleLog, setStorageItem, getStorageItem } from "lib/helpers";

// datum_deel_1 voor leeftijd voertuig
// datum_deel_1b voor berekening bpm

const startVoertuigState = {
    kenteken: "",
    merk: "",
    model: "",
    kmstand: "",
    cilinder_inhoud: "",
    brandstof: "",
    carrosserie_soort: "",
    tellerstandoordeel: "",
    image_url: "",
    eerste_registratie: "",
    datum_eerste_toelating: "",
    datum_eerste_toelating_dt: "",
    datum_deel_1: "",
    voertuigleeftijd: "",
    leeftijd_in_maanden: "",
    voertuigsoort: "",
    btw_marge: "",
    catalogusprijs: "",
    grijskenteken: "",
    bruto_bpm: "",
    rest_bpm: ""
}

const initialVoertuigState = getStorageItem("voertuigstate") || startVoertuigState;

const initialAanvullendeGegevens = {
    aanhef: "",
    voornaam: "",
    achternaam: "",
    geboortedatum: "",
    rijbewijsnummer: "",
    iban: "",
    email: "",
    telefoon: ""
}

const initialKvkData = {
    postcode: "",
    huisnummer: "",
    huisnummertoevoeging: "",
    bedrijf_leeftijd: "",
    datum_oprichting: "",
    dossiernummer: "", 
    bedrijfsnaam: "",
    handelsnaam: "",
    straat: "",
    plaats: ""
}

// getStorageItem("berekeningstate") || 
const initialState = {
    // cars: [],
    dataIsFetched: false,
    recalculation: false,
    keep_contactinfo: false,
    recalculate_for_dealid: "",
    voertuig: initialVoertuigState,
    inlossing_details: {
        active: false,
        saldo: "",
        kenteken: "",
        contractnummer: "",
        bank: ""
    },
    berekening: { 
        leasebedrag: "",
        calcsrc: "df",
        current_field: "",
        aanschafwaarde: "",
        aanschafwaarde_excl: "",
        inlossing: "",
        inruil: "",
        aanbetaling: "",
        btw_bedrag: "",
        slottermijn: "",
        slottermijn_is_touched: false,
        looptijd: 60,
        max_looptijd: 72,
        // rente: 7.99,
        klantrente: "",
        provisies: [],
        provisiebedrag: "",
        provisiebedrag_rest: "",
        provisietotaal: "",
        provisiepercentage: "",
        leasetermijn: "",
        bruto_bpm: "",
        rest_bpm: "",
        totale_aanbetaling: "",
        bemiddelingskosten: 249,
        rentes: []
    },
    aanvullendegegevens: getStorageItem("aanvullendegegevensstate") || initialAanvullendeGegevens,
    kvkListData: [],
    kvkData: getStorageItem("kvkDatastate") || initialKvkData
};

export const dossierSlice = createSlice({
    name: 'dossier',
    initialState: initialState,
    reducers: {
        reset: () => initialState,
        resetVoertuig: (state) => {
            state.voertuig = startVoertuigState;
        },
        setCarsByDealer(state, actions) {
            state.cars = actions.payload;
        },
        clearAdditionalFieldData(state, actions) {
            state.aanvullendegegevens = initialAanvullendeGegevens;
        },
        clearKvKData(state,actions) {
            state.kvkData = initialKvkData;
        },
        setAdditionalFieldData(state, actions) {
            state.aanvullendegegevens = {
                ...state.aanvullendegegevens,
                ...actions.payload
            };
            setStorageItem("aanvullendegegevensstate", state.aanvullendegegevens);
        },
        setVoertuigDataManually(state, actions) {
            state.voertuig = {
                ...state.voertuig,
                ...actions.payload
            };
            if("datum_eerste_toelating" in actions.payload) {               
                var datum_eerste_toelating = actions.payload.datum_eerste_toelating;
                var voertuigleeftijd = calcAgeInMonths(datum_eerste_toelating);
                var max_looptijd = calcMaxDuration(voertuigleeftijd);
                state.berekening.max_looptijd = max_looptijd; 
                setStorageItem("berekeningstate", state.berekening);
            }
            setStorageItem("voertuigstate", state.voertuig);
        },
        saveCalculation(state, actions) {
            state.berekening = actions.payload;
            if(actions.payload.administrationCost) {
                state.berekening.bemiddelingskosten = actions.payload.administrationCost;
            }

            if(!actions.payload.calc_popup) {
                // add berekening to storage, when no popup
                setStorageItem("berekeningstate", state.berekening);
            }

        },
        saveInlossingDetails(state, actions) {
            state.inlossing_details = actions.payload;
            if(!actions.payload.calc_popup) {
                // add berekening to storage, when no popup
                setStorageItem("inlossingstate", state.inlossing_details);
            }
        },
        clearCalculation(state) {
            state.berekening = {}
        },
        attachBtwFinanciering(state) {
            state.keep_contactinfo = true;
        },
        reCalculate(state, actions) {
            state.recalculation = true;
            state.recalculate_for_dealid = actions.payload.dealID;
            state.dataIsFetched = false;
        },
        setKvkListData(state, actions) {
            state.kvkListData = actions.payload;
        },
        setKvkData(state, actions) {
            consoleLog(actions);
            state.kvkData.dossiernummer = actions.payload.dossiernummer || "";
            state.kvkData.bedrijfsnaam = actions.payload.handelsnaam || "";
            state.kvkData.handelsnaam = actions.payload.handelsnaam || "";
            state.kvkData.huisnummer = actions.payload.huisnummer || "";
            state.kvkData.huisnummertoevoeging = actions.payload.huisnummertoevoeging || "";
            state.kvkData.straat = actions.payload.straat || "";
            state.kvkData.postcode = actions.payload.postcode || "";
            state.kvkData.plaats = actions.payload.plaats || "";
            setStorageItem("kvkDatastate", state.kvkData);           
        },
        setActiveManualCalcField(state, actions) {
            state.berekening.current_field = actions.payload;
            if(actions.payload==="slottermijn") state.berekening.slottermijn_is_touched = true;
        },
        changeCalcSrc(state, actions) {
            state.berekening.provisiepercentage = "";
        },
        setCarDataByInventory(state, actions) {
            // consoleLog('payload: ', actions.payload);
            var occasion_id = actions.payload.occasion_id || 0;
            var bruto_bpm = actions.payload.bruto_bpm;
            var kenteken = actions.payload.kenteken;
            var grijskenteken = checkGrijsKenteken(kenteken);
            var datum_deel_1b = actions.payload.datum_deel_1b; // bruto bpm, nederland
            var datum_deel_1 = actions.payload.datum_deel_1; // eerste registratie
            
            var eerste_registratie = datum_deel_1;
            if(!eerste_registratie) eerste_registratie = "01-01-"+actions.payload.bouwjaar;
 
            var rest_bpm;
            var voertuigleeftijd = calcAgeInMonths(datum_deel_1);
            var max_looptijd = calcMaxDuration(voertuigleeftijd);
            state.berekening.max_looptijd = max_looptijd;

            if(datum_deel_1b) {
                // leeftijd_in_maanden = calcAgeInMonths(datum_deel_1b); // voor bruto bpm
                rest_bpm = calcRestBpm(voertuigleeftijd, bruto_bpm);
            }
            state.voertuig = {
                ...state.voertuig,
                ...actions.payload,
                leeftijd_in_maanden: voertuigleeftijd,
                eerste_registratie: eerste_registratie,
                datum_eerste_toelating: datum_deel_1b,
                datum_deel_1: datum_deel_1,
                voertuigleeftijd: voertuigleeftijd,
                grijskenteken: grijskenteken,
                rest_bpm: rest_bpm,
                occasion_id: occasion_id
            };
            state.berekening = {
                ...state.berekening,
                aanschafwaarde: actions.payload.aanschafwaarde,
                btw_bedrag: actions.payload.btw_bedrag,
                aanschafwaarde_excl: actions.payload.aanschafwaarde_excl
            }
            setStorageItem("voertuigstate", state.voertuig);
            setStorageItem("berekeningstate", state.berekening);

            state.dataIsFetched = true;
        },
        setCarDataByRDW(state, actions) {
            consoleLog("XX setCarDataByRDW", actions.payload);
            var kenteken = actions.payload.kenteken || "";
            var grijskenteken = checkGrijsKenteken(kenteken);
            var bruto_bpm = actions.payload.bruto_bpm;
            var leeftijd_in_maanden = calcAgeInMonths(actions.payload.datum_eerste_toelating_dt);
            var rest_bpm = calcRestBpm(leeftijd_in_maanden, bruto_bpm);

            state.voertuig.brandstof = actions.payload.brandstof;
            state.voertuig.leeftijd_in_maanden = leeftijd_in_maanden;
            state.voertuig.bruto_bpm = bruto_bpm;
            state.voertuig.rest_bpm = rest_bpm;
            state.voertuig.catalogusprijs = actions.payload.catalogusprijs;
            state.voertuig.kenteken = kenteken;
            state.voertuig.merk = actions.payload.merk;
            state.voertuig.model = actions.payload.handelsbenaming;
            if(actions.payload.datum_eerste_toelating_dt) {
                var datum_eerste_toelating = "" + new Date(actions.payload.datum_eerste_toelating_dt).toLocaleDateString();
                state.voertuig.eerste_registratie = datum_eerste_toelating;
                state.voertuig.datum_eerste_toelating = datum_eerste_toelating;

                /* added 30-1 */
                var voertuigleeftijd = calcAgeInMonths(datum_eerste_toelating);
                var max_looptijd = calcMaxDuration(voertuigleeftijd);
                state.berekening.max_looptijd = max_looptijd; 
                /*/ */

                state.voertuig.datum_eerste_toelating_dt = "" + new Date(actions.payload.datum_eerste_toelating_dt);
            
            }
            state.voertuig.voertuigsoort = actions.payload.voertuigsoort;
            state.voertuig.grijskenteken = grijskenteken;
            state.voertuig.tellerstandoordeel = actions.payload.tellerstandoordeel;
            state.voertuig.carrosserie_soort = actions.payload.inrichting;



            state.voertuig.cilinder_inhoud = actions.payload.cilinderinhoud;
            setStorageItem("voertuigstate", state.voertuig);

            state.dataIsFetched = true;
        },
        clearVoertuig(state) {
            state.dataIsFetched = false;
            state.voertuig = initialVoertuigState;
        },
        clearInlossingDetails(state) {
            state.inlossing_details = {
                active: false,
                saldo: "",
                kenteken: "",
                contractnummer: "",
                bank: ""
            };
        },
        setBtwMarge(state, actions) {
            state.voertuig.btw_marge = actions.payload;
            setStorageItem("voertuigstate", state.voertuig);
        },
    }
});

export const dossierActions = dossierSlice.actions;