import { sortKvKDataByCompany } from "lib/kvk";
import { uiActions } from "./ui-slice";
import { dossierActions } from "./dossier-slice";
import { sendRequest } from "lib/calc";
import { consoleLog, removeStorageItem } from "lib/helpers";
import { progressActions } from "./progress-slice";

export const getCarByLicensePlate = (kenteken) => {
    return async dispatch => {
        try {
            const voertuigData = fetch("https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken="+kenteken).then((res) => res.json()).catch(err => {});
            const brandstofData = fetch("https://opendata.rdw.nl/resource/8ys7-d773.json?kenteken="+kenteken).then((res) => res.json()).catch(err => {});            
            const allData = Promise.all([voertuigData, brandstofData]);
            allData.then(([voertuig, brandstof]) => {
                const extendedVoertuigData = {
                    ...voertuig[0],
                    ...brandstof[0],
                    brandstof: (brandstof[0]?.brandstof_omschrijving ? brandstof[0]?.brandstof_omschrijving : '')
                }
                if (!allData) {
                    dispatch(uiActions.setNotification({
                        title: 'Ongeldig kenteken',
                        message: 'Het kenteken dat je hebt opgegeven is onbekend.',
                        visible: true
                    }));
                    return;
                }
                dispatch(dossierActions.setCarDataByRDW(extendedVoertuigData));
            });
      } catch(err) {
        dispatch(uiActions.setNotification({
            title: 'Ongeldig kenteken',
            message: 'Het kenteken dat je hebt opgegeven is onbekend.',
            visible: true
        }));
      };
    }
}

export const resetDossier = () => {
    return async (dispatch) => {
        try {
            await removeStorageItem(['berekeningstate', 'kvkDatastate', 'aanvullendegegevensstate', 'voertuigstate', 'inlossingstate']);   
            dispatch(progressActions.reset());
            dispatch(dossierActions.clearAdditionalFieldData());
            dispatch(dossierActions.clearKvKData());

        } catch (err) {
            consoleLog(err.message, true);
        }

    }
}