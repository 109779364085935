import { useState, useEffect, useRef } from "react";
import useAuth from "hooks/use-auth";

import { useNavigate } from "react-router-dom";
import Table, { Thead, Th, Tr, Td } from "components/ui/Table/Table";
import Container from "components/hoc/Container";
import useSearch from "hooks/use-search";
import SearchForm from "components/Forms/SearchForm/SearchForm";
import InputRadio from "components/ui/InputRadio/InputRadio";
import { serialize } from "lib/helpers";
import Button from "components/ui/Button/Button";

const PartnerOverzicht = () => {
  const navigate = useNavigate();
  const [partners, setPartners] = useState([]);
  const { token: jwt } = useAuth();
  const [query, setQuery] = useState('');
  const { resetHandler, searchHandler, isTouched: searchIsTouched, isResetted: searchIsResetted, searchResult } = useSearch(partners);
  const archiveRef = useRef();

  useEffect(() => {
    setPartners(searchResult);
  },[searchResult]);

  useEffect(() => {
    if(searchIsResetted===false) return;
      fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/Partner'+(query ? '?'+query : ''), {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + jwt
          }
      }).then(resp => resp.json()).then(responseData => {
        setPartners(responseData.data);
      });
  }, [query, searchIsResetted]);

  const changeFilterHandler = () => {
    var query = {};
    var isArchive = archiveRef.current.checked;     
    if(isArchive) query['isArchive'] = true;
    var serializedQuery = serialize(query);
    setQuery(serializedQuery);
  }

  return (
    <div className="text-xs lg:text-base max-w-6xl">

  <div className="flex flex-row justify-between items-center mb-5">
  <div className={`${(searchIsTouched) ? "invisible" : ""}`}>
              <SearchForm onReset={resetHandler} onSearch={searchHandler} isTouched={searchIsTouched} />  
          </div>
        
          <InputRadio className="hidden lg:block" ref={archiveRef} name="isArchive" type="checkbox" options={[{label: "Archief"}]} onChange={changeFilterHandler} />
          <Button className={`btn-secondary ${(query || searchIsTouched) ? "" : "invisible"}`} onClick={resetHandler}>Reset</Button>
  </div>
    
      {partners.length ? (
        <Table>
          <Thead>
            <Tr thead="true">
              <Th>ID</Th>
              <Th>Naam</Th>
              <Th>Telefoonnummer</Th>
              <Th>E-mail</Th>
              <Th>Adres</Th>
            </Tr>
          </Thead>
          <tbody>
            {partners.map(partner => {
              return (
                <Tr key={'k'+partner.partnerID} className={(partner.isArchive ? 'opacity-25 ' : '')+" cursor-pointer"} onClick={() => navigate(""+partner.partnerID)}>
                  <Td>{partner.partnerID}</Td>
                  <Td>{partner.name}</Td>
                  <Td>{partner.phoneNumber1}</Td>
                  <Td>{partner.eMailAddress1}</Td>
                  <Td>{partner.address} {partner.postalCode} {partner.place}</Td>
                </Tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <p>Er zijn nog geen partners</p>
      )}
    </div>
  );
};

export default PartnerOverzicht;
