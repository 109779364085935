import PrevNext from "components/Navigation/PrevNext";
import BtwMargeForm from "components/Forms/BtwMargeForm/BtwMargeForm";
import Container from "components/hoc/Container";
import ProgressBar from "components/ProgressBar/ProgressBar";
import useHead from "hooks/use-head";

const BtwMarge = () => {
  useHead({title: "Btw / Marge"});
  
  return (
    <>
      <Container type="fullscreen">
        <ProgressBar />
      </Container>
      <Container>
        <BtwMargeForm />
        <PrevNext disableNext={true} />
      </Container>
    </>
  );
};

export default BtwMarge;
