import { useSelector } from "react-redux";

const useTutorial = () => {
    const tutorial = useSelector(state => state.tutorial);
    const { showTutorial, tutorialStep, showBgLayer, tutorialStepName } = tutorial;

    return {
        showTutorial,
        tutorialStep,
        showBgLayer,
        tutorialStepName
    };
}

export default useTutorial;
