import { useNavigate } from "react-router-dom";
const Page404 = () => {
    const navigate = useNavigate();
    setTimeout(function() {
        navigate("/");
    }, 500);
    return (
        <div>Pagina niet gevonden. Je wordt doorgestuurd naar de homepage...</div>
    )
}

export default Page404;