import React, { useEffect, useState } from "react";

const Content = ({title, description, contentAlign}) => {
  const [transitionClasses, setTransitionClasses] = useState("scale-90 opacity-0");
  useEffect(() => {
    setTransitionClasses("");
},[]);

var alignContent = "text-center";
if(contentAlign==="left") alignContent = "text-left"; 

  return (
    <>
      <div className={"py-5 xleading-1 text-xs md:text-base md:py-14 max-w-xl mx-auto transition xdelay-100 ease-in "+transitionClasses}>
        <div className={alignContent+" p-5"}>
          <h1 className={`text-sld-blue h3 ${!description && 'mb-0'}`}>{title}</h1>
          {(description) && (typeof description==="object") && <div className="">{description.map((item, index) => {
            return <React.Fragment key={"x"+index}>{item}</React.Fragment>;
          })}</div>}
          {(description) && (typeof description==="string") && <div className="">{description}</div>}
        </div>
      </div>
    </>
  );
};

export default Content;
