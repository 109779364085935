import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PrevNext from "components/Navigation/PrevNext";
import Input from "components/ui/Input/Input";
import InputKvk from "components/ui/InputKvk/InputKvk";
import InputDatum2 from "components/ui/InputDatum/InputDatum2";
import useInput from "hooks/use-input";
import { saveDeal } from "store/deal-actions";
import ProgressBar from "components/ProgressBar/ProgressBar";
import Container from "components/hoc/Container";
import Select from "components/ui/Select/Select";
import useBerekening from "hooks/use-berekening";
import useVoertuig from "hooks/use-voertuig";
import useAuth from "hooks/use-auth";
import { noDotAndInteger } from "lib/helpers";
import Button from "components/ui/Button/Button";
import { dateToISO, dateWithoutToWithToZero } from "lib/calc";
import useTutorial from "hooks/use-tutorial";
import Uitleg from "components/Tutorial/Uitleg";
import { escapeSpecialChars } from "lib/voertuig";

const StapContact = () => {
    const { showTutorial } = useTutorial();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    var voertuig = useVoertuig();
    const { token, userInfo } = useAuth();
    const berekening = useBerekening();
    const customer = useSelector(state => state.dossier.aanvullendegegevens);
    const {recalculate_for_dealid} = useSelector(state => state.dossier);
    const customer_company = useSelector(state => state.dossier.kvkData);
    const {currentCase, nextPath }  = useSelector(state => state.progress);
    
    const { bedrijfsnaam } = customer_company;
    const enteredBedrijfsnaamIsValid = bedrijfsnaam.length > 2;
    const bedrijfsnaamInputHasError = !enteredBedrijfsnaamIsValid;

    const [formIsValid, setFormIsValid] = useState(false);
    const { valueChangeHandler, inputBlurHandler } = useInput();
    const { ref: rijbewijsRef, value: enteredRijbewijs, isValid: enteredRijbewijsIsValid, hasError: rijbewijsInputHasError, valueChangeHandler: rijbewijsChangeHandler, inputBlurHandler: rijbewijsBlurHandler } = useInput('rijbewijsnummer', value => /\d{10}/.test(value) && value.length === 10);
    const { ref: ibanRef, value: enteredIban, isValid: enteredIbanIsValid, hasError: ibanInputHasError, valueChangeHandler: ibanChangeHandler, inputBlurHandler: ibanBlurHandler, } = useInput('iban', value => /\w+/g.test(value) && value.replace(/\s/g, "") && value.length === 18);
    const { ref: voornaamRef, value: enteredVoornaam, isValid: enteredVoornaamIsValid, hasError: voornaamInputHasError } = useInput('voornaam', value => /[^\d]+/.test(value) && value.length > 1);
    const { ref: achternaamRef, value: enteredAchternaam, isValid: enteredAchternaamIsValid, hasError: achternaamInputHasError } = useInput('achternaam', value => /[^\d]+/.test(value) && value.length > 1);
    const { ref: bedrijfsnaamRef, value: enteredBedrijfsnaam, valueChangeHandler: bedrijfsnaamChangeHandler } = useInput('bedrijfsnaam');
    const { ref: aanhefRef, value: enteredAanhef, isValid: enteredAanhefIsValid } = useInput('aanhef', value => /(Dhr|Mevr)/i.test(value));
    const { ref: emailRef, value: enteredEmail, isValid: enteredEmailIsValid, hasError: emailInputHasError, valueChangeHandler: emailChangeHandler, inputBlurHandler: emailBlurHandler } = useInput('email', value => /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,15})+$/.test(value));
    const { ref: telefoonRef, value: enteredTelefoon, isValid: enteredTelefoonIsValid, hasError: telefoonInputHasError, valueChangeHandler: telefoonChangeHandler, inputBlurHandler: telefoonBlurHandler } = useInput('telefoon', value => value.length>8 && !/[^\d\-\+\(\)]/.test(value));
    const { value: enteredGeboortedatum, isTouched : geboortedatumIsTouched } = useInput('geboortedatum');
    const { dealerID: dealerID_from_dealer } = useSelector(state => state.dealer.dealer);
    var dealerID = (userInfo.dealerID ? userInfo.dealerID : dealerID_from_dealer);

    var customerBirthDay = customer.geboortedatum;
    var enteredGeboortedatumIsValid = customerBirthDay.length > 2;
    var geboortedatumInputHasError = !enteredGeboortedatumIsValid && geboortedatumIsTouched; 

    useEffect(() => {
        (enteredVoornaamIsValid && enteredAchternaamIsValid && enteredBedrijfsnaamIsValid && enteredAanhefIsValid && enteredEmailIsValid && enteredTelefoonIsValid && enteredGeboortedatumIsValid) ? setFormIsValid(true) : setFormIsValid(false);
    }, [enteredVoornaamIsValid, enteredAchternaamIsValid, enteredBedrijfsnaamIsValid, enteredAanhefIsValid, enteredEmailIsValid, enteredTelefoonIsValid, enteredGeboortedatumIsValid]);

    const submitDealHandler = (evt) => {
        evt.preventDefault();
        var address = (customer_company.straat ? customer_company.straat : '') + " " + (customer_company.huisnummer ? customer_company.huisnummer : '') + " " +(customer_company.huisnummertoevoeging ? customer_company.huisnummertoevoeging : '');
        
        var geboortedatum = dateToISO(customer.geboortedatum);
        
        var final_registratiedatum = voertuig.eerste_registratie || voertuig.datum_eerste_toelating;
        var eerste_registratie = dateToISO(final_registratiedatum);
        var carrosserie = voertuig.carrosserie_soort || "";

        var datum_oprichting = "";
        var voertuigsoort = voertuig.voertuigsoort;
        if(voertuigsoort==="Bedrijfswagen") voertuigsoort = "Bedrijfsauto";
        var btw_bedrag = noDotAndInteger(berekening.btw_bedrag);
        var amountpurchaseExclVAT = noDotAndInteger(berekening.aanschafwaarde_excl);
        if(/marge/i.test(voertuig.btw_marge)) {
            btw_bedrag = 0;
            amountpurchaseExclVAT = noDotAndInteger(berekening.aanschafwaarde);
        }

        var repayment = noDotAndInteger(berekening.inlossing);
        if(!repayment) repayment = 0;
        var tradeInAmount = noDotAndInteger(berekening.inruil);
        if(!tradeInAmount) tradeInAmount = 0;
        
        var dealData = {
            "source": "Dealer-aanvraag",
            "dealerID": dealerID,
            "provisiePercentage": +berekening.provisiepercentage,
            "tradeInAmount": tradeInAmount,
            "repayment": repayment,
            "leaseAmount": noDotAndInteger(berekening.leasebedrag),
            "typeOfDeal": ""+voertuig.btw_marge,
            "amountpurchase": noDotAndInteger(berekening.aanschafwaarde),
            "commissionAmountDealer": Number(berekening.provisiebedrag),
            "totalToPay": berekening.totale_aanbetaling,
            "downpayment": berekening.aanbetaling ? noDotAndInteger(berekening.aanbetaling) : 0,
            "monthlyamount": Number(berekening.leasetermijn.replace(".", "").replace(",", ".")),
            "vat": btw_bedrag,
            "description": berekening.description,
            "duration": +berekening.looptijd,
            "administrationCost": berekening.bemiddelingskosten, // (berekening.bemiddelingskosten ? noDotAndInteger(berekening.bemiddelingskosten): 0),
            "commissionAmountSLD": (berekening.provisiebedrag_rest ? Number(berekening.provisiebedrag_rest) : 0),
            "amountpurchaseExclVAT": amountpurchaseExclVAT,
            "damageFreeYearsInsurance": "",
            "damageHistoryInsurance": "",
            "chosenBank": ""+berekening.calcsrc,
            "finalpayment": noDotAndInteger(berekening.slottermijn),
            "interest": +berekening.klantrente,
            "phase": "Uw aanvraag is ontvangen",
            "typeOfInsurance": "",
            "saleType": ""+currentCase,
            "customerDetail": {
                "companyName": escapeSpecialChars(customer_company.bedrijfsnaam),
                "email": customer.email,
                "salutation": customer.aanhef,
                "firstName": customer.voornaam,
                "lastName": customer.achternaam,
                "dateofBirth": geboortedatum, // customer.geboortedatum
                "driverLicenseNumber": customer.rijbewijsnummer,
                "phone": customer.telefoon,
                "iban": customer.iban,
                "addition": "",
                "type": "Indivisul",
                "address": address,
                "chamberNumber": customer_company.dossiernummer,
                "dateofEsteb": datum_oprichting,
                "postalCode": customer_company.postcode.replace(/\s/,"").toUpperCase(),
                "place": customer_company.plaats,
                "age": customer_company.bedrijf_leeftijd
            },
            "vehicleDetail": {
              "occasionNumber": voertuig.occasion_id,
              "make": escapeSpecialChars(voertuig.merk),
              "model": escapeSpecialChars(voertuig.model),
              "marginVAT": 0, // marginVAT
              "mileage": noDotAndInteger(voertuig.kmstand),
              "vehicleType": voertuigsoort,
              "firstRegistrationDate": eerste_registratie,
              "age": noDotAndInteger(voertuig.voertuigleeftijd),
              "engineCapacity": voertuig.cilinder_inhoud,
              "fuelType": voertuig.brandstof,
              "grossBPM": voertuig.bruto_bpm,
              "licensePlate": voertuig.kenteken,
              "listPrice": noDotAndInteger(voertuig.catalogusprijs),
              "positionJudgement": "",
              "restBPM": ""+voertuig.rest_bpm,
              "carrosserie": carrosserie
            }
          }

          // Bij herberekening send dealID
          if(recalculate_for_dealid) {
            dealData.relatedID = recalculate_for_dealid;
          }
          dispatch(saveDeal(dealData, token)).then(resp => {
            if(resp) navigate(nextPath);
          });
    }


    return (
        <>
            <Container type="fullscreen">
                <ProgressBar />
            </Container>
            <Container>
                <form onSubmit={submitDealHandler}>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mb-5">
                        {showTutorial && <Uitleg hideNextButton={true} arrowPosition="left" title="Contactgegevens" description="De bedrijfsgegevens worden via de KvK verkregen door te zoeken op bedrijfsnaam." />}
                        <div className="text-xl font-bold lg:col-span-3">Bedrijfsnaam</div>
                        <InputKvk hasError={bedrijfsnaamInputHasError} errorMessage="Bedrijfsnaam is verplicht" onChange={bedrijfsnaamChangeHandler} className="col-start-1 col-span-2" value={enteredBedrijfsnaam} ref={bedrijfsnaamRef} />
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
                        <div className="text-xl font-bold lg:col-span-3">Persoonsgegevens</div>
                        <Select set="aanhef" label="Aanhef" view="horizontal" value={enteredAanhef} onChange={valueChangeHandler} name="aanhef" ref={aanhefRef} />
                        <Input hasError={voornaamInputHasError} errorMessage="" name="voornaam" placeholder="Voornaam" value={enteredVoornaam} ref={voornaamRef} onChange={valueChangeHandler} onBlur={inputBlurHandler} />
                        <Input hasError={achternaamInputHasError} errorMessage="" name="achternaam" placeholder="Achternaam" value={enteredAchternaam} ref={achternaamRef} onChange={valueChangeHandler} onBlur={inputBlurHandler} />
                        <Input hasError={emailInputHasError} errorMessage="Ongeldig e-mailadres" name="email" placeholder="E-mailadres" value={enteredEmail} ref={emailRef} onChange={emailChangeHandler} onBlur={emailBlurHandler} />
                        <Input hasError={telefoonInputHasError} errorMessage="Ongeldig telefoonnummer" name="telefoon" placeholder="Telefoonnummer" value={enteredTelefoon} ref={telefoonRef} onChange={telefoonChangeHandler} onBlur={telefoonBlurHandler} />
                        <InputDatum2 hasError={geboortedatumInputHasError} errorMessage="Geboortedatum is verplicht" placeholder="Geboortedatum" type="geboortedatum" value={enteredGeboortedatum} name="geboortedatum" wrapperClasses="order-5" />
                        <div className="text-xl font-bold lg:col-span-3">Aanvullende gegevens</div>
                        <Input hasError={rijbewijsInputHasError} errorMessage="Het rijbewijsnummer dient uit 10 cijfers te bestaan" name="rijbewijsnummer" placeholder="Rijbewijsnummer" value={enteredRijbewijs} ref={rijbewijsRef} onChange={rijbewijsChangeHandler} onBlur={rijbewijsBlurHandler} />
                        <Input hasError={ibanInputHasError} errorMessage="IBAN dient uit 18 karakters te bestaan" name="iban" placeholder="IBAN" value={enteredIban} ref={ibanRef} onChange={ibanChangeHandler} onBlur={ibanBlurHandler} />
                        <div className="lg:col-span-3">
                            <PrevNext disableNext={true}>
                                <Button className="btn-primary" disabled={!formIsValid}>Versturen</Button>
                            </PrevNext>
                        </div>
                    </div>
                </form>
            </Container>
        </>
    )
}

export default StapContact;

