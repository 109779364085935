import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Input from "components/ui/Input/Input";
import Button from "components/ui/Button/Button";
import Container from "components/hoc/Container";
import Content from "components/Content/Content";
import InputKvk from "components/ui/InputKvk/InputKvk";
import { uiActions } from "store/ui-slice";
import image from "components/0x0-Model3_20.jpg";
import Toggle from "components/ui/Toggle/Toggle";
import InputRadio from "components/ui/InputRadio/InputRadio";

const Introductie = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formIsValid, setFormIsValid] = useState(false);
    const kvkRef = useRef();
    const [demo, setDemo] = useState(false);
    const [demoTouched, setDemoTouched] = useState(false);
    
    const { kvkData } = useSelector(state => state.dossier);
    const kvkIsValid = kvkData.bedrijfsnaam.length>1;

    const [email, setEmail] = useState("");
    const [emailTouched, setEmailTouched] = useState(false);
    const emailValid = emailTouched && /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,15})+$/.test(email);

    const [phone, setPhone] = useState("");
    const [phoneTouched, setPhoneTouched] = useState(false);
    const phoneValid = phoneTouched && !/[^\d\-\+\(\)]/.test(phone) && phone.length>8;

    const [firstName, setFirstName] = useState("");
    const [firstNameTouched, setFirstNameTouched] = useState(false);
    const firstNameValid = firstNameTouched && firstName.length > 1;

    const [lastName, setLastName] = useState("");
    const [lastNameTouched, setLastNameTouched] = useState(false);
    const lastNameValid = lastNameTouched && firstName.length > 1;

    const [contact, setContact] = useState("");
    const [contactTouched, setContactTouched] = useState(false);
    const contactValid = contactTouched && contact.length > 1;

    useEffect(() => {
        (emailValid && phoneValid && lastNameValid && kvkIsValid && demoTouched) ? setFormIsValid(true) : setFormIsValid(false);
    },[emailValid,phoneValid,lastNameValid,kvkIsValid,demoTouched]);  

    const submitHandler = (evt) => {
        evt.preventDefault();
        dispatch(uiActions.showBackdrop());
  
        var demoBoolean = !!+demo;

        const body = {
            "subject": "Demo Request",
            "companyname": kvkData.bedrijfsnaam,
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "phonenumber": phone,
            "demo": demoBoolean
        }

        var formState = {
            firstName,
            lastName,
            email,
            phone
        }

        fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/RequestDemo', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
        .then(resp => resp.json())
        .then((data) => {
            if(data.status!=="Success") throw new Error();
            dispatch(uiActions.hideBackdrop());
            navigate("bedankpagina", { state: formState });
        })
        .catch(err => {
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({       
                type: "danger",
                message: "Uw aanmelding kan niet verstuurd worden. Probeer opnieuw."
            }));
        });
    }

    const changeDemoHandler = (evt) => {
        var checked = evt.target?.value;
        setDemo(checked);
        setDemoTouched(true);
    }

    return (
        <>
        <div className="container mx-auto px-5 h-full my-5">
        <div className="flex flex-row h-full items-center justify-center md:justify-start">
        <div className="fixed top-0 left-0 w-full h-full bg-no-repeat bg-center lg:bg-left-top lg:bg-cover opacity -z-10 scale-x-flip xscale-y-flip" style={{backgroundImage:`url(${image})`}}></div>
        <Container>
        <form onSubmit={submitHandler} className="border-slate-100 border justify-start gap-y-5 flex flex-col max-w-2xl p-10 shadow-xl rounded-xl bg-white-transparent">
        <h1 className="text-2xl mb-0 flex flex-row justify-start items-center whitespace-nowrap">
                <span className="fa fa-user-group pr-4"></span>
                <span>Autobedrijf aanmelden</span>
            </h1>
            <div className="">
                Interesse? Meld uw bedrijf aan!
            </div>
            <div className="flex flex-col gap-y-3">
                <InputKvk ref={kvkRef} />
                <Input value={firstName} onChange={evt => setFirstName(evt.target.value)} onBlur={() => setFirstNameTouched(true)} placeholder="Voornaam" />
                <Input value={lastName} onChange={evt => setLastName(evt.target.value)} onBlur={() => setLastNameTouched(true)} placeholder="Achternaam" />
                <Input value={email} onChange={evt => setEmail(evt.target.value)} onBlur={() => setEmailTouched(true)} placeholder="E-mailadres" />
                <Input value={phone} onChange={evt => setPhone(evt.target.value)} onKeyUp={() => setPhoneTouched(true)} placeholder="Telefoonnummer" />

                <div className="px-3">
                    <InputRadio onChange={changeDemoHandler} options={[{label: "Ja", value: "1"},{label: "Nee", value: "0"}]} name="demo" label="Wilt u een demo van het systeem?" />
                </div>

                <div className="mt-5">
                    <Button disabled={!formIsValid}>Aanmelding versturen</Button>
                </div> 
            </div>
        </form>
        </Container>
        </div>
        </div>
        </>
    )
}

export default Introductie;