import Container from "components/hoc/Container";
import Content from "components/Content/Content";
import ProfielForm from "components/Forms/ProfielForm/ProfielForm";

const PageProfiel = () => {
    return (
        <>
            <Container type="fullscreen">
                <Content title="Profiel" />
            </Container>
            <Container>
                <ProfielForm />
            </Container>
        </>
    )
}


export default PageProfiel; 