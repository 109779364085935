import { useState, useEffect } from "react";
import { consoleLog } from "lib/helpers";
const useInputTransition = (value, onFocus, onBlur, onKeyUp) => {
    const initialTransitionState = "translate-y-2.5 text-xl opacity-0 -z-10";
    const [isFocussed, setIsFocussed] = useState(false);
    const [transitionClasses, setTransitionClasses] = useState(initialTransitionState);

    useEffect(() => {
        consoleLog("[useInputTransition] inputValue changed!");
        // if(inputValue.length) setTransitionClasses("translate-y-2.5 text-xs");
        if(value) setTransitionClasses("text-xs");
        if(!value) setTransitionClasses(initialTransitionState);

        // setTransitionClasses("xtranslate-y-2.5 text-xs");
    },[value]);
 
    const focusHandler = (fn) => {
        if(typeof onFocus === "function") onFocus(fn);
        setTransitionClasses("text-xs");
        setIsFocussed(true);
    }

    const blurHandler = (fn) => {
        // setTransitionClasses("translate-y-2.5 text-x opacity-1 z-10");
        // if(value) setTransitionClasses("text-xs opacity-0 z-10");
        setIsFocussed(false);     
        if(typeof onBlur === "function") onBlur(fn);
    }

    const keyUpHandler = (fn) => {
        if(typeof onKeyUp === "function") onKeyUp(fn);     
    }

    return {
        isFocussed,
        transitionClasses,
        focusHandler,
        blurHandler,
        keyUpHandler
    }
}

export default useInputTransition;
