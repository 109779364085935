import React, { useState } from "react";
import { TwitterPicker } from "react-color";
import Input from "../Input/Input";

const InputColor = React.forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const [inputClasses, setInputClasses] = useState();
  const [color, setColor] = useState(props.value);

  const changeHandler = (clr) => {
    setColor(clr.hex)
    if(typeof props.onChange === "function") {
      props.onChange(null, {name: props.name, value: clr.hex});
    }
    setIsVisible(false);
  };

  // const acceptHandler = () => setIsVisible(false);
  const cancelHandler = () => setIsVisible(false);
  const focusHandler = () => setIsVisible(true);


  return (
    <div className="relative">
      <Input ref={ref} backgroundColor={color} placeholder={props.placeholder ? props.placeholder : ''} onChange={changeHandler} name={props.name} value={color} onFocus={focusHandler} />
      {isVisible && (
        <div className="absolute bottom-full left-0 z-20">
          <TwitterPicker colors={['red', 'orange', 'yellow', 'green', '#0000FF', '#ADD8E6', 'purple', '#fff', '#aaa', '#000']} color={color} data-onSwatchHover={changeHandler} onChange={changeHandler} />
        </div>
      )}
    </div>
  );
});

export default InputColor;
