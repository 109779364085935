import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    phaseRecords: [],
    totalRecords: {}
};

export const counterSlice = createSlice({
    name: "counter",
    initialState: initialState,
    reducers: {
        count: (state, actions) => {
            state.phaseRecords = actions.payload.phaseRecords;
            state.totalRecords = actions.payload.totalRecords;
        }
    }

});

export const counterActions = counterSlice.actions;