import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { progressActions } from "store/progress-slice";
import Content from "components/Content/Content";
import Container from "components/hoc/Container";
import Actieblokken from "components/Actieblokken/Actieblokken";
import useHead from "hooks/use-head";
import { resetDossier } from "store/dossier-actions";
// import Button from "components/ui/Button/Button";
const Home = props => {
    useHead({title: "Financial Lease aanvraag"});
    const dispatch = useDispatch();
    const recalculation = useSelector(state => state.dossier.recalculation);

    useEffect(() => {
        dispatch(progressActions.changeCase('FinancialLease'));
        if(!recalculation) {
            dispatch(resetDossier());
        }
    },[recalculation]);

    return (
        <>
            <Container type="fullscreen">
                <Content title="Financial Lease" />
            </Container>
            <Actieblokken />
            {/* <div className="">
                <Button className={`flex flex-row gap-x-3 py-2 mx-auto mb-10`} onClick={() => window.location.reload()}>
                    <span>Versie {process.env.REACT_APP_VERSION}</span>
                    <span className="fa fa-arrows-rotate cursor-pointer"></span>
                </Button>
            </div> */}
        </>
    )
}

export default Home;