import { createSlice } from "@reduxjs/toolkit";

export const occSlice = createSlice({
    name: 'occ',
    initialState: {
        makes: [],
        models: [],
        unique_dealers: [],
        import_id: "",
        inventory: [],
        num_inventory: ""
    },
    reducers: {
        setMakes(state, actions) {
            state.makes = actions.payload;
        },
        setModels(state, actions) {
            state.models = actions.payload;
        },
        setUniqueDealers(state, actions) {
            state.unique_dealers = actions.payload;
        },
        setCarsByDealer(state, actions) {
            state.inventory = actions.payload;
            state.num_inventory = actions.payload.length;
        }
    }
});

export const occActions = occSlice.actions;