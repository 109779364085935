import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { createPortal } from 'react-dom';

import Input from "components/ui/Input/Input";
import { uiActions } from "store/ui-slice";
import useAuth from "hooks/use-auth";
import Button from "components/ui/Button/Button";
import Modal from "components/hoc/Modal";
import Select from "components/ui/Select/Select";
import TextArea from "components/ui/TextArea/TextArea";

const NewActivityForm = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [activityType, setActivityType] = useState("");

    const [subject, setSubject] = useState("");
     
    const dispatch = useDispatch();
    const params = useParams();

    const nameRef = useRef();
    const descriptionRef = useRef();
    const offerRef = useRef();
    const documentURLRef = useRef();
    const typeRef = useRef();

    const { token: jwt } = useAuth();

    useEffect(() => {
        if(typeof props.id === "number") setShowModal(true);
    },[props.id]);

    const submitHandler = (evt) => {
        evt.preventDefault();
        dispatch(uiActions.showBackdrop());
        const formData = new FormData();
        formData.append("createAt", new Date());
        formData.append("name", nameRef.current.value);
        formData.append("description", descriptionRef.current.value);
        formData.append("offer", "0");
        formData.append("documentURLForm", "");
        formData.append("dealID", params.dealID);
        formData.append("type", typeRef.current.value);

        fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/ActivitiesDeal', {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + jwt
            },
            body: formData
        })
            .then(resp => {
                if (resp.status === 400) throw new Error();
                dispatch(uiActions.hideBackdrop());
                dispatch(uiActions.setAlert({ message: "Activiteit succesvol aangemaakt", type: "success" }));
                setShowModal(false);
                props.onUpdate();
            })
            .catch(() => {
                dispatch(uiActions.setAlert({ message: "Kan activiteit niet aanmaken", type: "danger" }));
            }); 
            setActivityType("");
    }

    const dealerUpdateHandler = () => {
        setActivityType("Dealer update");
        setSubject(`Statusupdate van uw aanvraag ${props.dealID}, ${props.companyName}`);
        setShowModal(true);
    }
    
    const closeModalHandler = () => {
        setActivityType("");
        setShowModal(false)
    }

    const createActivityHandler = () => {
        setActivityType("");
        setSubject("");
        setShowModal(true)
    }

    const ModalComponent = <Modal title="Nieuwe activiteit aanmaken" show={showModal} onClose={closeModalHandler}>
    <form onSubmit={submitHandler} className="flex flex-col gap-3">
        {activityType!=="Dealer update" && <Select ref={typeRef} name="type" set="activityType" placeholder="Type" label="Type" />}

        <Input ref={nameRef} name="name" placeholder="Onderwerp" value={subject} onChange={evt => setSubject(evt.target.value)} />
        {activityType==="Dealer update" &&
        <input ref={typeRef} readOnly type="hidden" value="Dealer update" />
        }

        <TextArea ref={descriptionRef} name="description" placeholder="Omschrijving" />
        <span className="col-span-2">
            <Button data-modal-toggle="defaultModal" className="btn-secondary">Activiteit aanmaken</Button>
        </span>
    </form>
</Modal>;



    return (
        <>
        {!props.id && 
        (
        <div className="flex flex-row gap-x-5">
        <Button type="button" className="btn-secondary" onClick={createActivityHandler}>Nieuwe activiteit</Button>
        {props.source!=="Website-aanvraag" && <Button type="button" className="btn-secondary" onClick={dealerUpdateHandler}>Dealer update</Button>}
        </div>  
        )
        }
        {createPortal(ModalComponent, document.body)}
        </>
    )
}

export default NewActivityForm;