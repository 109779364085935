import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "store/ui-slice";

const Alert = () => {
    const dispatch = useDispatch();
    const alert = useSelector(state => state.ui.alert);
    const [transitionClasses, setTransitionClasses] = useState('-right-60');
    var { message, visible, type, permanent, time } = alert;

    useEffect(() => {
        if(permanent) return;
        if(!time) time = 2000;
        setTransitionClasses('right-10');
        const timer = setTimeout(function() {
            dispatch(uiActions.hideAlert());
            setTransitionClasses('-right-full');
        }, time);
        return () => clearTimeout(timer);
    }, [visible, permanent, time, dispatch]);
    
    const classes = [];
    if(type==="success") classes.push("border-2 bg-[#d4edda] border-[#c3e6cb] color-[#155724]");
    if(type==="danger") classes.push("border-2 bg-[#f8d7da] border-[#f5c6cb] color-[#721c24]");
    if(type==="warning") classes.push("border-2 bg-[#fff3cd] border-[#ffeeba] color-[#856404]");
    if(type==="info") classes.push("border-2 bg-[#d1ecf1] border-[#bee5eb] color-[#0c5460]");
    if(!type) classes.push("border-2 bg-[#e2e3e5] border-[#d6d8db] color-[#383d41]");
    return (
        <>
        {(message && !permanent) &&
        <div className={classes.join("")+" z-50 fixed top-5 px-5 py-2 transition-all "+transitionClasses}>{message}</div>
        }
        </>
    )
}

export default Alert;