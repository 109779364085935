import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "components/hoc/Container";
import Content from "components/Content/Content";
import useHead from "hooks/use-head";
import useInput from "hooks/use-input";
import InputKvk from "components/ui/InputKvk/InputKvk";
import Input from "components/ui/Input/Input";
import Select from "components/ui/Select/Select";
import InputRange from "components/ui/InputRange/InputRange";
import useAuth from "hooks/use-auth";
import { noDotAndInteger } from "lib/helpers";
import { saveDeal } from "store/deal-actions";
import Button from "components/ui/Button/Button";
import useTutorial from "hooks/use-tutorial";
import Uitleg from "components/Tutorial/Uitleg";
import useCurrentCase from "hooks/use-current-case";
import { dossierActions } from "store/dossier-slice";
import { resetDossier } from "store/dossier-actions";
import { escapeSpecialChars } from "lib/voertuig";

const BtwFinanciering = () => {
    useCurrentCase();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showTutorial } = useTutorial();

    useHead({title: "BTW-financiering"});

    const customer_company = useSelector(state => state.dossier.kvkData);
    const { nextPath }  = useSelector(state => state.progress);
    const { keep_contactinfo } = useSelector(state => state.dossier);
    useEffect(() => {
        if(!keep_contactinfo) {
            dispatch(dossierActions.reset());
            dispatch(resetDossier());
        }
    },[keep_contactinfo]);
    const { userInfo, token } = useAuth();

    const bedrijfsnaamRef = useRef();
    const [enteredBedrijfsnaam, setEnteredBedrijfsnaam] = useState("");
    const { bedrijfsnaam } = customer_company;

    const bedrijfsnaamIsValid = bedrijfsnaam?.length > 2;

    const { valueChangeHandler } = useInput();

    const [formIsValid, setFormIsValid] = useState(false);
    
    const [enteredFinancieringsbedrag, setEnteredFinancieringsbedrag] = useState('0');
    const financieringIsValid = enteredFinancieringsbedrag > 0;

    const [enteredVoornaam, setEnteredVoornaam] = useState("");   
    const [voornaamTouched, setVoornaamTouched] = useState(false);
    const voornaamIsValid = voornaamTouched && enteredVoornaam.length > 1;

    const [enteredAchternaam, setEnteredAchternaam] = useState("");
    const [achternaamTouched, setAchternaamTouched] = useState(false);
    const achternaamIsValid = achternaamTouched && enteredAchternaam.length > 1;

    const [enteredAanhef, setEnteredAanhef] = useState("");
    const aanhefIsValid = /(Dhr|Mevr)/i.test(enteredAanhef);

    const [enteredEmail, setEnteredEmail] = useState("");
    const [emailTouched, setEmailTouched] = useState(false);
    const emailIsValid = emailTouched && /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,15})+$/.test(enteredEmail);

    const [enteredTelefoon, setEnteredTelefoon] = useState("");
    const [telefoonTouched, setTelefoonTouched] = useState(false);
    const telefoonIsValid = telefoonTouched && !/[^\d\-\+\(\)]/.test(enteredTelefoon) && enteredTelefoon.length>8;

    useEffect(() => {
        (bedrijfsnaamIsValid&&financieringIsValid&&voornaamIsValid&&achternaamIsValid&&aanhefIsValid&&emailIsValid&&telefoonIsValid) ? setFormIsValid(true) : setFormIsValid(false);
    }, [bedrijfsnaamIsValid,financieringIsValid,voornaamIsValid,achternaamIsValid,aanhefIsValid,emailIsValid,telefoonIsValid]);

    const { dealerID: dealerID_from_dealer } = useSelector(state => state.dealer.dealer);
    var dealerID = (userInfo.dealerID ? userInfo.dealerID : dealerID_from_dealer);

    const submitDealHandler = (evt) => {
        evt.preventDefault();
        var address = (customer_company.straat ? customer_company.straat : '') + " " + (customer_company.huisnummer ? customer_company.huisnummer : '') + " " +(customer_company.huisnummertoevoeging ? customer_company.huisnummertoevoeging : '');
        var voertuig = {};

        var dealData = {
            "source": "Dealer-aanvraag",
            "dealerID": dealerID,
            "provisiePercentage": 0,
            "tradeInAmount": 0,
            "repayment": 0,
            "leaseAmount": enteredFinancieringsbedrag,
            "typeOfDeal": "BTW",   
            "amountpurchase": 0,
            "commissionAmountDealer": 50,
            "totalToPay": 0,
            "downpayment": 0,
            "monthlyamount": 0,
            "vat": 0,   
            "description": 0,
            "duration": 0,
            "administrationCost": 0, // (berekening.bemiddelingskosten ? noDotAndInteger(berekening.bemiddelingskosten): 0),
            "commissionAmountSLD": 50, 
            "amountpurchaseExclVAT": 0,  
            "damageFreeYearsInsurance": "",
            "damageHistoryInsurance": "",
            "chosenBank": "",
            "finalpayment": 0,
            "interest": 0,
            "phase": "Uw aanvraag is ontvangen",
            "typeOfInsurance": "",
            "saleType": "BtwFinanciering", // +currentCase,
            "customerDetail": {
                "companyName": escapeSpecialChars(customer_company.bedrijfsnaam),
                "email": enteredEmail,
                "salutation": enteredAanhef,
                "firstName": enteredVoornaam,
                "lastName": enteredAchternaam,
                "dateofBirth": new Date().toISOString(), // customer.geboortedatum
                "driverLicenseNumber": 0,
                "phone": enteredTelefoon,
                "iban": 0,
                "addition": "",
                "type": "Indivisul",
                "address": address,
                "chamberNumber": customer_company.dossiernummer,
                "dateofEsteb": new Date().toISOString(),
                "postalCode": customer_company.postcode.replace(/\s/,"").toUpperCase(),
                "place": customer_company.plaats,
                "age": customer_company.bedrijf_leeftijd
            },
            "vehicleDetail": {
              "make": "",
              "model": "",
              "marginVAT": 0, // marginVAT
              "mileage": noDotAndInteger(voertuig.kmstand),
              "vehicleType": "",
              "firstRegistrationDate": new Date().toISOString(),
              "age": noDotAndInteger(voertuig.voertuigleeftijd),
              "engineCapacity": voertuig.cilinder_inhoud,
              "fuelType": voertuig.brandstof,
              "grossBPM": voertuig.bruto_bpm,
              "licensePlate": voertuig.kenteken,
              "listPrice": noDotAndInteger(voertuig.catalogusprijs),
              "positionJudgement": "",
              "restBPM": ""+voertuig.rest_bpm,
              "carrosserie": ""
            }
          }

        //   navigate("/financiering/btw-financiering/bedankpagina");
        //   return;

          dispatch(saveDeal(dealData, token)).then(resp => {
            if(resp) navigate("/financiering/btw-financiering/bedankpagina");
          });
    }

    return (
        <>
            <Container type="fullscreen">
                <Content title="BTW financiering" description="" />
            </Container>
            <Container>
                <form onSubmit={submitDealHandler}>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mb-5">
                    <div className="text-xl font-bold lg:col-span-3">Grootte van de financiering</div>
                    <InputRange prefix={"\u20AC "} thousandNotation={true} label="Grootte van de financiering" value={enteredFinancieringsbedrag} min="0" step="1000" max="25000" name="financieringsbedrag" onChange={(evt) => setEnteredFinancieringsbedrag(evt.target.value)} />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mb-5">
                        {showTutorial && <Uitleg hideNextButton={true} arrowPosition="left" title="Contactgegevens" description="De bedrijfsgegevens worden via de KvK verkregen door te zoeken op bedrijfsnaam." />}
                        <div className="text-xl font-bold lg:col-span-3">Bedrijfsnaam</div>
                        <InputKvk onChange={valueChangeHandler} className="col-start-1 col-span-2" value={enteredBedrijfsnaam} ref={bedrijfsnaamRef} />
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
                        <div className="text-xl font-bold lg:col-span-3">Persoonsgegevens</div>

                        <Select set="aanhef" label="Aanhef" view="horizontal" value={enteredAanhef} onChange={evt=> setEnteredAanhef(evt.target.value)} name="aanhef" />

                        <Input errorMessage="" name="voornaam" placeholder="Voornaam" value={enteredVoornaam} onChange={evt => setEnteredVoornaam(evt.target.value)} onBlur={() => setVoornaamTouched(true)} />
                        <Input errorMessage="" name="achternaam" placeholder="Achternaam" value={enteredAchternaam} onChange={evt => setEnteredAchternaam(evt.target.value)} onBlur={() => setAchternaamTouched(true)} />

                        <Input errorMessage="" name="email" placeholder="E-mailadres" value={enteredEmail} onChange={evt => setEnteredEmail(evt.target.value)} onBlur={() => setEmailTouched(true)} />
                        <Input errorMessage="" name="telefoon" placeholder="Telefoonnummer" value={enteredTelefoon} onChange={evt => setEnteredTelefoon(evt.target.value)} onBlur={() => setTelefoonTouched(true)} />
                    </div>
                    <div className="flex flex-row justify-end mb-10">
                        <Button className="btn-primary" disabled={!formIsValid}>Versturen</Button>
                    </div>
                </form>
            </Container>
            </>
    )
}

export default BtwFinanciering;