import { useState, useEffect } from "react";
import useAuth from "./use-auth";
import { sortDataByKey } from "lib/voertuig";

const useDealers = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [dealers, setDealers] = useState({});
    const { token: jwt, role } = useAuth();

    useEffect(() => {
        if(role.includes("Admin")) {
                fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/Dealer?pageSize=9999', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },        
            })
            .then(resp => resp.json())
            .then(({data:responseData}) => {
                var transformedData = responseData.map(item => ({label: item.name+' ('+item.importID+')', id: item.importID, value: item.importID, dealerID: item.dealerID}));
                var sortedData = sortDataByKey(transformedData, 'label');
                setDealers(sortedData);
                setIsLoaded(true);
            })
            .catch(err => console.log(err));
        }
    },[role, jwt]);

    return {
        isLoaded: isLoaded,
        data: dealers
    };
}

export default useDealers;