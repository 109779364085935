import { useState, useEffect } from "react";
import { formatThousandNotation, intTo2DecStr, formattedPMT_v2 } from "lib/calc";

const ActivitySummary = (props) => {
    const { autoAssignedType, activity, view } = props;
    const [activityData, setActivityData] = useState(activity.description);
    const [inlossingsDetails, setInlossingsDetails] = useState(false);

    useEffect(() => {
        if(!activityData) return;
        if(autoAssignedType) {
            var activityJson = JSON.parse(activity.description);
            setActivityData(activityJson);
            if("inlossing_details" in activityJson && activityJson.inlossing_details) {
                var inlossing = JSON.parse(activityJson.inlossing_details) || "";
                setInlossingsDetails(inlossing);
            }

        }
    },[]);

    if(!autoAssignedType) return <div>{activity.description}</div>;

    return (
<div className={`grid lg:grid-cols-4 grid-cols-2 ${view==='rows' ? 'text-xs leading-4 gap-x-1' : ''}`}>
    <div>Dealer provisie</div><div>&euro; {intTo2DecStr(activityData.commissionAmountDealer)}</div>
    <div>SLD provisie</div><div>&euro; {intTo2DecStr(activityData.commissionAmountSLD)}</div> 
    <div>Aanschaf Incl.</div><div>&euro; {formatThousandNotation(activityData.amountpurchase)}</div>
    <div>BTW</div><div>&euro; {formatThousandNotation(activityData.vat)}</div>
    <div>Aanschaf Excl.</div><div>&euro; {formatThousandNotation(activityData.amountpurchaseExclVAT)}</div>
    <div>Aanbetaling</div><div>&euro; {formatThousandNotation(activityData.downpayment)}</div>
    <div>Inruil</div><div>&euro; {formatThousandNotation(activityData.tradeInAmount)}</div>
    <div>Te financieren</div><div>&euro; {formatThousandNotation(activityData.leaseAmount)}</div>
    <div>Slottermijn</div><div>&euro; {formatThousandNotation(activityData.finalpayment)}</div>
    <div>Maandbedrag</div><div>&euro; {formattedPMT_v2(activityData.monthlyamount)}</div>
    <div>Looptijd</div><div>{activityData.duration} maanden</div>
    <div>Rente</div><div>{activityData.interest}%</div>
    <div>Handelingsfee</div><div>&euro; {activityData.administrationCost===0 ? "0": intTo2DecStr(activityData.administrationCost)}</div>
    {inlossingsDetails && (
        <>
        <div className="col-span-4 text-lg my-3">Klant heeft lopend contract</div>
        <div>Kenteken</div><div>{inlossingsDetails.kenteken || ""}</div>
        <div>Contractnummer</div><div>{inlossingsDetails.contractnummer || ""}</div>
        <div>Bank</div><div>{inlossingsDetails.bank || ""}</div>
        <div>Inlossing openstaand saldo</div><div>&euro; {inlossingsDetails.saldo || ""}</div>
        </>
        )
    }
</div>
    )

}

export default ActivitySummary;