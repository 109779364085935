import React from "react";

const Toggle = React.forwardRef((props, ref) => {
    return (
        <label className="relative inline-flex items-center cursor-pointer">
            <input onChange={props.onChange} ref={ref} name={props.name} type="checkbox" className="sr-only peer" defaultChecked={props.checked} />
            <div className="w-11 h-6 bg-[#FF0000] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#008002]"></div>
            <span className="ml-3 font-medium text-gray-900">{props.label}</span>
        </label>
    )
});

export default Toggle;