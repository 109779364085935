import GebruikerForm from "components/Forms/GebruikerForm/GebruikerForm";

const GebruikerDetail = () => {
  return (
    <>
      <GebruikerForm />
    </>
  );
};

export default GebruikerDetail;
