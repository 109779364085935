import Container from "components/hoc/Container";
import Content from "components/Content/Content";
import useHead from "hooks/use-head";
import TextArea from "components/ui/TextArea/TextArea";
import Input from "components/ui/Input/Input";
import Button from "components/ui/Button/Button";
import { PLAATS, POSTCODE, STRAAT } from "lib/adres";

const ContactPage = () => {
    useHead({title: "Contact"});
    
    return (
        <>
            <Container type="fullscreen">
                <Content title="Contact" />
            </Container>
            <Container>
            <div className="grid justify-center mb-20">
                <div className="grid grid-cols-2 gap-10">
                    <div className="col-span-2 text-xl font-bold mb-5 text-center">Contactgegevens</div>

                    <div className="flex flex-col">
                        <strong>Bezoekadres</strong>
                        <div>{STRAAT}</div>
                        <div>{POSTCODE} {PLAATS}</div>
                    </div>
                    <div>
                        <strong className="mt-5">Openingstijden</strong>
                        <div>maandag - vrijdag</div>
                        <div>9.00u - 17.30u</div>
                    </div>
                    <div>
                        <strong className="mt-5">Telefoon</strong>
                        <div>0180 23 41 41</div>
                    </div>
                    <div>
                        <strong className="mt-5">E-mailadres</strong>
                        <div>info@sld.nl</div>
                    </div>
                    <div>
                        <strong className="mt-5">KvK</strong>
                        <div>76254348</div>
                    </div>
                    <div>
                        <strong>BTW</strong>
                        <div>NL860562931B01</div>
                    </div>
                </div>
                {1 > 2 &&
                <div>
                    <div className="text-xl font-bold mb-5">Contactformulier</div>
                    <form>
                        <div className="grid grid-cols-2 gap-3">
                            <div className="flex flex-col gap-y-3">
                                <Input placeholder="Naam" />
                                <Input placeholder="Telefoonnummer" />
                                <Input placeholder="E-mailadres" />
                            </div>
                            <div className="flex flex-col">
                                <TextArea placeholder="Uw vraag..."></TextArea>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="mt-5">
                                <Button>Verzenden</Button>
                            </div>
                        </div>


                    </form>
                </div>
                }
            </div>
            </Container>
            </>
    )
}

export default ContactPage;