import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useAuth from "hooks/use-auth";
import { dateToDutch } from "lib/kvk";
import { uiActions } from "store/ui-slice";
import ActivityForm from "components/Forms/ActivityForm/ActivityForm";
import Select from "components/ui/Select/Select";

const ActivityOverzicht = (props) => {
  const [activityType, setActivityType] = useState("Dealer update|Werkblok|Belblok|Mailing|Uitslag");
  const [showDetail, setShowDetail] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);

  const { dealID } = useParams();
  const { token: jwt } = useAuth();
  const dispatch = useDispatch();
  const [activities, setActivities] = useState([]);
  const { updated, relatedID } = props;

  const trClickHandler = (id) => {
    setShowDetail(id);
    setUpdateCounter(prevState => prevState+1);
  }

  useEffect(() => {
    dispatch(uiActions.showBackdrop());
    fetch(process.env.REACT_APP_SLD_API_BASE_URL + '/api/ActivitiesDeal/ActivitiesByRelatedId/'+relatedID+(activityType ? '?type='+activityType : ''), {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwt
      }
    })
    .then(resp => resp.json())
    .then((responseData) => {
      var sortedData = responseData.reverse();
      setActivities(sortedData); 
      dispatch(uiActions.hideBackdrop());
    })
    .catch(() => {
      dispatch(uiActions.hideBackdrop());
    })
  },[updated, relatedID, dealID, activityType]);


  return (
    <>
          <div className="mt-7 text-sm">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-2 xbg-sld-blue p-3 rounded-xl shadow-xl mb-3 items-center">
              <div className="md:col-span-2">
                <Select disableFirst={true} name="type" set="all_activityTypes" placeholder="Type" label="Type" value={activityType} onChange={evt => setActivityType(evt.target.value)}></Select>
               </div>
               
              <div className="hidden md:block xtext-white md:col-start-3">Onderwerp</div>
              <div className="hidden md:block xtext-white ">Beschrijving</div>
            </div>

            {activities?.length>0 && activities.map(activity => {
              const autoAssignedType = (/Deal\s(aangemaakt|bijgewerkt|gearchiveerd)/.test(activity.type));

              return (
                <div onClick={() => trClickHandler(activity.activityID)} key={activity.activityID} className={"grid grid-cols-2 md:grid-cols-4 items-center hover:bg-[#F6901E] hover:text-white px-5 py-3 shadow-md bg-white rounded-lg mb-5 cursor-pointer"+(activity.isArchive ? ' opacity-50' : '')}>
                  <div className="col-span-2">
                    <div className="flex flex-col">
                      <div className="flex flex-row">
                          <span className="opacity-40 mr-1">Type:</span>
                          <span className="">{activity.type}</span>
                      </div>
                      <div className="flex flex-row">
                          <span className="opacity-40 mr-1">Datum:</span>
                          <span className="">{dateToDutch(activity.createAt)} {new Date(activity.createAt).toLocaleTimeString()}</span>
                      </div>
                      <div className="flex flex-row">
                        <span className="opacity-40 mr-1">Door:</span>
                        <span className="">{activity.createdBy}</span>
                      </div>
                    </div>
                  </div>
                  <div className="">{activity.name}</div>
                  <div className="break-words">
                    {autoAssignedType && 'Bekijk eerdere berekening'}
                    {activity.type==="Dealer update" && 'Bekijk bericht'}
                    {(activity.type!=="Dealer update" && !autoAssignedType) && (
                      <span>
                      {activity.description.slice(0, 100)}
                      {activity.description.length>100 && '...'}
                      </span>
                    )}
                    
                  </div>
                </div>
              )
            })}

            {!activities.length && <p className="p-3">Er zijn nog geen activiteiten</p>}

        </div>

      {showDetail && <ActivityForm updated={updateCounter} onUpdate={props.onUpdate} id={showDetail} subid={!showDetail} />}

      {/* <Pagination page={page} prevPageHandler={prevPageHandler} nextPageHandler={nextPageHandler} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} /> */}
    </>
  );
};

export default ActivityOverzicht;
