const Container = (props) => {
  var classes = [];
  if(props.type==="fullscreen") classes.push("max-w-full bg-slate-100 mb-10");

  var classes = classes.join(" ");
  return (
    <div className={`container mx-auto px-5 ${props.className ? props.className : ""} ${classes ? classes : ""} ${props.zIndex ? 'z-'+props.zIndex : ''}`}>{props.children}</div> 
  );
};

export default Container;