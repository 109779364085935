import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import image from "components/0x0-Model3_20.jpg";
import { authActions } from "store/auth-slice";

const AuthMessage = (props) => {
    const dispatch = useDispatch();
    
    return (
        <div className="container mx-auto px-5 h-full my-5">
            <div className="fixed top-0 left-0 w-full h-full bg-no-repeat bg-center lg:bg-left-top lg:bg-cover opacity -z-10 scale-x-flip xscale-y-flip" style={{ backgroundImage: `url(${image})` }}></div>

            <div className="flex flex-row h-full items-center justify-center md:justify-start">
                <div
                    className="border-slate-100 border justify-start gap-y-5 flex flex-col max-w-md p-10 shadow-xl rounded-xl bg-white-transparent"
                >
                    <h1 className="text-2xl mb-0 flex flex-row justify-start items-center wxhitespace-nowrap">
                        <span className="fa fa-lock pr-4"></span>
                        <span>{props.title}</span>
                    </h1>

                    <div className="flex flex-col gap-y-5">
                        <div>
                        {props.content.map(item => {
                            return <React.Fragment key={Math.random()}>{item}</React.Fragment>;
                        })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthMessage;