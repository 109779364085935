import { createAsyncThunk } from "@reduxjs/toolkit";

import { authActions } from "./auth-slice";
import { uiActions } from "./ui-slice";
import { sendRequest } from "lib/calc";
import { consoleLog, removeStorageItem, setError } from "lib/helpers";
import { dossierActions } from "./dossier-slice";
import { dealerActions } from "./dealer-slice";

export const registerRole = createAsyncThunk(
    'auth/register-role',
    async(dataObj, { dispatch, rejectWithValue}) => {
        const { role, token: jwt } = dataObj;
        dispatch(uiActions.showBackdrop());
        try {
            await sendRequest({url: process.env.REACT_APP_SLD_API_BASE_URL+"/api/Authenticate/register-role?role="+role, method: "POST", headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt }});
            dispatch(uiActions.hideBackdrop());
            dispatch(uiActions.setAlert({message: "Rol succesvol aangemaakt", type: "success"}));
        } catch (err) {
            dispatch(uiActions.setAlert({message: "Rol kan niet worden aangemaakt", type: "danger"}));
            dispatch(uiActions.hideBackdrop());
        }
    }
)

export const registerAdmin = createAsyncThunk(
    'auth/register-admin',
    async (dataObj, {dispatch, rejectWithValue }) => {
        const { userData, token: jwt } = dataObj;
        dispatch(uiActions.showBackdrop());
        try {
            const data = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Authenticate/register-admin', method: 'POST', body: userData, headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt } });
            dispatch(uiActions.setAlert({message: "Gebruiker succesvol aangemaakt", type: "success"}));
            // dispatch(authActions.register({ userData: userData }));
            dispatch(uiActions.hideBackdrop());
        } catch (err) {
            if (err.response && err.response.data.message) {
                alert("FAIL");
                dispatch(uiActions.setAlert({message: "Gebruiker kan niet worden aangemaakt", type: "danger"}));
                return rejectWithValue(err.response.data.message)
            } else {
                var title;
                var errorMessage = JSON.parse(err.message);
                var errorTitle = errorMessage.title;
                if(errorTitle==="Unauthorized") title = "Je combinatie van e-mailadres en wachtwoord is onjuist";
                dispatch(uiActions.setAlert({message: "Gebruiker kan niet worden aangemaakt", type: "danger"}));
                // dispatch(uiActions.setAlert({message: title, type: "danger"}));
                return rejectWithValue(err.message)
            }
        }
    }    
);

export const registerUser = (userData, jwt) => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            const data = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Authenticate/register', method: 'POST', body: userData, headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt } });
            consoleLog('data=', data);
            dispatch(authActions.register({ userData: data }));
            dispatch(uiActions.setAlert({message: "Gebruiker succesvol aangemaakt", type: "success"}));
            dispatch(uiActions.hideBackdrop());
        } catch (err) {
            var errorMessage = JSON.parse(err.message);
            // dispatch(uiActions.setNotification({ visible: true, title: errorMessage.title, message: errorMessage.message }));
            dispatch(uiActions.setAlert({message: "Gebruiker kan niet worden aangemaakt", type: "danger"}));
            dispatch(uiActions.hideBackdrop());
            setTimeout(function () {
                dispatch(uiActions.setNotification({ visible: false }));
            }, 2000);
        }

    }
}

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();
    const remainingDuration = adjExpirationTime - currentTime;
    return remainingDuration;
};

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem("token");
    const storedExpirationDate = localStorage.getItem("expiration");
    const remainingTime = calculateRemainingTime(storedExpirationDate);
    if(remainingTime <= 3600) {
        localStorage.removeItem("token");
        localStorage.removeItem("expiration");
        return null;
    }
    return {
        token: storedToken,
        duration: remainingTime
    };
}

export const userLogout = createAsyncThunk(
    'auth/logout',
    async (data, {dispatch}) => {
        try {
            sessionStorage.clear();
            localStorage.clear();
            dispatch(dossierActions.reset());
            dispatch(dealerActions.reset());
        } catch (error) {
            // throw new Error('Error');
            // consoleLog(JSON.stringify(error), true);
            // localStorage.setItem("error", JSON.stringify(error));
        }
    }
);

export const userLogin = createAsyncThunk(
    'auth/login',
    async (userData, {dispatch, rejectWithValue }) => {
      dispatch(uiActions.showBackdrop());
      try {
        const data = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Authenticate/loginv2', method: 'POST', body: userData, headers: { 'Content-Type': 'application/json'} });
        const { status, message, token, expiration } = data;
        if(status==="Error") throw new Error(message);
        if(status==="Succcess") {
            const userId = data.dealer?.userId || "";
            localStorage.setItem('token', token);
            localStorage.setItem('expiration', expiration);
            userId && localStorage.setItem('userId', ''+userId);
            const remainingTime = calculateRemainingTime(expiration);
            dispatch(uiActions.hideBackdrop());
            return data;
        }
      } catch (err) {
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({message: "Je combinatie van e-mailadres en wachtwoord is onjuist", type: "danger", permanent: true}));
        return rejectWithValue(err.message)
      }
    }
  )

  export const getUserInfo = (storedToken) => {
    return async (dispatch) => {
        dispatch(uiActions.showBackdrop());
        try {
            const responseData = await sendRequest({url: process.env.REACT_APP_SLD_API_BASE_URL+'/api/User/GetUserInfo', method: "POST", body: storedToken, headers: { 'Content-Type': 'application/json'}});
            dispatch(uiActions.hideBackdrop());
            dispatch(authActions.get_user_info(responseData));
            dispatch(dealerActions.setDealerData(responseData.dealer));
        } catch (err) {
            dispatch(uiActions.setAlert({message: "Sessie verlopen, je wordt uitgelogd. Log opnieuw in", type: "danger"}));
            if(err.message==="Token is not valid") dispatch(userLogout());
            dispatch(uiActions.hideBackdrop());
        }
    }
}

export const fetchDealers = (jwt) => {
    return async (dispatch) => {
        try {
            const dealers = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Dealer', headers: { 'Authorization': 'Bearer ' + jwt } });
            dispatch(authActions.set_dealers({ dealers: dealers }));
        } catch (err) {
            dispatch(uiActions.setAlert({message: "Kan lijst met autobedrijven niet laden", type: "danger"}));
            consoleLog(err, true);
        }
    }
};

export const fetchDealerById = (id, jwt) => {
    return async (dispatch) => {
        try {
            const dealerDetail = await sendRequest({ url: process.env.REACT_APP_SLD_API_BASE_URL + '/api/Dealer/'+id, headers: { 'Authorization': 'Bearer ' + jwt } });
            consoleLog(dealerDetail);
            // dispatch(authActions.set_dealers({dealers: dealers}));
        } catch (err) {
            dispatch(uiActions.setAlert({message: "Kan autobedrijf niet laden", type: "danger"}));
            consoleLog(err, true);
        }
    }
}
 
export const authLoginWithUserPwdCode = createAsyncThunk('auth/loginuserpwd', async (userData, {dispatch, rejectWithValue }) => {
    dispatch(uiActions.showBackdrop());
    try {
        const data = await sendRequest({url: process.env.REACT_APP_SLD_API_BASE_URL+"/api/Authenticate/loginWithPasswordv2", method: "POST", body: userData, headers: { "Content-Type": "application/json"}});
        const { token, expiration, status, message } = data;
        if(status==="Error") throw new Error(message);
        localStorage.setItem('token', token);
        localStorage.setItem('expiration', expiration);
        const userId = data.dealer?.userId || "";
        userId && localStorage.setItem('userId', ''+userId);

        dispatch(dealerActions.setDealerData(data.dealer));

        // const remainingTime = calculateRemainingTime(expiration);
        dispatch(uiActions.hideBackdrop());
        return data;
    } catch (err) {       
        dispatch(uiActions.hideBackdrop());
        dispatch(uiActions.setAlert({message: "De verificatiecode is onjuist", type: "danger", permanent: true}));
        return rejectWithValue(err.message);
    }
});