import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/use-auth";
import { getUserInfo } from "store/auth-actions";
import useHead from "hooks/use-head";

const ProtectedCrmRoute = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { role, userInfo, token, userIsLoggedIn } = useAuth();
    useHead({title: "SLD - CRM"});

    useEffect(() => {    
        if(!userInfo && token) {
            dispatch(getUserInfo(token));
        }
        if(!token) navigate("/");
        if(!role || !userInfo) return;
        if(!/(SuperAdmin|Admin)/.test(role)) navigate("/");
    },[userIsLoggedIn, role]);

    if(!/(SuperAdmin|Admin)/.test(role)) return <></>;
    return props.children;
}

export default ProtectedCrmRoute;