import { makes, models } from "dummy_data/makemodels";
import { sortDataByKey } from "lib/voertuig";
import { occActions } from "./occ-slice";
import { uiActions } from "./ui-slice";
import { mapToIdAndLabel } from "lib/helpers";

export const fetchMakeModels = (make, jwt) => {
    return async dispatch => {
        dispatch(uiActions.showBackdrop());
        if(typeof make==="object") make = "";
        fetch(process.env.REACT_APP_SLD_API_BASE_URL+`/api/ExternalVehicle/UniqueMakeModel${make && '?make='+make}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },        
        })
        .then(resp => resp.json())
        .then(({make: responseMakes, model: responseModels}) => {
            var transformedMakes = mapToIdAndLabel(responseMakes);
            var transformedModels = mapToIdAndLabel(responseModels);
            dispatch(occActions.setMakes(transformedMakes));
            dispatch(occActions.setModels(transformedModels));
            dispatch(uiActions.hideBackdrop());
        })
        .catch(() => {
            var dummyMakes = mapToIdAndLabel(makes);
            var dummyModels = mapToIdAndLabel(models);
            dispatch(occActions.setMakes(dummyMakes));
            dispatch(occActions.setModels(dummyModels));
            dispatch(uiActions.hideBackdrop());
        });
    };
}

export const fetchUniqueDealers = (jwt) => {
    return async (dispatch) => {
        var storageDealers = false;
        if(Storage && sessionStorage.getItem("unique_dealers")) {
            storageDealers = JSON.parse(sessionStorage.getItem("unique_dealers"));
            dispatch(occActions.setUniqueDealers(storageDealers));
            return;
        }
        if(!storageDealers) {
            fetch(process.env.REACT_APP_SLD_API_BASE_URL+'/api/Dealer?pageSize=9999', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                },        
            })
            .then(resp => resp.json())
            .then(responseData => {
                var options = responseData.data.map(option => {
                    return {
                        label: option.name+' ('+option.importID+')',
                        value: option.importID
                    }
                });
                var sortedDealers = sortDataByKey(options, 'label');
                dispatch(occActions.setUniqueDealers(sortedDealers));
            })
            .catch(err => console.log(err));
        }
    }
}