import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dossierActions } from "store/dossier-slice";
import useAuth from "hooks/use-auth";
import { fetchMakeModels } from "store/occ-actions";

const useMakeModel = (merkRef, modelRef, kenteken) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const { makes, models } = useSelector((state) => state.occ);

  useEffect(() => {
    if(!token) return;
    dispatch(fetchMakeModels("", token));
  }, [token, dispatch]);

  const merkChangeHandler = (storageMake=null, storageModel) => {
    if(kenteken) return;
    if(!storageModel) storageModel = "";
    var make = merkRef.current.value || storageMake;
    if(typeof make==="object") make = "";
    dispatch(fetchMakeModels(make, token));
    dispatch(dossierActions.setVoertuigDataManually({ merk: make, model: storageModel }));
  };

  const modelChangeHandler = () => {
    var model = modelRef.current.value;
    var voertuigsoort = "";
    var autocategorie = modelRef.current.selectedOptions[0].getAttribute('data-extra');
    if(/^(AUTO|BEDRIJF)$/.test(autocategorie)) {
      voertuigsoort = autocategorie.replace("AUTO","Personenauto").replace("BEDRIJF", "Bedrijfsauto");
    }
    dispatch(dossierActions.setVoertuigDataManually({
        model: model,
        voertuigsoort: voertuigsoort
      })
    );
  }

  return {
    makes, models, merkChangeHandler, modelChangeHandler
  };
};

export default useMakeModel;
