import { useDispatch, useSelector } from "react-redux";
import { useState, useRef } from "react";
import { dossierActions } from "store/dossier-slice";
import { localDate } from "lib/calc";

const useInput = (field, validateValue = () => true, additionalBlurFn, dontDispatch) => {
    const dispatch = useDispatch();
    const fieldRef = useRef();

    var enteredValue = useSelector(state => state.dossier.aanvullendegegevens[field]);
    if(field==="geboortedatum" && /00Z/.test(enteredValue)) enteredValue = localDate(enteredValue);
    const [isTouched, setIsTouched] = useState(false);
    const valueIsValid = validateValue(enteredValue);
    const hasError = !valueIsValid && isTouched;

    const valueChangeHandler = evt => {
        var value = evt.target.value;
        var fieldname = evt.target.name;
        if(dontDispatch) return;
        dispatch(dossierActions.setAdditionalFieldData({ [fieldname]: value }));
    }

    const inputBlurHandler = evt => {      
        if(evt.target.value.length) {
            setIsTouched(true);
        }
        if(typeof additionalBlurFn === "function") {
            dispatch(additionalBlurFn());
        }
    }

    return {
        ref: fieldRef,
        value: enteredValue,
        hasError,
        valueChangeHandler,
        inputBlurHandler,
        isValid: valueIsValid,
        isTouched
    }

}

export default useInput;