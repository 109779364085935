import Container from "components/hoc/Container";
import Content from "components/Content/Content";
import useHead from "hooks/use-head";

const OperationalLease = () => {
    useHead({title: "Operational Lease"});
    
    return (
        <>
            <Container type="fullscreen">
                <Content title="Operational Lease" description="In eu nisi non nunc fringilla tincidunt. Nulla elit dui, euismod a pharetra at, gravida non arcu. Morbi semper, ex et ultrices interdum, dui leo accumsan sapien, sit amet luctus dolor leo vel ligula. Cras eu odio ac est malesuada facilisis. Phasellus eget felis dolor. Mauris euismod ipsum in lectus viverra, et efficitur massa luctus. Cras mi odio, tincidunt id massa eu, venenatis eleifend dui. Aliquam erat volutpat." />
            </Container>
            </>
    )
}

export default OperationalLease;