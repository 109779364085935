import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "store/ui-slice";
import useAuth from "./use-auth";

const useCars = (obj) => {
    const { query } = obj;
    const dispatch = useDispatch();

    const { token: jwt } = useAuth();

    const [cars, setCars] = useState({
        data: []
    });

    var dealerId = useSelector(state => state.dealer.dealer.dealerID);

    useEffect(() => {
        if(!dealerId) return;
        dispatch(uiActions.showBackdrop());
        fetch(process.env.REACT_APP_SLD_API_BASE_URL+`/api/ExternalVehicle/GetByDealerId?dealerId=${dealerId}${query ? "&query="+query : ""}&PageSize=9999`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+ jwt
            }
        }).then(resp => resp.json())
        .then(({data: voertuigen}) => {
            setCars({data: voertuigen});
            dispatch(uiActions.hideBackdrop());

        }).catch(() => {
            dispatch(uiActions.setNotification({
                title: 'Voorraad auto\'s kunnen niet opgehaald worden',
                message: 'Uw voorraad kan niet opgehaald worden. Probeer opnieuw, of doe de aanvraag via kenteken, of kenteken (onbekend)',
                visible: true,
                buttons: [{ label: 'sluiten', action: 'cancel' }]
            }));
            dispatch(uiActions.hideBackdrop());
        });
    },[dealerId, query, dispatch]);

    return cars;
}

export default useCars;